import React, { useState, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../global-context';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';

const log = true;

////////// COMPONENT //////////
export default function DriverSelect(props) {
  const ctx = useContext(GlobalContext);

  const GET_TOOKAN_DRIVERS = async (api_key) => axios.post(`https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/v2/get_available_agents`, { api_key: api_key });

  const [tookan, setTookan] = useState({ loading: true, drivers: [] });

  const getTookanDriverData = async () => {
    // log && console.log(`Getting data from tookan...`);
    let drivers = [];

    // Tookan DRIVER data
    await GET_TOOKAN_DRIVERS(ctx.userProfile[`https://api_keys.io/jwt/claims`][`TookanKey`])
      .then(res => {
        // log && console.log(`TOOKAN Drivers Response:`, res);
        if (res.status === 200 && res.data.status === 200 && res.data.data.length > 0) {
          let tookanDrivers = res.data.data;
          tookanDrivers = tookanDrivers.sort((a, b) => {
            if (a.fleet_id < b.fleet_id) return -1;
            else if (a.fleet_id > b.fleet_id) return 1;
            else return 0;
          });
          if (tookanDrivers.length > 0) drivers = tookanDrivers;
        }
      })
      .catch(err => {
        console.log(`Failed to retrieve Tookan drivers:`, err);
      });

    // log && console.log(`Return Data from Tookan:`, drivers);
    setTookan({ loading: false, drivers: drivers });
  }

  if (tookan.loading) {
    getTookanDriverData();
    return `Loading...`
  }
  else return (<>
    <TextField
      fullWidth
      required={props.required || false}
      select
      name="driver"
      label={props.label || `Driver`}
      placeholder={props.placeholder || `Select a driver...`}
      variant="outlined"
      margin="dense"
      value={props.value}
      onChange={props.onChange}
      style={props.style || { margin: 0 }}
      InputProps={{
        startAdornment: (
          <InputAdornment style={{ verticalAlign: "top" }} position="start">
            <Icon color="disabled" fontSize="small">person</Icon>
          </InputAdornment>)
      }}
    >
      {props.selectAllItem ?
        <MenuItem key={"Any Drivers"} value={0}>Any Drivers</MenuItem> :
        <MenuItem key={"Select a Driver"} value={0}><em>Select a Driver</em></MenuItem>
      }
      {!props.drivers && tookan.drivers.map(driver => (
        <MenuItem key={driver.name} value={driver.fleet_id}>{driver.fleet_id} - {driver.name}</MenuItem>
      ))}
      {props.drivers && props.drivers.map(driver => (
        <MenuItem key={driver.driver_name || 'Unassigned'} value={driver.driver_id || -1}>{driver.driver_id || 0} - {driver.driver_name || 'SAT Driver'}</MenuItem>
      ))}
    </TextField>
  </>)
}