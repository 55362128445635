import React, { useContext } from 'react';
import moment from 'moment';
import { GlobalContext } from '../../../global-context';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import IframeComm from 'react-iframe-comm';

const log = false;

const getTimeBetween = (startTime, endTime) => {
  let start = moment.utc(startTime);
  let end = moment.utc(endTime);
  let dur = Math.round(Math.abs(moment.duration(end.diff(start)).asMinutes()));
  return dur;
}

////////// COMPONENT //////////
export default function MoveDetailsTracking(props) {
  const { width } = useContext(GlobalContext);
  const cls = useStyles();
  const { move } = props;

  const getStartTime = () => {
    if (move.pickup_started) return moment(move.pickup_started).format(`LT`);
    else if (move.pickup_time) return moment(move.pickup_time).format(`LT`);
    else return `N/A`;
  }
  const getEndTime = () => {
    if (move.delivery_successful) return moment(move.delivery_successful).format(`LT`);
    else if (move.delivery_time) return moment(move.delivery_time).format(`LT`);
    else return `N/A`;
  }

  const formatPickupStarted = moment(move.pickup_started).format(`LT`);
  const formatPickupArrived = moment(move.pickup_arrived).format(`LT`);
  const formatPickupSuccessful = moment(move.pickup_successful).format(`LT`);
  const formatDeliveryStarted = moment(move.delivery_started).format(`LT`);
  const formatDeliveryArrived = moment(move.delivery_arrived).format(`LT`);
  const formatDeliverySuccessful = moment(move.delivery_successful).format(`LT`);

  const pickupDrive = getTimeBetween(move.pickup_started, move.pickup_arrived);
  const pickupInspect = getTimeBetween(move.pickup_arrived, move.pickup_successful);
  const pickupDwell = getTimeBetween(move.pickup_successful, move.delivery_started);
  const deliveryDrive = getTimeBetween(move.delivery_started, move.delivery_arrived);
  const deliveryInspect = getTimeBetween(move.delivery_arrived, move.delivery_successful);
  const totalTime = pickupDrive + pickupInspect + pickupDwell + deliveryDrive + deliveryInspect;

  const useBarStyles = makeStyles(theme => ({
    p1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(totalTime) ? pickupDrive / totalTime * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.primary.main,
    },
    p2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(totalTime) ? pickupInspect / totalTime * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.secondary.main,
    },
    p3: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(totalTime) ? pickupDwell / totalTime * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.secondary.light,
    },
    d1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(totalTime) ? deliveryDrive / totalTime * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.primary.main,
    },
    d2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${!isNaN(totalTime) ? deliveryInspect / totalTime * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.secondary.main,
    },
    txt: {
      marginTop: '6px',
      color: '#fff',
      lineHeight: 1,
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
      cursor: 'default',
    },
  }))
  const barCls = useBarStyles();

  const attributes = {
    id: `iTrack`,
    src: move.tracking_link,
    width: `100%`,
    height: width === `xs` ? `400px` : (width === `sm` ? `540px` : `720px`),
  }

  const postMessageData = `Hi`;

  const onReceiveMessage = (message) => {
    if (message.data) {
      log && console.log(`iFrame Response:`, message.data);
    }
    else {
      console.log(`Error: No iframe data found!`);
    }
  }

  const onReady = () => {

  }

  return (<>
    <div className={cls.timeline}>
      <div className={cls.leftBlock}>
        <Typography className={cls.time}>{getStartTime()}</Typography>
      </div>
      <div className={cls.midBlock}>
        <Tooltip title={`Pickup Drive - ${pickupDrive ? `${pickupDrive} minutes (${formatPickupStarted} to ${formatPickupArrived})` : `N/A`}`}>
          <div className={barCls.p1}><Typography className={barCls.txt}>{pickupDrive ? `${pickupDrive}m` : `0m`}</Typography></div>
        </Tooltip>
        <Tooltip title={`Pickup Inspection - ${pickupInspect ? `${pickupInspect} minutes (${formatPickupArrived} to ${formatPickupSuccessful})` : `N/A`}`}>
          <div className={barCls.p2}><Typography className={barCls.txt}>{pickupInspect ? `${pickupInspect}m` : `0m`}</Typography></div>
        </Tooltip>
        <Tooltip title={`Pickup Dwelling - ${pickupDwell ? `${pickupDwell} minutes (${formatPickupSuccessful} to ${formatDeliveryStarted})` : `N/A`}`}>
          <div className={barCls.p3}><Typography className={barCls.txt}>{pickupDwell ? `${pickupDwell}m` : `0m`}</Typography></div>
        </Tooltip>
        <Tooltip title={`Delivery Drive - ${deliveryDrive ? `${deliveryDrive} minutes (${formatDeliveryStarted} to ${formatDeliveryArrived})` : `N/A`}`}>
          <div className={barCls.d1}><Typography className={barCls.txt}>{deliveryDrive ? `${deliveryDrive}m` : `0m`}</Typography></div>
        </Tooltip>
        <Tooltip title={`Delivery Inspection - ${deliveryInspect ? `${deliveryInspect} minutes (${formatDeliveryArrived} to ${formatDeliverySuccessful})` : `N/A`}`}>
          <div className={barCls.d2}><Typography className={barCls.txt}>{deliveryInspect ? `${deliveryInspect}m` : `0m`}</Typography></div>
        </Tooltip>
      </div>
      <div className={cls.rightBlock}>
        <Typography className={cls.time}>{getEndTime()}</Typography>
      </div>
    </div >

    {!move.cancel_status && move.status && !move.status.includes(`delivery successful`) && (move.status.includes(`pickup`) || move.status.includes(`delivery`)) && move.tracking_link ? <>
      <div className={cls.break} />
      <div className={cls.map}>
        <IframeComm
          attributes={attributes}
          postMessageData={postMessageData}
          handleReady={onReady}
          handleReceiveMessage={onReceiveMessage}
        />
      </div>
    </> : null}
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  timeline: {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'relative',
    width: '100%',
  },
  leftBlock: {
    position: 'relative',
    marginLeft: theme.spacing(2),
  },
  midBlock: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  rightBlock: {
    position: 'relative',
    marginRight: theme.spacing(2),
  },
  time: {
    whiteSpace: 'nowrap',
    marginTop: '2.5px',
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginTop: '3px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '3.5px',
      fontSize: '14px',
    },
  },
  map: {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  break: {
    width: '100%',
    height: theme.spacing(2)
  },
}));