import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  DialogActions,
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../utils/Loading';

const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing(2),
    textTransform: 'none',
  },
  pwBtn: {
    transform: "scale(0.75)",
    marginLeft: theme.spacing(-2),
    textTransform: 'none',
  }
}));

export default function CustomerUserForm(props) {

  const ctx = useContext(GlobalContext)
  const cls = useStyles();

  const [loading, setLoading] = useState(false)
  const [inMemoryUser, setInMemoryUser] = useState({
    name: '',
    email: '',
    password: '',
    role: "dealer",
    customer_id: props.customer.id
  })

  useEffect(() => {
    if (props.existingUser) setInMemoryUser({
      name: props.existingUser.name,
      email: props.existingUser.email,
      password: '********',
      role: "dealer",
      customer_id: props.customer.id
    })
  }, [])

  function handleDisabled() {
    if (
      !inMemoryUser.name
      || (inMemoryUser.name && !inMemoryUser.name.trim())
      || (inMemoryUser.name && inMemoryUser.name.trim().length < 1)
      || !inMemoryUser.email
      || (inMemoryUser.email && !inMemoryUser.email.trim())
      || (inMemoryUser.email && inMemoryUser.email.trim().length < 1)
      || !inMemoryUser.password
      || (inMemoryUser.password && !inMemoryUser.password.trim())
      || (inMemoryUser.password && inMemoryUser.password.trim().length < 1)
      || !inMemoryUser.role
      || (inMemoryUser.role && !inMemoryUser.role.trim())
      || (inMemoryUser.role && inMemoryUser.role.trim().length < 1)
    ) return true;
    else return false
  }

  function handleActionButtons() {
    if (props.existingUser) return (
      <>
        <Button disabled={loading || handleDisabled()} className={cls.btn} onClick={() => handleUpdateUser()}>
          {!loading ? "Update User" : <Loading relative />}
        </Button>
      </>
    )
    else return (
      <>
        <Button disabled={loading || handleDisabled()} className={cls.btn} onClick={() => handleCreateUser()}>
          {!loading ? "Create User" : <Loading relative />}
        </Button>
      </>
    )
  }

  function handleClose() {
    if (loading) {
      if (window.confirm("There is a pending network call that has not been resolved. Are you sure you want to close this window?")) {
        props.setModalRender(false);
        props.setExistingUser(null)
      }
      else return;
    }
    else {
      props.setModalRender(false);
      props.setExistingUser(null)
    }
  }

  const handleChange = event => setInMemoryUser(Object.assign({ ...inMemoryUser }, { [event.target.name]: event.target.value }));

  function handleCreateUser() {
    setLoading(true)
    try {
      axios.post(`/.netlify/functions/auth0CreateUser`, inMemoryUser)
        .then(res => {
          setLoading(false)
          if (res && res.status === 200) {
            navigator.clipboard.writeText(inMemoryUser.password);
            ctx.handleNotifications(true, `info`, `Password copied to clipboard!`);
            props.refetch();
            props.setModalRender(false);
          }
          else if (res && res.status === 206) {
            setLoading(false)
            ctx.handleNotifications(true, `warning`, `User was created but role could not be assigned.`)
            props.refetch();
            alert("The user was created but was not assigned a role. Please contact IT to resolve this issue.")
          }
        })
        .catch(err => {
          setLoading(false)
          console.error("Failed to create new user:", err)
          ctx.handleNotifications(true, `error`, `Failed to create new user`)
        })
    } catch (err) {
      setLoading(false)
      console.error("Unexpected error while creating new user:", err)
      ctx.handleNotifications(true, `error`, `Failed to create new user`)
    }
  }
  function handleUpdateUser() {
    setLoading(true)
    try {
      axios.post(`/.netlify/functions/auth0UpdateUser`, {
        user_id: props.existingUser.id,
        data: {
          name: inMemoryUser.name,
          email: inMemoryUser.email,
        }
      })
        .then(res => {
          setLoading(false)
          if (res && res.status === 200) {
            props.refetch();
            props.setModalRender(false);
          }
        })
        .catch(err => {
          setLoading(false)
          console.error("Failed to update user:", err)
          ctx.handleNotifications(true, `error`, `Failed to update user`)
        })
    } catch (err) {
      setLoading(false)
      console.error("Unexpected error while updating user:", err)
      ctx.handleNotifications(true, `error`, `Failed to update user`)
    }
  }

  function generatePassword() {
    try {
      let password = { ...inMemoryUser }.name.trim().split(" ")[0]
      password = "@" + password.charAt(0).toUpperCase() + password.slice(1) + "#1"
      let charsRemaining = 8 - password.length;
      if (charsRemaining > 0) {
        for (let i = 2; i < charsRemaining + 2; i++) {
          password += i;
        }
      }
      setInMemoryUser(Object.assign({ ...inMemoryUser }, { password: password }))
    } catch (err) {
      console.log(err)
      return
    }
  }

  function handlePasswordErr() {
    try {
      if (inMemoryUser.password && inMemoryUser.password.trim() && inMemoryUser.password.trim().length < 8) return true
      else return false
    } catch {
      return true
    }
  }

  return (

    <Dialog
      open={props.modalRender}
      onClose={() => handleClose()}
      aria-labelledby="customer-user-modal"
      id="UserDialog"
    >
      <DialogTitle id="customer-user-modal">{props.existingUser ? "Edit User" : "New User"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
        {!props.existingUser ? `Create a new user account for ${props.customer.name || "the current customer"} by filling in the required fields (*) below.` : `Update existing user account for ${props.existingUser.name}. If you need to reset the password for this user please contact IT.`}
        </DialogContentText>
        <br />
        <FormControl>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item xs={8}>
              <TextField id="auth0-user-name-field" label="Name" required variant="outlined" name="name" value={inMemoryUser.name} fullWidth onChange={handleChange} disabled={loading} />
            </Grid>
            <Grid item xs={8}>
              <TextField id="auth0-user-email-field" label="Email" required variant="outlined" name="email" value={inMemoryUser.email} fullWidth onChange={handleChange} disabled={loading} />
            </Grid>
            <Grid item xs={8}>
              <TextField id="auth0-user-password-field" error={handlePasswordErr()} label={handlePasswordErr() ? "Password must be at least 8 characters" : "Password"} required variant="outlined" name="password" value={inMemoryUser.password} fullWidth onChange={handleChange} disabled={loading || props.existingUser} helperText={props.existingUser ? null : <Button disabled={!inMemoryUser.name || (inMemoryUser.name && !inMemoryUser.name.trim()) || (inMemoryUser.name && inMemoryUser.name.trim() && inMemoryUser.name.trim().length < 1)} size="small" color="primary" className={cls.pwBtn} onClick={() => generatePassword()}>Auto generate</Button>} />
            </Grid>
            <Grid item xs={8}>
              <FormControl disabled variant="outlined" fullWidth>
                <InputLabel id="auth0-user-role-label">Role</InputLabel>
                <Select
                  name="role"
                  labelId="auth0-user-role-label"
                  id="auth0-user-role-select"
                  value={inMemoryUser.role}
                  onChange={handleChange}
                  label="Role"
                  // value={inMemoryUser.role} // Revisit when introducing dealer admin role
                  value={'dealer'}
                >
                  <MenuItem value={'dealer'}>Dealer</MenuItem>
                  <MenuItem value={'admin'}>Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
        <br />
        <br />
        <DialogActions>
          {handleActionButtons()}
          <Button onClick={() => handleClose()} color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog >
  )

}