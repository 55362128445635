import React, { useContext } from 'react';
import { GlobalContext } from '../../../global-context';

import gql from 'graphql-tag';
import fragments from '../../utils/graphQL/fragments';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

const log = true;

////////// COMPONENT //////////
export default function LocationRemoveModal(props) {
  const ctx = useContext(GlobalContext);

  const { open, onClose, locationInput } = props;

  const handleSubmit = async () => {
    try {
      let res = await ctx.apolloClient.mutate({
        mutation: REMOVE_LOCATION,
        variables: { id: locationInput.id }
      });
      if (res) {
        const resLoc = res.data.update_locations.returning[0];
        log && console.log(`>> UPDATED Location:`, resLoc);
        ctx.handleNotifications(true, `success`, `Location: '${resLoc.name}' was successfully removed.`);
        handleClose();
      }
    }
    catch (err) {
      console.log(`Error removing location:`, err);
      ctx.handleNotifications(true, `error`, `Failed to remove location: ${err.toString()}`);
    }
  }
  const handleClose = () => {
    if (onClose) onClose();
  }

  return (<>
    <Modal open={open}>

      <ModalHeader handleClose={handleClose}>Remove Location</ModalHeader>

      <ModalContent subtitle={`Are you sure you want to remove the location: '${locationInput && locationInput.name}'? Note this will set the location to inactive, but will not remove it from the database.`} />

      <ModalFooter>
        <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
        <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
      </ModalFooter>

    </Modal>
  </>)
}

////////// GRAPHQL //////////
const REMOVE_LOCATION = gql`
mutation remove_location(
  $id: bigint!,
) {
  update_locations(
    where: {id: {_eq: $id}}, 
    _set: {
      active: 0,
    }
  ) {
    affected_rows
    returning {
      ...Location
    }
  }
}
${fragments.location}
`;