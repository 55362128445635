import React from "react";
import { GlobalContext } from "../../../global-context";
import {
  DialogContentText,
  Grid,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  DialogActions,
  OutlinedInput,
  TextField,
  Fab
} from "@material-ui/core";
import { Subscription } from "react-apollo";
import Loading from "../../utils/Loading";
import fragments from "../../utils/graphQL/fragments";
import { gql } from "apollo-boost";
import { withStyles } from "@material-ui/styles";
import ManageAccessorialsTable from "../driverPayReview/ManageAccessorialsTable";
// import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  fullWidth: {
    minWidth: '100%',
  },
})

const getAccessorials = (type = 'query') => (gql`
    ${type} getAccessorials($id: bigint!) {
      accessorials(where: { move_id: {_eq: $id} } ) {
          ...Accessorials
          move {
            accountsReceivable {
              status
            }
          }
          appayment {
            ...APPayment
          }
        }
      }
      ${fragments.accessorials}
      ${fragments.appayment}
    `);


class CancelMoveModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: props.move.cancel_status,
      name: 'status',
      index: 0,
    }
  }

  componentWillReceiveProps = newProps => {
    if (!newProps.move) return;
    if (JSON.stringify(this.props.move) === JSON.stringify(newProps.move)) return;
    else if (newProps.move.cancel_status !== this.props.move.cancel_status) {
      this.setState({ status: newProps.move.cancel_status, index: 0 })
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleDialogContent = () => {
    switch (this.state.status) {
      case "started":
        return (
          <>
            <DialogContentText id="alert-dialog-description">
              The cancellation status of this move will be set to "started". It has been partially worked and may require additional charges to the customer or partial driver pay. Please add any additions as accessorials below:
            </DialogContentText>
            <Subscription
              subscription={getAccessorials('subscription')}
              variables={{ id: this.props.move.id }}
              onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve moves data")}}>
              {({ ...result }) => {
                if (result.loading) return <Loading />;
                if (result.error) return `Error! ${result.error.message}`;
                return (
                  <>
                    <DialogContentText id="alert-dialog-description">
                      Does the customer need to be charged?
                    </DialogContentText>
                    <ManageAccessorialsTable
                      type="customer"
                      accessorials={Array.from(result.data.accessorials.filter(o => o.ar_amount > 0) || [])}
                      moveId={this.props.move.id}
                    />
                    <br />
                    <DialogContentText id="alert-dialog-description">
                      Does the driver need to be paid?
                    </DialogContentText>
                    <ManageAccessorialsTable
                      type="driver"
                      accessorials={Array.from(result.data.accessorials.filter(o => o.ap_amount > 0) || [])}
                      moveId={this.props.move.id}
                    />
                  </>
                )
              }}
            </Subscription>
          </>
        )
        break;
      case "canceled":
        return (
          <>
            <DialogContentText id="alert-dialog-description">
              The cancellation status of this move will be set to "canceled". It will be removed from the list unplanned moves and will not appear on any driver pay or customer invoices.
            </DialogContentText>
          </>
        )
        break;
      case "delivered":
        return (
          <>
            <DialogContentText id="alert-dialog-description">
              The cancellation status of this move will be set to "delivered". It is unable to be canceled due to being already delivered or being too far along in the move process to stop. Nothing on the move will change and driver pay and customer invoices will be unaffected.
            </DialogContentText>
          </>
        )
        break;
      default:
        return null
    }
  }

  handleButtons = () => {
    if (this.state.index === 0) {
      if (["pending", "seen"].indexOf(this.state.status) >= 0) {
        return (
          <>
            <Button onClick={this.props.onCancel} color="primary">
              Close
            </Button>
            <Button onClick={() => { this.props.onSave(this.state.status) }} color="primary">
              Save
            </Button>
          </>
        )
      } else return (
        <>
          <Button onClick={this.props.onCancel} color="primary">
            Close
          </Button>
          <Button onClick={() => { this.setState({ index: 1 }) }} color="primary">
            Next
          </Button>
        </>
      )
    } else return (
      <>
        <Button onClick={this.props.onCancel} color="primary">
          Close
        </Button>
        <Button onClick={() => { this.setState({ index: 0 }) }} color="primary">
          Back
        </Button>
        <Button onClick={() => { this.props.onSave(this.state.status) }} color="primary">
          Save
        </Button>
      </>
    )
  }

  handleClose = () => {
    this.props.onCancel();
    this.setState({ index: 0 });
  }

  render() {
    const { open } = this.props
    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="change-cancel-status-modal" id="CancelMoveDialog">
        <DialogTitle id="change-cancel-status-modal">Change Cancel Status</DialogTitle>
        <DialogContent>
          {
            this.state.index === 0 ? (
              <>
                <DialogContentText id="alert-dialog-description">
                  Set the status of a move cancelation request from a customer.
                  Sometimes a move is already in progress and cannot be canceled.
                  <br />
                  <br />
                  <span style={{ fontSize: ".8em" }}>
                    <strong>Pending</strong> - The cancelation request that has not yet been seen by a dispatcher<br />
                    <strong>Seen</strong> - Acknowledged by the dispatcher so it can be removed from any dispatcher notifications<br />
                    <strong>Started</strong> - The dispatcher was able to tell the driver to return the vehicle to the pickup location<br />
                    <strong>Canceled</strong> - The dispatcher was able to cancel the move before it was worked<br />
                    <strong>Delivered</strong> - The dispatcher was NOT able to cancel the move as it was already delivered
                  </span>
                </DialogContentText>
                <FormControl style={{ margin: 2, minWidth: 250 }}>
                  <InputLabel htmlFor="cancel-status">Cancel Status</InputLabel>
                  <Select
                    value={this.state.status}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'status',
                      id: 'cancel-status',
                    }}
                  >
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'seen'}>Seen</MenuItem>
                    <MenuItem value={'started'}>Started</MenuItem>
                    <MenuItem value={'canceled'}>Canceled</MenuItem>
                    <MenuItem value={'delivered'}>Delivered</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : this.handleDialogContent()
          }
        </DialogContent>
        <DialogActions>
          {this.handleButtons()}
        </DialogActions>
      </Dialog>
    )
  }
};

CancelMoveModal.contextType = GlobalContext;

export default withStyles(styles)(CancelMoveModal);