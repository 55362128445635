import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../../global-context';
import { makeStyles, withStyles, Grid, Typography, Tooltip, Switch, InputBase, TextField, InputAdornment, Icon, MenuItem, Button, Chip } from '@material-ui/core';

import VehicleEditModal from './VehicleEditModal';

const placeholder = require('../../../static/vehicle-placeholder.jpg');

const log = true;

const cap = (str) => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
}

////////// COMPONENT //////////
function MoveDetailsInfo(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { move, tookan, editMode, editVals, editSetVals } = props;
  const { make, model, year, color, manualFlag, stock, vin, refNum, priority, rateClass, moveType, rideType, lyftFlag, active, rateClassOverride, autoAssign, chargeable, payable, consumerPickup, consumerName, consumerPhone, tags } = editVals;
  const { setMake, setModel, setYear, setColor, setManualFlag, setStock, setVin, setRefNum, setPriority, setRateClass, setMoveType, setRideType, setLyftFlag, setActive, setRateClassOverride, setAutoAssign, setChargeable, setPayable, setConsumerPickup, setConsumerName, setConsumerPhone, setTags } = editSetVals;

  let moveTags = move.tags && move.tags !== `` ? move.tags.split(`,`) : [];
  useEffect(() => {
    if (move.tags && move.tags !== ``) moveTags = move.tags.split(`,`);
  }, [move])

  const [selectedTag, setSelectedTag] = useState(``);

  const [vehicleModal, setVehicleModal] = useState({ open: false });
  const handleVehicleEditModalOpen = (input = null) => {
    setVehicleModal({ open: true, input: input });
  }
  const handleVehicleEditModalClose = (output = null) => {
    setVehicleModal({ ...vehicleModal, open: false });
    if (output) {
      setMake(output.make);
      setModel(output.model);
      setYear(output.year);
      setColor(output.color);
    }
  }

  useEffect(() => {
    setMake(move.vehicle_make || ``);
    setModel(move.vehicle_model || ``);
    setYear(move.vehicle_year || ``);
    setColor(move.vehicle_color || ``);
    setManualFlag(move.manual_flag);
    setStock(move.vehicle_stock || ``);
    setVin(move.vehicle_vin || ``);
    setRefNum(move.reference_num || ``);
    setPriority(move.priority || 10);
    setRateClass(move.class || ``);
    setMoveType(move.move_type || ``);
    setRideType(move.ride_type || ``);
    setLyftFlag(move.lyft_flag === 1 ? true : false);
    setActive(move.active === 1 ? true : false);
    setRateClassOverride(move.rate_class_override === 1 ? true : false);
    setAutoAssign(move.auto_assign === 1 ? true : false);
    setChargeable(move.chargeable);
    setPayable(move.payable);
    setConsumerPickup(move.consumer_pickup);
    setConsumerName(move.consumer_name || ``);
    setConsumerPhone(move.consumer_phone || ``);
    setTags(move.tags ? move.tags.split(`,`) : []);
  }, [editMode])

  const handleSwitch = (prev, setHandler) => {
    if (setHandler) {
      if (prev) setHandler(false);
      else setHandler(true);
    }
  }
  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  }
  const handlePriorityChange = event => {
    let input = 9;

    if (event.key) input = Number(event.key);
    else if (event.target && event.target.value) input = Number(event.target.value);

    if (input >= 1 && input <= 9) setPriority(input);
  }

  const handleVehicleEdit = () => {
    handleVehicleEditModalOpen({
      make: { name: make, vehiclemodels: [] },
      model: { name: model },
      year: year,
      color: color,
    });
  }

  const handleAddTag = () => {
    if (selectedTag !== ``) {
      if (!tags.includes(selectedTag)) setTags([...tags, selectedTag]);
      else ctx.handleNotifications(true, `warning`, `Tag already exists on this move!`);
    }
    else ctx.handleNotifications(true, `warning`, `Please select a tag to add it.`);
    setSelectedTag(``);
  }
  const handleDeleteTag = (tag) => {
    let newTags = [...tags];
    if (newTags.includes(tag)) {
      const index = newTags.indexOf(tag);
      newTags.splice(index, 1);
      log && console.log(`Deleting tag:`, { tag, index, newTags });
      ctx.handleNotifications(true, `info`, `Tag '${tag}' removed.`);
      setTags(newTags);
    }
    else console.log(`Error: Tag not found!`, { tags, tag });
  }

  const getVehicle = () => {
    let vehicle = ``;
    if (!move.vehicle_make && !move.vehicle_model) vehicle = `No Vehicle Info`;
    else vehicle = `${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`;

    let vehicleColor = ``;
    if (move.vehicle_color) vehicleColor = ` (${cap(move.vehicle_color)})`;

    return vehicle + vehicleColor;
  }

  const getDriverInitials = () => {
    let fullName = move.driver_name ? move.driver_name : `N/A`
    if (fullName !== `N/A`) {
      let names = fullName.split(` `);
      let firstI = names[0][0];
      let secondI = names[names.length - 1][0];
      return `${firstI}${secondI}`.toUpperCase();
    }
    else return fullName;
  }

  const getStatus = () => {
    if (move.cancel_status && move.cancel_status !== ``) {
      if (move.cancel_status === `canceled`) return `Cancelled`;
      else if (move.cancel_status === `pending`) return `Cancel Pending`;
      else if (move.cancel_status === `seen`) return `Cancel Seen`;
      else if (move.cancel_status === `started`) return `Cancel Started`;
      else if (move.cancel_status === `delivered`) return `Delivery Successful`;
      else return cap(move.cancel_status);
    }
    else if (move.status && move.status !== ``) {
      if (move.status === `dispatched`) return `Dispatched`;
      else if (move.status === `pickup started`) return `Pickup Started`;
      else if (move.status === `pickup arrived`) return `Pickup Arrived`;
      else if (move.status === `pickup successful`) return `Pickup Successful`;
      else if (move.status === `delivery started`) return `Delivery Started`;
      else if (move.status === `delivery arrived`) return `Delivery Arrived`;
      else if (move.status === `delivery successful`) return `Delivery Successful`;
      else if (move.status === `droppedOff`) return `Dropped Off`;
      else if (move.status === `canceled`) return `Cancelled`;
      else if (move.status === `failed`) return `Failed`;
      else return cap(move.status);
    }
    else return `Unplanned`;
  }

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.secondary.light,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: editMode ? theme.palette.primary.main : theme.palette.secondary.light,
          backgroundColor: editMode ? theme.palette.primary.main : theme.palette.secondary.light,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
    checked: {},
  }))(Switch);

  return (<>
    <VehicleEditModal open={vehicleModal.open} onClose={handleVehicleEditModalClose} vehicleInput={vehicleModal.input} />

    <div className={cls.main}>
      <div className={cls.info}>
        <Typography className={cls.infoTxt}>VEHICLE</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={getVehicle()}>
          {tookan && tookan.pickupImage ?
            <img className={cls.infoImage} src={tookan.pickupImage} alt="vehicle" /> :
            <img className={cls.infoImage} src={placeholder} alt="placeholder vehicle" />
          }
        </Tooltip>
      </div>

      {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>Information</Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip placement="top" title="Move Date">
              <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>{moment(move.pickup_started ? move.pickup_started : move.pickup_time).format(`MM/DD/YYYY`)}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip placement="top-end" title="Move ID">
              <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>#{move.id}</Typography>
            </Tooltip>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
              {editMode ?
                <Typography className={cls.valTxt} style={{ background: '#00000008', color: ctx.theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleVehicleEdit()}>Click to edit vehicle</Typography> :
                <Typography className={cls.valTxt}>{getVehicle()}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>{editMode ? `Manual\xa0Flag` : `Transmission`}:&nbsp;&nbsp;</Typography>
              {editMode ?
                <div className={cls.switch}><AntSwitch disabled={!editMode} checked={manualFlag} onChange={() => handleSwitch(manualFlag, setManualFlag)} /></div> :
                <Typography className={cls.valTxt}>{move.manual_flag ? `Manual` : `Automatic`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase value={stock} onChange={handleInputChange(setStock)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.vehicle_stock || `-`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase value={vin} onChange={handleInputChange(setVin)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.vehicle_vin || `-`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Reference&nbsp;#:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase value={refNum} onChange={handleInputChange(setRefNum)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.reference_num || `-`}</Typography>
              }
            </div>
          </Grid>

          <div className={cls.hiddenLineBreak} />

          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.driver_name || `SAT Driver`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.customer.name || `Unknown Dealer`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Move&nbsp;Status:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getStatus()}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Drive&nbsp;Distance:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.lane && move.lane.distance_miles ? `${move.lane.distance_miles} miles` : `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Notes/Instructions:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.move_details || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealer&nbsp;Contact:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.dealer_contact || `-`}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

      <div className={cls.info}>
        <Typography className={cls.infoTxt}>DRIVER</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={move.driver_name ? move.driver_name : `SAT Driver`}>
          {tookan && tookan.driver && tookan.driver.fleet_thumb_image ?
            <img className={cls.infoImage} src={tookan.driver.fleet_thumb_image} alt="driver" /> :
            <div className={cls.infoImage}>
              <Typography className={cls.infoNoDriverTxt}>{getDriverInitials()}</Typography>
            </div>
          }
        </Tooltip>
      </div>
    </div>

    <div className={cls.bigBreak} />

    <div className={cls.main}>
      <div className={cls.info}>
        <div className={cls.infoSpacer} />
      </div>

      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Active:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? active : (move.active === 1 ? true : false)} onChange={() => handleSwitch(active, setActive)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Priority:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase type="number" min="1" max="9" value={priority} onKeyPress={handlePriorityChange} onChange={handlePriorityChange} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.priority || `-`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Move&nbsp;Type:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase value={moveType} onChange={handleInputChange(setMoveType)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.move_type ? cap(move.move_type) : `-`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Ride&nbsp;Type:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase value={rideType} onChange={handleInputChange(setRideType)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.ride_type ? cap(move.ride_type) : `-`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Rate&nbsp;Class:&nbsp;&nbsp;</Typography>
              {editMode ?
                <InputBase value={rateClass} onChange={handleInputChange(setRateClass)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                <Typography className={cls.valTxt}>{move.class ? cap(move.class) : `-`}</Typography>
              }
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Consumer&nbsp;Pickup:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? consumerPickup : move.consumer_pickup} onChange={() => handleSwitch(consumerPickup, setConsumerPickup)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Return&nbsp;Ride:&nbsp;&nbsp;</Typography>
              <div className={cls.valTxt}>{move.moveByReturnRideId ? <Button variant="outlined" size="small" color="primary" style={{maxHeight: '20px'}} onClick={() => {props.history.push(`/moves/${move.moveByReturnRideId.id}`)}}>{move.moveByReturnRideId.id}</Button> : ''}</div>
            </div>
          </Grid>

          <div className={cls.hiddenLineBreak} />

          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Chargeable:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? chargeable : move.chargeable} onChange={() => handleSwitch(chargeable, setChargeable)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Payable:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? payable : move.payable} onChange={() => handleSwitch(payable, setPayable)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Auto&nbsp;Assign:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? autoAssign : (move.auto_assign === 1 ? true : false)} onChange={() => handleSwitch(autoAssign, setAutoAssign)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Lyft&nbsp;Flag:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? lyftFlag : (move.lyft_flag === 1 ? true : false)} onChange={() => handleSwitch(lyftFlag, setLyftFlag)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Rate&nbsp;Class&nbsp;Override:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={!editMode} checked={editMode ? rateClassOverride : (move.rate_class_override === 1 ? true : false)} onChange={() => handleSwitch(rateClassOverride, setRateClassOverride)} /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Has&nbsp;Loaner:&nbsp;&nbsp;</Typography>
              <div className={cls.switch}><AntSwitch disabled={true} checked={move.moveByReturnRideId && move.moveByReturnRideId.tags && move.moveByReturnRideId.tags.includes('loaner')}  /></div>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Move&nbsp;Type:&nbsp;&nbsp;</Typography>
              <div className={cls.valTxt}>
                {move && move.consumer_pickup && move.moveByReturnRideId && move.moveByReturnRideId.tags && move.moveByReturnRideId.tags.includes('loaner') &&
                  <div>Concierge + <Button variant="outlined" size="small" color="primary" style={{maxHeight: '20px'}} onClick={() => {props.history.push(`/moves/${move.moveByReturnRideId.id}`)}}>Loaner</Button></div>
                }
                {move && move.consumer_pickup && !move.moveByReturnRideId &&
                  <div>Concierge (no loaner)</div>
                }
                {move && !move.consumer_pickup && !move.moveByReturnRideId && !move.chargeable && move.tags && move.tags.includes('loaner') &&
                  <div>Loaner</div>
                }
                {move && !move.consumer_pickup && !move.moveByReturnRideId && move.chargeable && move.tags && !move.tags.includes('loaner') &&
                  <div>Stranded Move (not concierge)</div>
                }
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={cls.info}>
        <div className={cls.infoSpacer} />
      </div>
    </div>

    <div className={cls.bigBreak} />

    {(!editMode && move.consumer_pickup) || (editMode && consumerPickup) ? <>
      <div className={cls.main}>
        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Consumer&nbsp;Name:&nbsp;&nbsp;</Typography>
                {editMode ?
                  <InputBase value={consumerName} onChange={handleInputChange(setConsumerName)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                  <Typography className={cls.valTxt}>{move.consumer_name || `-`}</Typography>
                }
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Consumer&nbsp;Phone:&nbsp;&nbsp;</Typography>
                {editMode ?
                  <InputBase value={consumerPhone} onChange={handleInputChange(setConsumerPhone)} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> :
                  <Typography className={cls.valTxt}>{move.consumer_phone || `-`}</Typography>
                }
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>
      </div>

      <div className={cls.bigBreak} />
    </> : null}

    <div className={cls.main}>
      <div className={cls.info}>
        <div className={cls.infoSpacer} />
      </div>

      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              select
              fullWidth
              disabled={!editMode}
              margin="dense"
              variant="outlined"
              label="Tag Select"
              placeholder="Select a tag..."
              value={selectedTag}
              onChange={handleInputChange(setSelectedTag)}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: "top" }} position="start">
                    <Icon color="disabled" fontSize="small">label</Icon>
                  </InputAdornment>
                )
              }}
            >
              <MenuItem value=""><em>Select a tag...</em></MenuItem>
              <MenuItem value="delivery reported">Delivery Reported</MenuItem>
              <MenuItem value="loaner">Loaner</MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <Button disabled={!editMode} className={cls.btn} onClick={() => handleAddTag()}>Add Tag</Button>
          </Grid>
        </Grid>

        {!editMode && moveTags.length > 0 ? <>
          <div className={cls.break} />
          <div className={cls.tags}>
            {moveTags.map((tag, i) => <Chip key={`chip-tag-${i}`} label={cap(tag)} className={cls.tag} style={{ background: ctx.theme.palette.secondary.light }} />)}
          </div>
        </> : null}

        {editMode && tags.length > 0 ? <>
          <div className={cls.break} />
          <div className={cls.tags}>
            {tags.map((tag, i) => <Chip key={`edit-chip-tag-${i}`} label={cap(tag)} clickable onClick={() => handleDeleteTag(tag)} icon={<Icon style={{ color: '#fff' }}>cancel</Icon>} className={cls.tag} style={{ background: ctx.theme.palette.primary.main }} />)}
          </div>
        </> : null}
      </div>

      <div className={cls.info}>
        <div className={cls.infoSpacer} />
      </div>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    display: 'block',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '128px',
    border: `1px solid #cdd6e5`,
    borderRadius: '50%',
    background: `#cdd6e5`,
    verticalAlign: 'top',
  },
  infoSpacer: {
    display: 'block',
    position: 'relative',
    width: '128px',
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 700,
    cursor: 'default',
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
    cursor: 'default',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    background: '#fff',
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  list: {

  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  input: {
    margin: 0,
  },
  btn: {
    verticalAlign: 'top',
    minHeight: '40px',
    maxHeight: '40px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
    },
  },
  tags: {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tag: {
    marginRight: theme.spacing(1),
    color: `#fff`,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  tinyBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

////////// EXPORT //////////
export default withRouter(MoveDetailsInfo);