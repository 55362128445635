import React, { useContext, useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import { GlobalContext } from '../../global-context';
import Loading from '../utils/Loading';
import { gql } from 'apollo-boost';
import fragments from '../utils/graphQL/fragments';
import { Container, Grid, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  grid: {
    padding: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  textField: {
    width: "100%"
  }
}));


function RegionSelection(props) {
  const { classes, regions, context } = props;
  const [selection, setSelection] = useState(initSelection())
  const [emails, setEmails] = useState(null)

  function initSelection() {
    let selection = {};
    regions.map(region => {
      Object.assign(selection, { [region.team_id]: true })
    })
    return selection;
  }


  function handleSelect(event) {
    setSelection({ ...selection, [parseInt(event.target.value)]: !selection[parseInt(event.target.value)] })
  }

  async function handleFetchEmails() {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/handleFetchDriverEmails',
        data: {
          team_ids: getTeamIdsFromSelection(),
        },
        headers: {
          'authorization': `Bearer ${context.userToken}`
        }
      })
        .then(res => {
          setEmails(res.data)
          navigator.clipboard.writeText(res.data)
          res.data.length >= 1 ? context.handleNotifications(true, "success", "Email list copied to clipboard") : context.handleNotifications(true, "warning", "No emails found")
        })
    } catch (err) {
      console.error("Failed to retrieve emails:", err);
      context.handleNotifications(true, "error", "Failed to retrieve emails: " + err.toString())
    }
  }

  function getTeamIdsFromSelection() {
    let team_ids = [];
    for (let [key, value] of Object.entries(selection)) {
      if (value) {
        team_ids.push(props.regions.find(o => o.team_id === parseInt(key)).team_id)
      }
    }
    return team_ids;
  }

  return (
    <Container className={classes.root} maxWidth="md">
      <Grid className={classes.grid} container justify='center' spacing={2}>
        <Grid item xs={6}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Get driver emails for the following regions:</FormLabel>
            <FormGroup>
              {regions.map(region => {
                return (
                  <FormControlLabel
                    key={`region-select-${region.team_id}`}
                    control={<Checkbox color="primary" checked={selection[region.team_id]} onChange={handleSelect} value={region.team_id} />}
                    label={region.name}
                  />
                )
              })}
            </FormGroup>
            <FormHelperText>A comma-separated list of emails will be returned and copied to the clipboard</FormHelperText>
            <Button disabled={!Object.values(selection).includes(true)} color="secondary" className={classes.btn} onClick={() => handleFetchEmails()}>
              <EmailIcon className={classes.icon} color={!Object.values(selection).includes(true) ? "disabled" : "action"} />
              Get Driver Emails
          </Button>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {emails ? (
            <TextField
              id="driver-email-text-field"
              label="Driver emails from selection"
              multiline
              rowsMax="20"
              value={emails}
              className={classes.textField}
              variant="outlined"
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}

export default function DriverEmails(props) {

  const context = useContext(GlobalContext)
  const classes = useStyles();

  return (
    <>
      { context && context.userIsAuthenticated() && (
        <Query
          query={gql`
            query {
              regions(order_by: {region_id: asc}) {
                ...Region
              }
            }
            ${fragments.region}
          `}
          onError={err => context.handleNotifications(true, "error", "Query failed to retrieve pending cancellations: " + err.toString())} >
          {({ ...result }) => {
            if (result.loading) return <Loading />;
            if (result.error) return `Error! ${result.error.message}`;
            if (result.data) {
              if (result.data.regions) {
                return <RegionSelection classes={classes} regions={result.data.regions} context={context} />
              } else return <Loading />
            }
          }}
        </Query>)
      }
    </>
  )
}