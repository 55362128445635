import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../../global-context';

import { makeStyles, Typography, IconButton, Icon, Tooltip } from '@material-ui/core';

import MapIndex from './MapIndex';
import MapLocation from './MapLocation';
import MapLane from './MapLane';

////////// COMPONENT //////////
export default function MapSidebar(props) {
  const { handleNotifications } = useContext(GlobalContext);
  const cls = useStyles();

  const { allLocations, locations, search, setSearch, customerId, setCustomerId, 
    tags, setTags, sidebarState, setSidebarState, selectedLocation, setSelectedLocation, 
    selectedLane, setSelectedLane, origin, setOrigin, destination, setDestination, setCreateMode, 
    laneMode, setLaneMode, handleLocationAddEditModalOpen 
  } = props;

  const [open, setOpen] = useState(true);

  const handleBack = () => {
    if (!laneMode && sidebarState === `location`) {
      setSidebarState(`index`);
      setSelectedLocation(null);
    }
    else if (laneMode && sidebarState === `location`) {
      setSidebarState(`lane`);
      setSelectedLocation(null);
    }
    else if (laneMode && sidebarState === `lane`) {
      setLaneMode(false);
      setSidebarState(`index`);
      setOrigin(null);
      setDestination(null);
      setSelectedLocation(null);
      setSelectedLane({ origin_location_id: undefined, destination_location_id: undefined, pickup: null, delivery: null });
    }
  }
  const handleCreateMode = () => {
    setCreateMode(true)
    handleNotifications(true, `info`, `Click somewhere on the map to create a new location.`);
  }

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  return (<>
    {open ? <>
      <div className={cls.sidebar}>
        <div className={cls.header}>
          {sidebarState !== `index` && <IconButton className={cls.headerBack} onClick={() => handleBack()}><Icon>arrow_back</Icon></IconButton>}
          <Typography className={cls.headerTxt}>{sidebarState === `index` ? `Index` : (sidebarState === `location` ? `Location Details` : `Lane Builder`)}</Typography>
          <IconButton className={cls.headerClose} onClick={() => handleClose()}><Icon>close</Icon></IconButton>
        </div>
        <div className={cls.body}>
          {sidebarState === `index` ? <>
            <MapIndex
              locations={locations}
              search={search}
              customerId={customerId}
              tags={tags}
              setSidebarState={setSidebarState}
              setSearch={setSearch}
              setCustomerId={setCustomerId}
              setTags={setTags}
              setSelectedLocation={setSelectedLocation}
            />
          </> : null}
          {sidebarState === `location` ? <>
            <MapLocation
              location={selectedLocation}
              setOrigin={setOrigin}
              setDestination={setDestination}
              handleLocationAddEditModalOpen={handleLocationAddEditModalOpen}
            />
          </> : null}
          {sidebarState === `lane` ? <>
            <MapLane
              allLocations={allLocations}
              lane={selectedLane}
              origin={origin}
              destination={destination}
              setLane={setSelectedLane}
              setOrigin={setOrigin}
              setDestination={setDestination}
            />
          </> : null}
        </div>
      </div>
      <div className={cls.actions}>
        <Tooltip placement="right" title="Create New Location">
          <IconButton className={cls.topButton} onClick={() => handleCreateMode()}><Icon>my_location</Icon></IconButton>
        </Tooltip>
      </div>
    </> :
      <div className={cls.actions}>
        <Tooltip placement="right" title="Open Sidebar">
          <IconButton className={cls.topButton} onClick={() => handleOpen()}><Icon>menu_open</Icon></IconButton>
        </Tooltip>
        <Tooltip placement="right" title="Create New Location">
          <IconButton className={cls.bottomButton} onClick={() => setCreateMode(true)}><Icon>my_location</Icon></IconButton>
        </Tooltip>
      </div>}
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  sidebar: {
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: '480px',
    minWidth: '480px',
    minHeight: '100%',
    maxHeight: '100%',
    background: '#fff',
    boxShadow: '3px 0px 8px -1px #20202850',
    [theme.breakpoints.down('md')]: {
      maxWidth: '420px',
      minWidth: '420px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '360px',
      minWidth: '360px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  actions: {
    position: 'relative',
  },
  topButton: {
    zIndex: 10,
    verticalAlign: 'top',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '40px',
    height: '40px',
    padding: theme.spacing(1),
    margin: '10px',
    borderRadius: '2px',
    background: '#fff',
    color: theme.palette.text.primary,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    '&:hover': {
      background: '#fff',
      color: '#000',
    },
    transition: 0,
  },
  bottomButton: {
    zIndex: 10,
    verticalAlign: 'top',
    position: 'absolute',
    top: '50px',
    left: 0,
    width: '40px',
    height: '40px',
    padding: theme.spacing(1),
    margin: '10px',
    borderRadius: '2px',
    background: '#fff',
    color: theme.palette.text.primary,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    '&:hover': {
      background: '#fff',
      color: '#000',
    },
    transition: 0,
  },
  header: {
    position: 'relative',
    padding: theme.spacing(1),
    borderBottom: '1px solid #ddd',
  },
  headerBack: {
    verticalAlign: 'top',
    display: 'inline-block',
  },
  headerTxt: {
    verticalAlign: 'top',
    display: 'inline-block',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '13.5px',
      paddingBottom: '13.5px',
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '18px',
    },
  },
  headerClose: {
    verticalAlign: 'top',
    display: 'inline-block',
    float: 'right',
  },
  body: {
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    height: 'auto',
  },
}));