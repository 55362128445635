import React from 'react';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core/';
import { Query } from "react-apollo";
import { GlobalContext } from '../../global-context';
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';


const GET_CUSTOMERS = gql`
  query {
    customers(order_by: {id: asc}) {
      ...Customer
    }
  }
  ${fragments.customer}
`;

export default class CustomerSelect extends React.Component {
  
  render() {
    return (
      <Query query={GET_CUSTOMERS} onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve customers data")}}>
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const customers = data.customers;
          return (
            <TextField
              select
              margin="dense"
              required={this.props.required ? this.props.required : false}
              name="customer"
              label={this.props.label ? this.props.label : "Customer"}
              placeholder="Select a customer..."
              variant="outlined"
              value={this.props.value}
              onChange={this.props.onChange}
              style={this.props.style ? this.props.style : {
                width: '100%',
                margin: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: "top" }} position="start">
                    <Icon color="disabled" fontSize="small">group</Icon>
                  </InputAdornment>)
              }}
            >
              {this.props.selectAllItem ?
                <MenuItem key={"All Customers"} value={0}>All Customers</MenuItem> :
                <MenuItem key={"Select a Customer"} value={0}><em>Select a Customer</em></MenuItem>
              }
              {customers.map(customer => (
                <MenuItem key={customer.name} value={customer.id}>
                  {customer.id} - {customer.name}
                </MenuItem>
              ))}
            </TextField>
          )
        }}
      </Query>
    )
  }
}
CustomerSelect.contextType = GlobalContext;