import React from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';

////////// COMPONENT //////////
export default function AccessorialsFooter(props) {
  const cls = useStyles();

  const { amount } = props;

  return (<>
    {amount &&
      <Grid container spacing={2}>
        <Grid item md={8} sm={6} xs={false}></Grid>
        <Grid item md={4} sm={6} xs={12}>
          <div className={cls.paper}>

            <Typography className={cls.headTxt}>Calculated Total</Typography>

            <div className={cls.smallBreak} />

            <Typography display="inline" className={cls.keyDemi}>Total&nbsp;</Typography>
            <Typography display="inline" className={cls.valDemi}>${amount.total.toFixed(2)}</Typography>

            {/* <div className={cls.lineBreak} />

            <Typography display="inline" className={cls.keyBold}>Total&nbsp;Successful&nbsp;</Typography>
            <Typography display="inline" className={cls.valBold}>${amount.successful.toFixed(2)}</Typography>
            <br />
            <Typography display="inline" className={cls.keyBold}>Total&nbsp;Failed&nbsp;</Typography>
            <Typography display="inline" className={cls.valBold}>${amount.failed.toFixed(2)}</Typography> */}

          </div>
        </Grid>
      </Grid>
    }
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '5px',
    background: '#f4f4f4',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  key: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  val: {
    float: 'right',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyDemi: {
    color: theme.palette.text.tertiary,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valDemi: {
    float: 'right',
    color: theme.palette.text.tertiary,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyBold: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valBold: {
    float: 'right',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '8px 0',
    background: '#ddd',
  },
}));
