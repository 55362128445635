import 'date-fns';
import React, { Component } from "react";
import gql from "graphql-tag";
import PropTypes from 'prop-types';
import fragments from '../../utils/graphQL/fragments';
import { TextField, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../../../global-context';
import { withRouter } from 'react-router';
import axios from 'axios';
import auth from '../../utils/auth0Helper';
import RegionAutoSuggest from './RegionAutoSuggest';

const log = false;

const styles = theme => ({
  grid: {
    width: '60%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const GET_REGION_BY_ID = gql`
  query get_region_by_id(
      $id: bigint!
      ){
        regions( where:
          {id:
            {_eq: $id}
          })
           {
            ...Region
           }
      }
      ${fragments.region}
`;

const UPSERT_REGION = gql`
    mutation upsert_region(
        $regionObjects: [regions_insert_input!]!){
        insert_regions(
        objects: $regionObjects
        on_conflict: {
            constraint: regions_pkey
            update_columns:[
                region_id
                team_id
                name
                description
                geofence
                updatedat
                last_synced
            ]
        }
        ) {
            returning {
            ...Region
            }
        }
    }
    ${fragments.region}
`;

class RegionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      description: '',
      region_id: '',
      team_id: '',
      regions: [],
    }
  }

  // sets region if in 'edit mode'
  componentDidMount = () => {
    if (this.props.formType === 'edit') {
      this.context.apolloClient.query({
        query: GET_REGION_BY_ID,
        variables: { id: parseInt(this.props.regionId) },
      }).then(res => {
        const foundRegion = res.data.regions[0]
        if (log) console.log("GET_REGION_BY_ID response (res.data):", res.data);
        this.setState({
          id: foundRegion.id,
          region_id: foundRegion.region_id,
          team_id: foundRegion.team_id,
          name: foundRegion.name,
          description: foundRegion.description,
        });
      }).catch(function (err) {
        console.error(err);
        throw err
      });
    }
    axios({
      method: "post",
      url: `https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/V2/view_regions`,
      data: {
        api_key: this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
        user_id: 0 // paramter required by Tookan but value is erroneous
      }
    }).then(res => {
      if (res.data.message === "INVALID_API_KEY") {
        console.log('Tookan.getRegions Invalid Tookan API key!', res)
        this.context.handleNotifications(true, "error", "Invalid Tookan API key.")
      } else {
        try {
          console.log("handleRegionOptions response from Tookan API - res.data.data:", res.data.data)
          this.setState({ regions: res.data.data.map(region => ({ label: region.region_name, value: region.region_id })) });
        } catch (err) {
          console.log(`handleRegionOptions failed fetching region list from Tookan API::`, err)
          this.context.handleNotifications(true, "error", "Failed to retrieve available regions from Tookan: ", err.toString())
        }
      }
    }).catch(err => {
      console.log(`handleRegionOptions failed fetching region list from Tookan API:`, err)
      this.context.handleNotifications(true, "error", "Failed to retrieve available regions from Tookan: ", err.toString())
    })
  }

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value.trim() });
  };

  handleAutoSuggest = event => {
    this.setState({
      region_id: event ? event.value : '',
      name: event ? event.label : ''
    })
  }

  clearForm = () => {
    this.setState({
      id: '',
      name: '',
      description: '',
      region_id: '',
      team_id: '',
    });
  }

  handleButtonDisable = () => {
    if (!this.state.region_id) return true;
    else if (this.state.region_id.toString().trim().length < 1) return true;
    else return false;
  }

  upsertRegion = async () => {
    const context = this.context;
    const props = this.props;
    const
      tookanAPIKey = context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
      client = context.apolloClient,
      tookanBaseURL = `https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/V2`;
    let regionObj = { id: this.state.id || null, region_id: this.state.region_id };
    if (log) console.log("starting syncRegionWithTookan with ", tookanAPIKey, client, regionObj)
    const handleGeofence = (arr) => {
      let geofence = [];
      for (let obj of arr) {
        let geopoint = [obj.y, obj.x];
        geofence.push(geopoint)
      }
      return {
        "type": "Polygon",
        "coordinates": [
          geofence
        ],
        "crs": {
          "type": "name",
          "properties": {
            "name": "EPSG:4326"
          }
        }
      };
    }
    // Look up region in Tookan
    if (log) console.log("Looking up region in Tookan with obj:", regionObj)
    const region = await axios({
      method: "post",
      url: `${tookanBaseURL}/view_regions`,
      data: {
        api_key: tookanAPIKey,
        user_id: 0, // user_id is required by Tookan but appears to be erroneous as the number passed in does not matter
        region_id: regionObj.region_id
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        if (log) console.log("Response from region lookup in Tookan", res)
        if (parseInt(res.status) === 200 && parseInt(res.data.status) === 200) {
          let region_data = res.data.data[0];
          if (log) console.log("found region_data", region_data)
          let regionInsertObj = {
            region_id: region_data.region_id,
            name: region_data.region_name,
            description: region_data.region_description,
            team_id: region_data.selected_team_id[0],
            geofence: handleGeofence(region_data.region_data),
            updatedat: "now()",
            last_synced: "now()"
          }
          if (regionObj.id) {
            if (parseInt(regionObj.id)) Object.assign(regionInsertObj, { id: regionObj.id })
          }
          return regionInsertObj;
        }
        else {
          let msg = "No regions found."
          if (log) console.log(msg)
          return context.handleNotifications(true, "error", msg);
        }
      })
      .catch(err => {
        let msg = "Failed to retrieve region data:"
        console.log(msg)
        return err
      })
    if (log) console.log("region set to", region)

    if (!region) return;

    // Upsert region entry in Hasura with Tookan response
    if (log) console.log("upserting with obj:", JSON.stringify(region))
    client.mutate({
      mutation: UPSERT_REGION,
      variables: { regionObjects: region }
    })
      .then(async res => {
        if (log) console.log("UPSERT_REGION res:", res.data.insert_regions.returning[0]);
        let returnObject = res.data.insert_regions.returning[0];
        if (returnObject) {
          if (returnObject.id) {
            
            // props.formType === 'edit' ? context.handleNotifications(true, "success", "Region successfully updated.") : context.handleNotifications(true, "success", "New region created.")

            // Update user's metadata to allow the new region_id
            let newRegions = context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-allowed-regions"]
            if (!newRegions) newRegions = [];
            else newRegions = JSON.parse(newRegions.replace("{", "[").replace("}", "]"));
            // If the returned region_id is already allowed, return early
            if (newRegions.includes(returnObject.id)) {
              return props.history.push(`/regions/${returnObject.id}`);
            } else {
              // Otherwise, add the region_id to the user's metadata
              newRegions.push(returnObject.id)
              await auth.updateMetadata(context.userProfile.sub, {
                "allowed_regions": newRegions
              })
                .then(async res => {
                  if (log) console.log("response from updateMetadata function", res)
                  if (res.status !== 200) {
                    context.handleNotifications(true, "error", "Failed to update allowed regions.")
                  }
                  // The below commented out code should in theory work
                  // The intended purpose is to update the user's metadata through the code above
                  // Afterwards, the new JWT is fetched is then used to update the user profile and apollo client
        
                  // let { __raw: token } = await context.auth0.getIdTokenClaims();
                  // await context.setUserAuth(context.auth0.user, token);
                  // await context.setupApollo(token);
                  //     setLoading(false);
                  //     props.toggle();
        
                  // Since the above is failing, forcing the app to relogin will update the user's auth
                  context.auth0.loginWithRedirect()
                })
                .catch(err => {
                  context.handleNotifications(true, "error", "Failed to update allowed regions. " + err.toString())
                })
            }
          }
        }
      })
      .catch(err => {
        console.log(err)
        props.formType === 'edit' ? context.handleNotifications(true, "error", "Failed to update region. Response received: " + JSON.stringify(err)) : context.handleNotifications(true, "error", "Failed to create new region. Response received: " + JSON.stringify(err));
      })
  }

  render() {
    // const { classes } = this.props;
    return (
      <>
        <Typography style={{ marginLeft: "45px" }} color="textSecondary">
          Region creation and maintenance take place in the Tookan Dashboard.  Please reference the region (geofence) ID and corresponding team ID when creating a new region or syncing an existing region with Tookan.
                </Typography>
        <div
          style={{
            marginTop: "50px",
            marginLeft: "45px",
            width: "500px",
            height: "50px"
          }}
        >
          <RegionAutoSuggest
            region={{
              id: this.state.id,
              region_id: this.state.region_id,
              name: this.state.name,
            }}
            handleAutoSuggest={this.handleAutoSuggest}
            regions={this.state.regions}
          />
          <br />
        </div>
        {
          this.props.formType === 'edit' ? <>
            <TextField
              name="description"
              label="Description"
              style={{
                marginTop: "30px",
                marginLeft: "45px",
                width: "500px",
              }}
              value={this.state.description}
              margin="normal"
              variant="outlined"
              disabled
            />
            <br />
          </> : null
        }
        {
          this.props.formType === 'edit' ? <>
            <TextField
              name="id"
              label="ID"
              style={{
                marginTop: "10px",
                marginLeft: "45px",
                width: "500px",
              }}
              value={this.state.id}
              margin="normal"
              variant="outlined"
              disabled
            />
            <br />
          </> : null
        }
        <TextField
          required
          disabled
          name="region_id"
          label="Geofence ID"
          style={{
            marginTop: this.props.formType === 'edit' ? "10px" : "30px",
            marginLeft: "45px",
            width: "500px",
          }}
          value={this.state.region_id}
          margin="normal"
          variant="outlined"
        />
        <br />
        {
          this.props.formType === 'edit' &&
          <TextField
            disabled
            name="team_id"
            label="Team ID"
            style={{
              marginTop: "10px",
              marginLeft: "45px",
              width: "500px",
            }}
            value={this.state.team_id}
            onChange={this.handleInputChange}
            margin="normal"
            variant="outlined"
          />
        }
        <br />
        <Button
          disabled={this.handleButtonDisable()}
          style={{
            marginTop: "30px",
            marginLeft: "45px"
          }}
          color="primary"
          variant="contained"
          onClick={() => this.upsertRegion()}
        >
          {this.props.formType === 'add' ? "Save" : "Resync with Tookan"}
        </Button>
        {this.props.formType === 'add' &&
          <Button
            style={{
              marginTop: "30px",
              marginLeft: "60px"
            }}
            color="default"
            variant="contained"
            onClick={this.clearForm}
          >
            Clear
          </Button>
        }
      </>
    )
  }
};

RegionForm.contextType = GlobalContext;

RegionForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(RegionForm));