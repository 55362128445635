import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Select, InputAdornment, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Badge } from '@material-ui/core';
import DotMenu from '../../reusable/DotMenu';
import { GlobalContext } from '../../../global-context';
import gql from 'graphql-tag';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

let log = true;

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2),
    textTransform: "none !important",
  },
  badge: {
    backgroundColor: 'white !important',
    textTransform: "none !important",
    position: 'relative',
  },
  badgeIcon: {
    marginTop: '-5px',
    marginRight: '-3px',
    transform: 'scale(0.75)',
  },
  table: {
    minWidth: 700,
  },
  formField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const ManageAccessorialsTable = (props) => {

  const context = useContext(GlobalContext);

  const classes = useStyles();

  const [newCharge, setCharge] = useState({
    code: '',
    cost: 0,
    time: 30,
    customer: 0,
    driver: 0,
    notes: '',
  });

  const [updatedCharge, setUpdatedCharge] = useState({
    id: '',
    code: '',
    cost: 0,
    time: 30,
    customer: 0,
    driver: 0,
    notes: '',
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("props.perMinRate", props.perMinRate)
  }, [props.perMinRate])

  const handleAddAccessorial = () => {
    setLoading(true)
    try {
      context.apolloClient.mutate({
        mutation: gql`
          mutation newAccessorial(
              $move_id: bigint!
              $code: String!
              $notes: String!
              $cost: numeric!
              $ap_amount: numeric!
              $ar_amount: numeric!
          ) {
              insert_accessorials(objects: {
                move_id: $move_id
                code: $code
                notes: $notes
                cost: $cost
                ap_amount: $ap_amount
                ar_amount: $ar_amount
              }) {
                returning {
                  id
              }
            }
          }
        `,
        variables: {
          move_id: props.moveId,
          code: newCharge.code,
          notes: newCharge.notes,
          cost: Number(newCharge.cost),
          ap_amount: Number(newCharge.driver),
          ar_amount: Number(newCharge.customer),
        },
      })
        .then(res => {
          if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
            handleError("Failed to save new accessorial.")
          }
          else {
            if (log) console.log("New accessorial insertion:", res)
            setCharge({
              code: '',
              cost: 0,
              time: 30,
              customer: 0,
              driver: 0,
              notes: '',
            })
            setLoading(false)
          }
        })
    } catch (err) {
      context.handleNotifications(true, "error", `Failed to add new accessorial: ${err.toString()}`);
      setLoading(false)
    }
  };

  const handleError = msg => {
    setLoading(false);
    context.handleNotifications(true, "error", msg)
  }

  const handleDeleteAccessorial = id => {
    setLoading(true)
    try {
      context.apolloClient.mutate({
        mutation: gql`
          mutation deleteAccessorial($id: bigint!) {
            delete_accessorials(where: {id: {_eq: $id}}) {
              affected_rows
            }
          }
        `,
        variables: { id: id },
      })
        .then(res => {
          if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
            handleError("Failed to delete accessorial.")
          }
          else setLoading(false)
        })
    } catch (err) {
      context.handleNotifications(true, "error", `Failed to delete accessorial: ${err.toString()}`);
      setLoading(false)
    }
  };

  const handleEditAccessorial = () => {
    setLoading(true)
    try {
      context.apolloClient.mutate({
        mutation: gql`
          mutation updateAccessorial(
            $id: bigint!
            $code: String!
            $notes: String!
            $cost: numeric!
            $ap_amount: numeric!
            $ar_amount: numeric!
          ) {
            update_accessorials(
              where: {id: {_eq: $id}}
              _set: {
                code: $code
                notes: $notes
                cost: $cost
                ap_amount: $ap_amount
                ar_amount: $ar_amount
                updatedat: "now()"
              }
            ) {
              affected_rows
            }
          }
        `,
        variables: {
          id: parseInt(updatedCharge.id),
          code: updatedCharge.code,
          notes: updatedCharge.notes,
          cost: Number(updatedCharge.cost),
          ap_amount: Number(updatedCharge.driver),
          ar_amount: Number(updatedCharge.customer),
        },
      })
        .then(res => {
          if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
            handleError("Failed to update accessorial.")
          }
          else {
            setUpdatedCharge({
              id: '',
              code: '',
              time: 30,
              cost: 0,
              customer: 0,
              driver: 0,
              notes: '',
            })
            setLoading(false)
          }
        })
    } catch (err) {
      context.handleNotifications(true, "error", `Failed to update accessorial: ${err.toString()}`);
      setLoading(false)
    }
  };

  const handleChange = name => event => setCharge({ ...newCharge, [name]: event.target.value });

  const handleUpdateChange = name => event => setUpdatedCharge({ ...updatedCharge, [name]: event.target.value });

  const chargeIsValid = () => {
    if (
      !newCharge.code || String(newCharge.code).trim().length < 1 ||
      String(newCharge.cost).trim().length < 1 ||
      String(newCharge.customer).trim().length < 1 ||
      String(newCharge.driver).trim().length < 1 ||
      !newCharge.notes || String(newCharge.notes).trim().length < 1
    ) return false;
    else return true;
  }

  const updatedChargeIsValid = () => {
    if (
      !updatedCharge.code || String(updatedCharge.code).trim().length < 1 ||
      String(updatedCharge.cost).trim().length < 1 ||
      String(updatedCharge.customer).trim().length < 1 ||
      String(updatedCharge.driver).trim().length < 1 ||
      !updatedCharge.notes || String(updatedCharge.notes).trim().length < 1
    ) return false;
    else return true;
  }

  const handleformSubmit = action => event => {
    event.preventDefault();
    if (action === "save") {
      if (!chargeIsValid()) {
        context.handleNotifications(true, "info", "Please fill out all accessorial fields.");
        return;
      } else handleAddAccessorial();
    } else if (action === "update") {
      if (!updatedChargeIsValid()) {
        context.handleNotifications(true, "info", "Please fill out all accessorial fields.");
        return;
      } else handleEditAccessorial();
    }
  }

  const isApAmountPaid = (accessorial) => {
    if (accessorial.appayment) {
      if (accessorial.appayment.status === 'paid') return true;
      else return false;
    } else return false;
  }

  const isArAmountPaid = (accessorial) => {
    if (accessorial.move.accountsReceivable) {
      if (accessorial.move.accountsReceivable.status === 'paid') return true;
      else return false;
    } else return false;
  }

  const handleTimeChange = type => event => {
    let delayCost = event.target.value > 30 ? (props.perMinRate * (event.target.value - 30)).toFixed(2) : 0;
    if (type === 'update') {
      setUpdatedCharge({
        ...updatedCharge,
        "time": event.target.value,
        "cost": delayCost,
        "customer": delayCost,
        "driver": delayCost
      })
    } else {
      setCharge({
        ...newCharge,
        "time": event.target.value,
        "cost": delayCost,
        "customer": delayCost,
        "driver": delayCost
      })
    }
  };


  const accessorialForm = (type, Charge) => {
    let onChange = type === 'update' ? handleUpdateChange : handleChange
    return (<>
      {/* Code */}
      <TableCell padding="none" component="th" scope="row">
        <Select
          disabled={loading}
          value={Charge.code}
          onChange={onChange("code")}
          displayEmpty
          name="code"
          className={classes.formField}
          style={{ minWidth: "70px" }}
        >
          {
            (process.env.REACT_APP_ACC_CODES || '').split(',').map(code => <MenuItem key={`${code}key1`} value={code}>{code}</MenuItem>)
          }
        </Select>
      </TableCell>
      {/* Set Cost */}
      {Charge.code !== "delay" ?
        <TableCell padding="none" align="left">
          <form onSubmit={handleformSubmit("save")}>
            <TextField
              disabled={loading}
              id="cost"
              className={classes.formField}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              type="number"
              value={Charge.cost}
              onChange={onChange('cost')}
            />
          </form>
        </TableCell>
        :
        <TableCell padding="none" align="left">
          <form onSubmit={handleformSubmit("update")}>
            <TextField
              disabled={loading}
              id="time"
              className={classes.formField}
              InputProps={{
                endAdornment: <InputAdornment position="end">mins</InputAdornment>,
              }}
              type="number"
              value={Charge.time}
              onChange={handleTimeChange(type)}
            />
          </form>
        </TableCell>}

      {/* Customer Cost */}
      {props.type !== 'driver' ? <TableCell padding="none" align="left">
        <form onSubmit={handleformSubmit("save")}>
          <TextField
            disabled={loading || Charge.code === "delay"}
            id="customer"
            className={classes.formField}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            type="number"
            value={Charge.customer}
            onChange={onChange('customer')}
          />
        </form>
      </TableCell> : null}
      {/* Driver Cost */}
      {props.type !== 'customer' ? <TableCell padding="none" align="left">
        <form onSubmit={handleformSubmit("save")}>
          <TextField
            disabled={loading || Charge.code === "delay"}
            id="driver"
            className={classes.formField}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            type="number"
            value={Charge.driver}
            onChange={onChange('driver')}
          />
        </form>
      </TableCell> : null}
      {/* Notes */}
      <TableCell padding="none" align="left">
        <form onSubmit={handleformSubmit("save")}>
          <TextField
            disabled={loading}
            id="notes"
            className={classes.formField}
            style={{ minWidth: "150px" }}
            value={Charge.notes}
            onChange={onChange('notes')}
          />
        </form>
      </TableCell>
    </>)
  }

  return (
    <>

      {props.type ? null : (
        <DialogTitle>
          Manage Accessorials
        </DialogTitle>
      )}
      <DialogContent>
        {props.type ? null : (
          <DialogContentText>
            Add or remove accessorial charges to the move.
          </DialogContentText>
        )}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="none" align="left">Code</TableCell>
              <TableCell padding="none" align="left">Cost</TableCell>
              {props.type !== 'driver' ? <TableCell padding="none" align="left">Customer</TableCell> : null}
              {props.type !== 'customer' ? <TableCell padding="none" align="left">Driver</TableCell> : null}
              <TableCell padding="none" align="left">Notes</TableCell>
              <TableCell padding="none" align="right"></TableCell> {/* Used to render an action menu */}
            </TableRow>
          </TableHead>
          <TableBody>

            {
              Array.from(props.accessorials || []).map((charge, i) => {
                const actions = [
                  {
                    label: "edit",
                    function: () => {
                      setUpdatedCharge({
                        id: charge.id,
                        code: charge.code,
                        time: 30,
                        cost: charge.cost,
                        customer: charge.ar_amount,
                        driver: charge.ap_amount,
                        notes: charge.notes,
                      })
                    }
                  },
                  { label: "delete", function: () => handleDeleteAccessorial(charge.id) }
                ]
                return charge.id !== updatedCharge.id ? (
                  // List of saved accessorials
                  <TableRow key={`accessorial-${i}`}>
                    {/* code */}
                    <TableCell padding="none" component="th" scope="row">{charge.code}</TableCell>
                    {/* Cost */}
                    {<TableCell padding="none" align="left">${charge.cost.toFixed(2)}</TableCell>}
                    {/* Driver Cost */}
                    {props.type !== 'driver' ? <TableCell padding="none" align="left">
                      {isArAmountPaid(charge) ? (
                        <Badge badgeContent={<MoneyIcon className={classes.badgeIcon} style={{ color: 'blue' }} />} className={classes.badge}>
                          ${charge.ar_amount.toFixed(2)}
                        </Badge>
                      )
                        : '$' + charge.ar_amount.toFixed(2)
                      }
                    </TableCell> : null}
                    {/* Customer Cost */}
                    {props.type !== 'customer' ? <TableCell padding="none" align="left">
                      {isApAmountPaid(charge) ? (
                        <Badge badgeContent={<MoneyIcon className={classes.badgeIcon} style={{ color: 'green' }} />} className={classes.badge}>
                          ${charge.ap_amount.toFixed(2)}
                        </Badge>
                      )
                        : '$' + charge.ap_amount.toFixed(2)
                      }
                    </TableCell> : null}
                    {/* Notes */}
                    <TableCell padding="none" align="left" style={{ whiteSpace: 'nowrap', overflowX: 'hidden', maxWidth: '200px' }}>{charge.notes}</TableCell>
                    <TableCell padding="none" align="left"><DotMenu actions={actions} /></TableCell>
                  </TableRow>
                )
                  :
                  //Update Accessorial form 
                  <TableRow key={`new-accessorial`}>
                    {accessorialForm('update', updatedCharge)}
                    <TableCell padding="none" align="right">
                      <Button
                        onClick={() => {
                          setUpdatedCharge({
                            id: '',
                            code: '',
                            time: 30,
                            cost: 0,
                            customer: 0,
                            driver: 0,
                            notes: '',
                          })
                        }}
                        className={classes.button}
                        size="small"
                        variant="contained"
                        color="default"
                        style={{ transform: "scale(.75)" }}
                      >
                        Cancel
                        </Button>
                      <Button
                        disabled={loading || !updatedChargeIsValid()}
                        onClick={handleEditAccessorial}
                        className={classes.button}
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ transform: "scale(.75)" }}
                      >
                        Update
                        </Button>
                    </TableCell>
                  </TableRow>
              })
            }
            <br />
            <br />
            {/* New Accessorial Form */}
            <TableRow>
              {accessorialForm('create', newCharge)}
              {/* Save Button */}
              <TableCell padding="none" align="right">
                <Button
                  disabled={loading || !chargeIsValid()}
                  onClick={handleAddAccessorial}
                  className={classes.button}
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ transform: "scale(.75)" }}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>

      </DialogContent>
      {props.type ? null : (
        <DialogActions>
          <Button
            className={classes.button}
            size="small"
            color="default"
            // variant="contained"
            onClick={props.close}
          >
            Close
        </Button>
          {/* <Button
          className={classes.button}
          size="small"
          color="primary"
          onClick={props.close}
        // variant="contained"
        >
          Save
        </Button> */}
        </DialogActions>
      )}
    </>
  )
}

export default ManageAccessorialsTable;