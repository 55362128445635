import React, { useState, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../../../global-context";
import gql from "graphql-tag";
import fragments from "../../utils/graphQL/fragments";
import { makeStyles } from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

const log = true;

////////// COMPONENT //////////
export default function LocationDetailsTextEditor(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { location } = props;

  const [content, setContent] = useState(``);

  let editorState = EditorState.createEmpty();
  if (location.notes) {
    editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(location.notes))
    );
  }

  const updateLocationNotes = (notes) => {
    ctx.apolloClient
      .mutate({
        mutation: EDIT_LOCATION_NOTE,
        variables: {
          id: location.id,
          notes: notes,
        },
      })
      .then((res) => {
        log && console.log(`>> Successfully updated location notes:`, res.data);
        ctx.handleNotifications(
          true,
          `success`,
          `Successfully updated location notes.`
        );
      })
      .catch((err) => {
        console.log(`<< Failed to update location notes:`, err);
        ctx.handleNotifications(
          true,
          `error`,
          `Failed to update location notes: ${err}`
        );
      });
  };

  const handleSave = (data) => {
    updateLocationNotes(content);
  };

  const handleChange = (editorState) => {
    const contentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    setContent(contentState);
  };

  const handleUpload = (file) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: `POST`,
        url: `/.netlify/functions/locationImageUploader`,
        data: { file: file.body, locationId: location.id },
        headers: {
          authorization: `Bearer ${ctx.userToken}`,
        },
      })
        .then((res) => {
          log && console.log(res.data);
          resolve({ publicUrl: `${res.data.publicUrl}` });
        })
        .catch((err) => {
          console.error(`Error uploading the image: ${err}`);
          resolve({
            publicUrl: `${file.body}`,
            error: `Error uploading the image: ${err}`,
          });
        });
    });
  };

  return (
    <>
      <div className={cls.main}>
        <div className={cls.paper}>
          <MUIRichTextEditor
            value={location.notes}
            label="Type something here..."
            onSave={handleSave}
            onChange={handleChange}
            inlineToolbar={true}
            onUpload={handleUpload}
          />
        </div>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    border: "1px solid #ddd",
    borderRadius: "8px",
    background: "#fff",
  },
}));

////////// GRAPHQL //////////
const EDIT_LOCATION_NOTE = gql`
  mutation update_location($id: bigint!, $notes: String!) {
    update_locations(
      where: { id: { _eq: $id } }
      _set: { updatedat: "now()", notes: $notes }
    ) {
      returning {
        ...Location
      }
    }
  }
  ${fragments.location}
`;
