import React, { useContext, useEffect, useState } from 'react';
import ManageAccessorialsTable from './ManageAccessorialsTable';
import gql from "graphql-tag";
import { Subscription } from 'react-apollo';
import Loading from '../../utils/Loading';
import fragments from '../../utils/graphQL/fragments';
import { GlobalContext } from '../../../global-context';
import { Dialog } from '@material-ui/core';

// let log = false;

const getAccessorials = (type = 'query') => (gql`
    ${type} getAccessorials($id: bigint!) {
      accessorials(where: { move_id: {_eq: $id} } ) {
          ...Accessorials
          move {
            accountsReceivable {
              status
            }
          }
          appayment {
            ...APPayment
          }
        }
      }
      ${fragments.accessorials}
      ${fragments.appayment}
    `);
const GET_LANE_INFO = gql`
query GetLaneInfo($move_id:bigint!) {
  moves(where: {id: {_eq: $move_id}}) {
    lane {
      distance_miles
      driver_pay_per_minute
      pickup{
        region_id
      }
    }
    customer_id
  }
}
`
const GET_PER_MIN_RATES = gql`query getPayRateRules(
    $date: timestamptz!,
    $region: bigint!,
    $customer: bigint!,
    $distance: numeric!
  ) {
    byCustomerAndRegion: payraterules(where:
      {_and: [
        {payrategroup: {active: {_eq: true}}},
        {payrategroup: {customer_id: {_eq: $customer}}},
        {payrategroup: {begin_date: {_lte: $date}}},
        {payrategroup: {end_date: {_gt: $date}}},
        {payrategroup: {region_id: {_eq: $region}}},
        {distance_start: {_lte: $distance}},
        {distance_end: {_gt: $distance}}
      ]}
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
    byCustomer: payraterules(where:
      {_and: [
        {payrategroup: {active: {_eq: true}}},
        {payrategroup: {customer_id: {_eq: $customer}}},
        {payrategroup: {begin_date: {_lte: $date}}},
        {payrategroup: {end_date: {_gt: $date}}},
        {payrategroup: {region_id: {_is_null: true}}},
        {distance_start: {_lte: $distance}},
        {distance_end: {_gt: $distance}}
      ]}
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
    byRegion: payraterules(where:
      {_and: [
        {payrategroup: {active: {_eq: true}}},
        {payrategroup: {customer_id: {_is_null: true}}},
        {payrategroup: {begin_date: {_lte: $date}}},
        {payrategroup: {end_date: {_gt: $date}}},
        {payrategroup: {region_id: {_eq: $region}}},
        {distance_start: {_lte: $distance}},
        {distance_end: {_gt: $distance}}
      ]}
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
    byMileage: payraterules(where:
      {_and: [
        {payrategroup: {active: {_eq: true}}},
        {payrategroup: {customer_id: {_is_null: true}}},
        {payrategroup: {begin_date: {_lte: $date}}},
        {payrategroup: {end_date: {_gt: $date}}},
        {payrategroup: {region_id: {_is_null: true}}},
        {distance_start: {_lte: $distance}},
        {distance_end: {_gt: $distance}}
      ]}
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
  }
`
/**
  * Add or remove accessorial charges to a move
  * @param {BigInteger} moveId  - Requires a move ID passed in as a prop in order to make accessorial changes
  * @param {Boolean} open - Pass in a boolean value of true to render the dialog
  * @param {Function} close - Function to toggle the 'open' value from true to false
*/
const ManageAccessorials = (props) => {

  const context = useContext(GlobalContext)
  const [payPerMinRate, setPayPerMinRate] = useState(false);
  useEffect(() => {
    console.log("useEffect", props.moveId)
    const fetchData = async () => {
      await context.apolloClient.query({
        query: GET_LANE_INFO,
        variables: { move_id: props.moveId }
      }).then(async (res) => {
        console.log('----RES----', res)
        let move = res.data ? res.data.moves[0] : null
        // IF LANE OVERRIDE EXISTS, SET PERMINUTE RATE TO OVERRIDE
        if (
          move &&
          move.lane &&
          move.lane.driver_pay_per_minute !== null
        ) {
          console.log(move.lane.driver_pay_per_minute)
          setPayPerMinRate(move.lane.driver_pay_per_minute)
        } else if ( // GET OTHER PERMINUTE RATES (ORGANIZED BY SPECIFICITY)
          move &&
          move.lane &&
          move.lane.pickup &&
          move.lane.distance_miles) {
          await context.apolloClient.query({
            query: GET_PER_MIN_RATES,
            variables: { date: "now()", region: move.lane.pickup.region_id, customer: move.customer_id, distance: move.lane.distance_miles }
          }).then(res => {
            console.log("RATERULE RES", res)
            if (res.data &&  res.data.byCustomerAndRegion &&  res.data.byCustomerAndRegion.length > 0) { setPayPerMinRate(res.data.byCustomerAndRegion[0].per_minute_rate) }
            else if(res.data && res.data.byCustomer && res.data.byCustomer.length > 0){setPayPerMinRate(res.data.byCustomer[0].per_minute_rate)}
            else if(res.data && res.data.byRegion && res.data.byRegion.length > 0){setPayPerMinRate(res.data.byRegion[0].per_minute_rate)}
            else if(res.data && res.data.byMileage && res.data.byMileage.length > 0){setPayPerMinRate(res.data.byMileage[0].per_minute_rate)}
            else { context.handleNotifications(true, "error", "No PayRateRule Data Found - Cannot Set PerMinRate") }
          })
        } else {
          context.handleNotifications(true, "error", "No Lane Data Found - Cannot Query PerMinRate")
        }
      })
    }
    try {
      fetchData()
    } catch (err) {
      console.log('ERROR', err)
      context.handleNotifications(true, "error", "Unknown Error Getting Per Minute Pay Data")

    }
  }, [props.moveId])

  return (
    <>
      {props.open && props.moveId &&
        <Subscription
          subscription={getAccessorials('subscription')}
          variables={{ id: props.moveId }}
          onError={err => context.handleNotifications(true, "error", "Query failed to retrieve moves data: " + err.toString())}>
          {({ ...result }) => {
            if (result.loading) return <Loading />;
            if (result.error) return `Error! ${result.error.message}`;
            return <Dialog
              id="ManageAccessorialsDialog"
              open={props.open}
            >
              <ManageAccessorialsTable
                accessorials={Array.from(result.data.accessorials || [])}
                close={props.close}
                moveId={props.moveId}
                perMinRate={payPerMinRate}
              />
            </Dialog>
          }}
        </Subscription>
      }
    </>
  )
}

export default ManageAccessorials;