import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';

const AuthContext = createContext({});

function AuthProvider({children}) {
  const fetchLocalUser = async () => {
    const localUserJson = await localStorage.getItem('user');
    const localUser = localUserJson && JSON.parse(localUserJson);
    setUser(localUser);
  };
  const [user, setUser] = useState();

  useEffect(() => {
    console.log('[AuthProvider] Initial startup');
    fetchLocalUser();
  }, []);

  const saveUser = user => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  const deleteUser = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const login = user => sendRequest('login', user, saveUser);
  const logout = () => sendRequest('logout', undefined, deleteUser);

  return <AuthContext.Provider value={{user, login, logout}}>{children}</AuthContext.Provider>;
}

async function sendRequest(endpoint, body, successCallback) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  };

  if (body) {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify(body);
  }

  const response = await fetch(`https://sat-driver-app-backend.netlify.app/api/v1/auth/${endpoint}`, requestOptions);

  if (response.ok) {
    const responseBody = await response.json();
    successCallback(responseBody);
  }
}

const useAuth = () => useContext(AuthContext);

export {useAuth, AuthProvider};
