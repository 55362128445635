import React, { useEffect, useContext, useState } from "react";
import { makeStyles, Grid, Paper, Box } from "@material-ui/core";
import { GlobalContext } from "../../global-context";
import RateRuleGroupSelector from "./customerPricing/RateRuleGroupSelector";
import RateRuleGroupEditor from "./customerPricing/RateRuleGroupEditor";

const log = true

export default function CustomerPricing({customer}) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [selectedRateRuleGroup, setSelectedRateRuleGroup] = useState(null);
  const [editorHasChanges, setEditorHasChanges] = useState(false);

  const defaultRateRuleGroup = {
    id: null,
    customer_id: customer.id,
    name: 'Original Pricing',
    description: 'Pricing entered before time based versioning was a thing.',
    begin_date: '2018-05-01',
    end_date: '2021-12-31',
    createdat: null,
    updatedat: null,
    raterules: customer.raterules
  }

  useEffect(() => {
    //If we don't have an array of rate rule groups then artificially create one
    try {
      if (customer.raterulegroups.length < 1) {
        customer.raterulegroups = [defaultRateRuleGroup]
      }
    } catch (error) {
      customer.raterulegroups = [defaultRateRuleGroup]
    }
  }, [customer])

  const handleRateRuleGroupChange = rateRuleGroup => {
    log && console.log('CustomerPricing: Rate rule group selected: ', rateRuleGroup)
    setEditorHasChanges(false)
    setSelectedRateRuleGroup(rateRuleGroup)
  }

  const handleEditorHasChanges = () => {
    log && console.log('Editor just informed that there are now changes!')
    setEditorHasChanges(true)
  }

  return (
    <div className={cls.root}>
      <Grid container>
        <Grid item md={12}>
          <Paper>
            <Grid container>
                <Grid item md={4}>
                  <Box borderRight={0} borderColor="LightGrey" display="flex" flexWrap="wrap" alignContent="flex-start" height="100%">
                    <RateRuleGroupSelector allowChanges={!editorHasChanges} rateRuleGroups={customer && customer.raterulegroups} rateRules={customer && customer.raterules} onChange={handleRateRuleGroupChange} />
                  </Box>
                </Grid>
                <Grid item md={8} >
                  <Box m={2}>
                    <RateRuleGroupEditor customer={customer} rateRuleGroup={selectedRateRuleGroup} onHasChanges={handleEditorHasChanges} />
                  </Box>
                </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));