import React, { useState, useContext, useEffect } from "react";
import { withRouter } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { GlobalContext } from "../../global-context";

import { makeStyles, Container, SwipeableDrawer, Typography, Icon, IconButton, Divider, Checkbox, Tooltip, Dialog } from "@material-ui/core";
import classNames from "classnames";

import CarmaxInvoice from "./CarmaxInvoice/CarmaxInvoice";
import Modal from "../reusable/Modal";
import PostPaymentModal from "./ARInvoices/PostPaymentModal";
import ARMoveDetail from './ARInvoices/ARMoveDetail';
import ARIndex from "./ARInvoices/ARIndex";
import ARIndexFilter from "./ARInvoices/ARIndexFilter";
import ARInvoiceHeader from "./ARInvoices/ARInvoiceHeader";
import ARInvoiceFooter from "./ARInvoices/ARInvoiceFooter";

import gql from "graphql-tag";
import { Query } from "react-apollo";

import Loading from '../utils/Loading';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';
import DocumentDefinition from '../utils/PDFDocumentDefinition';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
};

////////// TOOLS //////////
const defaultStart = moment.utc(moment().subtract(6, `months`)).format();
const defaultEnd = moment.utc(moment()).format();

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

////////// COMPONENT //////////
function ARInvoices(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const localInvoiceId = parseInt(localStorage.getItem('invoiceId'));
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(localInvoiceId || null);
  const localCustomerId = parseInt(localStorage.getItem('customerId'));
  const [customerId, setCustomerId] = useState(localCustomerId || null);
  const localExpandedRowId = parseInt(localStorage.getItem('expandedRowId'));

  const [start, setStart] = useState(Date.parse(localStorage.getItem('ar-invoice-start')) ? localStorage.getItem('ar-invoice-start') : defaultStart);
  const [end, setEnd] = useState(Date.parse(localStorage.getItem('ar-invoice-end')) ? localStorage.getItem('ar-invoice-end') : defaultEnd);

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(localExpandedRowId || 0);

  const [modalRender, setModalRender] = useState(null);
  const [modalData, setModalData] = useState(null);

  const [checkedItems, setCheckedItems] = useState([])

  const [openPostPaymentModal, setOpenPostPaymentModal] = useState(false)
  const [manualPaymentMethodId, setManualPaymentMethodId] = useState(null)
  const [armoveData, setArmoveData] = useState([])

  const [carmaxInvoice, setCarmaxInvoice] = useState(null)

  const handleDateChange = (value, name) => {
    const newDate = moment.utc(moment(value)).format();
    if (name === `start`) {
      localStorage.setItem(`ar-invoice-start`, newDate);
      setStart(newDate);
    }
    else {
      localStorage.setItem(`ar-invoice-end`, newDate);
      setEnd(newDate);
    }
  }

  // Set invoiceId and customerId in local storage
  useEffect(() => {
    localStorage.setItem("customerId", customerId)
  }, [customerId])
  useEffect(() => {
    localStorage.setItem("invoiceId", selectedInvoiceId)
  }, [selectedInvoiceId])
  useEffect(() => {
    localStorage.setItem("expandedRowId", expandedRowId)
  }, [expandedRowId])

  const [drawer, setDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setDrawer(true);
  }
  const handleDrawerClose = () => {
    setDrawer(false);
  }

  const applyFilters = (rows) => {
    if (!search || search.length < 1) {
      return rows
    } else {
      return rows.filter(o =>
        (o.move.id && (o.move.id + ``).toLocaleLowerCase().includes(search)) ||
        (o.move.vehicle_stock && o.move.vehicle_stock.toLocaleLowerCase().includes(search)) ||
        (o.move.reference_num && o.move.reference_num.toLocaleLowerCase().includes(search)) ||
        (o.move.lane.description && o.move.lane.description.toLocaleLowerCase().includes(search))
      )
    }
  }

  const generateCSV = (invoice) => {
    // Create rows and options for CSV
    const createCsvRow = (armove) => {
      return {
        MOVE_ID: armove.move.id,
        REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
        MOVE_DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
        STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : `-`,
        LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
        DISTANCE: armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
        TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
      }
    }
    const csvRows = invoice.armoves.map(armove => createCsvRow(armove));
    const csvOptions = {
      filename: `${invoice.customer.name.replace(/ /g, "_")}_Invoice_${moment(invoice.start_datetime).add(1, "day").format('MM-DD-YYYY')}_to_${moment(invoice.end_datetime).format('MM-DD-YYYY')}`,
      showTitle: true,
      title: `Invoice #${invoice.id}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  async function generatePDF(invoice, amount) {
    if (invoice.customer.id === 2) {
      setCarmaxInvoice(invoice)
    }
    else {
      var docDefinition = new DocumentDefinition(invoice, amount);
      let dd = docDefinition.create(invoice, amount);
      let name = 'invoice.pdf';
      try {
        name = `${invoice.customer.name.replace(/ /g, "_")}_Invoice_${moment(invoice.start_datetime).add(1, "day").format('MM-DD-YYYY')}_to_${moment(invoice.end_datetime).format('MM-DD-YYYY')}.pdf`
      }
      catch (err) {
        console.log("Could not parse invoice document name")
      }
      pdfMake.createPdf(dd).download(name);
    }
  }

  const goToMoveDetails = (moveId) => {
    props.history.push(`/moves/${moveId}`);
  }

  const handleReview = (armove) => {
    setModalData(armove);
    setModalRender(`review`);
  }

  const handleRerun = (armove) => {
    try {
      axios({
        method: `POST`,
        url: `/.netlify/functions/rerunARMove`,
        data: {
          id: armove.move_id,
          user: ctx.userProfile.email
        },
        headers: {
          'authorization': `Bearer ${ctx.userToken}`
        }
      }).then(res => {
        console.log(`Rerun armove success:`, res);
      }).catch(err => {
        console.log(`An error occured while attempting to rerun armove:`, err);
      });
    }
    catch (err) {
      console.log(`An error occured while attempting to rerun armove:`, err);
    }
  }

  const reprocessInvoice = async (invoice, action, message) => {
    return await axios({
      method: `POST`,
      url: `/.netlify/functions/processInvoiceAR`,
      data: {
        invoiceId: invoice.id,
        action: action,
        callMethod: "adminInvoicePage"
        // user: ctx.userProfile.email
      },
      headers: {
        'authorization': `Bearer ${ctx.userToken}`
      }
    }).then(res => {
      console.log("res", res)
      if (res.status === 200) {
        ctx.handleNotifications(true, "success", `${message}`);
      } else {
        ctx.handleNotifications(true, "error", `Invoice failed to update: ${res.status} ${res.message}`);
      }
    }).catch(err => {
      console.log('RESPONSE', err.response)
      if (err.response) {
        ctx.handleNotifications(true, "error", `Invoice failed to update: ${err.response.data}`);
      } else {
        console.error(err)
        ctx.handleNotifications(true, "error", `Invoice failed to update: ${err.message}`);
      }
    })
  }

  const getInvoiceLogs = async (invoice) => {
    return await ctx.apolloClient.query({
      query: GET_INVOICE_LOGS,
      variables: { invoiceId: invoice.id }
    }).then(res => {
      setModalData(res.data.arevents);
      setModalRender(`invoice logs`);
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  const getMoveLogs = async (move) => {
    return await ctx.apolloClient.query({
      query: GET_MOVE_LOGS,
      variables: { moveId: move.id }
    }).then(res => {
      setModalData(res.data.arevents);
      setModalRender(`move logs`);
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  const handleTaggingDeliveryReported = async (move) => {
    let tags = move.tags || ''
    if (!tags.includes("delivery reported")) {
      if (tags === '') {
        tags = 'delivery reported'
      } else {
        tags += ', delivery reported'
      }
    }
    await ctx.apolloClient.mutate({
      mutation: gql`
      mutation updateTags($id: bigint!, $tags: String!) {
        update_moves(where: {id: {_eq: $id}}, _set: {tags: $tags}) {
          affected_rows
          returning {
            id
            tags
          }
        }
      }`,
      variables: { id: move.id, tags: tags }
    }).then(res => {
      console.log(`Updated move ${move.id}. Added delivery reported tag.`)
    }).catch(err => {
      console.error(err)
    })
  }

  const handleUntaggingDeliveryReported = async (move) => {
    let tags = move.tags || ''
    if (tags.includes("delivery reported")) {
      if (tags === 'delivery reported') {
        tags = ''
      } else {
        tags = tags.replace(', delivery reported', '')
      }
    }
    await ctx.apolloClient.mutate({
      mutation: gql`
      mutation updateTags($id: bigint!, $tags: String!) {
        update_moves(where: {id: {_eq: $id}}, _set: {tags: $tags}) {
          affected_rows
          returning {
            id
            tags
          }
        }
      }`,
      variables: { id: move.id, tags: tags }
    }).then(res => {
      console.log(`Updated move ${move.id}. Removed delivery reported tag.`)
    }).catch(err => {
      console.error(err)
    })
  }

  const setInvoiceStatus = async (invoice, stat) => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_INVOICE_STATUS,
      variables: { invoiceId: invoice.id, status: stat }
    }).then(res => {
      //
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  function handleSelectDeliveryReported(invoice) {
    let selection = [];
    invoice.armoves.map(o => {
      if (String(o.move.tags || "").includes("delivery reported")) selection.push(o.id)
    })
    setCheckedItems(selection)
  }

  //Checkbox handler
  const handleCheck = (event, name) => {
    event.stopPropagation()
    const checkedIndex = checkedItems.indexOf(name);
    let newChecked = [];

    if (checkedIndex === -1) {
      newChecked = newChecked.concat(checkedItems, name);
    } else if (checkedIndex === 0) {
      newChecked = newChecked.concat(checkedItems.slice(1));
    } else if (checkedIndex === checkedItems.length - 1) {
      newChecked = newChecked.concat(checkedItems.slice(0, -1));
    } else if (checkedIndex > 0) {
      newChecked = newChecked.concat(
        checkedItems.slice(0, checkedIndex),
        checkedItems.slice(checkedIndex + 1),
      );
    }
    setCheckedItems(newChecked);
  };

  const handleCheckAll = (event, rowArr) => {
    const rowIdArr = rowArr.filter(row => row.status !== "paid").map(row => row.id)
    //uncheck all if all are checked
    if ((checkedItems.length > 0) && (checkedItems.length === rowIdArr.length)) {
      setCheckedItems([]);
    } else {
      setCheckedItems(rowIdArr)
    }
  }
  //Behavior: If clicked, will check/uncheck all unpaid armoves. If all armoves are checked will become checked. If all armoves are paid, will become disabled and indeterminate.
  const HeaderCheckbox = (selectedInvoice) => {
    const armoves = selectedInvoice.armoves
    const paidArmoves = armoves.filter(o => o.status === "paid")
    const unpaidArmoves = armoves.filter(o => o.status !== "paid")
    return <Checkbox
      onClick={(event) => { handleCheckAll(event, armoves) }}
      checked={(checkedItems.length !== 0) && unpaidArmoves.length === checkedItems.length}
      indeterminate={(paidArmoves.length !== 0) && paidArmoves.length === armoves.length}
      disabled={(paidArmoves.length !== 0) && paidArmoves.length === armoves.length}
    />
  }

  const handlePostPaymentModal = (invoice) => {
    let selectedArmoves = invoice.armoves.filter(o => checkedItems.includes(o.id))
    setOpenPostPaymentModal(true)
    setArmoveData(selectedArmoves)
  }

  const isItemChecked = id => checkedItems.indexOf(id) !== -1
  //reset checked array when new invoice selected
  //set manualPaymentMethod
  useEffect(() => {
    setCheckedItems([])
  }, [selectedInvoiceId])

  const buildInvoiceContextMenuActions = (invoice, amount) => {
    return [
      { name: `select-delivery-reported`, label: `Select All Reported Deliveries`, data: { invoice: invoice }, handler: handleContextMenuClick, hide: invoice.customer.id !== 2 },
      { name: `set-invoice-status-to-processing`, label: `Force Status to 'Processing'`, data: { invoice: invoice }, handler: handleContextMenuClick },
      { name: `set-invoice-status-to-closed`, label: `Force Status to 'Closed'`, data: { invoice: invoice }, handler: handleContextMenuClick },
      { name: `post-invoice`, label: `Post Invoice to Accounting System`, data: { invoice: invoice }, handler: handleContextMenuClick },
      {
        name: `manually-post-payment`,
        label: `Manually Post Payment`,
        data: { invoice: invoice },
        disabled: !(invoice.accounting_num) || checkedItems.length === 0,
        handler: handleContextMenuClick,
      },
      { name: `resend-customer-email`, label: `Resend Email to Customer`, data: { invoice: invoice }, handler: handleContextMenuClick },
      { name: `rerun-autopay`, label: `Rerun Autopay for Invoice`, data: { invoice: invoice }, handler: handleContextMenuClick, disabled: (invoice.accounting_num === null), hide: !invoice.customer.auto_pay },
      { name: `repost-payment-to-accounting`, label: `Repost Payments to Accounting System`, data: { invoice: invoice }, handler: handleContextMenuClick, hide: invoice.arpayments.length === 0 },
      { name: `repost-processing-fee-to-accounting`, label: `Repost Processing Fees to Accounting System`, data: { invoice: invoice }, handler: handleContextMenuClick, hide: invoice.arpayments.length === 0 },
      { name: `generate-csv`, label: `Generate\xa0CSV`, data: { invoice: invoice }, handler: handleContextMenuClick },
      { name: `generate-pdf`, label: `Generate\xa0PDF`, data: { invoice: invoice, amount: amount }, handler: handleContextMenuClick },
      { name: `show-invoice-logs`, label: `View Invoice Logs`, data: { invoice: invoice }, handler: handleContextMenuClick },
    ]
  }

  const buildARMoveContextMenuActions = (armove) => {
    return [
      { name: `move-details`, label: `Move Details`, id: armove.move_id, handler: handleContextMenuClick },
      { name: `edit-move`, label: `Edit\xa0Move`, data: { armove: armove }, handler: handleContextMenuClick },
      { name: `review-discount`, label: `Review/Discount`, data: { armove: armove }, handler: handleContextMenuClick },
      { name: `manual-rerun`, label: `Manually\xa0Rerun\xa0AR`, data: { armove: armove }, handler: handleContextMenuClick },
      { name: `tag-delivery-reported`, label: `Tag\xa0Delivery\xa0Reported`, data: { armove: armove }, handler: handleContextMenuClick, hide: !(armove.move.customer.id === 2 && armove.move.tags && !armove.move.tags.includes("delivery reported") || armove.move.customer.id === 2 && !armove.move.tags) },
      { name: `untag-delivery-reported`, label: `Untag\xa0Delivery\xa0Reported`, data: { armove: armove }, handler: handleContextMenuClick, hide: !(armove.move.customer.id === 2 && armove.move.tags && armove.move.tags.includes("delivery reported")) },
      { name: `copy-delivery-timestamp`, label: `Copy\xa0Delivery\xa0Timestamp`, data: { armove: armove }, handler: handleContextMenuClick, hide: !(armove.move.customer.id === 2) },
      { name: `copy-ref-num`, label: `Copy\xa0Reference\xa0Number`, data: { armove: armove }, handler: handleContextMenuClick, hide: !(armove.move.customer.id === 2) },
      { name: `view-move-logs`, label: `View\xa0Move\xa0Logs`, data: { armove: armove }, handler: handleContextMenuClick },
    ]
  }

  const handleContextMenuClick = async (e, data, target) => {
    // Table Options (Invoice)
    if (data.action.name === `select-delivery-reported`) {
      handleSelectDeliveryReported(data.invoice)
      return
    }
    if (data.action.name === `set-invoice-status-to-processing`) {
      setInvoiceStatus(data.invoice, `processing`)
      return
    }
    if (data.action.name === `set-invoice-status-to-closed`) {
      setInvoiceStatus(data.invoice, `closed`)
      return
    }
    if (data.action.name === `post-invoice`) {
      reprocessInvoice(data.invoice, `accountingInvoice`, `Invoice updated`)
      return
    }
    if (data.action.name === `manually-post-payment`) {
      handlePostPaymentModal(data.invoice)
      return
    }
    if (data.action.name === `resend-customer-email`) {
      reprocessInvoice(data.invoice, `notify`, `Customer emailed`)
      return
    }
    if (data.action.name === `rerun-autopay`) {
      reprocessInvoice(data.invoice, `pay`, `Customer charged for invoice.`)
      return
    }
    if (data.action.name === `repost-payment-to-accounting`) {
      reprocessInvoice(data.invoice, `accountingPayment`, `Payment posted to accounting system`)
      return
    }
    if (data.action.name === `repost-processing-fee-to-accounting`) {
      reprocessInvoice(data.invoice, `postProcessingFee`, `Posted processing fee to accounting system`)
      return
    }
    if (data.action.name === `generate-csv`) {
      generateCSV(data.invoice);
      return
    }
    if (data.action.name === `generate-pdf`) {
      generatePDF(data.invoice, data.amount);
      return
    }
    if (data.action.name === `show-invoice-logs`) {
      getInvoiceLogs(data.invoice)
      return
    }

    // Row Options (ARMove)
    if (data.action.name === `move-details`) {
      goToMoveDetails(data.action.id);
      return
    }
    if (data.action.name === `edit-move`) {
      props.history.push(`/moves/${data.armove.move.id}/edit`)
      return
    }
    if (data.action.name === `review-discount`) {
      handleReview(data.armove)
      return
    }
    if (data.action.name === `manual-rerun`) {
      handleRerun(data.armove)
      return
    }
    if (data.action.name === `tag-delivery-reported`) {
      handleTaggingDeliveryReported(data.armove.move)
      return
    }
    if (data.action.name === `untag-delivery-reported`) {
      handleUntaggingDeliveryReported(data.armove.move)
      return
    }
    if (data.action.name === `view-move-logs`) {
      getMoveLogs(data.armove.move)
      return
    }
    if (data.action.name === `copy-ref-num`) {
      navigator.clipboard.writeText(data.armove.move.reference_num)
      return
    }
    if (data.action.name === `copy-delivery-timestamp`) {
      navigator.clipboard.writeText(`${moment(data.armove.move.delivery_successful).format(`YYYY-MM-DD HH:MM:mm.SSS`)}Z`)
      return
    }
  }

  const getRowTotal = (row) => {
    return (<>
      {(row.armove.disputed || row.armove.discount_amount > 0) ?
        <Tooltip disableFocusListener title={row.armove.disputed ? `This AR record is disputed - ${row.armove.dispute_reason}` : `This AR record is discounted for $${row.armove.discount_amount} - ${row.armove.discount_reason}`}>
          <Icon className={cls.rowIcon} fontSize="small">{row.armove.disputed ? `announcement` : `local_offer`}</Icon>
        </Tooltip> : null}
      <Typography className={cls.rowTxt} style={{ display: 'inline' }}>{row.TOTAL}</Typography>
    </>)
  }

  return (
    <div className={cls.root}>
      { ctx && ctx.userIsAuthenticated() && (
        <>
          <Modal open={modalRender !== null} data={modalData} setData={setModalData} render={modalRender} setRender={setModalRender} />
          <Query
            query={GET_INVOICES(`query`)}
            variables={{ customerId: customerId, start: start, end: end }}
            onError={err => ctx.handleNotifications(true, "error", "Failed to retrieve invoices: " + err.toString())}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <Loading fixed />
              if (error) {
                console.log(`Error getting invoices:`, error);
                return (
                  <div className={cls.rootTable}>
                    <Container maxWidth="sm">
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>ERROR GETTING INVOICES</Typography>
                      </div>
                    </Container>
                  </div>
                )
              }
              if (data && data.arinvoices && data.arinvoices.length > 0) {
                let invoices = data.arinvoices.filter(invoice=>!(invoice.armoves.length < 1 && invoice.status === "closed"));
                if (selectedInvoiceId === null) setSelectedInvoiceId(invoices && invoices.length > 0 ? invoices[0].id : null);

                let selectedInvoice = invoices.find(invoice => invoice.id === selectedInvoiceId || null);
                if (selectedInvoice && selectedInvoice !== null) {

                  const manualPaymentMethod = selectedInvoice.customer.paymentmethods.length > 0 ? selectedInvoice.customer.paymentmethods[0].id : null
                  setManualPaymentMethodId(manualPaymentMethod)

                  // Set a consistent amount object that holds the totals
                  var amount = {};

                  // Valid records to calculate base totals
                  const subtotalMoves = selectedInvoice.armoves.filter(item => item.due_amount > 0);
                  const discountedMoves = subtotalMoves.filter(item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0);
                  const disputedMoves = subtotalMoves.filter(item => item.disputed === true);
                  const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);

                  // Base totals from valid records
                  amount.subtotal = round(subtotalMoves.length > 0 ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
                  amount.discounted = round(discountedMoves.length > 0 ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current) : 0, 2);
                  amount.disputed = round(disputedMoves.length > 0 ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
                  amount.paid = round(paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0, 2);

                  // Calculate totals
                  amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);
                  amount.dueSans = checkNeg(amount.total - amount.paid);
                  amount.processingFee = round(checkNeg(amount.dueSans) * 0.03, 2);
                  amount.due = checkNeg(amount.dueSans + amount.processingFee);

                  // Boolean to determine if the invoice can be payed for
                  // var payable = amount.due > 0 && selectedInvoice.status === `closed`;

                  const filteredData = applyFilters(selectedInvoice.armoves)
                  var headers = [
                    { id: `HEADER-CHECKBOX`, alignLeft: true, label: `Checkbox`, value: HeaderCheckbox(selectedInvoice) },
                    { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
                    { id: `DLV_REP`, alignLeft: true, numeric: false, label: `Rep`, hide: selectedInvoice.customer.id !== 2 },
                    { id: `REF_NUM`, alignLeft: true, numeric: true, label: `Ref\xa0#` },
                    { id: `MOVE_DATE`, alignLeft: true, numeric: true, label: `Move\xa0Date` },
                    { id: `STOCK`, alignLeft: false, numeric: true, label: `Stock` },
                    { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
                    { id: `DISTANCE`, alignLeft: false, numeric: true, label: `Distance` },
                    { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                  ]
                  var rows = filteredData.map(armove => {
                    return {
                      HEADER_CHECKBOX: <Checkbox onClick={(event) => handleCheck(event, armove.id)} checked={isItemChecked(armove.id)} disabled={armove.status === "paid"} indeterminate={armove.status === "paid"} />,
                      MOVE_ID: armove.move.id,
                      DLV_REP: armove.move.tags && armove.move.tags.includes("delivery reported") ? <Icon fontSize='small' className={cls.noMargin}>backup</Icon> : <Icon fontSize='small' className={cls.noMargin}></Icon>,
                      REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
                      MOVE_DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
                      STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : `-`,
                      LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
                      DISTANCE: armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
                      TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
                      armove: armove
                    }
                  })
                }

                return (<>
                  <div className={cls.revealIndexBtn} onClick={handleDrawerOpen}><Icon>vertical_split</Icon></div>
                  <div className={cls.rootTable}>
                    {selectedInvoice && selectedInvoice !== null ?
                      <Container maxWidth="lg">
                        <PostPaymentModal open={openPostPaymentModal} openModal={setOpenPostPaymentModal} setCheckedItems={setCheckedItems} armoves={armoveData} manualPaymentMethodId={manualPaymentMethodId} setManualPaymentMethodId={setManualPaymentMethodId} selectedInvoice={selectedInvoice} />
                        <ARInvoiceHeader selectedInvoice={selectedInvoice} />
                        <AccordianTable
                          title={`Invoice #${selectedInvoice.id}`}
                          size={`small`}
                          headers={headers}
                          rows={rows}
                          actions={buildInvoiceContextMenuActions(selectedInvoice, amount)}
                          search={search}
                          order={order}
                          orderBy={orderBy}
                          tablePage={tablePage}
                          rowsPerPage={rowsPerPage}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          setSearch={setSearch}
                          setOrder={setOrder}
                          setOrderBy={setOrderBy}
                          setTablePage={setTablePage}
                          setRowsPerPage={setRowsPerPage}
                          setExpandedRowId={setExpandedRowId}
                          refetch={refetch}
                          refreshPersistAs="ar_invoices"
                        >
                          {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                            .map(row => (
                              <AccordianRow
                                key={`invoice-armove-${row.armove.id}`}
                                rowId={row.armove.id}
                                expandedRowId={expandedRowId}
                                setExpandedRowId={setExpandedRowId}
                                columns={[
                                  { align: 'left', value: row.HEADER_CHECKBOX },
                                  { align: 'left', value: row.MOVE_ID },
                                  { align: 'left', value: row.DLV_REP, hide: row.armove.move.customer.id !== 2 },
                                  { align: 'left', value: row.REF_NUM },
                                  { align: 'left', value: row.MOVE_DATE },
                                  { align: 'right', value: row.STOCK },
                                  { align: 'left', value: row.LANE },
                                  { align: 'right', value: row.DISTANCE },
                                  { align: 'right', value: getRowTotal(row) },
                                ]}
                                actions={buildARMoveContextMenuActions(row.armove)}
                                className={expandedRowId === row.armove.id ? (row.armove.disputed ? cls.rowDisputedActive : cls.rowActive) : (row.armove.disputed ? cls.rowDisputed : cls.row)}
                              >
                                <ARMoveDetail key={`armovedetail-${row.armove.id}`} row={row} armove={row.armove} actions={buildARMoveContextMenuActions(row.armove)} />
                              </AccordianRow>
                            ))}
                        </AccordianTable>
                        <ARInvoiceFooter selectedInvoice={selectedInvoice} amount={amount} />
                      </Container> :
                      <Container maxWidth="sm">
                        <div className={cls.notFound}>
                          <Typography className={cls.notFoundTxt}>NO INVOICE SELECTED</Typography>
                        </div>
                      </Container>}
                  </div>
                  <div className={cls.spaceIndex} />
                  <div className={cls.rootIndex}>
                    <div className={cls.index}>
                      <div style={{ width: '100%', height: '64px' }} />
                      <ARIndexFilter
                        setSelectedInvoiceId={setSelectedInvoiceId}
                        customerId={customerId}
                        setCustomerId={setCustomerId}
                        start={start}
                        end={end}
                        onChange={handleDateChange} />
                      <ARIndex
                        invoices={invoices}
                        selectedInvoiceId={selectedInvoiceId}
                        setSelectedInvoiceId={setSelectedInvoiceId}
                        buildInvoiceContextMenuActions={buildInvoiceContextMenuActions}
                        setTablePage={setTablePage} />
                    </div>
                  </div>
                  <SwipeableDrawer anchor="right" open={drawer} onClose={handleDrawerClose} onOpen={handleDrawerOpen} classes={{ paper: classNames(cls.drawer) }}>
                    <div>
                      <IconButton className={cls.drawerIcon} onClick={handleDrawerClose}>
                        <Icon>chevron_right</Icon>
                      </IconButton>
                    </div>
                    <Divider />
                    <ARIndexFilter
                      setSelectedInvoiceId={setSelectedInvoiceId}
                      customerId={customerId}
                      setCustomerId={setCustomerId}
                      start={start}
                      end={end}
                      onChange={handleDateChange} />
                    <ARIndex
                      invoices={invoices}
                      selectedInvoiceId={selectedInvoiceId}
                      setSelectedInvoiceId={setSelectedInvoiceId}
                      buildInvoiceContextMenuActions={buildInvoiceContextMenuActions}
                      setTablePage={setTablePage}
                      handleDrawerClose={handleDrawerClose} />
                  </SwipeableDrawer>
                </>)
              }
              else {
                return (
                  <div className={cls.rootTable}>
                    <Container maxWidth="sm">
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO INVOICES FOUND</Typography>
                      </div>
                    </Container>
                  </div>
                )
              }
            }}
          </Query>
          {carmaxInvoice ?
            <Dialog id="CarmaxInvoiceDialog" open={carmaxInvoice ? true : false} onClose={() => setCarmaxInvoice(null)}>
              <CarmaxInvoice invoice={carmaxInvoice} checkedItems={Array.from(checkedItems || [])} />
            </Dialog> : null}
        </>)
      }
    </div >
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  spaceIndex: {
    verticalAlign: 'top',
    position: 'relative',
    minWidth: '320px',
    width: '320px',
    height: '1px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rootIndex: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '320px',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  index: {
    overflow: 'auto',
    width: '320px',
    height: '100%',
    background: theme.palette.background.main,
  },
  revealIndexBtn: {
    zIndex: 1001,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: '42px',
    height: '42px',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50px',
    background: '#fff',
    color: '#808080',
    fontSize: '16px',
    "&:hover": {
      border: '1px solid #808080',
      background: '#808080',
      color: '#fff',
    },
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(2),
      right: '50%',
      transform: 'translateX(50%)',
    },
  },
  drawer: {
    minWidth: '240px',
    maxWidth: '320px',
  },
  drawerIcon: {
    margin: theme.spacing(1),
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowActive: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.primary.main,
    color: '#fff',
    boxShadow: 'none',
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    color: theme.palette.text.disabled,
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputedActive: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.error.light,
    color: '#fff',
    boxShadow: 'none',
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  action: {
    zIndex: 1001,
    position: 'absolute',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: '128px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  actionDisabled: {
    zIndex: 1001,
    position: 'absolute',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: '128px',
    backgroundColor: '#00000016',
    "&:hover": {
      backgroundColor: '#00000016',
    },
  },
  noMargin: {
    margin: '0px',
  },
  smallPad: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));



////////// QUERIES //////////
const GET_MOVE_LOGS = gql`
query get_move_logs($moveId: bigint!) {
  arevents(where: {move_id: {_eq: $moveId}}, order_by: {id: desc}) {
    id
    arevent_id
    move_id
    invoice_id
    message
    level
    createdat
  }
}
`;

const GET_INVOICE_LOGS = gql`
query get_invoice_logs($invoiceId: bigint!) {
  arevents(where: {invoice_id: {_eq: $invoiceId}}, order_by: {id: desc}) {
    id
    arevent_id
    move_id
    invoice_id
    message
    level
    createdat
  }
}
`;

const UPDATE_INVOICE_STATUS = gql`
mutation update_invoice_status( $invoiceId: bigint!, $status: String!) {
  update_arinvoices(where: {id: {_eq: $invoiceId}}, _set: {status: $status}) {
    affected_rows
    returning {
      id
    }
  }
}
`;

const GET_INVOICES = (type = 'query') => gql`
${type} get_invoices($customerId: bigint, $start: timestamptz!, $end: timestamptz!) {
  arinvoices(where: {
    customer_id: {_eq: $customerId}, 
    _or: [ 
      {start_datetime: {_gte: $start, _lte: $end} }, 
      {end_datetime: {_gte: $start, _lte: $end}} 
    ]}, order_by: {id: desc}) {
    id
    accounting_num
    start_datetime
    end_datetime
    status
    customer {
      id
      name
      address
      billing_frequency
      payment_terms
      auto_pay
      notify_billing
      paymentmethods(where: {type: {_eq: "manual"}}){
        id
      }
    }
    arpayments (order_by: {createdat: asc}){
      id
      amount
      status
      accounting_id
      gateway_transaction_id
      createdat
    }
    armoves(where: {active: {_eq: 1}, type: {_eq: "move"}}, order_by: {id: asc}) {
      id
      active
      type
      move_id
      invoice_id
      arevent_id
      billable_datetime
      discount_amount
      discount_reason
      disputed
      dispute_reason
      due_amount
      paid_amount
      status
      notes
      author
      accounting_item_id
      invoice {
        id
        accounting_num
        customer {
          id
          accounting_id
        }
      }
      move {
        active
        id
        class
        chargeable
        customer_id
        delivery_stop_id
        status
        delivery_arrived
        delivery_started
        delivery_successful
        move_type
        pickup_arrived
        pickup_started
        pickup_successful
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        reference_num
        tags
        lane {
          id
          description
          distance_miles
          dealer_base_price
          dealer_stranded_price
          dealer_base_discount
          dealer_base_rate_type
          dealer_stranded_discount
          dealer_stranded_rate_type
          tolls
          delivery {
            id
            address
            name
          }
          pickup {
            id
            address
            name
          }
        }
        raterule {
          id
          rate
          type
        }
        customer {
          id
          name
          address
          billing_frequency
          payment_terms
          auto_pay
        }
      }
      details {
        id
        name
        notes
        amount
      }
      revisions(order_by: {revision: desc}) {
        id
        revision
        due_amount
        discount_amount
        discount_reason
        disputed
        dispute_reason
        details {
          id
          name
          notes
          amount
        }
      }
    }
  }
}
`;

////////// EXPORT //////////
export default withRouter(ARInvoices);