import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Button, Chip, Icon, Typography, TableRow, TableCell, TableHead, TableBody, Table, Paper } from "@material-ui/core";
import { GlobalContext } from "../../../global-context";
import RateRuleFormModal from "./RateRuleFormModal";

const log = false

export default function RateRulesTable({
  rateRules,
  onChange = rateRules => { },
}) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [unsavedRateRules, setUnsavedRateRules] = useState(rateRules)
  const [selectedRateRule, setSelectedRateRule] = useState({})
  const [formModalOpen, setFormModalOpen] = useState(false);

  useEffect(() => {
    if (!rateRules) return
    log && console.log('RateRulesTable loaded with: ', rateRules)
    rateRules.sort((a,b) => a.class - b.class)

    //Catch the scenario where a legacy customer is being modified with a missing rate rules group
    // we need to set all rate rules to hasChanges true so they get written as children to the new
    // rate rule group when it is all saved.
    for (let index = 0; index < rateRules.length; index++) {
      if (!rateRules[index].rate_rule_group_id || rateRules[index].rate_rule_group_id < 1) {
        rateRules[index].hasChanges = true
      }
    }

    setUnsavedRateRules(rateRules)
  }, [rateRules])

  useEffect(() => {
    if (!unsavedRateRules) return
    if (unsavedRateRules.length < 1) return
    log && console.log('Unsaved Rate Rules changed to: ', unsavedRateRules)
    const hasChanges = unsavedRateRules.some(o => o.hasChanges)
    if (hasChanges) onChange(unsavedRateRules)
  }, [unsavedRateRules])

  const handleRowClick = rateRule => event => {
    setSelectedRateRule(rateRule)
    setFormModalOpen(true)
  }

  const handleAddRateRule = defaultClass => () => {
    const newRateRule = {
      id: unsavedRateRules.length * -1,  //Need a unique key for the UI. As long as the id is negative, the editor will remove before mutation (see RateRuleGroupEditor.js:handleSaveChanges())
      customer_id: null,
      rate_rule_group_id: null,
      class: defaultClass,
      distance_end: 0,
      distance_start: 0,
      rate: 0,
      type: "flat",
      hasChanges: true
    }
    setUnsavedRateRules([...unsavedRateRules, newRateRule])
    setSelectedRateRule(newRateRule)
    setFormModalOpen(true)
  }

  const handleRateRuleSave = unsavedRateRule => {
    setFormModalOpen(false)

    unsavedRateRule.rate = Number(unsavedRateRule.rate)
    unsavedRateRule.distance_start = Number(unsavedRateRule.distance_start)
    unsavedRateRule.distance_end = Number(unsavedRateRule.distance_end)

    //Replace this unsaved rate rule in the rate rules state array
    setUnsavedRateRules(unsavedRateRules.map((rateRule) => {
      if (rateRule.id === unsavedRateRule.id) return {...unsavedRateRule, hasChanges: true}
      return rateRule
    }))

    setSelectedRateRule({})
  }

  return (<>
    {!rateRules && 
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>NO MILEAGE BANDS FOUND</Typography>
      </div>
    }
    {rateRules && <>
      <RateRuleFormModal
        open={formModalOpen}
        rateRule={selectedRateRule}
        onSave={handleRateRuleSave}
        onCancel={() => {setFormModalOpen(false);setSelectedRateRule({});}}
      />
      <Table className={cls.table} size="small" aria-label="rate rules table">
        <TableHead>
          <TableRow>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">Class</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unsavedRateRules.filter(o => o.class === 'stranded').map((row) => (
            <TableRow
            hover
            key={row.id}
            className={`${selectedRateRule.id == row.id ? cls.rowIsSelected : null} ${row.hasChanges == true ? cls.rowHasChanges : null}`}>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>{row.distance_start}</TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>{row.distance_end}</TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align="center"><Chip label={row.type} variant={(row.type === 'per') ? 'outlined' : 'default'} size="small" color='secondary' /></TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align="right">${row.rate.toFixed(2)}</TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align="right" width="20%">{row.class}</TableCell>
            </TableRow>
          ))}
          <TableRow hover key={-1}>
            <TableCell colSpan={5} align="center" onClick={handleAddRateRule('stranded')} className={cls.clickableItem}><Button variant="text" color="primary" size="small" startIcon={<Icon>add_box</Icon>}>Add Row</Button></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table className={cls.table} style={{marginTop: '15px'}} size="small" aria-label="rate rules table">
        <TableHead>
          <TableRow>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">Class</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unsavedRateRules.filter(o => o.class === 'base').map((row) => (
            <TableRow
            hover
            key={row.id}
            className={`${selectedRateRule.id == row.id ? cls.rowIsSelected : null} ${row.hasChanges == true ? cls.rowHasChanges : null}`}>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>{row.distance_start}</TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>{row.distance_end}</TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align="center"><Chip label={row.type} variant={(row.type === 'per') ? 'outlined' : 'default'} size="small" color='secondary' /></TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align="right">${row.rate.toFixed(2)}</TableCell>
              <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align="right" width="20%">{row.class}</TableCell>
            </TableRow>
          ))}
          <TableRow hover key={-1}>
            <TableCell colSpan={5} align="center" onClick={handleAddRateRule('base')} className={cls.clickableItem}><Button variant="text" color="primary" size="small" startIcon={<Icon>add_box</Icon>}>Add Row</Button></TableCell>
          </TableRow>
        </TableBody>
      </Table>
  </>}</>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
  },
  rowIsSelected: {
    transition: "all .2s ease-in-out",
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c3c3c3',
      color: '#fff',
    }
  },
  rowHasChanges: {
    backgroundColor: '#ffe9ec',
    fontStyle: 'italic',
    color: '#fff',
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));