import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { GlobalContext } from '../../../global-context';

import { makeStyles, Icon, Typography } from '@material-ui/core';

import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";

const log = false;

////////// COMPONENT //////////
export default function MapBounds(props) {
  const ctx = useContext(GlobalContext);
  const { theme } = ctx;
  const cls = useStyles();

  // var googleMaps
  var directionsService
  var directionsRenderer

  const [maps, setMaps] = useState(null)
  const [map, setMap] = useState(null)
  const [service, setService] = useState(null)
  const [renderer, setRenderer] = useState(null)

  //default center is Richmond's coordinates
  const { center = { lat: 37.5, lng: -77.4 }, locations, selectedLocation, setSelectedLocation, setSidebarState,
    createMode, setCreateMode, handleLocationAddEditModalOpen, setBounds, selectedLane, origin, destination, laneMode } = props;

  //Render route when lane is seta
  useEffect(() => {
    // render route when lane selected
    console.log('route effect', map ? "map ready" : "map is null", service ? "service ready" : "service is null", renderer ? "renderer ready" : "renderer is null",
      origin ? "origin ready" : "origin is null", destination ? "destination ready" : "destination is null")
    if (map && service && renderer && origin && destination) {
      console.log('Route Effect if')
      calcRoute()
    }
    else if (maps && (origin === null || destination === null)) {
      console.log('Route Effect else')
      renderer && renderer.setMap(null)
    }
  }, [map, service, renderer, origin, destination])

  //Clicking Map will open create location modal if in createMode has been turned on
  const handleMapClick = async (info) => {
    log && console.log(`Map Clicked:`, info);
    if (createMode) {
      setCreateMode(false);
      Geocode.setApiKey(ctx.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"]);
      Geocode.fromLatLng(info.lat, info.lng)
        .then(res => {
          const address = res.results[0].formatted_address;
          const placeId = res.results[0].place_id;
          log && console.log(`Found address and place_id from lat/lng:`, { address, placeId });
          handleLocationAddEditModalOpen({ type: `customer`, address: address, latitude: info.lat, longitude: info.lng, place_id: placeId });
        })
        .catch(err => {
          console.log(`Error creating new location from lat/lng:`, err);
        });
    }
  }

  //Clicking markers will select and deselect them
  const handleMarkerClick = (location) => {
    if (selectedLocation && selectedLocation.id === location.id) {
      if (!laneMode) {
        setSidebarState(`index`);
        setSelectedLocation(null);
      }
      else {
        setSidebarState(`lane`);
        setSelectedLocation(null);
      }
    }
    else {
      setSelectedLocation(location);
      setSidebarState(`location`);
    }
  }

  const LocationMarker = ({ location, icon, classType }) => {
    return (<>
      <div
        onClick={() => handleMarkerClick(location)}
        className={clsx(cls.marker, {
          [cls.markerHover]: (classType === 'hover'),
          [cls.activeMarker]: (classType === 'selected'),
          [cls.laneLocMarker]: (classType === 'laneLoc'),
        })}>
        <Icon className={cls.icon}>{icon}</Icon>
        <div className={cls.bubbleBox}>
          <Typography className={cls.bubbleNameTxt}>{location.name || `No name provided`}</Typography>
          <Typography className={cls.bubbleAddressTxt}>{location.address || `No address provided`}</Typography>
        </div>
      </div>
    </>)
  }

  const handleMarkerIcon = (location) => {
    let icon = 'place'
    if (selectedLane.origin_location_id && selectedLane.origin_location_id === location.id) { icon = 'gps_not_fixed' }
    else if (selectedLane.destination_location_id && selectedLane.destination_location_id === location.id) { icon = 'gps_fixed' }
    else if (location.type === 'customer') { icon = 'place' }
    else if (location.type === 'consumer business') { icon = 'store' }
    else if (location.type === 'consumer residential') { icon = 'home' }
    return icon
  }
  //Note: the order here is important- selected laneLoc will be class 'selected' not 'laneLoc'
  const handleMarkerClass = (location) => {
    let style = undefined
    if (selectedLane.origin_location_id && selectedLane.origin_location_id === location.id) { style = 'laneLoc' }
    else if (selectedLane.destination_location_id && selectedLane.destination_location_id === location.id) { style = 'laneLoc' }
    else if (selectedLocation && selectedLocation.id === location.id) { style = 'selected' }
    else { style = 'hover' }
    return style
  }



  const handleApiLoaded = (map, maps) => {
    //Set Map object
    setMaps(maps)
    setMap(map)
    //Set objects for rendering routes
    directionsService = new maps.DirectionsService();
    directionsRenderer = new maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "#2893ef"
      },
      suppressMarkers: true,
      provideRouteAlternatives: true
    });
    directionsRenderer.setMap(map);
    setService(directionsService)
    setRenderer(directionsRenderer)
    //Listener for map bounds
    maps.event.addListener(map, 'bounds_changed', () => {
      var bounds = map.getBounds();
      var latLngBounds = bounds.toJSON()
      setBounds(latLngBounds)
    })
  };

  const calcRoute = () => {
    if (maps && map) {
      renderer.setMap(map);
      console.log('calcRoute')
      var request = {
        origin: origin.address,
        destination: destination.address,
        travelMode: 'DRIVING'
      };
      service.route(request, function (response, status) {
        if (status === 'OK') {
          console.log('setting directions...')
          renderer.setDirections(response);
        }
      });
    } else {
      console.log('Missing the origin or destination', origin, destination)
      //TODO: error handling
    }
  }

  return (<>
    <div className={cls.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: ctx.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"] }}
        //TODO: change these to props, and find a way to set these from our DB
        onClick={(info) => { handleMapClick(info) }}
        defaultCenter={center}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={
          // STYLE GUIDE: https://developers.google.com/maps/documentation/javascript/styling
          // STYLE REFERENCE: https://developers.google.com/maps/documentation/javascript/style-reference
          {
            styles: [
              // Remove click labels
              {
                featureType: `administrative.neighborhood`,
                elementType: `labels`,
                stylers: [{ visibility: `off` }]
              },
              {
                featureType: `poi`,
                elementType: `labels`,
                stylers: [{ visibility: `off` }]
              },

              // Map-Styling
              {
                elementType: `geometry`,
                stylers: [{ color: theme.palette.secondary.veryDark }]
              },
              {
                elementType: `labels.icon`,
                stylers: [{ visibility: `off` }]
              },
              {
                elementType: `labels.text.fill`,
                stylers: [{ color: `#757575` }]
              },
              {
                elementType: `labels.text.stroke`,
                stylers: [{ color: theme.palette.secondary.veryDark }]
              },
              {
                featureType: `administrative`,
                elementType: `geometry`,
                stylers: [{ color: `#757575` }]
              },
              {
                featureType: `administrative.country`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#9e9e9e` }]
              },
              {
                featureType: `administrative.land_parcel`,
                stylers: [{ visibility: `off` }]
              },
              {
                featureType: `administrative.locality`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#bdbdbd` }]
              },
              {
                featureType: `poi`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#757575` }]
              },
              {
                featureType: `poi.park`,
                elementType: `geometry`,
                stylers: [{ color: `#000000` }]
              },
              {
                featureType: `poi.park`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#616161` }]
              },
              {
                featureType: `poi.park`,
                elementType: `labels.text.stroke`,
                stylers: [{ color: `#1b1b1b` }]
              },
              {
                featureType: `road`,
                elementType: `geometry.fill`,
                stylers: [{ color: `#2c2c2c` }]
              },
              {
                featureType: `road`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#8a8a8a` }]
              },
              {
                featureType: `road.arterial`,
                elementType: `geometry`,
                stylers: [{ color: `#373737` }]
              },
              {
                featureType: `road.highway`,
                elementType: `geometry`,
                stylers: [{ color: `#3c3c3c` }]
              },
              {
                featureType: `road.highway.controlled_access`,
                elementType: `geometry`,
                stylers: [{ color: `#4e4e4e` }]
              },
              {
                featureType: `road.local`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#616161` }]
              },
              {
                featureType: `transit`,
                elementType: `labels.text.fill`,
                stylers: [{ color: `#757575` }]
              },
              {
                featureType: `water`,
                elementType: `geometry`,
                stylers: [{ color: `#000000` }]
              },
              {
                featureType: `water`,
                elementType: `labels.text.fill`,
                stylers: [{ color: theme.palette.secondary.light }]
              }
            ]
          }}
      // setOptions={{clickableIcons:false}}
      >
        {locations.map(location =>
          <LocationMarker
            key={`location-marker-${location.id}`}
            lat={location.latitude}
            lng={location.longitude}
            location={location}
            classType={handleMarkerClass(location)}
            icon={handleMarkerIcon(location)}
          />
        )}
      </GoogleMapReact>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  map: {
    zIndex: 1,
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    background: '#f0f0f0',
  },
  //Basic Marker styling used for all markers
  marker: {
    zIndex: 2,
    position: 'absolute',
    transformOrigin: '50% 85%',
    transform: 'translate(-50%, -85%)',
    color: theme.palette.error.main,
    transition: '0.2s',
    cursor: 'pointer',
    '& $icon': {
      fontSize: '24px',
    },
    '& $bubbleBox': {
      display: 'none',
    },
  },
  //Adds hover css (currently used on markers that are not selected/origin/destinatoin/etc)
  markerHover: {
    '&:hover': {
      zIndex: 4,
      color: theme.palette.error.light,
      transform: 'translate(-50%, -85%)',
      '& $icon': {
        fontSize: '48px',
      },
      '& $bubbleBox': {
        display: 'block',
      },
    },
  },
  //for selected location
  activeMarker: {
    zIndex: 3,
    color: theme.palette.primary.main,
    '& $icon': {
      fontSize: '48px',
    },
    '& $bubbleBox': {
      display: 'block',
    },
  },
  //for origin and destination of lane
  laneLocMarker: {
    zIndex: 6,
    color: theme.palette.primary.light,
  },
  icon: {
    filter: 'drop-shadow(1px 1px 1px #00000064)',
    transition: '0.2s',
  },
  bubbleBox: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    left: theme.spacing(7),
    minWidth: '180px',
    maxWidth: '180px',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    boxShadow: '1px 1px 2px #00000064',
    backgroundColor: '#fff',
    transition: '0.2s',
  },
  bubbleNameTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bubbleAddressTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
}));
