import 'date-fns';
import React, { Component } from "react";
import RegionForm from './regionEntry/RegionForm'
// import gql from "graphql-tag";
// import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../../global-context';


const styles = theme => ({
    root: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
})


class RegionEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                { this.context && this.context.userIsAuthenticated() && (
                    <Container maxWidth="lg">
                    <Typography variant='h5' align='justify' style={{ marginLeft: "45px" }}>
                        {this.props.location.pathname.includes('add') ? "Add" : "Edit"}  Region
                    </Typography>
                    <RegionForm
                        formType={this.props.location.pathname.includes('add') ? "add" : "edit"}
                        regionId={this.props.location.pathname.includes('add') ? null : this.props.match.params.id}
                    >
                    </RegionForm>
                    </Container>)
                }
            </div>
        )
    }
}

RegionEntry.propTypes = {
    classes: PropTypes.object.isRequired,
};

RegionEntry.contextType = GlobalContext

export default withStyles(styles)(RegionEntry);