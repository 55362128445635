import React from 'react';

import { makeStyles, Typography, TextField, InputAdornment, Icon, Tooltip, MenuItem } from '@material-ui/core';

const satLogo = require('../../../static/location-icon.png');

////////// COMPONENT //////////
export default function LocationSelect(props) {
  const cls = useStyles();

  const { allLocations, loading, location, onChange, placeholder, icon, iconTip } = props;

  const handleOnChange = event => {
    if (onChange) onChange(event.target.value);
  }

  return (<>
    {loading ?
      <TextField
        fullWidth
        placeholder={placeholder || ``}
        variant="outlined"
        margin="dense"
        value={location ? location.name : `Unknown Location`}
        className={cls.select}
        InputProps={{
          className: cls.white,
          startAdornment: (
            <InputAdornment style={{ verticalAlign: "top" }} position="start">
              <Tooltip placement="top" title={iconTip || `Select Location`}>
                <Icon fontSize="small" style={{ color: '#fff', cursor: 'default' }}>{icon || `place`}</Icon>
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
      :
      <TextField
        select
        fullWidth
        placeholder={placeholder || ``}
        variant="outlined"
        margin="dense"
        value={location}
        onChange={handleOnChange}
        className={cls.select}
        InputProps={{
          className: cls.white,
          startAdornment: (
            <InputAdornment style={{ verticalAlign: "top" }} position="start">
              <Tooltip placement="top" title={iconTip || `Select Location`}>
                <Icon fontSize="small" style={{ color: '#fff', cursor: 'default' }}>{icon || `place`}</Icon>
              </Tooltip>
            </InputAdornment>
          )
        }}
        SelectProps={{
          inputProps: {
            renderValue: (value) => value.name,
            classes: {
              icon: cls.white,
            }
          }
        }}
      >
        {allLocations.map((loc, i) =>
          <MenuItem key={`location-select-item-${loc.id}`} value={loc} style={{ background: i % 2 === 0 ? '#fff' : '#f4f4f4' }}>
            <Tooltip placement="top" title="Stored Location">
              <div className={cls.optionIcon}>
                <img className={cls.satIcon} src={satLogo} alt="location pin" />
              </div>
            </Tooltip>
            <div className={cls.option}>
              <Typography className={cls.optionName}>{loc.name}</Typography>
              <Typography className={cls.optionAddress}>{loc.address}</Typography>
            </div>
          </MenuItem>
        )}
      </TextField>
    }
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  satIcon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  googleIcon: {
    color: theme.palette.text.secondary,
  },
  option: {
    display: 'block',
  },
  optionName: {
    fontSize: '16px',
    fontWeight: 500,
  },
  optionAddress: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
  select: {
    verticalAlign: 'top',
    borderRadius: '4px',
    margin: 0,
    background: '#ffffff24',
    color: '#fff',
    '&:hover': {
      background: '#ffffff36',
    },
    transition: '0.2s',
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff00',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff00',
      },
    },
  },
  white: {
    color: '#fff',
  }
}));