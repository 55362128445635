import React from "react";
import { makeStyles, Typography, DialogTitle, IconButton, Icon } from "@material-ui/core";

////////// COMPONENT //////////
export default function ModalHeader(props) {
  const cls = useStyles();
  const { className, titleClassName, closeClassName, handleClose } = props;
  return (<>
    <DialogTitle disableTypography className={cls.padding + " " + className}>
      <Typography className={cls.title + " " + titleClassName}>{props.children}</Typography>
    </DialogTitle>
    {handleClose &&
      <IconButton className={cls.close + " " + closeClassName} onClick={() => { if (handleClose) handleClose() }} aria-label="close">
        <Icon>close</Icon>
      </IconButton>
    }
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(2),
    margin: 0,
  },
  title: {
    fontSize: '21px',
    fontWeight: 500,
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));