import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, TextField, MenuItem, Tooltip } from '@material-ui/core';

////////// TOOLS //////////
const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }
const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

////////// COMPONENT //////////
export default function MoveDetailsAR(props) {
  const cls = useStyles();
  const { move } = props;

  const armove = move.accountsReceivable || null;
  const revisions = armove ? move.accountsReceivable.revisions : [];

  const currentRevision = revisions && revisions.length > 0 ? revisions[0].revision + 1 : 1;
  const [revisionNum, setRevisionNum] = useState(currentRevision);
  const getSelectedRevision = () => {
    if (currentRevision === revisionNum) return armove;
    else {
      for (let i = 0; i < revisions.length; i++) {
        if (revisions[i].revision === revisionNum) return revisions[i];
      }
    }
  }
  const selectedRevision = getSelectedRevision();

  useEffect(() => {
    if (move) setRevisionNum(currentRevision);
  }, [move])

  const total = selectedRevision ? selectedRevision.due_amount - selectedRevision.discount_amount : 0;
  const paid = selectedRevision ? selectedRevision.paid_amount : 0;
  const due = total - paid;

  let lineItems = selectedRevision && selectedRevision.details && selectedRevision.details.length > 0 ? selectedRevision.details.map(ar => {
    let name = capFirst(ar.name);
    let notes = capFirst(ar.notes);
    let amount = ar.amount;

    if (ar.name === `Transport`) name = ` Transport`;
    else if (!ar.name.includes(`Promo`) && !ar.name.includes(`promo`)) name = `Accessorial: ${capFirst(ar.name)}`;
    else name = capFirst(ar.name);

    if (ar.amount < 0) amount = `($${Math.abs(amount).toFixed(2)})`;
    else amount = `$${amount.toFixed(2)}`;

    return { name: name, notes: notes, amount: amount };
  }) : [];

  lineItems = lineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (<>
    {armove ? <>
      <div className={cls.paper}>

        <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
          <Grid item xs>
            <Tooltip placement="top-start" title="AR Move ID">
              <Typography className={cls.headTxtL}>{armove.id ? `#${armove.id}` : `No ARMove ID`}</Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip placement="top-end" title="Invoice ID">
              <Typography className={cls.headTxtR}>Invoice #{armove.invoice.id || null}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <div className={cls.break} />

        {revisions && revisions.length > 0 && <>
          <TextField
            select
            fullWidth
            label="Revision"
            margin="dense"
            variant="outlined"
            value={revisionNum}
            onChange={(event) => setRevisionNum(event.target.value)}
            style={{ margin: 0 }}
          >
            <MenuItem value={currentRevision}>Current Revision #{currentRevision}</MenuItem>
            {revisions.map(item => <MenuItem key={`revision-num-${item.revision}`} value={item.revision}>Revision #{item.revision}</MenuItem>)}
          </TextField>
          <div className={cls.break} />
        </>}

        {lineItems.length > 0 && lineItems.map((item, i) =>
          <React.Fragment key={`selected-revision-detail${i}`}>
            <div className={cls.block}>
              <Typography className={cls.detailNameTxt}>{capFirst(item.name)}</Typography>
              <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
                <Grid item xs><Typography className={cls.detailNotesTxt}>{capFirst(item.notes)}</Typography></Grid>
                <Grid item><Typography className={cls.detailAmountTxt}>{item.amount}</Typography></Grid>
              </Grid>
            </div>
            <div className={cls.lineBreak} />
          </React.Fragment>)
        }

        {selectedRevision.discount_amount > 0 && <>
          <div className={cls.block}>
            <Typography className={cls.detailNameTxt}>Discount</Typography>
            <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
              <Grid item xs><Typography className={cls.detailNotesTxt}>{capFirst(selectedRevision.discount_reason)}</Typography></Grid>
              <Grid item><Typography className={cls.detailAmountTxt}>(${selectedRevision.discount_amount.toFixed(2)})</Typography></Grid>
            </Grid>
          </div>
          <div className={cls.lineBreak} />
        </>}

        {lineItems.length > 0 || selectedRevision.discount_amount > 0 ? <div className={cls.break} /> : null}

        <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
          <Grid item xs>
            {selectedRevision.notes ? <>
              <div className={cls.notesBox}>
                <Typography className={cls.boxKey}>Notes:</Typography>
                <Typography className={cls.boxVal}>{capFirst(selectedRevision.notes)}</Typography>
              </div>
            </> : null}
            {selectedRevision.notes && selectedRevision.disputed ? <div className={cls.smallBreak} /> : null}
            {selectedRevision.disputed ? <>
              <div className={cls.disputeBox}>
                <Typography className={cls.boxKey}>Disputed&nbsp;Reason:</Typography>
                <Typography className={cls.boxVal}>{capFirst(selectedRevision.dispute_reason)}</Typography>
              </div>
            </> : null}
          </Grid>
          <Grid item>
            <Typography className={cls.key}>Total:&nbsp;&nbsp;&nbsp;</Typography>
            <div className={cls.smallBreak} />
            <Typography className={cls.key}>Amount&nbsp;Paid:&nbsp;&nbsp;&nbsp;</Typography>
            <div className={cls.smallBreak} />
            <Typography className={cls.key}>Amount&nbsp;Due:&nbsp;&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item>
            <Typography className={cls.val}>${checkNeg(total).toFixed(2)}</Typography>
            <div className={cls.smallBreak} />
            <Typography className={cls.val}>${checkNeg(paid).toFixed(2)}</Typography>
            <div className={cls.smallBreak} />
            <Typography className={cls.val}>${checkNeg(due).toFixed(2)}</Typography>
          </Grid>
        </Grid>

      </div>
    </> :
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>NO AR RECORD FOUND</Typography>
      </div>
    }
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  detailAmountTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  notesBox: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    borderRadius: '4px',
    background: '#f0f0f0',
  },
  disputeBox: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    borderRadius: '4px',
    background: '#ffdddd',
  },
  boxKey: {
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  boxVal: {
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  key: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  val: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  block: {
    display: 'block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: '#e0e0e0',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));