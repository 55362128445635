import React from "react";
import { makeStyles, Grid } from '@material-ui/core/';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomerSelect from '../../reusable/CustomerSelect';

////////// COMPONENT //////////
export default function ARIndexFilter(props) {
  const cls = useStyles();

  const { setSelectedInvoiceId, customerId, setCustomerId, start, end, onChange } = props;

  const handleDateChange = name => event => {
    onChange(event, name);
  }

  return (<>
    <div className={cls.main}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <CustomerSelect
              selectAllItem
              value={customerId || 0}
              onChange={(event) => ((event.target.value ? setCustomerId(event.target.value) : (setCustomerId(null)), setSelectedInvoiceId(null)))}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              autoOk
              maxDate={end}
              orientation="portrait"
              format="MM/dd/yyyy"
              label="Start Date"
              margin="dense"
              // inputVariant="outlined"
              value={start}
              onChange={handleDateChange(`start`)}
              className={cls.date}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              autoOk
              minDate={start}
              orientation="portrait"
              format="MM/dd/yyyy"
              label="End Date"
              margin="dense"
              // inputVariant="outlined"
              value={end}
              onChange={handleDateChange(`end`)}
              className={cls.date}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

        </Grid>

      </MuiPickersUtilsProvider>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(3.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  date: {
    width: '100%',
  },
}));