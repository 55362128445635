import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { CardContent, Card, Button, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Query } from "react-apollo";
import CardHeader from "@material-ui/core/CardHeader";
import MoveAttributeAutoSuggest from "../../reusable/MoveAttributeAutoSuggest";
import ReactSelectAutocomplete from "../../reusable/ReactSelectAutocomplete";
import queries from "../../utils/graphQL/queries";
import Loading from "../../utils/Loading";
import moment from "moment";

let log = false;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 180,
    marginRight: 5,
    marginLeft: 5,
  },
  consumerField: {
    marginRight: 5,
    marginLeft: 5,
  },
  card: {
    minWidth: 275,
  },
  year: {
    position: "relative",
    top: "31.5%",
    display: "inline-block",
    marginRight: 5,
  },
  color: {
    position: "relative",
    top: "31.5%",
    display: "inline-block",
    marginRight: 5,
  },
  make: {
    position: "relative",
    top: "29%",
    display: "inline-block",
    marginRight: 5,
    alignItems: "baseline",
  },
  instructions: {
    width: "50%",
  },
  checkbox: {
    marginLeft: "5px",
    position: "relative",
    top: 5,
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: "20px",
    display: "inline-block",
  },
  select: {
    width: "200px",
    textAlign: "center",
  },
});

const colorList = [
  "Black",
  "Blue",
  "Brown",
  "Gold",
  "Gray",
  "Green",
  "Orange",
  "Purple",
  "Red",
  "Silver",
  "Tan",
  "White",
  "Yellow",
];

class VehicleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  generateYearList = () => {
    const year = moment().add(1, "y").format("YYYY");
    const till = 1990;
    let yearList = [];
    for (var y = year; y >= till; y--) {
      yearList.push(`${y}`);
    }
    return yearList;
  };

  setMakeName = (n) => {
    n ? this.setState({ makeName: `%${n}%` }) : this.setState({ makeName: `%%` });
  };

  validateVehicleEntry = () => {
    const { stock, make, model, consumer_pickup, consumer_name, consumer_number } = this.props;
    if (!stock || !make || !model) return false;
    else if (
      consumer_pickup &&
      (consumer_name.toString().trim().length < 1 || consumer_number.toString().trim().length < 1)
    )
      return false;
    else return true;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader title="Vehicle" />
          <hr />
          <CardContent>
            <Grid container spacing={10}>
              <Query query={queries.getStockAndVin}>
                {({ ...result }) => {
                  if (log) console.log("result from stock and VIN query:", result);
                  if (result.loading) return <Loading />;
                  if (result.error) return `Error! ${result.error.message}`;
                  return (
                    <>
                      <Grid item lg={2} md={4} sm={6} xs={12}>
                        {/* <StockLookup updateVehicleData={this.props.updateVehicleData} stock={this.props.stock} handleChange={this.props.handleChange} /> */}
                        <MoveAttributeAutoSuggest
                          queriedMoves={Array.from(result.data.moves || [])}
                          attribute="vehicle_stock"
                          inputLabel="Stock #"
                          updateVehicleData={this.props.updateVehicleData}
                          stock={this.props.stock}
                          handleChange={this.props.handleChange}
                          handleAttributeChange={this.props.handleAttributeChange}
                        />
                      </Grid>

                      <Grid item lg={2} md={4} sm={6} xs={12}>
                        {/* <VinLookup updateVehicleData={this.props.updateVehicleData} handleChange={this.props.handleChange} vin={this.props.vin} /> */}
                        <MoveAttributeAutoSuggest
                          queriedMoves={Array.from(result.data.moves || [])}
                          attribute="vehicle_vin"
                          inputLabel="VIN #"
                          updateVehicleData={this.props.updateVehicleData}
                          vin={this.props.vin}
                          handleChange={this.props.handleChange}
                          handleAttributeChange={this.props.handleAttributeChange}
                        />
                      </Grid>
                    </>
                  );
                }}
              </Query>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={this.props.manual === "true" || this.props.manual}
                      onChange={this.props.handleCheckChange("manual_flag")}
                      value="manual"
                      margin="normal"
                    />
                  }
                  label="Manual Transmission"
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={this.props.consumer_pickup}
                      onChange={this.props.handleCheckChange("consumer_pickup")}
                      value="consumer_pickup"
                      margin="normal"
                    />
                  }
                  label="Consumer Pickup"
                />
              </Grid>
            </Grid>

            <div style={{ alignItems: "baseline" }}>
              <Grid container spacing={6}>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <div className={classes.year}>
                    <ReactSelectAutocomplete
                      options={this.generateYearList()}
                      onChange={this.props.handleChange}
                      onSelectChange={this.props.handleSelectChange}
                      year={this.props.year}
                      name="year"
                      placeholder="Year"
                    ></ReactSelectAutocomplete>
                  </div>
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <div className={classes.color}>
                    <ReactSelectAutocomplete
                      options={colorList}
                      onChange={this.props.handleChange}
                      onSelectChange={this.props.handleSelectChange}
                      color={this.props.color}
                      name="color"
                      placeholder="Color"
                    ></ReactSelectAutocomplete>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={6}>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className={classes.color}>
                  <Query query={queries.getAllMakes}>
                    {({ ...result }) => {
                      if (result.loading)
                        return (
                          <ReactSelectAutocomplete
                            options={["loading"]}
                            onChange={this.props.handleChange}
                            onSelectChange={this.props.handleSelectChange}
                            make={this.props.make}
                            name="make"
                            placeholder="Make"
                          ></ReactSelectAutocomplete>
                        );
                      if (result.error) return `Error! ${result.error.message}`;
                      const makesArray = result.data.vehiclemakes.map((make) => {
                        return make.name;
                      });
                      if (log) console.log("results of Make query: ", makesArray);
                      return (
                        <ReactSelectAutocomplete
                          options={makesArray}
                          onChange={this.props.handleChange}
                          onSelectChange={this.props.handleSelectChange}
                          make={this.props.make}
                          name="make"
                          placeholder="Make"
                        ></ReactSelectAutocomplete>
                      );
                    }}
                  </Query>
                </div>
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className={classes.color}>
                  {/* queries models for make entered in field above. If no name entered, will return all models */}
                  <Query
                    query={queries.getModelsByMakeName}
                    variables={{ makeName: this.props.make ? "%" + this.props.make + "%" : "No Make" }}
                    onError={(err) => {
                      console.error(err);
                      this.context.handleNotifications(true, "error", "Query failed to retrieve vehicle models data");
                    }}
                  >
                    {({ ...result }) => {
                      if (result.loading)
                        return (
                          // prevents user from noticing reloads
                          <ReactSelectAutocomplete
                            options={["loading"]}
                            onChange={this.props.handleChange}
                            onSelectChange={this.props.handleSelectChange}
                            model={this.props.model}
                            name="model"
                            placeholder="Model"
                          ></ReactSelectAutocomplete>
                        );
                      if (result.error) return `Error! ${result.error.message}`;
                      const modelsArray = result.data.vehiclemodels.map((model) => {
                        return model.name;
                      });
                      if (log) console.log("result Model query: ", modelsArray);
                      return (
                        <ReactSelectAutocomplete
                          options={modelsArray}
                          onChange={this.props.handleChange}
                          onSelectChange={this.props.handleSelectChange}
                          model={this.props.model}
                          name="model"
                          placeholder="Model"
                        ></ReactSelectAutocomplete>
                      );
                    }}
                  </Query>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <TextField
                  id="reference_num"
                  label="Reference number"
                  value={this.props.reference_num ? this.props.reference_num : ""}
                  onChange={this.props.handleChange("reference_num")}
                  margin="normal"
                />
                <br />
                <TextField
                  id="instructions"
                  label="Special Instructions"
                  className={classes.instructions}
                  value={this.props.instructions ? this.props.instructions : ""}
                  onChange={this.props.handleChange("instructions")}
                  margin="normal"
                />
              </Grid>

              {this.props.consumer_pickup && (
                <>
                  <Grid item xs={12} className={classes.gridFix}>
                    <Typography color="textSecondary">Please enter consumer details for this pickup:</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.gridFix}>
                    <TextField
                      required
                      id="consumer-pickup-name"
                      label="Name"
                      value={this.props.consumer_name}
                      onChange={this.props.handleChange("consumer_name")}
                      className={classes.consumerField}
                      helperText="Enter the name of the point-of-contact"
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.gridFix}>
                    <TextField
                      required
                      id="consumer-pickup-number"
                      label="Phone Number"
                      value={this.props.consumer_number}
                      onChange={this.props.handleChange("consumer_number")}
                      className={classes.consumerField}
                      helperText="Enter the phone number to contact"
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <br />
            <br />
            {this.props.createEdit === false && (
              <Button
                disabled={!this.validateVehicleEntry()}
                variant="contained"
                style={{ float: "right" }}
                color="primary"
                className={classes.button}
                onClick={() => this.props.addVehicle(this.props)}
              >
                Add
              </Button>
            )}
            <Button
              variant="contained"
              style={{ float: "right" }}
              className={classes.button}
              onClick={this.props.clearVehicle}
            >
              Clear
            </Button>
          </CardContent>
        </Card>
        <br />
      </div>
    );
  }
}

VehicleForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleForm);
