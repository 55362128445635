import React from 'react';
import CustomerSelect from '../../reusable/CustomerSelect';
import { TextField, Button, DialogContent, DialogActions, DialogTitle, FormControl } from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import fragments from '../../utils/graphQL/fragments';
import gql from "graphql-tag";

const LocationHelpers = require('sat-lane-manager/locations');
let myLocationHelpers = null

let log = false;

const CREATE_NEW_LOCATION = gql`
  mutation insert_location(
    $active: smallint!
    $address: String!
    $customer_id: bigint!
    $name: String!
    $nickname: String!
    $email: String!
    $latitude: numeric!
    $longitude: numeric!
    $phone: String!
    $region_id: bigint!
    )
    {
      insert_locations(
        objects: [{
          active: $active
          address: $address
          customer_id: $customer_id
          email: $email
          latitude: $latitude
          longitude: $longitude
          name: $name
          nickname: $nickname
          phone: $phone
          updatedat: "now()"
          region_id: $region_id
        }]
      ) {
      returning {
        ...Location
      }
    }
  }
  ${fragments.location}
`;

class LocationCreationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      street: "",
      city: "",
      state: "",
      email: "",
      name: "",
      nickname: "",
      customer: "",
      phone: "",
    }
  };

  componentWillMount = () => {
    myLocationHelpers = new LocationHelpers(
      `https://${process.env.REACT_APP_GQL_SD}.herokuapp.com/v1/graphql`,
      this.context.userProfile["https://api_keys.io/jwt/claims"]["GQLSecret"],
      this.context.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"])
  }

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  formatAddress = () => {
    let formattedAddress = `${this.state.street}, ${this.state.city}, ${this.state.state}`;
    return formattedAddress
  }

  createNewLocation = async () => {
    let newLocation = await myLocationHelpers.build(this.state.customer, this.state.name.trim(), this.state.street, this.state.email.trim(), this.state.phone.trim(), this.state.nickname.trim());
    if (log) console.log("createNewLocation - created location object:", newLocation);
    delete newLocation.updatedAt;  // This should not be sent as a variable
    this.context.apolloClient.mutate({
      mutation: CREATE_NEW_LOCATION,
      variables: newLocation,
    }).then(res => {
      if (log) console.log("createNewLocation - CREATE_NEW_LOCATION response (res.data):", res.data);
      this.props.closeForm();
    })
      .catch(err => {
        console.error(err);
        this.context.handleNotifications(true, "error", "Failed to create location, please ensure you are authorized for this region");
      })
  }

  render() {
    return (
      <>
        <DialogTitle>New Location</DialogTitle>
        <DialogContent>
          <FormControl style={{ margin: 2, minWidth: 300 }}>
            <TextField
              required
              name="name"
              label="Name"
              value={this.state.name}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            />
            <CustomerSelect
              value={this.state.customer}
              onChange={this.handleInputChange}
            />
            <TextField
              required
              name="street"
              label="Address"
              value={this.state.street}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            />
            {/* <TextField
              required
              name="city"
              label="City"
              value={this.state.city}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            />
            <TextField
              required
              name="state"
              label="State"
              value={this.state.state}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            /> */}
            <TextField
              name="nickname"
              label="Nickname"
              value={this.state.nickname}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            />
            <TextField
              name="email"
              label="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            />
            <TextField
              name="phone"
              label="Phone"
              value={this.state.phone}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
              shrink
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!this.state.name.trim() || !this.state.customer || !this.state.street.trim()}
            color="primary"
            variant="contained"
            onClick={this.createNewLocation}
          >
            Save
        </Button>
          <Button
            color="default"
            variant="contained"
            onClick={this.props.closeForm}
          >
            Cancel
        </Button>
        </DialogActions>
      </>
    )
  }
}

LocationCreationForm.contextType = GlobalContext;

export default LocationCreationForm;