import React, { useEffect, useContext, useState } from "react";
import { GlobalContext } from "../../global-context";
import Grid from "@material-ui/core/Grid";
import { useAuth } from "../../providers/AuthProvider";
import { TextField, Button, Typography } from "@material-ui/core";
import { Redirect } from "react-router";
import Loading from "./Loading";

export default function Login() {
  const ctx = useContext(GlobalContext);
  // const { login } = useAuth();
  const [userEmailInput, setUserEmailInput] = useState("");
  const [userPassInput, setUserPassInput] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleDisabled() {
    if (!userEmailInput || !userPassInput || userEmailInput.trim().length < 1 || userPassInput.trim().length < 1)
      return true;
    else return false;
  }

  function handleLogin() {
    setLoading(true);
    try {
      ctx.login({
        email: userEmailInput,
        password: userPassInput,
      });
    } catch (err) {
      console.error("Failed to login!", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    console.log("ctx has updated!");
    console.log(ctx.userProfile);
  }, [ctx]);

  return (
    <>
      {!ctx.userIsAuthenticated() ? (
        <Grid container direction="column" justify="center" alignItems="center">
          <div style={{ topMargin: "20px" }}>
            {/* <div id="login"></div> */}
            <TextField
              name="userEmailInput"
              label="Email"
              placeholder="Email"
              style={{
                marginTop: "30px",
                // marginLeft: "45px",
                width: "500px",
              }}
              value={userEmailInput}
              margin="normal"
              variant="outlined"
              onChange={(e) => setUserEmailInput(e.target.value)}
            />
            <br />
            <TextField
              name="userPassInput"
              type="password"
              label="Password"
              placeholder="Password"
              style={{
                marginTop: "15px",
                // marginLeft: "45px",
                width: "500px",
              }}
              value={userPassInput}
              margin="normal"
              variant="outlined"
              onChange={(e) => setUserPassInput(e.target.value)}
            />
            <br />
            {!loading ? (
              <Button
                disabled={handleDisabled()}
                style={{
                  marginTop: "15px",
                  // marginLeft: "45px"
                }}
                color="primary"
                variant="contained"
                onClick={() => handleLogin()}
              >
                Login
              </Button>
            ) : (
              <Loading />
            )}
          </div>
        </Grid>
      ) : (
        <Redirect to="/moves" />
      )}
    </>
  );
}
