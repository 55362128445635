import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel, Toolbar, Typography, TextField, Icon, IconButton, Tooltip } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import '../../reusable/react-contextmenu.css';
import AutoRefresh from '../../reusable/AutoRefresh';

export default function LanesTable(props) {
  const classes = useStyles();
  const { title, size, headers, rows, actions, search, setSearch, defaultOrder, defaultOrderBy, 
    order, orderBy, setOrder, setOrderBy, setExpandedRowId, className } = props;

  let contextTrigger = null;

  const handleSearchInput = event => {
    setSearch(event.target.value.toLocaleLowerCase())
  }

  const handleResetTable = () => {
    setSearch(``)
    setOrder(defaultOrder)
    setOrderBy(defaultOrderBy)
    if (setExpandedRowId) setExpandedRowId(0)
  }

  const handleSortTable = (event, property) => {
    const isAscending = (orderBy === property && order === 'asc')
    setOrder(isAscending ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleSortByProperty = property => event => {
    handleSortTable(event, property);
  };

  const handleOptionsOpen = e => {
    if (contextTrigger) {
      contextTrigger.handleContextClick(e);
    }
  }

  const collect = (props) => {
    return props
  }

  return (<>
    <div className={classes.paper + " " + className}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.tableName}>
          <div className={classes.name}>
            <Typography className={classes.titleTxt} style={{display: 'inline'}}>{title}
            </Typography>
          </div>
        </div>
      </Toolbar>

      <div className={classes.tableWrapper}>
        <Table className={classes.table} size={size ? size : `medium`}>
          <TableHead>
            <TableRow>
              {headers.map(header => (!header.hide &&
                <TableCell key={`${header.id}-call`}align={header.alignLeft ? 'left' : 'right'} padding="default" sortDirection={orderBy === header.id ? order : false}>
                  {header.value ||
                    <TableSortLabel className={classes.headTxt} active={orderBy === header.id} direction={order} onClick={handleSortByProperty(header.id)}>
                      {header.label}
                      {orderBy === header.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
                    </TableSortLabel>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.children}
          </TableBody>
        </Table>
      </div>
    </div>
  </>)
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    border: '1px solid #50525824',
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
    background: '#fff',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: '400px'
  },
  toolbar: {
    position: 'relative',
    minHeight: '80px',
    padding: theme.spacing(2),
    borderRadius: '8px 8px 0 0',
    background: '#fff',
    color: theme.palette.text.primary,
  },
  tableName: {
    left: theme.spacing(2),
    position: 'absolute',
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-30px',
    },
  },
  name: {
    width: '100%',
    height: '100%',
    paddingRight: theme.spacing(1),
    verticalAlign: 'top',
  },
  titleTxt: {
    margin: '8px 0 0 0',
    fontSize: '20px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0 0 0',
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '14px 0 0 0',
      fontSize: '14px',
    },
  },
  tableTools: {
    right: theme.spacing(2),
    position: 'absolute',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(1.25),
    },
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(0.5),
      display: 'none'
    },
  },
  search: {
    display: 'inline-block',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: '8px',
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  searchBar: {
    width: '320px',
    margin: '4px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'top',
  },
  headTxt: {
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  notFound: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  button1: {
    zIndex: 1001,
    position: 'absolute',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: '160px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  button2: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  pagination: {
    borderRadius: '0 0 8px 8px',
    background: '#fff',
    fontSize: '12px',
  },
  refreshBtn: {
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.veryLight
    }
  }
}))