import React, { Component } from "react";
import DateFilter from "../reusable/DateFilter";
import moment from 'moment';
import { Container, TextField, Typography, MenuItem, withStyles, Grid } from "@material-ui/core";
import MoveAggregate from "./dashboard/MoveAggregate";
import { Query } from 'react-apollo';
import queries from '../utils/graphQL/queries';
import subscriptions from '../utils/graphQL/subscriptions';
import Loading from '../utils/Loading';
import { GlobalContext } from '../../global-context';

let log = false;

// Values for dropdown which will be used to determine date range
const filterOptions = [
  { name: "today", label: "today" },
  { name: "week to date", label: "week to date" },
  { name: "month to date", label: "month to date" },
  { name: "year to date", label: "year to date" },
  { name: "custom range", label: "custom range" },
];

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  textField: {
    marginTop: "0px !important",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: "0px !important",
  },
  menuItem: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
})

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      dateRangeLabel: "today",
    }
  };

  // Sets dates from local storage - if no range is stored it will default to today
  componentWillMount = () => {
    const localLabel = localStorage.getItem('dateRangeLabelDash');
    switch (localLabel) {
      case 'today': case 'week to date': case 'month to date': case 'year to date':
        this.setState({
          dateRangeLabel: localLabel
        })
        this.handleDateRangeConversion(localLabel)
        break;
      case 'custom range':
        this.setState({
          dateRangeLabel: localLabel,
          startDate: localStorage.getItem('startDateDash'),
          endDate: localStorage.getItem('endDateDash')
        })
        break;
      default:
        this.setState({
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          dateRangeLabel: 'today'
        })
    }
  };

  // Match the name of a child component's event and set the value of the corresponding state property
  // Sets dates in localStorage as well
  handleChangeFromChild = (name, value) => {
    this.setState({ [name]: value });
    localStorage.setItem(name + 'Dash', value);
  };

  // Match a child component's label name to a state property and set its value using the trigger event
  // Sets dateRangeLabel in localStorage as well
  handleDateRangeSelect = (event) => {
    if (log) console.log(event.target)
    this.setState({ dateRangeLabel: event.target.value }, () => {
      this.handleDateRangeConversion();
    })
    this.setLocalDateRange(event.target.value);
  };

  // Set the date range in state to match the chosen range parameter, set by user
  handleDateRangeConversion = () => {
    switch (this.state.dateRangeLabel) {
      case 'today':
        this.setState({
          endDate: moment().format("YYYY-MM-DD"),
          startDate: moment().format("YYYY-MM-DD"),
        })
        break;
      case 'week to date':
        this.setState({
          endDate: moment().format("YYYY-MM-DD"),
          startDate: moment().startOf('week').format("YYYY-MM-DD"),
        })
        break;
      case 'month to date':
        this.setState({
          endDate: moment().format("YYYY-MM-DD"),
          startDate: moment().startOf('month').format("YYYY-MM-DD"),
        })
        break;
      case 'year to date':
        this.setState({
          endDate: moment().format("YYYY-MM-DD"),
          startDate: moment().startOf('year').format("YYYY-MM-DD"),
        })
        break;
      default:
        console.log("Unknown selection");
    }
  };

  // sets and removes items from localStorage
  setLocalDateRange = (range) => {
    if (range === 'custom range') {
      localStorage.setItem('dateRangeLabelDash', range);
      localStorage.setItem('startDateDash', this.state.startDate);
      localStorage.setItem('endDateDash', this.state.endDate);
    } else {
      localStorage.setItem('dateRangeLabelDash', range);
      localStorage.removeItem('startDateDash');
      localStorage.removeItem('endDateDash');
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography inline="true" component="div" variant="h6">Show for
              <TextField
                  id="dashboard-select-date-range"
                  select
                  value={this.state.dateRangeLabel}
                  inputProps={{ className: classes.menuItem, }}
                  onChange={this.handleDateRangeSelect}
                  className={classes.textField}
                  margin="normal">
                  {filterOptions.map(option => (
                    <MenuItem key={option.label} value={option.label}>
                      <Typography inline="true" component="div" variant="h6">{option.label}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DateFilter startDate={this.state.startDate} endDate={this.state.endDate} handleChange={this.handleChangeFromChild} dateRangeLabel={this.state.dateRangeLabel} />
            </Grid>
          </Grid>
          { this.context.userProfile["https://hasura.io/jwt/claims"] && this.context.userIsAuthenticated() && (
            <Query query={queries.movesByRange}
              variables={{ startDate: moment(this.state.startDate).format(), endDate: moment(this.state.endDate).add(1, "day").format() }}
              onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve moves data")}}>
              {({ subscribeToMore, ...result }) => {
                if (log) console.log("querying on dates: ", moment(this.state.startDate).format(), moment(this.state.endDate).add(1, "day").format())
                if (log) console.log("result:", result)
                if (result.loading) return <Loading />;
                if (result.error) return `Error! ${result.error.message}`;
                return (
                  <MoveAggregate
                    // Pass a subscribeToMore function to the child component as a prop that will be called on componentDidMount
                    //  This will initialize the subscription to keep the cache up to date with current data 
                    subscribeToNewMoves={() =>
                      subscribeToMore({
                        document: subscriptions.movesByRange,
                        variables: { startDate: moment(this.state.startDate).format(), endDate: moment(this.state.endDate).add(1, "day").format() },
                        updateQuery: (prev, { subscriptionData }) => {
                          if (log) console.log("subscriptionData:", subscriptionData, "prev:", prev);
                          if (!subscriptionData.data) return prev;
                          return Object.assign({}, prev, subscriptionData.data)
                        }
                      })
                    }
                    // Feed the array of moves returned from the query as a prop, this will update automatically due to the subscribeToMore function called in child
                    moves={Array.from(result.data.moves || [])}
                  />
                );
              }}
            </Query>)
          }
        </Container>
      </div>
    )
  }
};

Dashboard.contextType = GlobalContext;

export default withStyles(styles)(Dashboard);