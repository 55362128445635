import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, Chip, Icon } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

////////// COMPONENT //////////
export default function MoveDetailsAccessorials({ move }) {
  const cls = useStyles();

  useEffect(() => {
    console.log('Spend authorizations rendering for move: ', move)
  }, [move])

  return (<>
    <div className={cls.paper}>
      <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
        <Grid item xs>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <div className={cls.break} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {move && move.accessorials && move.accessorials && move.accessorials.length > 0 ? <>
            <Table className={cls.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Id</TableCell>
                  <TableCell align="left">Code</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Cost</TableCell>
                  <TableCell align="left">Payable</TableCell>
                  <TableCell align="left">Chargeable</TableCell>
                  <TableCell align="left">Max Charge</TableCell>
                  <TableCell align="left">Attempts</TableCell>
                  <TableCell align="left">Last Decline</TableCell>
                  <TableCell align="left">Notes</TableCell>
                  <TableCell align="right">Window</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {move.accessorials.map(accessorial => {
                  let authorization = accessorial && accessorial.authorization || {}
                  return (
                    <TableRow key={`accessorial-${accessorial.id}`}>
                      <TableCell component="th" scope="row">{accessorial.id}/{authorization.id}</TableCell>
                      <TableCell component="th" scope="row">{accessorial.code}</TableCell>
                      <TableCell align="left">
                        <Chip variant={accessorial.status === 'approved' ? 'default' : 'outlined'} icon={<Icon fontSize='small'>{accessorial.status === 'approved' ? 'done' : 'monetization_on'}</Icon>} size="small" color={accessorial.status === 'pending' ? 'primary' : 'secondary'} label={accessorial.status} />
                      </TableCell>
                      <TableCell align="left">{'$' + (accessorial.cost || 0).toFixed(2)}</TableCell>
                      <TableCell align="left">{'$' + (accessorial.ap_amount || 0).toFixed(2)}</TableCell>
                      <TableCell align="left">{'$' + (accessorial.ar_amount || 0).toFixed(2)}</TableCell>
                      <TableCell align="left">{'$' + (accessorial.max_charge || 0).toFixed(2)}</TableCell>
                      <TableCell align="left">{authorization.auth_attempts || 0}</TableCell>
                      <TableCell align="left">{`${new Date(authorization.last_declined_at).toDateString()} ${authorization.last_declined_reason}`}</TableCell>
                      <TableCell align="left" style={{ maxWidth: '200px' }}>{accessorial.notes}</TableCell> {/*whiteSpace: 'nowrap', overflowX: 'hidden', */} 
                      <TableCell align="right" >{authorization.valid_from ? `Valid between ${moment(authorization.valid_from).format('LT')} and ${moment(authorization.valid_to).format('LT')}` : ''}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </> : null}
        </Grid>

      </Grid>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    border: "1px solid #ddd",
    borderRadius: "8px",
    background: "#fff",
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: "21px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    cursor: "default",
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    cursor: "default",
  },
}));