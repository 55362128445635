import React, { Component } from "react";
import "./index.css";
import { GlobalContext } from "./global-context";
import Layout from "./components/Layout";
import ApolloClient from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { ApolloProvider } from "react-apollo";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { withWidth } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/react";
import { withAuth0 } from "@auth0/auth0-react";
import Loading from "./components/utils/Loading";
import { useAuth } from "./providers/AuthProvider";

let log = false;

const theme = createMuiTheme({
  palette: {
    primary: {
      veryLight: `#70bbf7`,
      light: `#4dabf5`,
      main: `#2196f3`,
      dark: `#1769aa`,
      veryDark: `#104976`,
      contrastText: `#fff`,
    },
    secondary: {
      veryLight: `#c0c2c8`,
      light: `#909298`,
      main: `#505258`,
      dark: `#202024`,
      veryDark: `#101012`,
      contrastText: `#fff`,
    },
    error: { veryLight: `#ffdddd`, light: `#e57373`, main: `#f44336`, dark: `#d32f2f`, contrastText: `#fff` },
    warning: { veryLight: `#ffffcc`, light: `#ffb74d`, main: `#ff9800`, dark: `#f57c00`, contrastText: `#fff` },
    info: { light: `#64b5f6`, main: `#2196f3`, dark: `#1976d2`, contrastText: `#fff` },
    success: { light: `#81c784`, main: `#4caf50`, dark: `#388e3c`, contrastText: `#fff` },

    text: {
      primary: `#505258`,
      secondary: `#505258a0`,
      tertiary: `#50525880`,
      disabled: `#50525880`,
      hint: `#50525880`,
    },

    divider: `#50525824`,
    softDivider: `#50525816`,
    hardDivider: `#50525832`,

    action: {
      active: `#505258a0`,
      hover: `#50525810`,
      selected: `#50525820`,
      disabled: `#50525860`,
      disabledBackground: `#50525830`,
      focus: `#50525830`,
    },

    background: {
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },
  },

  typography: { useNextVariants: true },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth0: props.auth0,
      // The below function 'userIsAuthenticated()' determines if a user is both logged in through Auth0 and has an Apollo Client initialized
      // The 'auth0' prop has a boolean 'isAuthenticated' that identifies whether or not the current user is logged in
      // Additionally, we set our own boolean 'apolloInitialized' in state when a user sets up a new Apollo Client with their JWT
      // If both of these values are true, then the user is deemed 'authenticated'
      userIsAuthenticated: () => (this.state.userToken && this.state.apolloInitialized ? true : false),
      apolloInitialized: false,
      theme: theme,
      width: props.width,
      notificationShow: false,
      notificationVariant: "",
      notificationMessage: "",
      handleNotifications: async (
        show,
        variant = this.state.notificationVariant,
        message = this.state.notificationMessage
      ) =>
        this.setState({
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message,
        }),
      userProfile: {},
      userToken: "",
      redirectPath: null,
      login: (user) => {
        if (log) {
          console.log("running login function");
        }
        this.login(user);
      },
      logout: () => {
        if (log) {
          console.log("running logout function");
        }
        //Rob 05/13 - call AuthProvider logout
        this.sendRequest("logout", undefined);
        this.setState({ userProfile: {}, userToken: "", apolloInitialized: false, apolloClient: {} });
      },
      loadGlobalTrips: (tripsSub) => {
        if (log) {
          console.log("loading trips:", tripsSub);
        }
        this.setState({ trips: tripsSub });
      },
      loadGlobalLanes: (lanesSub) => {
        if (log) {
          console.log("loading lanes:", lanesSub);
        }
        this.setState({ lanes: lanesSub });
      },
      loadGlobalLocations: (locationsSub) => {
        if (log) {
          console.log("loading locations:", locationsSub);
        }
        this.setState({ locations: locationsSub });
      },
      loadGlobalCustomers: (customersSub) => {
        if (log) console.log("loading customers:", customersSub);
        this.setState({ customers: customersSub });
      },
      loadGlobalMoves: (movesSub) => {
        if (log) console.log("loading moves:", movesSub);
        this.setState({ moves: movesSub });
      },
      setUserAuth: (profile, token) => {
        if (log) console.log("profile", profile, "and token", token);
        this.setState({ userProfile: profile }); //Rob 05/13 - pulling profile from AuthProvider
        this.setState({ userToken: this.state.userToken }); //Rob 05/13 - pulling JWT from AuthProvider
      },
      apolloClient: {},
      setupApollo: (token) => {
        if (log) {
          console.log("Setting up the Apollo...");
        }
        const authLink = setContext(async (_, { headers }) => {
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${token}`,
              },
            };
          }),
          wsurl = `wss://${process.env.REACT_APP_GQL_SD}.herokuapp.com/v1/graphql`,
          httpurl = `https://${process.env.REACT_APP_GQL_SD}.herokuapp.com/v1/graphql`,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query);
              return kind === "OperationDefinition" && operation === "subscription";
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          });
        if (log) {
          console.log("Apollo Client Initialized! ", client);
        }
        this.setState({ apolloClient: client, apolloInitialized: true });
      },
    };
  }
  buildSentryUserObj = (userProfile) => ({
    email: userProfile.email,
    name: userProfile.name,
    nickname: userProfile.nickname,
    user: userProfile.id,
    role: userProfile.role,
  });

  fetchLocalUser = async () => {
    const localUserJson = await localStorage.getItem("user");
    const localUser = localUserJson && JSON.parse(localUserJson);
    this.setState({ userProfile: localUser });
    //setUser(localUser);
  };

  saveUser = (user) => {
    this.setState({ userProfile: user });
    localStorage.setItem("user", JSON.stringify(user));
  };

  deleteUser = () => {
    this.setState({ userProfile: null });
    localStorage.removeItem("user");
  };

  login = (user) => this.sendRequest("login", user, this.initializeAfterLogin);
  logout = () => this.sendRequest("logout", undefined);

  initializeAfterLogin = (user) => {
    this.setState({
      userProfile: user,
      userToken: user.access_token,
    });
    this.state.setupApollo(user.access_token);
  };

  sendRequest = async (endpoint, body, successCallback) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
    };

    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }

    const response = await fetch(`/api/v1/auth/${endpoint}`, requestOptions);

    if (response.ok) {
      const responseBody = await response.json();
      if (successCallback) successCallback(responseBody);
    }
  };

  // When the Auth0 provider updates with a new auth0 object, check against the current obj
  // If the new object is different, then update the global state
  componentWillReceiveProps = (nextProps) => {
    console.log("[AuthProvider] Initial startup");
    this.fetchLocalUser();

    // if (JSON.stringify({ ...nextProps.auth0 }) !== JSON.stringify({ ...this.state.auth0 }))
    //   this.setState({ auth0: nextProps.auth0 });
  };

  render() {
    // if (this.state.auth0.isLoading) return <Loading />
    if (this.state.userIsAuthenticated()) {
      console.log("setting up the ol' sentry obj...", this.state.userProfile);
      this.state.userProfile && Sentry.setContext("user", this.buildSentryUserObj(this.state.userProfile));
      log && console.log("Rendering with ApolloProvider now...");
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <ApolloProvider client={this.state.apolloClient}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout />
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      );
    } else {
      if (log) {
        console.log("Rendering without ApolloProvider until user is logged in.");
      }
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <Layout />
          </GlobalContext.Provider>
        </MuiThemeProvider>
      );
    }
  }
}

export default withWidth()(withAuth0(App));
