import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Chip, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Icon, Tooltip, Button } from "@material-ui/core";
import { GlobalContext } from "../../../global-context";
import ChangeConfirmationModal from './ChangeConfirmationModal'
import moment from "moment";

const log = false

export default function RateRuleGroupSelector({
    rateRuleGroups,
    rateRules,
    onChange = () => { },
    allowChanges = true,
}) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [selectedRateRuleGroup, setSelectedRateRuleGroup] = useState(null);
  const [pendingRateRuleGroup, setPendingRateRuleGroup] = useState(null);
  const [changeConfirmationModalOpen, setChangeConfirmationModalOpen] = useState(false);

  useEffect(() => {
    log && console.log('Inbound rate rule groups prop set to: ', rateRuleGroups)
    if (!rateRuleGroups) return
    setSelectedRateRuleGroup(rateRuleGroups.length > 0 ? rateRuleGroups[0] : null)
  }, [rateRuleGroups])

  useEffect(() => {
    if (!selectedRateRuleGroup) return
    log && console.log('Selected rate rule group changed to: ', selectedRateRuleGroup.name)
    onChange(selectedRateRuleGroup)
  }, [selectedRateRuleGroup])

  const attemptSelectionChange = rateRuleGroup => {
    if (allowChanges) {
      setSelectedRateRuleGroup(rateRuleGroup)
    } else {
      setChangeConfirmationModalOpen(true)
      setPendingRateRuleGroup(rateRuleGroup)
    }
  }

  const handleChangeConfirmation = () => {
    setChangeConfirmationModalOpen(false)
    setSelectedRateRuleGroup(pendingRateRuleGroup)
    setPendingRateRuleGroup(null)
  }

  return (
    <div className={cls.root}>
      <ChangeConfirmationModal
        open={changeConfirmationModalOpen}
        onAgree={handleChangeConfirmation}
        onDisagree={() => {setChangeConfirmationModalOpen(false);setPendingRateRuleGroup(null);}}
      />
      <List component="div" aria-label="rate rule group selector" style={{width:'100%', padding:'0px'}}>
        {!rateRuleGroups &&
          <ListItem>
            <ListItemText disabled secondary={'Loading...'} />
          </ListItem>
        }
        {rateRuleGroups && rateRuleGroups.map((rateRuleGroup, index) => (
            <ListItem
              button
              classes={{ root: cls.selectList, selected: cls.selected }}
              key={`rate-rule-group-${rateRuleGroup.id}`}
              onClick={() => {attemptSelectionChange(rateRuleGroup)}}
              selected={selectedRateRuleGroup && rateRuleGroup.id === selectedRateRuleGroup.id}
            >
              <ListItemText
                primary={
                  <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item md={8}>
                      <Typography style={{ textAlign: 'left' }}>{rateRuleGroup.name}</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography style={{ textAlign: 'right' }} component='div'>
                        {((moment(rateRuleGroup.begin_date) <= moment() && moment(rateRuleGroup.end_date) > moment()) || (rateRuleGroup.end_date === null)) && <Chip label="Active" variant="default" size="small" color='primary' />}
                        {moment(rateRuleGroup.end_date) <= moment() && <Chip label="Expired" variant="default" size="small" color='secondary' />}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={rateRuleGroup.begin_date !== null ? `${moment(rateRuleGroup.begin_date).format('ll')} - ${moment(rateRuleGroup.end_date).format('ll')}` : `Unlimited timeframe`}
              />
            </ListItem>
        ))}
        {/*}
        <ListItem button>
          <ListItemIcon style={{minWidth: '35px'}}><Icon>add_box</Icon></ListItemIcon>
          <ListItemText primary={'Add New'} secondary={'Add a new revision'} />
        </ListItem>
        <ListItem style={{display:'flex', justifyContent:'center'}}>
          <Tooltip placement="top" title={`Click to add a new version of this pricing`}>
            <Button className={cls.newVersionBtn} onClick={null}>New Version</Button>
          </Tooltip>
        </ListItem>
        */}
        {/* <ListItem style={{display:'flex', justifyContent:'center', marginTop: '20px', marginBottom: '20px'}}>
          <Tooltip placement="top" title={`Click to add a new version of the active pricing`}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<Icon>add_box</Icon>}
            >
              New Pricing
            </Button>
          </Tooltip>
        </ListItem> */}
      </List>
    </div>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    background: '#f4f4f4',
    //paddingLeft: '15px'
  },
  selectList: {
    '&$selected': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#c3c3c3',
        color: '#fff',
      }
    },
  },
  selected: {},
  newVersionBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));