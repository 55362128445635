import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../global-context';

import { makeStyles, Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import fragments from '../utils/graphQL/fragments';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';

import MapSidebar from './map/MapSidebar';
import MapBounds from './map/MapBounds';
import LocationAddEditModal from './map/LocationAddEditModal';

const log = true;

////////// COMPONENT //////////
export default function Map(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [sidebarState, setSidebarState] = useState(`index`);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [origin, setOrigin] = useState(null)
  const [destination, setDestination] = useState(null)
  const [selectedLane, setSelectedLane] = useState({ origin_location_id: undefined, destination_location_id: undefined });
  const [createMode, setCreateMode] = useState(false);
  const [laneMode, setLaneMode] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [search, setSearch] = useState(``);
  const localCustomerId = parseInt(localStorage.getItem(`customerId`));
  const [customerId, setCustomerId] = useState(localCustomerId || null);
  const [typeTags, setTypeTags] = useState([]);

  useEffect(() => {
    if (ctx && ctx.userIsAuthenticated()) {
      if (window.location.pathname.includes(`/lanes/`)) {
        log && console.log(`Lane ID:`, Number(props.match.params.id));
        ctx.apolloClient.query({
          query: GET_LANE,
          variables: { id: Number(props.match.params.id) }
        }).then(res => {
          if (res && res.data && res.data.lanes && res.data.lanes.length > 0) {
            const resLane = res.data.lanes[0];
            log && console.log(`Lane Found:`, resLane);
            setOrigin(resLane.pickup);
            setDestination(resLane.delivery);
          }
        }).catch(err => {
          console.log(err);
        });
      }
      else if (window.location.pathname.includes(`/locations/`)) {
        log && console.log(`Location ID:`, Number(props.match.params.id));
        ctx.apolloClient.query({
          query: GET_LOCATION,
          variables: { id: Number(props.match.params.id) }
        }).then(res => {
          if (res && res.data && res.data.locations && res.data.locations.length > 0) {
            const resLoc = res.data.locations[0];
            log && console.log(`Location Found:`, resLoc);
            setSelectedLocation(resLoc);
            setSidebarState(`location`);
          }
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(`customerId`, customerId);
  }, [customerId])
  useEffect(() => {
    selectedLane ? console.log('selectedLane: ', selectedLane.origin_location_id + ' ' + selectedLane.destination_location_id) : console.log('no locations set to lane');
    if (selectedLane.origin_location_id && selectedLane.destination_location_id) {
      console.log('ENTER LANE MODE')
      handleEnterLaneMode()
    }
  }, [selectedLane])
  //Set origin and destination on lane
  useEffect(() => {
    origin && setSelectedLane({ origin_location_id: origin.id, destination_location_id: selectedLane.destination_location_id, pickup: origin, delivery: selectedLane.delivery })
  }, [origin])
  useEffect(() => {
    destination && setSelectedLane({ origin_location_id: selectedLane.origin_location_id, destination_location_id: destination.id, pickup: selectedLane.pickup, delivery: destination })
  }, [destination])

  //Function for getting center lat long coordinate of a region
  let getCenterCoord = (region) => {
    if (!region) { console.log('region undefined'); return }
    //Note: there is an "extra" level of array the coordinates are stored in- not sure why
    let regionCoordsArr = region.geofence.coordinates[0];
    var latSum = 0;
    var longSum = 0;
    //Note: for some reason the region coordinates are orginized [longitude, latitude]
    regionCoordsArr.forEach(coordinate => {
      latSum += coordinate[1]
      longSum += coordinate[0]
    })
    let latAvg = latSum / regionCoordsArr.length;
    let longAvg = longSum / regionCoordsArr.length;
    return { lat: latAvg, lng: longAvg }
  }
  const boundsContainsLoc = (bounds, loc) => {
    //Prevents filter from crashing out if missing params
    if (!bounds || !loc) { return true }
    // Location is within the bounds if South < LAT < North and West < LONG < East
    if (bounds.west < loc.longitude && bounds.east > loc.longitude && bounds.south < loc.latitude && bounds.north > loc.latitude) { return true } else { return false }
  }

  const getFilteredLocations = (locs) => {
    let filteredResults = locs;
    //filter by search string
    if (search && search.length > 0) filteredResults = filteredResults.filter(loc =>
      (loc.id && (loc.id + ``).toLocaleLowerCase().includes(search)) ||
      (loc.name && loc.name.toLocaleLowerCase().includes(search)) ||
      (loc.nickname && loc.nickname.toLocaleLowerCase().includes(search)) ||
      (loc.address && loc.address.toLocaleLowerCase().includes(search))
    );
    //filter by customer
    if (customerId) filteredResults = filteredResults.filter(loc => loc.customer.id === customerId);
    //filter by tag
    if (typeTags && typeTags.length > 0) {
      filteredResults = filteredResults.filter(loc => {
        if (loc.type && typeTags.includes(loc.type)) return true;
        return false;
      });
    }
    //filter by map bounds coordinates
    filteredResults = filteredResults.filter((location) => boundsContainsLoc(bounds, location))
    return filteredResults;
  }

  const [modal, setModal] = useState({ open: false });
  const handleLocationAddEditModalOpen = (input = null) => {
    setModal({ open: true, input: input });
  }
  const handleLocationAddEditModalClose = (output = null) => {
    setModal({ ...modal, open: false });
    if (output) {
      setSelectedLocation(output);
      setSidebarState(`location`);
    }
  }

  const handleEnterLaneMode = () => {
    setLaneMode(true);
    setSidebarState('lane');
  }

  return (<>
    <LocationAddEditModal open={modal.open} onClose={handleLocationAddEditModalClose} locationInput={modal.input} />

    <div className={cls.root}>
      { ctx && ctx.userIsAuthenticated() && (
        <Subscription subscription={GET_LOCATIONS} onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve locations: ` + err.toString())}>
          {({ loading, error, data }) => {
            if (loading) return <Loading fixed />
            if (error) {
              console.log(`Failed to retrieve locations:`, error);
              return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>ERROR FINDING LOCATIONS</Typography>
                </div>
              )
            }
            if (data && data.locations && data.locations.length > 0) {
              // log && console.log(`Locations for Location Index:`, data.locations);
              const locations = getFilteredLocations(data.locations);
              //Testing with first location's region - will be set differently in the future
              const region = locations.length > 0 ? locations[0].region : null;
              const centerOfRegion = getCenterCoord(region);
              // log && console.log('CENTER OF REGION', centerOfRegion)

              return (<>
                <div className={cls.offsetFix}>
                  <div className={cls.spacer} />
                  <div className={cls.main}>
                    <MapSidebar
                      allLocations={data.locations}
                      locations={locations}
                      origin={origin}
                      destination={destination}
                      search={search}
                      customerId={customerId}
                      tags={{ type: typeTags }}
                      sidebarState={sidebarState}
                      selectedLocation={selectedLocation}
                      selectedLane={selectedLane}
                      laneMode={laneMode}
                      setOrigin={setOrigin}
                      setDestination={setDestination}
                      setSearch={setSearch}
                      setCustomerId={setCustomerId}
                      setTags={{ type: setTypeTags }}
                      setSidebarState={setSidebarState}
                      setSelectedLocation={setSelectedLocation}
                      setSelectedLane={setSelectedLane}
                      setCreateMode={setCreateMode}
                      setLaneMode={setLaneMode}
                      handleLocationAddEditModalOpen={handleLocationAddEditModalOpen}
                    />
                    <MapBounds
                      locations={locations}
                      selectedLane={selectedLane}
                      origin={origin}
                      destination={destination}
                      selectedLocation={selectedLocation}
                      createMode={createMode}
                      laneMode={laneMode}
                      // center={centerOfRegion}
                      setSelectedLocation={setSelectedLocation}
                      setSidebarState={setSidebarState}
                      setCreateMode={setCreateMode}
                      setBounds={setBounds}
                      handleLocationAddEditModalOpen={handleLocationAddEditModalOpen}
                    />
                  </div>
                </div>
              </>)
            }
            else return (
              <div className={cls.notFound}>
                <Typography className={cls.notFoundTxt}>NO LOCATIONS FOUND</Typography>
              </div>
            )
          }}
        </Subscription>)
      }
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  offsetFix: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  spacer: {
    display: 'block',
    width: '100%',
    minHeight: '64px',
    maxHeight: '64px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '56px',
      maxHeight: '56px',
    },
  },
  main: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'no-wrap',
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

////////// GRAPHQL //////////
const GET_LOCATIONS = gql`
subscription get_locations {
  locations (where: {active: {_eq: 1}}, order_by: {name: asc}) {
    ...Location
  }
}
${fragments.location}
`;
const GET_LOCATION = gql`
query get_location($id: bigint!) {
  locations (where: {id: {_eq: $id}}) {
    ...Location
  }
}
${fragments.location}
`;
const GET_LANE = gql`
query get_lane($id: bigint!) {
  lanes (where: {id: {_eq: $id}}) {
    ...Lane
  }
}
${fragments.lane}
`;