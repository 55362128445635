import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Typography, Tooltip, Icon } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../utils/Loading';
import ARReportMovesFilter from './ARReportMoves/ARReportMovesFilter';
import ARReportMovesFooter from './ARReportMoves/ARReportMovesFooter';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = false;

const cap = (str) => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
}

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`arreport-moves-range`);
  if (localRange) return localRange;
  else return `week`;
}
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`arreport-moves-range`);
  const localStart = localStorage.getItem(`arreport-moves-start`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return moment.utc(moment().startOf(`day`).subtract(1, `week`)).format();
}
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`arreport-moves-range`);
  const localEnd = localStorage.getItem(`arreport-moves-end`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return moment.utc(moment().startOf(`day`)).format();
}
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`arreport-moves-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
}

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

////////// COMPONENT //////////
function ARReportMoves(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [status, setStatus] = useState(null);
  const localCustomerId = parseInt(localStorage.getItem(`customerId`));
  const [customerId, setCustomerId] = useState(localCustomerId || null);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  useEffect(() => {
    localStorage.setItem(`customerId`, customerId)
  }, [customerId])

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`arreport-moves-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(1, value)).format());
      setEnd(moment.utc(moment().startOf(`day`)).format());
    }
    else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  }

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`arreport-moves-start`, newDate);
      setStart(newDate);
    }
    else {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`arreport-moves-end`, newDate);
      setEnd(newDate);
    }
  }

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.move.customer.name && o.move.customer.name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.id && (o.move.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.lane.description && o.move.lane.description.toLocaleLowerCase().includes(search))
        ) {
          return true;
        }
        else return false;
      })
    }
  }

  const generateCSV = (armoves) => {
    const createCsvRow = (armove) => {
      return {
        ID: armove.id,
        CUSTOMER: armove.move.customer ? armove.move.customer.name : `-`,
        MOVE_ID: armove.move.id,
        DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
        LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
        STATUS: armove.status ? cap(armove.status) : `-`,
        TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
        REGION: armove.move.lane.pickup.region.name,
        WEEK_YEAR: moment.utc(moment(armove.move.pickup_time)).format(`YYYY-W`),
      }
    }
    const csvRows = armoves.map(armove => createCsvRow(armove));
    const csvOptions = {
      filename: `${customerId ? armoves[0].move.customer.name.replace(/ /g, "_") : `All`}_AR_Records_from_${start}_to_${end}`,
      showTitle: true,
      title: `${customerId ? armoves[0].move.customer.name : `All`} AR Records from ${start} to ${end}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const goToMoveDetails = (moveId) => {
    props.history.push(`/moves/${moveId}`);
  }

  const getTableActions = (armoves) => {
    return [
      { label: `Generate\xa0CSV`, handler: () => generateCSV(armoves) },
    ];
  }
  const getRowActions = (armove) => {
    return [
      { label: `View\xa0Move\xa0Details`, handler: () => goToMoveDetails(armove.move.id) },
    ];
  }

  const getRowTotal = (row) => {
    return (<>
      {(row.armove.disputed || row.armove.discount_amount > 0) ?
        <Tooltip disableFocusListener title={row.armove.disputed ? `This AR record is disputed - ${row.armove.dispute_reason}` : `This AR record is discounted for $${row.armove.discount_amount} - ${row.armove.discount_reason}`}>
          <Icon className={cls.rowIcon} fontSize="small">{row.armove.disputed ? `announcement` : `local_offer`}</Icon>
        </Tooltip> : null}
      <Typography className={cls.rowTxt} style={{ display: 'inline' }}>{row.TOTAL}</Typography>
    </>)
  }

  return (<>
    <div className={cls.root}>
    { ctx && ctx.userIsAuthenticated() && (
        <Container maxWidth="lg">
          <Typography className={cls.headTxt}>AR Report Index (Moves)</Typography>

          <ARReportMovesFilter
            status={status}
            customerId={customerId}
            range={range}
            start={start}
            end={end}
            onStatusChange={setStatus}
            onCustomerChange={setCustomerId}
            onRangeChange={handleRangeChange}
            onDateChange={handleDateChange}
            disablePickers={disablePickers}
          />

          <div className={cls.break} />

          <Query query={GET_ARMOVES} variables={{ status: status, customerId: customerId, start: start, end: end }} onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve armoves: ${err.toString()}`)}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <Loading fixed />
              if (error) {
                console.log(`Failed to retrieve armoves:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING AR MOVES</Typography>
                  </div>
                )
              }
              if (data && data.armoves && data.armoves.length > 0) {
                // log && console.log(`ARMoves for ARReport Index:`, data.armoves);
                const filteredAR = applyFilters(data.armoves);

                // Set a consistent amount object that holds the totals
                var amount = {};

                // Valid moves to calculate base totals
                const subtotalMoves = filteredAR.filter(item => item.due_amount > 0);
                const discountedMoves = subtotalMoves.filter(item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0);
                const disputedMoves = subtotalMoves.filter(item => item.disputed === true);
                const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);

                // Base totals from valid moves
                amount.subtotal = round(subtotalMoves.length > 0 ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
                amount.discounted = round(discountedMoves.length > 0 ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current) : 0, 2);
                amount.disputed = round(disputedMoves.length > 0 ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
                amount.paid = round(paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0, 2);

                // Set calculations from base totals
                amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);
                amount.unpaid = checkNeg(amount.total - amount.paid);

                // log && console.log(`AR Amount:`, amount);

                const headers = [
                  { id: `ID`, alignLeft: true, numeric: true, label: `ID` },
                  { id: `CUSTOMER`, alignLeft: true, numeric: false, label: `Customer` },
                  { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
                  { id: `DATE`, alignLeft: false, numeric: true, label: `Date` },
                  { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
                  { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
                  { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                ]
                const rows = filteredAR.map(armove => {
                  return {
                    ID: armove.id,
                    CUSTOMER: armove.move.customer ? armove.move.customer.name : `-`,
                    MOVE_ID: armove.move.id,
                    DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
                    LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
                    STATUS: armove.status ? cap(armove.status) : `-`,
                    TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `-`),
                    armove: armove
                  }
                })

                return (<>
                  <AccordianTable
                    title={`${rows.length} AR Moves`}
                    headers={headers}
                    rows={rows}
                    actions={getTableActions(data.armoves)}
                    search={search}
                    defaultOrder={defaultOrder}
                    defaultOrderBy={defaultOrderBy}
                    order={order}
                    orderBy={orderBy}
                    tablePage={tablePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setTablePage={setTablePage}
                    setRowsPerPage={setRowsPerPage}
                    setExpandedRowId={setExpandedRowId}
                    className={cls.table}
                    refetch={refetch}
                    refreshPersistAs="ar_report_moves"
                  >
                    {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <AccordianRow
                          key={`arreport-move-${row.armove.id}`}
                          rowId={row.armove.id}
                          expandedRowId={expandedRowId}
                          setExpandedRowId={setExpandedRowId}
                          columns={[
                            { align: 'left', value: row.ID },
                            { align: 'left', value: row.CUSTOMER },
                            { align: 'left', value: row.MOVE_ID },
                            { align: 'right', value: row.DATE },
                            { align: 'left', value: row.LANE },
                            { align: 'left', value: row.STATUS },
                            { align: 'right', value: getRowTotal(row) },
                          ]}
                          actions={getRowActions(row.armove)}
                          onClick={() => goToMoveDetails(row.MOVE_ID)}
                          className={!row.armove.disputed ? cls.row : cls.rowDisputed}
                        >
                          <div />
                        </AccordianRow>
                      ))}
                  </AccordianTable>
                  <ARReportMovesFooter amount={amount} />
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO AR MOVES FOUND</Typography>
                </div>
              )
            }}
          </Query>

        </Container>)
      }
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

////////// GRAPHQL //////////
const GET_ARMOVES = gql`
query get_armoves($status: String, $customerId: bigint, $start: timestamptz!, $end: timestamptz!) {
  armoves(where: {
    active: {_eq: 1},
    status: {_eq: $status},
    move: {customer_id: {_eq: $customerId}, active: {_eq: 1}},
    billable_datetime: {_gte: $start, _lte: $end},
  }, order_by: {id: desc}) {
    id
    active
    type
    move_id
    billable_datetime
    discount_amount
    discount_reason
    disputed
    dispute_reason
    due_amount
    paid_amount
    status
    move {
      active
      id
      customer_id
      pickup_time
      lane {
        id
        description
        pickup {
          region {
            name
          }
        }
      }
      customer {
        id
        name
      }
    }
  }
}
`;

////////// EXPORT //////////
export default withRouter(ARReportMoves);