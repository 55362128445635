import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

////////// COMPONENT //////////
function MoveDetailsBreakdown(props) {
  const cls = useStyles();

  const { move } = props;
  const { lane } = move;
  const finishedRide = move.lyftrides && move.lyftrides.length > 0 && move.lyftrides[0].attempts && move.lyftrides[0].attempts.length > 0 ? move.lyftrides[0].attempts.find(lr => lr.status === `droppedOff` && lr.ride_cost !== 0) : null;

  const arprice = Number(move.accountsReceivable.due_amount) - Number(move.accountsReceivable.discount_amount);

  const appay = move.appayments.map(ap => ap.amount).reduce((total, current) => total + current);
  const returnPay = Number(lane.driver_return_pay);
  const returnRide = Number(finishedRide ? finishedRide.ride_cost : lane.estimated_rideshare_return_cost);
  const insurance = Number(lane.insurance_cost);
  const commission = 1.55 + (0.024 * Number(lane.dealer_base_price));

  const gain = arprice;
  const loss = appay + returnPay + returnRide + insurance + commission;
  const profit = gain - loss;

  return (<>
    <Grid container spacing={0}>
      <Grid item lg={3} md={2} sm={1} xs={false}></Grid>
      <Grid item lg={6} md={8} sm={10} xs={12}>
        <div className={cls.paper}>
          <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
            <Grid item xs>
              <Typography className={cls.headTxtL}>Profit&nbsp;&&nbsp;Cost&nbsp;Breakdown</Typography>
            </Grid>
            <Grid item>
              <Tooltip placement="top-end" title="Approximated Profit">
                <Typography className={cls.headTxtL}>${profit.toFixed(2)}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <div className={cls.break} />
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Typography className={cls.subheadTxt}>Profits</Typography>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Customer&nbsp;Price:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${arprice.toFixed(2)}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Total&nbsp;Gain:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${gain.toFixed(2)}</Typography>
              </div>
            </Grid>

            <div className={cls.hiddenBreak} />

            <Grid item sm={6} xs={12}>
              <Typography className={cls.subheadTxt}>Costs</Typography>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver&nbsp;Pay:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${appay.toFixed(2)}</Typography>
              </div>
              {move.class === `stranded` && returnPay ? <>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Return&nbsp;Pay:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>${returnPay.toFixed(2)}</Typography>
                </div>
              </> : null}
              {move.class === `stranded` && returnRide ? <>
                <div className={cls.lineBreak} />
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Return&nbsp;Ride:&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>${returnRide.toFixed(2)}</Typography>
                </div>
              </> : null}
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Insurance&nbsp;Cost:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${insurance.toFixed(2)}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Commission:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${commission.toFixed(2)}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Total&nbsp;Loss:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${loss.toFixed(2)}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item lg={3} md={2} sm={1} xs={false}></Grid>
    </Grid>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
}));

////////// EXPORT //////////
export default withRouter(MoveDetailsBreakdown);