import React, { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TripTimelineHeader from "./Header";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import "./Timeline.css";
import Plan from "./Plan";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import PersonPinIcon from "@material-ui/icons/PersonPin";
// import SettingsIcon from '@material-ui/icons/Settings';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Popper from "@material-ui/core/Popper";
import PopupState, { bindPopper, bindHover } from "material-ui-popup-state";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import UnplannedMoves from "./UnplannedMoves";
// import { withApollo } from 'react-apollo';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Tookan from "../../utils/Tookan";
import { DragDropContext } from "react-beautiful-dnd";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
// import MomentUtils from '@date-io/moment'
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { GlobalContext } from "../../../global-context";
import Slider from "@material-ui/core/Slider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { ContextMenu, MenuItem, ContextMenuTrigger, connectMenu } from "react-contextmenu";
import PlanHelper from "../../utils/PlanHelper";
import MoveHelper from "../../utils/MoveHelper";
import LaneHelper from "../../utils/LaneHelper";
import RegionTabs from "./RegionTabs";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import ChipInput from "material-ui-chip-input";
import ManualIcon from "@material-ui/icons/NatureTwoTone";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import MoreIcon from "@material-ui/icons/More";
import PlayArrowIcon from "@material-ui/icons/PlayCircleFilled";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
//Context menu comes from https://vkbansal.github.io/react-contextmenu/#/ https://github.com/vkbansal/react-contextmenu
import "../../reusable/react-contextmenu.css";
import { FormControlLabel, InputAdornment } from "@material-ui/core";
import ReactSelectAutocomplete from "../../reusable/ReactSelectAutocomplete";
import fragments from "../../utils/graphQL/fragments";
import ManageAccessorials from "../driverPayReview/ManageAccessorials";
import { Howl } from "howler";
import CancelMoveModal from "./CancelMoveModal";
import LyftRideModal from "./LyftRideModal";

const sound = require("../../../static/beepbeep.mp3");
const newMoveSound = new Howl({
  src: [sound],
});

const RateEngine = require("sat-rating");
const axios = require("axios");
const LaneHelpers = require("sat-lane-manager/lanes");
let myLaneHelpers = null;

let log = false;
let driverLog = false;
let enrichPlanLog = false;
let suggestedRideMoves = [];
let suggestedDriveMoves = [];
// let lastEnrichPlanCall = moment()
// let forceEnrichAllPlans = false

const GET_ALL_LOCATIONS = gql`
  query {
    locations(order_by: { updatedat: desc_nulls_last }, where: { active: { _eq: 1 } }) {
      ...Location
    }
  }
  ${fragments.location}
`;

const styles = (theme) => ({
  root: {
    maxWidth: "100vw",
    // JSS uses px as the default units for this CSS property.
    padding: theme.spacing(0), // = 8 * 2
  },
  paper: {
    padding: 0,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    height: "100vh",
    overflow: "auto",
  },
  rightPanelTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabButton: {
    margin: theme.spacing(1),
  },
  card: {
    minWidth: 275,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  animateJiggle: {
    "-webkit-animation": "jiggle 0.2s infinite",
    "-moz-animation-duration": "0.2s",
    "-moz-animation-name": "jiggle",
    "-moz-animation-iteration-count": "infinite",
    "-webkit-transform": "rotate(-3deg)",
    "-moz-transform": "rotate(-3deg)",
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "50%",
    right: -3,
    // The border color match the background color.
    border: `2px solid ${theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[900]}`,
  },
}))(Badge);

function collect(props) {
  return { driver: props.driver };
}

function collectPlan(props) {
  return props;
}

class Timeline extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      rightTab: 0,
      timelineScale: 3.0, //1.0 here = 1px per min
      planHeight: 45,
      planLabelWidth: 260,
      planLabelPad: 2,
      planLabelLeft: -30,
      headerHeight: 60,
      headerTop: 0,
      drivers: [],
      unplannedMoves: [],
      overrideClassModalOpen: false,
      rideTypeChangeModalOpen: false,
      setPinnableStartTimeModalOpen: false,
      clearPlannedTimesModalOpen: false,
      deleteUnplannedMoveModal: false,
      setDestinationModalOpen: false,
      setPickupModalOpen: false,
      addMoveModalOpen: false,
      cancelMoveModalOpen: false,
      shareRideModalOpen: false,
      settingsModalOpen: false,
      adjustPriorityModalOpen: false,
      rideShareOptions: [],
      selectedMove: null,
      contextMenuActionMove: null,
      contextMenuActionMoveId: null,
      contextMenuActionMoveLane: null,
      showLyftModal: false,
      showRegionModal: false,
      regionModalData: null,
      lyftModalMove: null,
      useDebugMoveTooltip: false,
      showAccessorialsModal: false,
      selectedRegionId: 0,
      newUnplannedMoveTimestamp: new Date().toISOString(),
    };
    this.actionBarRef = null;
    this.planLabelHeaderRef = null;
    this.planLabelsRef = null;
    this.timelineHeaderRef = null;
    this.rightPanelRef = null;
    this.timelineScrollOffset = 0;
    this.pinnableDropTPos = null;
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.unplannedMoves.length > 0) {
      let notify = false;
      for (let move of nextProps.unplannedMoves) {
        if (move.createdat > this.state.newUnplannedMoveTimestamp) notify = true;
      }
      if (notify) {
        //Reset timestamp so only new moves will trigger it
        this.setState({ newUnplannedMoveTimestamp: new Date().toISOString() });
        // Trigger new move notification sound here
        if (log) console.log("New unplanned move received, triggering notification");
        newMoveSound.play();
      }
    }
  };

  componentWillMount = () => {
    myLaneHelpers = new LaneHelpers(
      `https://${process.env.REACT_APP_GQL_SD}.herokuapp.com/v1/graphql`,
      this.context.userProfile["https://api_keys.io/jwt/claims"]["GQLSecret"],
      this.context.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"]
    );
    this.setState({
      timelineScale: Number(localStorage.getItem("timelineScale") || 3),
      planHeight: Number(localStorage.getItem("planHeight") || 75),
    });
    this.timelineScrollOffset = Number(localStorage.getItem("timelineScrollOffset") || 0);
  };

  componentDidMount = async () => {
    this._isMounted = true;
    if (log) console.log("Timeline called componentDidMount()");

    // Setup subscriptions to any server data changes
    this.props.subscribeToMorePlans();
    this.props.subscribeToMoreUnplannedMoves();
    this.props.subscribeToMoreRegions();

    // Keep late moves not started yet pushing out past the timeline marker (current time)
    await this.refreshDriversFromTookan();
    this.timerID = setInterval(() => {
      if (this._isMounted) {
        this.refreshDriversFromTookan();
        this.forceUpdate();
      }
    }, 10 * 1000);

    this.actionBarDiv = findDOMNode(this.actionBarRef);
    this.planLabelHeaderDiv = findDOMNode(this.planLabelHeaderRef);
    this.planLabelsDiv = findDOMNode(this.planLabelsRef);
    this.timelineHeaderDiv = findDOMNode(this.timelineHeaderRef);
    this.rightPanelDiv = findDOMNode(this.rightPanelRef);
    this.mainDiv = findDOMNode(this.context.mainRef);

    //Scroll to current time
    try {
      this.mainDiv.scrollLeft = (
        this.timelineHeaderDiv.scrollWidth / 2 -
          this.state.planLabelWidth -
          this.state.planLabelPad -
          this.state.planLabelLeft || 0
      ).toFixed(0);

      this.mainDiv.scrollTop = 0;

      //Scroll to last known scroll
      if (log)
        console.log(
          "Auto scrolling timeline to last known position: ",
          typeof this.timelineScrollOffset,
          this.timelineScrollOffset
        );
      this.mainDiv.scrollLeft = this.timelineScrollOffset;
    } catch (error) {}

    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("unload", this.saveScrollPosition, true);
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("unload", this.saveScrollPosition);
    clearInterval(this.timerID);
    localStorage.setItem("timelineScrollOffset", this.timelineScrollOffset);
  }

  handleScroll = (event) => {
    if (this._isMounted) {
      if (event.target.tagName === "MAIN") {
        this.timelineScrollOffset = event.target.scrollLeft;

        this.actionBarDiv.style.top = event.target.scrollTop - 60 + "px";
        this.actionBarDiv.style.left = event.target.scrollLeft + this.state.planLabelLeft + "px";

        this.rightPanelDiv.style.top = event.target.scrollTop - this.state.headerTop + "px";
        this.rightPanelDiv.style.right = event.target.scrollLeft * -1 + this.state.planLabelLeft + "px";

        this.planLabelHeaderDiv.style.left = event.target.scrollLeft + this.state.planLabelLeft + "px";
        this.planLabelHeaderDiv.style.top = event.target.scrollTop - this.state.headerTop + "px";

        this.planLabelsDiv.style.left = event.target.scrollLeft + this.state.planLabelLeft + "px";

        this.timelineHeaderDiv.style.top = event.target.scrollTop - this.state.headerTop + "px";
      }
    }
  };

  saveScrollPosition = (e) => {
    localStorage.setItem("timelineScrollOffset", this.timelineScrollOffset);
  };

  refreshDriversFromTookan = async () => {
    if (!this._isMounted) {
      return;
    }
    let drivers = [];
    try {
      drivers = await Tookan.getDrivers(
        this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
        this.props.regions
      );
    } catch (error) {
      if (log) console.log(`Fetching drivers failed`, error);
    }
    if (driverLog) {
      console.log("Drivers: ", drivers);
    }
    if (drivers && this._isMounted) {
      //Cleanup bad default image icons that are not found
      //TODO: Once we move to the new Tookan environment we should just make this default exist
      drivers.map((d) => {
        const f = d.fleet_thumb_image.substring(d.fleet_thumb_image.lastIndexOf("/") + 1);
        if (f === "user.png") {
          d.fleet_thumb_image = "https://dispatch-prod.socialautotransport.com/app/img/default-user.png";
        }
      });
      this.setState({
        drivers: drivers,
      });
      if (driverLog) {
        console.log(`Refreshed ${drivers.length} drivers`);
      }
    }
  };

  diffMin = (start, end) => {
    return Number(moment.duration(end.diff(start)).asMinutes());
  };

  timeMinPx = (start, min) => {
    return {
      times: {
        start: start.clone(),
        end: start.clone().add(min.toFixed(0), "minutes"),
      },
      min: min,
      px: this.px(min),
    };
  };

  px = (min) => {
    let px = 0;
    try {
      //px = Math.ceil(min * this.state.timelineScale)
      px = Math.round(min * this.state.timelineScale);
      px = px < 0 ? 0 : px;
    } catch {}
    return px;
  };

  enrichAllPlans = (plans) => {
    //This is called A LOT so we set a limit to how frequently
    // it really rolls through and does enrichPlan(plan). We keep track of the time since
    // the last time it was called and just skip processing if enough time hasn't passed

    //TODO: Figure out why preventing duplicate calls to this causes such a huge amount of
    // render bugs! For the meantime, disable this optimization
    /*
        const diffMs = moment.duration(moment().diff(lastEnrichPlanCall)).asMilliseconds()
        if ((diffMs > 500) || (forceEnrichAllPlans)) {
            plans.map(plan => {
                this.enrichPlan(plan)
            })
            lastEnrichPlanCall = moment()
            forceEnrichAllPlans = false
        } else {
            console.log(`Skip enrichAllPlans() - called again only ${diffMs} ms after the last time!`)
        }
        */

    plans.map((plan) => {
      this.enrichPlan(plan);
    });

    return plans;
  };

  enrichPlan = (plan) => {
    const { px, minPx, diffMin, timeMinPx } = this; //Make code easier to read without "this." all over the place

    //Assume our timeline always starts at midnight
    const midnight = this.props.timelineDate.clone().startOf("day");

    //Save a reference to the prior move so we can adjust finish times of lyft rides to prevent overlap
    let priorMove = null;
    let planRideSuggestions = suggestedRideMoves.filter((suggested) => suggested.plan_id === plan.id);
    let planDriveSuggestions = suggestedDriveMoves.filter((suggested) => suggested.plan_id === plan.id);
    //A rolling position on the timeline as we iterate (add total width as we go)
    let pos = timeMinPx(midnight, 0);
    if (enrichPlanLog)
      console.log(
        `ENRICH MOVES on plan-${plan.id} with rolling pos starting: px: ${pos.px}, min: ${
          pos.min
        }, times: ${pos.times.start.format("M/D h:mm A")} - ${pos.times.end.format("M/D h:mm A")}`
      );
    //Places ride suggestions back into plan if they have been wiped by a subscription update
    if (planRideSuggestions.length > 0 && !plan.moves.find((move) => String(move.id).includes("S"))) {
      plan.moves = plan.moves.concat(planRideSuggestions);
    }
    //Places drive suggestions back into plan if they have been wiped by a subscription update
    if (planDriveSuggestions.length > 0 && !plan.moves.map((move) => move.id).includes(planDriveSuggestions[0].id)) {
      //find better way to do this check
      plan.moves = plan.moves.concat(planDriveSuggestions);
    }
    //Sort the moves in the timeline by sequence
    plan.moves.sort((a, b) => a.sequence - b.sequence);

    plan.moves.map((move) => {
      this.enrichMove(plan, move, pos);
      if (enrichPlanLog) console.log(`   plan-${plan.id} move-${move.id} prior-${priorMove ? priorMove.id : null}`);
      priorMove = move;
    });
  };

  enrichMove = (plan, move, pos = null) => {
    const { px, minPx, diffMin, timeMinPx } = this; //Make code easier to read without "this." all over the place

    //console.log(`Enriching move-${move.id}`)

    //Assume our timeline always starts at midnight
    const midnight = this.props.timelineDate.clone().startOf("day");

    //Set now to the same time of current day but for the date selected
    const now = midnight.clone().add(diffMin(moment().startOf("day"), moment()), "minutes");

    move.changed = move.changed || false;

    this.addLocalMoveAttributes(move);

    //Allow for pos to not be passed in and to just use the pos of the prior move on the plan instead
    if (pos === null) {
      for (var i = 0; i < plan.moves.length; i++) {
        if (plan.moves[i].id === move.id) {
          //The move in priorMove is now what we should set our pos to
          if (i - 2 < 0) {
            if (log) console.log(`enrichMove pos came in null and set to midnight (no prior move found)`);
            pos = timeMinPx(midnight, 0);
          } else {
            if (log)
              console.log(
                `enrichMove pos came in null and set to deliveryExtra end time for move-${plan.moves[i - 2].id}`
              );
            pos = timeMinPx(plan.moves[i - 2].box_specs.deliveryExtra.times.end, 0);
          }
          break;
        }
      }
    }

    //We should by default start at the ending position of our last move (represented by pos.times.end)
    // Starting at the end of the prior move is basically just stacking them left to right. We should let
    // use a different start time only if a valid scenario of a real start time calls for it
    let start = pos.times.end.clone();

    //If this is a lyft type move then check for actual ride request time
    // Below is the structure returned if the lyft has been called
    /*
            "movesByReturnRideId": [
                {
                    "lyftrides": [
                        {
                            "driver_first_name": "Tyrone",
                            "requested_at": "2019-07-30T14:54:53+00:00"
                        }
                    ]
                }
            ]
        */

    // Below is the structure returned if the lyft has not yet been called
    /*
            "movesByReturnRideId": []
        */

    let lyftRide = null;
    if (move.lyftride) {
      if (move.lyftride.activeAttempt) {
        lyftRide = move.lyftride.activeAttempt;
      }
    }

    let srcMsg = "source";
    if (move.move_type === "drive") {
      srcMsg += ".drive";
      //Fall through in this specific priority order to determine the starting point
      if (move.pickup_arrived) {
        srcMsg += ".pickup_arrived";
        //If we have the time the driver swiped arrive from Tookan, use it
        start = moment(move.pickup_arrived);
      } else {
        //Check if it should be pinned to current time or use the planned or ready by times
        // If it doesn't have an actual arrive time yet (move.pickup_arrived == null)
        // and the target pickup times are in the past then it is running
        // late and should be considered next up for the driver in sequence order
        // so set it's starting position to current time so it will constantly move out to the right
        // with the passage of time until it gets an actual start time to pin it somewhere or the
        // dispatcher pins it to a new time in the future

        const readyByInPast = move.ready_by && moment(move.ready_by) < now;
        const pickupTimeInPast = move.pickup_time && moment(move.pickup_time) < now;

        if (move.pinnable) {
          srcMsg += ".pinnable";
          //If we hit here, then we should be positioning the move on the readyBy or pickupTime
          if (move.pickup_time) {
            if (pickupTimeInPast && moment().isSame(this.props.timelineDate, "day")) {
              srcMsg += ".now";
              start = moment().add(1, "minute");
              start = now.clone().add(1, "minute"); // Make sure it's technically in the future still so it's draggable
            } else {
              srcMsg += ".pickup_time";
              start = moment(move.pickup_time);
            }
          } else {
            if (readyByInPast && moment().isSame(this.props.timelineDate, "day")) {
              srcMsg += ".now";
              start = now.clone().add(1, "minute"); // Make sure it's technically in the future still so it's draggable
            } else {
              srcMsg += ".ready_by";
              start = moment(move.ready_by);
            }
          }
        } else {
          srcMsg += ".calculated";
          if (readyByInPast && moment().isSame(this.props.timelineDate, "day")) {
            srcMsg += ".now";
            start = moment().add(1, "minute");
            //start = now.clone().add(1, 'minute')  // Make sure it's technically in the future still so it's draggable
          } else {
            srcMsg += ".ready_by";
            start = moment(move.ready_by);
          }
        }
      }
    } else if (move.move_type === "ride") {
      srcMsg += ".ride";
      if (move.ride_type === "lyft") {
        srcMsg += ".lyft";
        if (move.sequence > 1) {
          srcMsg += ".next";
          //We don't know an actual Lyft pickup time so lets use the expected (or actual) end time of the prior move (next position)
          start = pos.times.end.clone();
        } else {
          //If we hit here, then we should be positioning the move on the readyBy or pickupTime
          if (move.pickup_successful) {
            srcMsg += ".pickup_successful";
            start = moment(move.pickup_successful);
          } else if (move.ready_by) {
            srcMsg += ".ready_by";
            start = moment(move.ready_by);
          } else {
            srcMsg += ".now";
            start = now.clone().add(1, "minute"); // Make sure it's technically in the future still so it's draggable
          }
        }
      } else {
        srcMsg += "." + move.ride_type;
        srcMsg += ".now";
        start = now.clone().add(1, "minute"); // Make sure it's technically in the future still so it's draggable
      }
    } else {
      //Should never hit this
      console.error(`ERROR Unknown move type on plan-${plan.id} move-${move.id} type-${move.move_type}`);
      start = now;
    }

    move.startSource = srcMsg;

    //If after all calcs the start position is prior to where we are already at in the timeline, then we
    // should start at the ending position of our last move (represented by pos.times.end)
    // Starting at the end of the prior move is basically just stacking them left to right. We could allow
    // them to stack without adjusting the start position, however that negatively impacts buffer width
    // calcs for moves further down the plan that do not stack up (driver took lunch break for instance)
    if (start < pos.times.end) {
      srcMsg += ".pos";
      start = pos.times.end;
    }

    if (enrichPlanLog)
      console.log(`   plan-${plan.id} move-${move.id} - starting at ${start.format("M/D h:mm A")} using: ${srcMsg}`);

    //Calc the pickup time with optional extra (extra is extra actual time it took beyond what we plan for)
    let actualPickupMin = move.pickup_successful ? diffMin(start, moment(move.pickup_successful)) : 0;
    let plannedPickupMin = move.lane.pickup_inspection_sec / 60;
    let extraPickupMin = actualPickupMin - plannedPickupMin;
    extraPickupMin = extraPickupMin < 0 ? 0 : extraPickupMin;

    //Don't count inspection time for ride moves
    if (move.move_type === "ride") {
      //actualPickupMin = 0
      //plannedPickupMin = 0
      //extraPickupMin = 0
    }

    if (enrichPlanLog)
      console.log(`   plan-${plan.id} move-${move.id}  Pickup: `, {
        plannedPickupMin,
        actualPickupMin,
        extraPickupMin,
      });
    let pickup = timeMinPx(start, actualPickupMin > 0 ? actualPickupMin - extraPickupMin : plannedPickupMin);
    let pickupExtra = timeMinPx(pickup.times.end, extraPickupMin);

    //Calc the drive time with optional extra
    let actualDriveMin =
      move.pickup_successful && move.delivery_arrived
        ? diffMin(moment(move.pickup_successful), moment(move.delivery_arrived))
        : 0;
    const plannedDriveMin =
      (move.lane.duration_sec - move.lane.pickup_inspection_sec - move.lane.delivery_inspection_sec) / 60;
    let extraDriveMin = actualDriveMin - plannedDriveMin;
    extraDriveMin = extraDriveMin < 0 ? 0 : extraDriveMin;

    //To prevent from double counting any extra time driven, subtract it from the actual
    actualDriveMin -= extraDriveMin;

    if (enrichPlanLog)
      console.log(`   plan-${plan.id} move-${move.id}  Drive: `, { plannedDriveMin, actualDriveMin, extraDriveMin });
    const drive = timeMinPx(pickupExtra.times.end, actualDriveMin > 0 ? actualDriveMin : plannedDriveMin);
    const driveExtra = timeMinPx(drive.times.end, extraDriveMin);

    //Calc the delivery time with optional extra
    let actualDeliveryMin =
      move.delivery_arrived && move.delivery_successful
        ? diffMin(moment(move.delivery_arrived), moment(move.delivery_successful))
        : 0;
    let inProgressDeliveryMin =
      move.delivery_arrived && !move.delivery_successful ? diffMin(moment(move.delivery_arrived), now) : 0;
    let plannedDeliveryMin = move.lane.delivery_inspection_sec / 60;
    let extraDeliveryMin =
      actualDeliveryMin > 0 ? actualDeliveryMin - plannedDeliveryMin : inProgressDeliveryMin - plannedDeliveryMin;
    extraDeliveryMin = extraDeliveryMin < 0 ? 0 : extraDeliveryMin;

    //Don't count inspection time for ride moves
    if (move.move_type === "ride") {
      actualDeliveryMin = 0;
      plannedDeliveryMin = 0;
      extraDeliveryMin = 0;
    }

    if (enrichPlanLog)
      console.log(`   plan-${plan.id} move-${move.id}  Delivery: `, {
        plannedDeliveryMin,
        actualDeliveryMin,
        extraDeliveryMin,
      });
    const delivery = timeMinPx(
      driveExtra.times.end,
      actualDeliveryMin > 0 ? actualDeliveryMin - extraDeliveryMin : plannedDeliveryMin
    );
    const deliveryExtra = timeMinPx(delivery.times.end, extraDeliveryMin);

    const gapSinceLastMoveMin = diffMin(pos.times.end, start);
    const buffer = timeMinPx(start, gapSinceLastMoveMin);
    buffer.times.start = pos.times.end.clone();
    buffer.times.end = start.clone();
    if (enrichPlanLog)
      console.log(
        `   plan-${plan.id} move-${move.id}  Buffer: ${gapSinceLastMoveMin.toFixed(0)} minutes (${
          buffer.px
        }px) between start time: ${pos.times.end.format("M/D h:mm A")} and end time: ${start.format("M/D h:mm A")}`
      );

    move.box_specs = {
      buffer: buffer,
      pickup: pickup,
      pickupExtra: pickupExtra,
      drive: drive,
      driveExtra: driveExtra,
      delivery: delivery,
      deliveryExtra: deliveryExtra,
    };

    if (enrichPlanLog) console.log(`   plan-${plan.id} move-${move.id}   Box specs: `, move.box_specs);

    //Increment the current position on the timeline by the full width of this move
    pos.times.start.add(
      buffer.min + pickup.min + pickupExtra.min + drive.min + driveExtra.min + delivery.min + deliveryExtra.min,
      "minutes"
    );
    pos.times.end.add(
      buffer.min + pickup.min + pickupExtra.min + drive.min + driveExtra.min + delivery.min + deliveryExtra.min,
      "minutes"
    );
    pos.min +=
      buffer.min + pickup.min + pickupExtra.min + drive.min + driveExtra.min + delivery.min + deliveryExtra.min;
    pos.px += buffer.px + pickup.px + pickupExtra.px + drive.px + driveExtra.px + delivery.px + deliveryExtra.px;

    if (enrichPlanLog)
      console.log(
        `   plan-${plan.id} rolling pos: px: ${pos.px}, min: ${pos.min.toFixed(0)}, times: ${pos.times.start.format(
          "M/D h:mm A"
        )} - ${pos.times.end.format("M/D h:mm A")}`
      );
  };

  addLocalMoveAttributes = (move) => {
    //Enrich with local flags (this is needed because the subscription will override local modifications to the moves)
    //if (log) console.log(`Enriching move-${move.id} with local flags`)
  };

  orderAllPlanMovesByStartTime = (plans) => {
    plans.map((plan, index) => {
      plan.moves.sort((a, b) => a.box_specs.pickup.times.start - b.box_specs.pickup.times.start);
    });
  };

  // A little function to help us with reordering an array
  moveToPosition = (plan, indexToMove, posToMoveTo) => {
    if (log) console.log(`   move-${plan.moves[indexToMove].id} moving from index ${indexToMove} to ${posToMoveTo}`);
    const [removed] = plan.moves.splice(indexToMove, 1);
    if (!removed) return; // For reordering unplanned moves
    removed.changed = true;
    plan.moves.splice(posToMoveTo, 0, removed);
    plan.changed = true;
  };

  // Moves a move from one plan to another
  moveToPlan = (sourcePlan, destinationPlan, droppableSource, droppableDestination) => {
    if (log)
      console.log(
        `   move-${sourcePlan.moves[droppableSource.index].id} moving from plan ${sourcePlan.id} at index ${
          droppableSource.index
        } to ${destinationPlan.id} at ${droppableDestination.index} with changed = ${
          sourcePlan.moves[droppableSource.index].changed ? "true" : "false"
        }`
      );
    // Set a previous_driver attribute to check against when syncing moves to Tookan
    if (sourcePlan.moves[droppableSource.index].driver_id && sourcePlan.moves[droppableSource.index].driver_id > 0) {
      if (log)
        console.log(
          `setting previous driver to ${sourcePlan.moves[droppableSource.index].driver_name} (${
            sourcePlan.moves[droppableSource.index].driver_id
          })`
        );
      // Utilize localStorage to cache previous driver name and ID instead of local state
      localStorage.setItem(
        `${sourcePlan.moves[droppableSource.index].id}-driver_id`,
        sourcePlan.moves[droppableSource.index].driver_id
      );
    }
    const [removed] = sourcePlan.moves.splice(droppableSource.index, 1);
    removed.plan_id = destinationPlan.id;
    removed.driver_name = destinationPlan.driver_name;
    removed.driver_id = destinationPlan.driver_id;
    removed.changed = true;
    destinationPlan.moves.splice(droppableDestination.index, 0, removed);
    sourcePlan.changed = true;
    destinationPlan.changed = true;
    console.log("removed", removed);
  };

  // Moves a move from unplanned moves section to a plan
  addToPlan = (move, destinationPlan, droppableDestination) => {
    if (log)
      console.log(`   move-${move.id} moving being added to ${destinationPlan.id} at ${droppableDestination.index}`);
    move.changed = true;
    move.plan_id = destinationPlan.id;
    move.driver_name = destinationPlan.driver_name;
    move.driver_id = destinationPlan.driver_id;
    destinationPlan.moves.splice(droppableDestination.index, 0, move);
    destinationPlan.changed = true;
  };

  reclassMoves = (moves) => {
    let driveMoves = moves.filter((o) => o.move_type === "drive");
    let priorMove = null;
    driveMoves.forEach(function (move, index) {
      if (priorMove != null) {
        let moveClass = priorMove.class + ""; //force a copy for comparison
        if (!priorMove.rate_class_override) {
          priorMove.class = priorMove.lane.delivery.id == move.lane.pickup.id ? "base" : "stranded";
        }
        priorMove.changed = !priorMove.changed ? moveClass != priorMove.class : priorMove.changed;
      } else {
        //Since we dont have a prior move then move is the first move
        let lastMove = driveMoves[driveMoves.length - 1];
        let moveClass = lastMove.class + ""; //force a copy for comparison
        if (!lastMove.rate_class_override) {
          lastMove.class = lastMove.lane.delivery.id == move.lane.pickup.id ? "base" : "stranded";
        }
        lastMove.changed = !lastMove.changed ? moveClass != lastMove.class : lastMove.changed;
      }

      priorMove = move;
    });
  };

  retimeMoves = (moves) => {
    let priorMove = null;
    // let untimedMoves = moves.map(move => Object.assign(move, {pickup_time: null, delivery_time: null}))
    console.log("retime moves:", moves);
    moves.forEach(function (move, index) {
      //if (!move.pinnable) {  //10/18 Rob - Removed to fix delivery time not being calced for pinnable
      if (priorMove && priorMove.suggested) {
        priorMove = move; //drives after suggested moves were not being set as priorMove
        return;
      }
      if (log)
        console.log(
          `retimeMoves   move-${move.id}   pickup_time before: ${moment(
            move.pickup_time,
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ).format("HH:mm:ss")}`
        );
      const beforePickupChange = moment(move.pickup_time).clone();
      move.pickup_time = move.box_specs.pickup.times.start.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      if (log)
        console.log(
          `retimeMoves   move-${move.id}    pickup_time after: ${moment(
            move.pickup_time,
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ).format("HH:mm:ss")}`
        );
      //if (log) console.log(`retimeMoves   move-${move.id} delivery_time before: ${move.delivery_time}`)
      move.delivery_time = move.box_specs.delivery.times.start.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      const pickupDeltaMinutes = Math.abs(
        moment.duration(moment(move.pickup_time).diff(beforePickupChange)).asMinutes().toFixed(0)
      );
      if (pickupDeltaMinutes > 0.99 || !beforePickupChange.isValid()) {
        move.changed = true;
      }
      //if (log) console.log(`retimeMoves   move-${move.id}  delivery_time after: ${move.delivery_time}`)
      //}
      priorMove = move;
    });
  };

  clearAllPlanSuggestions = () => {
    suggestedRideMoves = [];
    if (log) console.log(`Looping through plans to remove all suggestions`);
    if (log) console.log(`suggestedRideMoves: `, suggestedRideMoves);
    let { plans } = this.props;
    plans.map((currentPlan, currentPlanIndex) => {
      currentPlan.moves.map((currentMove, currentMoveIndex) => {
        if (currentMove.suggested) {
          if (log) console.log(`   Removing suggested move ${currentMove.id} from plan ${currentPlan.id}`);
          currentPlan.moves.splice(currentMoveIndex, 1);
        }
      });
    });
  };

  clearPlanSuggestions = (plan) => {
    suggestedRideMoves = suggestedRideMoves.filter((suggestion) => suggestion.plan_id !== plan.id); //remove plan's suggestions from the global array
    suggestedDriveMoves = suggestedDriveMoves.filter((suggestion) => suggestion.plan_id !== plan.id);
    plan.moves.map((currentMove, currentMoveIndex) => {
      if (currentMove.suggested) {
        if (log) console.log(`   Removing suggested move ${currentMove.id} from plan ${plan.id}`);
        plan.moves.splice(currentMoveIndex, 1);
      }
    });
  };

  suggestReturnRides = async (plan) => {
    console.log(`Making return ride suggestions for plan ${plan.id} with ${plan.moves.length} moves`, plan);
    let origmoves = Array.from(plan.moves);
    let moves = origmoves.filter((move) => move.active === 1);

    //Cannot use a map or foreach here because the array is modified during iteration
    // Use for so that we can check the length of the array each iteration
    // As the array grows (suggestions being added) it will continue to loop until the (new) end
    for (var currentMoveIndex = 0; currentMoveIndex < moves.length; currentMoveIndex++) {
      let currentMove = moves[currentMoveIndex];

      if (currentMove.suggested) {
        if (log) console.log(`   Skipping suggested move ${currentMove.id}`);
        continue;
      }

      if (!currentMove.active) {
        if (log) console.log(`   Skipping inactive move ${currentMove.id}`);
        continue;
      }

      if (log) console.log(`   Processing move ${currentMove.id}`);

      let firstMove,
        priorMove,
        nextMove = null;
      firstMove = moves[0];

      if (log) console.log(`      First move ${firstMove.id}`, firstMove);

      if (currentMoveIndex > 0) {
        priorMove = moves[currentMoveIndex - 1];
        if (log) console.log(`      Prior move ${priorMove.id}`, priorMove);
      }

      if (currentMoveIndex < moves.length - 1) {
        nextMove = moves[currentMoveIndex + 1];
        if (log) console.log(`      Next move ${nextMove.id}`, nextMove);
      }

      // Check for gap on the left
      if (priorMove == null) {
        if (log) console.log(`      Skip checking for gap on left (no move to the left)`);
      } else {
        if (log) console.log(`      Checking for gap on left (prior vs current move)`);
        if (!this.movesHaveGap(priorMove, currentMove)) {
          if (log)
            console.log(
              `         No gap found left of ${currentMove.id}. (${priorMove.lane.delivery.id} == ${currentMove.lane.pickup.id})`
            );
        } else {
          //Make suggested move here
          if (log)
            console.log(
              `         Gap found left of ${currentMove.id}. Getting lane by locations: ${priorMove.lane.delivery.id} and ${currentMove.lane.pickup.id}`
            );
          await this.suggestReturnRideMove(
            log,
            priorMove,
            currentMove,
            moves,
            currentMoveIndex,
            plan,
            suggestedRideMoves
          );
        }
      }

      // Check for gap on the right
      if (nextMove == null) {
        if (log) console.log(`      Skip checking for gap on right (no move to the right)`);
      } else {
        if (log) console.log(`      Checking for gap on right (current vs next move)`);
        if (!this.movesHaveGap(currentMove, nextMove)) {
          if (log)
            console.log(
              `         No gap found right of ${currentMove.id}. (${currentMove.lane.delivery.id} == ${nextMove.lane.pickup.id})`
            );
        } else {
          //Make suggested move here
          if (log)
            console.log(
              `         Gap found right of ${currentMove.id}. Getting lane by locations: ${currentMove.lane.delivery.id} and ${nextMove.lane.pickup.id}`
            );
          await this.suggestReturnRideMove(
            log,
            currentMove,
            nextMove,
            moves,
            currentMoveIndex + 1,
            plan,
            suggestedRideMoves
          );
        }
      }

      // Check for gap on the end (loop back to the beginning)
      if (nextMove == null && firstMove != null) {
        if (log) console.log(`      Checking for gap on end (current vs first move)`);
        if (!this.movesHaveGap(currentMove, firstMove)) {
          if (log)
            console.log(
              `         No gap found right of ${currentMove.id}. (${currentMove.lane.delivery.id} == ${firstMove.lane.pickup.id})`
            );
        } else {
          //Make suggested move here
          if (log)
            console.log(
              `         Gap found on the end of the plan. Getting lane by locations: ${currentMove.lane.delivery.id} and ${firstMove.lane.pickup.id}`
            );
          await this.suggestReturnRideMove(
            log,
            currentMove,
            firstMove,
            moves,
            currentMoveIndex + 1,
            plan,
            suggestedRideMoves
          );
        }
      } else {
        if (log) console.log(`      Skipped checking for gap on end (???)`, nextMove, firstMove);
      }
    }

    //Put all of the filtered out inactive moves back on the plan to make sure
    // they get updated in the DB on save
    origmoves
      .filter((move) => move.active === 0)
      .map((move) => {
        moves.push(move);
      });

    //Update the moves array on the plan
    console.log(`   After return ride move suggestions, plan now has ${moves.length} moves in it`);
    plan.moves = moves;
    this.forceUpdate();
  };

  suggestRoundTrips = async (plan) => {
    console.log(`Making round trip suggestions for plan ${plan.id} with ${plan.moves.length} moves`, plan);
    let origmoves = Array.from(plan.moves);
    let moves = origmoves.filter((move) => move.active === 1);

    //Cannot use a map or foreach here because the array is modified during iteration
    // Use for so that we can check the length of the array each iteration
    // As the array grows (suggestions being added) it will continue to loop until the (new) end
    for (var currentMoveIndex = 0; currentMoveIndex < moves.length; currentMoveIndex++) {
      let currentMove = moves[currentMoveIndex];

      if (currentMove.suggested) {
        if (log) console.log(`   Skipping suggested move ${currentMove.id}`);
        continue;
      }

      if (!currentMove.active) {
        if (log) console.log(`   Skipping inactive move ${currentMove.id}`);
        continue;
      }

      if (log) console.log(`   Processing move ${currentMove.id}`);

      let firstMove,
        priorMove,
        nextMove = null;
      firstMove = moves[0];

      if (log) console.log(`      First move ${firstMove.id}`, firstMove);

      if (currentMoveIndex > 0) {
        priorMove = moves[currentMoveIndex - 1];
        if (log) console.log(`      Prior move ${priorMove.id}`, priorMove);
      }

      if (currentMoveIndex < moves.length - 1) {
        nextMove = moves[currentMoveIndex + 1];
        if (log) console.log(`      Next move ${nextMove.id}`, nextMove);
      }

      // Check for gap on the left
      if (priorMove == null) {
        if (log) console.log(`      Skip checking for gap on left (no move to the left)`);
      } else {
        if (log) console.log(`      Checking for gap on left (prior vs current move)`);
        if (!this.movesHaveGap(priorMove, currentMove)) {
          if (log)
            console.log(
              `         No gap found left of ${currentMove.id}. (${priorMove.lane.delivery.id} == ${currentMove.lane.pickup.id})`
            );
        } else {
          //Make suggested move here
          if (log)
            console.log(
              `         Gap found left of ${currentMove.id}. Getting lane by locations: ${priorMove.lane.delivery.id} and ${currentMove.lane.pickup.id}`
            );
          await this.suggestRoundTripMove(
            log,
            priorMove,
            currentMove,
            moves,
            currentMoveIndex,
            plan,
            suggestedDriveMoves
          );
        }
      }

      // Check for gap on the right
      if (nextMove == null) {
        if (log) console.log(`      Skip checking for gap on right (no move to the right)`);
      } else {
        if (log) console.log(`      Checking for gap on right (current vs next move)`);
        if (!this.movesHaveGap(currentMove, nextMove)) {
          if (log)
            console.log(
              `         No gap found right of ${currentMove.id}. (${currentMove.lane.delivery.id} == ${nextMove.lane.pickup.id})`
            );
        } else {
          //Make suggested move here
          if (log)
            console.log(
              `         Gap found right of ${currentMove.id}. Getting lane by locations: ${currentMove.lane.delivery.id} and ${nextMove.lane.pickup.id}`
            );
          await this.suggestRoundTripMove(
            log,
            currentMove,
            nextMove,
            moves,
            currentMoveIndex + 1,
            plan,
            suggestedDriveMoves
          );
        }
      }

      // Check for gap on the end (loop back to the beginning)
      if (nextMove == null && firstMove != null) {
        if (log) console.log(`      Checking for gap on end (current vs first move)`);
        if (!this.movesHaveGap(currentMove, firstMove)) {
          if (log)
            console.log(
              `         No gap found right of ${currentMove.id}. (${currentMove.lane.delivery.id} == ${firstMove.lane.pickup.id})`
            );
        } else {
          //Make suggested move here
          if (log)
            console.log(
              `         Gap found on the end of the plan. Getting lane by locations: ${currentMove.lane.delivery.id} and ${firstMove.lane.pickup.id}`
            );
          await this.suggestRoundTripMove(
            log,
            currentMove,
            firstMove,
            moves,
            currentMoveIndex + 1,
            plan,
            suggestedDriveMoves
          );
        }
      } else {
        if (log) console.log(`      Skipped checking for gap on end (???)`, nextMove, firstMove);
      }
    }

    //Put all of the filtered out inactive moves back on the plan to make sure
    // they get updated in the DB on save
    origmoves
      .filter((move) => move.active === 0)
      .map((move) => {
        moves.push(move);
      });

    //Update the moves array on the plan
    console.log(`   After round trip move suggestions, plan now has ${moves.length} moves in it`);
    plan.moves = moves;

    // alert user if no round trips found
    if (plan.moves.length === origmoves.length) {
      this.context.handleNotifications(true, "info", `No suitible unplanned move found. Try a return ride instead.`);
    }

    this.forceUpdate();
  };

  regionChange = (event, value) => {
    this.setState({ selectedRegionId: value });
  };

  movesHaveGap = (deliveryMove, pickupMove) => {
    let res = false;
    try {
      res = !(deliveryMove.lane.delivery.id == pickupMove.lane.pickup.id);
    } catch (error) {
      if (log) console.log("TripTimeline.movesHaveGap failed", error);
    }
    return res;
  };

  createNewLane = async (customer_id, pickup, delivery) => {
    if (log)
      console.log(
        "createNewLane: ",
        customer_id,
        pickup.name,
        pickup.address,
        pickup.id,
        delivery.name,
        delivery.address,
        delivery.id
      );
    this.context.handleNotifications(true, "info", `Creating Lanes...`);
    try {
      const response = await axios({
        method: "POST",
        url: "/.netlify/functions/build-lanes",
        data: {
          type: "fromLocations",
          customer_id: customer_id,
          pickup: { name: pickup.name, address: pickup.address, id: pickup.id },
          delivery: { name: delivery.name, address: delivery.address, id: delivery.id },
        },
      });
      let newLanes = response.data;

      // Check response for valid lane description(s)
      let lanesValid = true;
      for (let lane of newLanes) {
        if (!lane.description) lanesValid = false;
      }

      if (lanesValid) {
        const res = await LaneHelper.updateMultiple(this.context.apolloClient, newLanes);

        const sortedResData = res.data.insert_lanes.returning.sort((a, b) => a.id - b.id); //insert mutation was returning lanes in opposite order

        for (var i = 0; i < newLanes.length; i++) {
          newLanes[i].id = sortedResData[i].id;
          newLanes[i].pickup = sortedResData[i].pickup;
          newLanes[i].delivery = sortedResData[i].delivery;
        }

        // this.context.handleNotifications(true, "success", `Created Lanes: ${newLanes.map(lane => { return lane.id })}`);

        return newLanes[0];
      } else {
        console.error("error creating lanes: ", response.data);
        throw new Error(`Lanes came back as null:  ${response.data[0]}, ${response.data[1]}`);
      }
    } catch (err) {
      console.error(err);
      this.context.handleNotifications(true, "error", `Error creating new Lanes: ${err}`);
      return null;
    }
  };

  suggestReturnRideMove = async (
    log,
    beforeMove,
    afterMove,
    moves,
    currentMoveIndex,
    parentPlan,
    suggestedRideMoves
  ) => {
    if (log)
      console.log(
        `      suggestReturnRideMove start by calling laneHelpers.getLaneByLocations(${beforeMove.lane.delivery.id}, ${afterMove.lane.pickup.id})`
      );
    let gapLane = await LaneHelper.getLaneByLocations(
      this.context.apolloClient,
      beforeMove.lane.delivery.id,
      afterMove.lane.pickup.id
    );
    if (log) console.log(`      gapLane returned: `, gapLane);
    if (gapLane == null) {
      if (log)
        console.log(
          `      since gapLane was returned as null, lets create the lane by calling laneHelpers.createNewLane(${beforeMove.customer_id}, ${beforeMove.lane.delivery}, ${afterMove.lane.pickup})`
        );
      let newLane = await this.createNewLane(beforeMove.customer_id, beforeMove.lane.delivery, afterMove.lane.pickup);
      gapLane = newLane;
      if (log) console.log(`      calling createNewLane returned: `, gapLane);
      // if (log) console.log(`         Created a new lane ${gapLane.id} - ${gapLane.description} for pickup from: ${gapLane.pickup.id} and delivery to ${gapLane.delivery.id}`)
    }
    if (gapLane == null) {
      if (log) console.log(`      Gap lane returned as null`);
    } else {
      if (log) console.log(`         Gap determined to be ${gapLane.id} - ${gapLane.description}: `, gapLane);
      let suggestedMove = this.stubOutReturnRideMove(
        parentPlan,
        gapLane,
        afterMove.lane.pickup.id,
        beforeMove.lane.delivery.id,
        beforeMove
      );

      //We must enrich our newly created move so that it will have box_specs for rendering
      let pos = this.timeMinPx(this.props.timelineDate.clone().startOf("day"), 0);
      this.enrichMove(parentPlan, suggestedMove, pos);
      if (log) console.log(`         Suggested move enriched`, suggestedMove);

      if (log) console.log(`         Suggested move to be added at ${currentMoveIndex}`, suggestedMove);
      //Splice in the suggested move
      moves.splice(currentMoveIndex, 0, suggestedMove);
      this.resequenceMoves(moves);
      if (log)
        console.log(
          `         Inserted move into local copy of the plans moves array which now has ${moves.length} moves in it`
        );
    }
  };

  suggestRoundTripMove = async (
    log,
    beforeMove,
    afterMove,
    moves,
    currentMoveIndex,
    parentPlan,
    suggestedDriveMoves
  ) => {
    try {
      let canDriveManual = false;
      try {
        const tookanDriverInfo = this.state.drivers.filter((o) => o.fleet_id === parentPlan.driver_id) || {};
        if (log) console.log("tookan driver tags: ", tookanDriverInfo[0].tags);
        canDriveManual = tookanDriverInfo[0].tags.indexOf("manual") !== -1; //same as string.includes(string) but works in IE
      } catch (error) {}

      if (log)
        console.log(
          `      suggestRoundTripMove start by calling laneHelpers.getLaneByLocations(${beforeMove.lane.delivery.id}, ${afterMove.lane.pickup.id})`
        );
      let gapLane = await LaneHelper.getLaneByLocations(
        this.context.apolloClient,
        beforeMove.lane.delivery.id,
        afterMove.lane.pickup.id
      );
      if (log) console.log(`      gapLane returned: `, gapLane);
      if (gapLane == null) {
        if (log) console.log(`      No matching moves found - unknown gap lane`);
        return;
      } else {
        if (log)
          console.log(
            `         Gap determined to be ${gapLane.id} - ${gapLane.description} and the driver ${
              canDriveManual ? "can" : `can't`
            } drive a stick. `,
            gapLane
          );
        //const suggestedMoveIndex = this.props.unplannedMoves.findIndex(o => {return o.lane.id === gapLane.id})
        //let suggestedMove = this.props.unplannedMoves[suggestedMoveIndex]
        const suggestedMovesRes = await MoveHelper.getSuggestedRoundTripMoves(
          this.context.apolloClient,
          gapLane.id,
          canDriveManual,
          suggestedDriveMoves.map((o) => o.id)
        );
        let suggestedMove = null;
        if (suggestedMovesRes.length > 0) {
          suggestedMove = suggestedMovesRes[0];
        }

        if (suggestedMove == null) {
          if (log) console.log(`         No matching moves found`);
          return;
        }

        suggestedMove.suggested = true;
        suggestedMove.plan_id = parentPlan.id;
        suggestedMove.driver_id = parentPlan.driver_id;
        suggestedMove.driver_name = parentPlan.driver_name;

        suggestedDriveMoves.push(suggestedMove);

        if (log) {
          console.log("First matching unplanned move: ", suggestedMove);
        }

        //We must enrich the move so that it will have box_specs for rendering
        let pos = this.timeMinPx(this.props.timelineDate.clone().startOf("day"), 0);
        this.enrichMove(parentPlan, suggestedMove, pos);
        if (log) console.log(`         Suggested move enriched`, suggestedMove);
        if (log) console.log(`         Suggested move to be added at ${currentMoveIndex}`, suggestedMove);

        moves.splice(currentMoveIndex, 0, suggestedMove);

        this.resequenceMoves(moves);
        if (log)
          console.log(
            `         Inserted move into local copy of the plans moves array which now has ${moves.length} moves in it`
          );
        //this.context.handleNotifications(true, "success", `Suggested round-trip is unplanned move: ${suggestedMove.id}`)
      }
    } catch (err) {
      this.context.handleNotifications(true, "error", `Round-trip suggestion error: ${err}`);
    }
  };

  stubOutReturnRideMove = (parentPlan, gapLane, pickupLocId, deliveryLocId, parentMove) => {
    console.log({ parentPlan, gapLane, pickupLocId, deliveryLocId, parentMove });
    let overrides = {
      id: "S" + parentMove.sequence,
      plan_id: parentPlan.id,
      customer_id: gapLane.customer_id,
      lane_id: gapLane.id,
      lyft_flag: 0,
      move_type: "ride", //Suggestions are always for rides of some kind
      ride_type: "lyft", //Suggestions will always start by assuming lyft rides
      rate_class_override: 0,
      lane: gapLane,
      lyft_trigger_id: parentMove.lyft_flag ? parentMove.id : null,
      driver_id: parentPlan.driver_id,
      driver_name: parentPlan.driver_name,
      suggested: true, //Added for ui only (not really a part of the model)
      changed: true, //Added for ui only (not really a part of the model)
      parent: parentMove, //Added for ui only (not really a part of the model)
      plan: parentPlan, //Added for ui only (not really a part of the model)
      chargeable: false, // To prevent these moves from being charged to the customer when accepted
    };
    let newMove = MoveHelper.buildNewMoveObject(overrides);
    suggestedRideMoves.push(newMove);
    return newMove;
  };

  resequenceMoves = (moves) => {
    moves.forEach(function (move, index) {
      let moveSeq = move.sequence + 0; //force copy for comparison
      move.sequence = parseInt(index + 1);
      //if (log) console.log(`resequenceMoves move-${move.id} sequence: ${move.sequence}`)
      move.changed = !move.changed ? move.sequence != moveSeq : move.changed;
    });
    moves.sort((a, b) => a.sequence - b.sequence);
  };

  getTransform = (el) => {
    try {
      const txStr = el.style.getPropertyValue("transform"); //Assume returns string pattern of "translate(125px, -48px)"
      var matches = txStr.match(/[\-0-9]+/g);
      return { x: Number(matches[0]), y: Number(matches[1]) };
    } catch (error) {
      return { x: 0, y: 0 };
    }
  };

  getDraggableTimelinePosition = (draggableId) => {
    try {
      const draggable = document.getElementById(draggableId);
      const scrollOffset = this.timelineScrollOffset;
      const parentOffset = { x: draggable.offsetLeft, y: draggable.offsetTop };
      const draggableOffset = this.getTransform(draggable);
      console.log(`getDraggableTimelinePosition: `, {
        scrollOffset: scrollOffset,
        parentOffset: parentOffset,
        draggableOffset: draggableOffset,
      });

      const start = parentOffset.x - this.state.planLabelWidth + this.state.planLabelPad;
      const current =
        parentOffset.x - this.state.planLabelWidth + this.state.planLabelPad + draggableOffset.x + scrollOffset;

      const midnight = this.props.timelineDate.clone().startOf("day");
      const timelineStart = midnight.clone().add(start / this.state.timelineScale, "minutes");
      const timelineCurrent = midnight.clone().add(current / this.state.timelineScale, "minutes");

      //Cache the result so we can reference it on dropEnd
      this.pinnableDropTPos = {
        startX: start,
        startTime: timelineStart,
        currentX: current,
        currentTime: timelineCurrent,
      };

      return this.pinnableDropTPos;
    } catch (error) {
      console.error(`Unable to determine timeline position of draggable ${draggableId}`, error);
      return {
        startX: -1,
        startTime: this.props.timelineDate,
        currentX: -1,
        currentTime: this.props.timelineDate,
      };
    }
  };

  onBeforeDragStart = (start) => {
    console.log("onBeforeDragStart: ", start);
  };

  onDragStart = (start, provided) => {
    console.log("onDragStart: ", start, provided);
  };

  onDragUpdate = (update, provided) => {
    const tlPos = this.getDraggableTimelinePosition(update.draggableId);
    console.log(`onUpdate: `, { startTime: tlPos.startTime.format(), currentTime: tlPos.currentTime.format() });
  };

  onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
    const { selectedRegionId } = this.state;
    const { unplannedMoves } = this.props;
    const regionFilteredUnplannedMoves =
      selectedRegionId === 0
        ? unplannedMoves
        : unplannedMoves.filter(
            (move) =>
              move.lane.pickup.region_id === selectedRegionId || move.lane.delivery.region_id === selectedRegionId
          );
    // dropped outside the list
    if (!destination) {
      if (log) console.log("dropped outside of any droppable area");
      return;
    }
    // dropped in past

    // Get the move we are dragging
    let move = {};
    let sourcePlan = { moves: [] };

    if (source.droppableId === "unplanned") {
      move = regionFilteredUnplannedMoves ? regionFilteredUnplannedMoves[source.index] : unplannedMoves[source.index];
      const tlPos = this.pinnableDropTPos; // <-- unfortunately you have to drag over another plan or this is empty
      // Adding pinnable flag if the planview date does not match the current date
      if (!moment().isSame(this.props.timelineDate, "day")) {
        move.pinnable = true;
        move.pickup_time = tlPos.currentTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        // move.pickup_time = moment(pickupTime).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      }
      console.log("onDragEnd dragging unplanned move: ", move);
    } else if (source.droppableId.includes("plan")) {
      sourcePlan = this.props.plans.find((o) => "plan-" + o.id === source.droppableId);
      move = sourcePlan.moves[source.index];
      console.log("onDragEnd dragging move: ", move);
    } else {
      move = {};
    }
    // sets start and end position, width, etc.
    this.addLocalMoveAttributes(move);

    // Get the plan we are dropping onto
    let destPlan = { moves: [] };
    if (destination.droppableId.includes("plan")) {
      destPlan = this.props.plans.find((o) => "plan-" + o.id === destination.droppableId);
    }

    // Validation-
    //prevent dropping a move into  a plan that it does not share a region with
    if (
      destPlan &&
      this.state.selectedRegionId === 0 &&
      (typeof destPlan.region_id !== "number" ||
        destPlan.region_id !== move.lane.pickup.region_id ||
        destPlan.region_id !== move.lane.delivery.region_id)
    ) {
      this.context.handleNotifications(true, "warning", "Move is not in this plan's region");
      return;
    }
    // prevents dropping a move into the past or before their ready_by time
    let nextMoveAfterDrop = null;
    if (destPlan)
      nextMoveAfterDrop = destPlan.moves.find((m) => m.sequence === destination.index + 1 && m.id !== move.id);
    if (nextMoveAfterDrop && nextMoveAfterDrop !== undefined) {
      if (nextMoveAfterDrop.pinnable !== true) {
        console.log(nextMoveAfterDrop.id);
        let nextMoveStartTime = nextMoveAfterDrop.pickup_arrived; //do not prevent placing moves before a move which has not yet begun
        let draggedMoveDuration = undefined; // Unplanned moves will not have box_specs, and so for those we get their 'lane duration' times
        if (move.box_specs !== undefined) {
          draggedMoveDuration =
            move.box_specs.pickup.min +
            move.box_specs.pickupExtra.min +
            move.box_specs.drive.min +
            move.box_specs.driveExtra.min +
            move.box_specs.delivery.min +
            move.box_specs.deliveryExtra.min;
        } else {
          draggedMoveDuration =
            (move.lane.duration_sec + move.lane.pickup_inspection_sec + move.lane.delivery_inspection_sec) / 60;
        }
        if (moment(nextMoveStartTime).isBefore(moment())) {
          this.context.handleNotifications(true, "warning", "Move cannot be dropped into the past");
          return;
        } else if (moment(nextMoveStartTime).subtract(draggedMoveDuration, "minutes").isBefore(moment())) {
          this.context.handleNotifications(
            true,
            "warning",
            "Placing move there would push its pickup time into the past"
          );
          return;
        } else if (moment(nextMoveStartTime).isBefore(moment(move.ready_by))) {
          this.context.handleNotifications(true, "warning", "Move cannot be dropped before its ready by time");
          return;
        } else if (moment(nextMoveStartTime).subtract(draggedMoveDuration, "minutes").isBefore(moment(move.ready_by))) {
          this.context.handleNotifications(
            true,
            "warning",
            "Placing move there would place its pickup time before its ready by time"
          );
          return;
        }
      }
    }
    // prevents dropping a manual move onto a non-manual plan
    if (move.cancel_status) {
      this.context.handleNotifications(true, "warning", `Move has been canceled and cannot be planned.`);
      return;
    }
    // prevents dropping a manual move onto a non-manual plan
    if (move.manual_flag === true && destPlan) {
      let canDriveManual = false;
      const tookanDriverInfo = this.state.drivers.filter((o) => o.fleet_id === destPlan.driver_id) || {};
      if (log) console.log("tookan driver tags: ", tookanDriverInfo[0]);
      canDriveManual = tookanDriverInfo[0].tags.indexOf("manual") !== -1; //same as string.includes(string) but works in IE
      if (canDriveManual === false) {
        this.context.handleNotifications(true, "warning", `${destPlan.driver_name} cannot drive manual!`);
        return;
      }
    }
    // prevents dropping a concierge move onto a non-concierge plan
    if (move.consumer_pickup === true && destPlan) {
      let canDoConcierge = false;
      const tookanDriverInfo = this.state.drivers.filter((o) => o.fleet_id === destPlan.driver_id) || {};
      if (log) console.log("tookan driver tags: ", tookanDriverInfo[0]);
      canDoConcierge = tookanDriverInfo[0].tags.indexOf("concierge trained") !== -1; //same as string.includes(string) but works in IE
      if (canDoConcierge === false) {
        this.context.handleNotifications(true, "warning", `${destPlan.driver_name} is not trained for concierge!`);
        return;
      }
    }
    // dropped a move in the same plan it was already in
    if (source.droppableId === destination.droppableId) {
      if (source.index !== destination.index) {
        this.moveToPosition(sourcePlan, source.index, destination.index);

        //For all moves after the point where a move has been injected, clear the pickup_time
        // to ensure it's position is recalculated
        var self = this;
        sourcePlan.moves.forEach(function (move, index) {
          if (index >= destination.index) {
            if (log)
              console.log(
                `moveToPosition   move-${move.id}   move was to the right of where the drop happened. Need to reset the pickup_time`
              );
            move.pickup_time = null;
            move.delivery_time = null;
          }
        });
      }
    } else {
      if (source.droppableId === "unplanned") {
        this.addToPlan(move, destPlan, destination);
      } else {
        this.moveToPlan(sourcePlan, destPlan, source, destination);
        if (log)
          console.log(
            `move-${destPlan.moves[destination.index].id} after being put on new plan has changed = ${
              destPlan.moves[destination.index].changed ? "true" : "false"
            }`
          );
      }
    }

    if (move.pinnable) {
      //Cannot call getDraggableTimelinePostion here because after it's already dropped an incorrect time is returned
      // This is caused by the fact that after dragging is completed, the tranform property in the style prop of the
      // element is an empty string and the parent offset is also incorrect. Appears that the function only works
      // while dragging and an update is fired from React Beautiful DnD.
      //const tlPos = this.getDraggableTimelinePosition(draggableId)  // <-- This DOESNT WORK 8/15/2019 see above

      if (this.pinnableDropTPos != null) {
        const tlPos = this.pinnableDropTPos; // <-- unfortunately you have to drag over another plan or this is empty
        move.pickup_time = tlPos.currentTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        move.changed = true;
        if (log) console.log(`   Dragged pinnable move-${move.id} to ${tlPos.currentTime.format("hh:mm a")}`);
      }
    }

    this.resequenceMoves(sourcePlan.moves);
    this.enrichPlan(sourcePlan);
    this.reclassMoves(sourcePlan.moves);
    this.retimeMoves(sourcePlan.moves);

    if (!destPlan) return; // Return if no destination plan (eg: reordering unplanned moves)

    this.resequenceMoves(destPlan.moves);
    this.enrichPlan(destPlan);
    this.reclassMoves(destPlan.moves);
    this.retimeMoves(destPlan.moves);

    let sourceRes = await this.persistPlanChanges(sourcePlan);
    let destRes = await this.persistPlanChanges(destPlan);
    if (destRes && sourceRes) {
      if (log)
        console.log(
          `dropped ${`${move.id} - ${
            move.move_type == "ride"
              ? "Lyft"
              : `${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model} ${move.vehicle_color}`
          }`} onto ${destPlan.driver_name}'s plan at sequence ${destination.index}`
        );
      return; //if res.data is returned from db- persistance was successful and drag is valid
    } else {
      if (source.droppableId === destination.droppableId && source.index !== destination.index) {
        //undo move to position if persist fails
        this.moveToPosition(sourcePlan, destination.index, source.index);
        sourcePlan.moves.forEach(function (move, index) {
          if (index >= source.index) {
            if (log)
              console.log(
                `moveToPosition   move-${move.id}   move was to the right of where the drop happened. Need to reset the pickup_time`
              );
            move.pickup_time = null;
            move.delivery_time = null;
          }
        });

        this.resequenceMoves(sourcePlan.moves);
        this.enrichPlan(sourcePlan);
        this.reclassMoves(sourcePlan.moves);
        this.retimeMoves(sourcePlan.moves);
      } else {
        if (source.droppableId === "unplanned") {
          //unplan move if adding it to a plan didn't persist
          move.plan_id = null;
          move.driver_name = null;
          move.driver_id = null;
          destPlan.moves.splice(destination.index, 1);
          // MoveHelper.unplanMove(this.context.apolloClient, move);
          // Tookan.handleMoveUnplan(move);

          this.resequenceMoves(destPlan.moves);
          this.enrichPlan(destPlan);
          this.reclassMoves(destPlan.moves);
          this.retimeMoves(destPlan.moves);
        } //move plan back to original plan if it's move didn't persist
        else {
          // this.moveToPlan(destPlan, sourcePlan, destination, source)
          // this.resequenceMoves(sourcePlan.moves)
          // this.enrichPlan(sourcePlan)
          // this.reclassMoves(sourcePlan.moves)
          // this.retimeMoves(sourcePlan.moves)
          // this.resequenceMoves(destPlan.moves)
          // this.enrichPlan(destPlan)
          // this.reclassMoves(destPlan.moves)
          // this.retimeMoves(destPlan.moves)
        }
      }
    }
  };

  persistPlanChanges = async (plan) => {
    if (plan && !Array.isArray(plan.moves)) {
      if (log) console.error(`plan-${plan.id} Error saving plan. Plan is null or has no moves.`, plan);
      this.context.handleNotifications(true, "error", `Error saving plan. Plan is null or has no moves.`);
      return null;
    }
    if (log) console.log(`plan-${plan.id} Persisting changes to plan with ${plan.moves.length} moves`, plan.moves);
    let moves = [];
    plan.moves.forEach((move) => {
      // Remove the return_ride_id and lyft_flag if the return move is not found on the plan
      if (move.moveByReturnRideId && move.moveByReturnRideId.move_type === "ride") {
        if (move.return_ride_id && !plan.moves.find((o) => o.id === move.return_ride_id)) {
          move.return_ride_id = null;
          move.lyft_flag = 0;
        }
        // Set a return ride to inactive if its parent move is not found on the plan
        if (move.move_type === "ride" && !move.suggested && !plan.moves.find((o) => o.return_ride_id === move.id)) {
          move.active = 0;
          move.lyft_trigger_id = null;
        }
      }
      if (move.changed && !move.suggested) {
        if (log) console.log("   Making an insertable move from: ", move);

        move.plan_id = plan.id;
        move.driver_name = plan.driver_name;
        move.driver_id = plan.driver_id;
        move.status = 'pending acceptance';

        move.changed = false;
        moves.push(this.makeInsertable(move));
        if (log) console.log(`   persisting changes for move-${move.id}`, move);
      } else {
        if (move.suggested) {
          if (log) console.log(`   skipping move-${move.id} because it is a suggestion`);
        } else {
          if (log) console.log(`   skipping move-${move.id} because it does not have any pending changes`);
        }
      }
    });

    //Only call if there is something the persist
    if (moves.length > 0) {
      if (log) {
        console.log("Would write a collection of insertable moves to the db here: ", moves);
      }
      try {
        const res = await MoveHelper.updateMultiple(this.context.apolloClient, moves);
        if (log) {
          console.log("Would write a collection of insertable moves to the db here: ", moves);
        }
        // Called too often to be useful
        // this.context.handleNotifications(true, "info", `Would write a collection of insertable moves to the db here: ${(moves.map(move=>move.id)).join(", ")}`);
        return res.data;
      } catch (err) {
        console.error(err);
        this.context.handleNotifications(
          true,
          "error",
          `Failed to save moves into the database. <br />Moves:${moves.map((move) => move.id).join(", ")}`
        );
        return null;
      }
    }
  };

  makeInsertable = (move) => {
    if (log) console.log("Making an insertable move from: ", move);

    let insertableMove = {
      active: move.active,
      cancel_status: move.cancel_status,
      chargeable: move.chargeable,
      class: move.class,
      customer_id: move.customer_id,
      deliver_by: move.deliver_by,
      delivery_time: move.delivery_time,
      driver_name: move.driver_name,
      driver_id: move.driver_id,
      lane_id: move.lane_id,
      lyft_flag: move.lyft_flag,
      lyft_trigger_id: move.lyft_trigger_id,
      manual_flag: move.manual_flag,
      move_details: move.move_details,
      move_failed: move.move_failed,
      move_type: move.move_type,
      plan_id: move.plan_id,
      pickup_time: move.pickup_time,
      pinnable: move.pinnable,
      sequence: move.sequence,
      vehicle_year: move.vehicle_year,
      vehicle_make: move.vehicle_make,
      vehicle_model: move.vehicle_model,
      vehicle_color: move.vehicle_color,
      vehicle_stock: move.vehicle_stock,
      vehicle_vin: move.vehicle_vin,
      ride_type: move.ride_type,
      rate_class_override: move.rate_class_override,
      return_ride_id: move.return_ride_id,
      ready_by: move.ready_by,
      status: move.status,
      updatedat: "now()",
      reference_num: move.reference_num,
    };

    if (!move.new) {
      if (log) console.log("   Adding the move id since this is not a new move");
      Object.assign(insertableMove, { id: move.id });
    }

    if (log) console.log("   Insertable move: ", insertableMove);

    return insertableMove;
  };

  acceptAllSuggestedReturnRidesByPlan = async (plan) => {
    if (log) console.log(`   acceptAllSuggestedReturnRidesByPlan() plan-${plan.id}`);
    let priorMove = null;
    plan.moves.map((move) => {
      if (move.suggested) {
        if (log) console.log(`   plan-${plan.id} move-${move.id} prior-${priorMove ? priorMove.id : null}`);
        this.acceptSuggestedReturnRide(priorMove, move);
      }
      priorMove = move;
    });
  };

  acceptSuggestedReturnRide = async (priorMove, move) => {
    const temporaryReturnMoveId = move.id;
    move.lyft_trigger_id = priorMove.id; // set lyft_trigger_id

    //We need to first insert the accepted ride type move then get the new ID for it and
    // update the move to the left's return_ride_id with that ID to create the relationship
    if (log) console.log(`Saving accepted return ride suggestion ${move.id} for drive move ${priorMove.id}`);

    //Insert the accepted return ride move first then get it's id
    try {
      const savedReturnMove = await MoveHelper.insertMoves(this.context.apolloClient, [this.makeInsertable(move)]);
      const return_ride_id = savedReturnMove.data.insert_moves.returning[0].id;
      if (return_ride_id > 0) {
        move.changed = false;
        move.new = false;
        move.id = return_ride_id;
      }
      if (log) console.log(`   Saved accepted suggestion. ${temporaryReturnMoveId} became ${return_ride_id}`);

      //Update the parent drive move with the return ride id
      priorMove.return_ride_id = return_ride_id;
      if (log) console.log(`   Updated the return ride id of the parent drive move to ${return_ride_id}`);
      try {
        priorMove.lyft_flag = 1;
        MoveHelper.updateMultiple(this.context.apolloClient, [this.makeInsertable(priorMove)]);
      } catch (err) {
        console.error(err);
        this.context.handleNotifications(
          true,
          "error",
          `Error updating the return ride id of the parent drive move to ${return_ride_id}`
        );
      }

      //Remove the temporary move id from the suggestedRideMoves array
      var index = suggestedRideMoves.map((suggestion) => suggestion.id).indexOf(return_ride_id);
      if (index > -1) {
        suggestedRideMoves.splice(index, 1);
      }
      //set lyft_flag and lyft_trigger_id
      // MoveHelper.updateLyftPair(this.context.apolloClient, 1, priorMove.id, return_ride_id);

      return;
    } catch (err) {
      console.error(err);
      this.context.handleNotifications(
        true,
        "error",
        `Error saving accepted suggestion. ${temporaryReturnMoveId} was not changed to a permanent id`
      );
    }
  };

  acceptRoundTripSuggestion = async (move) => {
    if (log) console.log(`Saving accepted round trip suggestion ${move.id}`);
    try {
      MoveHelper.insertMoves(this.context.apolloClient, [this.makeInsertable(move)]);

      //Remove the temporary move id from the suggestedRideMoves array
      var index = suggestedDriveMoves.map((suggestion) => suggestion.id).indexOf(move.id);
      if (index > -1) {
        suggestedDriveMoves.splice(index, 1);
      }
    } catch (err) {
      console.error(err);
      this.context.handleNotifications(true, "error", `Error saving accepted round trip suggestion ${move.id}`);
    }
  };

  handleTimelineDateChange = (event) => {
    if (moment.isMoment(event)) {
      console.log("handleTimelineDateChange moment object passed: ", event.format("YYYY-MM-DD"));
      this.props.changeTimelineDate(event);
    } else {
      console.log("handleTimelineDateChange date string passed: ", event.target.value);
      this.props.changeTimelineDate(moment(event.target.value));
    }
  };

  handlePlanHeightChange = (event, value) => {
    this.setState({
      planHeight: value,
    });
    localStorage.setItem("planHeight", value);
  };

  handleTimelineScaleChange = (event, value) => {
    this.setState({
      timelineScale: value,
    });
    localStorage.setItem("timelineScale", value);
  };

  checkIsReturnRideLyft = (rideId, moves) => {
    if (rideId) {
      const returnRide = moves.find((m) => m.id === rideId);
      if (returnRide && returnRide.ride_type === "lyft") {
        if (log) {
          console.log("return ride type = ", returnRide.ride_type);
        }
        return true;
      } else {
        return false;
      }
    }
  };

  handleUnplannedMovesHeaderContextClick = async (e, data, target) => {
    if (data.action === "add-move") {
      this.setState({
        addMoveModalOpen: true,
      });
      return;
    }
  };

  handleUnplannedMoveContextClick = async (e, data, target) => {
    if (data.action === "move-details") {
      this.props.history.push(`/moves/${data.move.id}`);
      return;
    }
    if (data.action === "edit-move") {
      this.props.history.push(`/moves/${data.move.id}/edit`);
      return;
    }
    if (data.action === "manage-accessorials") {
      this.setState({
        showAccessorialsModal: true,
        contextMenuActionMoveId: data.move.id,
      });
      return;
    }
    if (data.action === "adjust-priority") {
      this.setState({
        adjustPriorityModalOpen: true,
        contextMenuActionMoveId: data.move.id,
      });
      return;
    }
    if (data.action === "view-lane") {
      this.props.history.push(`/map/lanes/${data.move.lane_id}`);
    }
    if (data.action === "edit-pickup") {
      if (data.move && data.move.lane && data.move.lane.pickup && data.move.lane.pickup.id)
        this.props.history.push(`/locations/${data.move.lane.pickup.id}`);
      else
        this.context.handleNotifications(
          true,
          "warning",
          "No pickup associated with this move, please check the related lane"
        );
    }
    if (data.action === "edit-delivery") {
      if (data.move && data.move.lane && data.move.lane.delivery && data.move.lane.delivery.id)
        this.props.history.push(`/locations/${data.move.lane.delivery.id}`);
      else
        this.context.handleNotifications(
          true,
          "warning",
          "No delivery associated with this move, please check the related lane"
        );
    }
    if (data.action === "cancel") {
      this.setState({
        contextMenuActionMoveId: data.move.id,
        contextMenuActionMove: data.move,
        cancelMoveModalOpen: true,
      });
      return;
    }
    if (data.action === "delete-unplanned-move") {
      try {
        this.setState({
          deleteUnplannedMoveModal: true,
          contextMenuActionMoveId: data.move.id,
        });
      } catch (err) {
        this.context.handleNotifications(true, "error", `Error deleting unplanned Move: ${err}`);
      }

      return;
    }
  };

  handleDriverContextClick = async (e, data, target) => {
    if (data.action === "add-plan") {
      if (this.props.plans.map((plan) => plan.driver_id).includes(data.driver.fleet_id)) {
        this.context.handleNotifications(true, "warning", `Plan already exists for this driver`);
        return;
      }
      // let selectedRegions = JSON.parse(this.context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-selected-regions"].replace("{", "[").replace("}", "]")); // parsing the selected regions claim as an array in the format of [1,2,3] instead of the stored "{1,2,3}"
      if (this.props.regions.length > 0) {
        //Set plan to driver's region by finding the region with the matching team_id
        const driversRegion = this.props.regions.find((region) => region.team_id === data.driver.team_id);
        await PlanHelper.create(
          this.context.apolloClient,
          this.props.timelineDate,
          data.driver.fleet_id,
          data.driver.username,
          driversRegion.id
        );
      } else {
        this.context.handleNotifications(true, "warning", `No region currently selected.`);
      }
      return;
    }
  };

  handlePlanContextClick = async (e, data, target) => {
    this.setState({
      contextMenuActionPlan: data.plan,
    });

    if (data.action === "suggest-return-rides") {
      this.clearPlanSuggestions(data.plan);
      await this.suggestReturnRides(data.plan);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.forceUpdate();

      return;
    } else if (data.action === "suggest-milk-runs") {
      alert(`TODO: Suggest milk runs from the unplanned moves list`);
      return;
    } else if (data.action === "suggest-round-trips") {
      this.clearPlanSuggestions(data.plan);
      this.suggestRoundTrips(data.plan);
      return;
    } else if (data.action === "clear-suggestions") {
      this.clearPlanSuggestions(data.plan);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.forceUpdate();

      return;
    } else if (data.action === "accept-all-suggested-return-rides") {
      if (log) console.log(`accept-all-suggested-return-rides plan-${data.plan.id}`);
      await this.acceptAllSuggestedReturnRidesByPlan(data.plan);

      return;
    } else if (data.action === "save-all") {
      this.persistPlanChanges(data.plan);

      return;
    } else if (data.action === "sync-with-tookan") {
      await this.handleSyncMovesToTookan(data.plan);
      return;
    } else if (data.action === "set-parked-location") {
      this.setState({
        setParkedLocationModalOpen: true,
      });
      return;
    }
  };

  handleCloseAccessorialsModal = () => this.setState({ showAccessorialsModal: false, contextMenuActionMoveId: null });

  handleMoveContextClick = async (e, data, target) => {
    this.setState({
      contextMenuActionMoveId: data.move.id,
      contextMenuActionPlan: data.plan,
      contextMenuActionMove: data.move,
    });

    if (data.action === "pinnable") {
      data.move.pinnable = !!!data.move.pinnable;
      if (log) console.log(`   Move ${data.move.id} is now ${data.move.pinnable ? "pinnable" : "not pinnable"}`);
      //if (!data.move.pinnable) {
      // this.resequenceMoves(data.plan.moves)
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);
      //}
      MoveHelper.updateMultiple(this.context.apolloClient, [this.makeInsertable(data.move)]);
      return;
    }

    if (data.action === "pinnable-time") {
      this.setState({
        setPinnableStartTimeModalOpen: true,
      });
      return;
    }

    if (data.action === "move-details") {
      this.props.history.push(`/moves/${data.move.id}`);
      return;
    }
    if (data.action === "edit") {
      this.props.history.push(`/moves/${data.move.id}/edit`);
      return;
    }

    if (data.action === "track") {
      window.open(data.move.tracking_link);
      return;
    }

    if (data.action === "unplan") {
      log && console.log("unplanning a move");
      try {
        await MoveHelper.unplanMove(this.context.apolloClient, data.move);
        // Tookan.handleMoveUnplan(
        //   this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
        //   this.context.apolloClient,
        //   data.move
        // );
        // driver-app functionality here
        this.resequenceMoves(data.plan.moves);
        this.enrichPlan(data.plan);
        this.reclassMoves(data.plan.moves);
        this.retimeMoves(data.plan.moves);
      } catch (err) {
        console.error(err);
        this.context.handleNotifications(true, "error", `Error unplanning Move`);
      }
      return;
    }

    if (data.action === "delete-return-move") {
      try {
        await MoveHelper.deleteReturnMove(this.context.apolloClient, data.move.id, data.move);
        // if (data.move.pickup_stop_id)
        //   Tookan.handleMoveUnplan(
        //     this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
        //     this.context.apolloClient,
        //     data.move
        //   );
        // driver-app functionality here
        this.resequenceMoves(data.plan.moves);
        this.enrichPlan(data.plan);
        this.reclassMoves(data.plan.moves);
        this.retimeMoves(data.plan.moves);
      } catch (err) {
        console.error(err);
        this.context.handleNotifications(true, "error", `Error deleting return Move`);
      }

      return;
    }

    if (data.action === "cancel") {
      this.setState({
        cancelMoveModalOpen: true,
      });
      return;
    }

    if (data.action === "clear-planned-times") {
      this.setState({
        clearPlannedTimesModalOpen: true,
      });
      return;
    }

    if (data.action === "accept-ride-suggestion") {
      data.move.suggested = false;
      await this.acceptSuggestedReturnRide(data.move.parent, data.move);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.persistPlanChanges(data.plan);

      return;
    }

    if (data.action === "accept-round-trip-suggestion") {
      data.move.suggested = false;
      this.acceptRoundTripSuggestion(data.move);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.persistPlanChanges(data.plan);

      return;
    }

    if (data.action === "move-details") {
      this.props.history.push(`/moves/${data.move.id}`);
      return;
    }

    if (data.action === "change-ride-type") {
      this.setState({
        rideTypeChangeModalOpen: true,
      });
      return;
    }

    if (data.action === "override-class") {
      this.setState({
        overrideClassModalOpen: true,
        contextMenuActionMove: data.move,
      });
      return;
    }

    if (data.action === "check-driver-pay") {
      // console.log(data.move)
      let driverPay = await RateEngine.driverPay(
        `https://${process.env.REACT_APP_GQL_SD}.herokuapp.com/v1/graphql`,
        this.context.userProfile["https://api_keys.io/jwt/claims"]["GQLSecret"]
      ).getDriverBasePay(data.move);
      console.log(driverPay);
    }

    if (data.action === "manage-accessorials") {
      this.setState({
        showAccessorialsModal: true,
        contextMenuActionMoveId: data.move.id,
      });
      return;
    }

    if (data.action === "lyft-flag") {
      const returnRide = data.plan.moves.find((m) => m.id === data.move.return_ride_id);
      if (returnRide.lyft_trigger_id !== null && returnRide.lyft_trigger_id !== data.move.id) {
        // this block is for changing the lyft flag for a ride-share group
        //  it activates when the return ride is in a ride-share group with another drive flagged as the trigger
        const rideShareGroup = [
          { id: data.move.id, lyft_flag: 1, lyft_trigger_id: null },
          { id: returnRide.lyft_trigger_id, lyft_flag: 0 },
        ].concat(
          this.props.plans
            .map((p) => p.moves)
            .flat()
            .filter((m) => m.lyft_trigger_id === returnRide.lyft_trigger_id)
            .map((m) => {
              return { id: m.id, lyft_trigger_id: data.move.id };
            })
        );
        MoveHelper.updateMultiple(this.context.apolloClient, rideShareGroup);
        // this.context.handleNotifications(true, "success", `Ride share group's lyft trigger switched to Move #${data.move.id}`);
      } else if (returnRide) {
        MoveHelper.updateLyftPair(this.context.apolloClient, 1, data.move.id, data.move.return_ride_id);
      } else {
        this.context.handleNotifications(
          true,
          "error",
          `Error setting ${data.move.id} as lyft trigger: no return ride found with move id ${data.move.return_ride_id}`
        );
      }
      return;
    }

    if (data.action === "share-ride") {
      function checkPickupTimesRange(rideOne, rideTwo, range) {
        const fiveBefore = rideOne.box_specs.pickup.times.start.subtract(range, "m").format("hh:mm");
        const fiveAfter = rideOne.box_specs.pickup.times.start.add(range, "m").format("hh:mm");
        const rideTwoTime = rideTwo.box_specs.pickup.times.start.format("hh:mm");
        if (rideTwoTime >= fiveBefore && rideTwoTime <= fiveAfter) {
          return true;
        } else {
          return false;
        }
      }
      const rideShareOptions = this.props.plans
        .map((p) => p.moves)
        .flat()
        .filter(
          (m) =>
            m.move_type === "ride" &&
            m.ride_type === data.move.ride_type &&
            m.lane_id === data.move.lane_id &&
            checkPickupTimesRange(data.move, m, 5)
        )
        .map((m) => {
          return {
            id: m.id,
            lyft_trigger_id: data.move.lyft_trigger_id,
            currentShareGroupId: m.lyft_trigger_id,
            driver_name: m.driver_name,
          };
        });
      this.setState({
        rideShareOptions: rideShareOptions,
        shareRideModalOpen: true,
      });
      return;
    }

    if (data.action === "set-destination") {
      this.setState({
        setDestinationModalOpen: true,
        contextMenuActionMoveId: data.move.id,
        contextMenuActionMoveLane: data.move.lane,
      });
      return;
    }

    if (data.action === "set-pickup") {
      this.setState({
        setPickupModalOpen: true,
        contextMenuActionMoveId: data.move.id,
        contextMenuActionMoveLane: data.move.lane,
      });
      return;
    }

    if (data.action === "show-lyft-modal") {
      this.lyftModalToggle(data.move);
      return;
    }

    if (data.action === "add-lyft-ride") {
      const newLyftMove = await this.createLyftReturnRide(data.move, data.plan);
      data.move = Object.assign(data.move, { changed: true, lyft_flag: 1, return_ride_id: newLyftMove.id });

      data.plan.moves.splice(data.move.sequence - 1, 1, data.move, newLyftMove);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.persistPlanChanges(data.plan);

      return;
    }

    if (data.action === "add-before-lyft-ride") {
      const newLyftMove = await this.createLyftReturnRide(data.move, data.plan, true);
      data.move = Object.assign(data.move, { changed: true, lyft_flag: 1, return_ride_id: newLyftMove.id });

      data.plan.moves.splice(data.move.sequence - 1, 1, newLyftMove, data.move);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.persistPlanChanges(data.plan);

      return;
    }

    if (data.action === "add-lyft-ride-to-parked-location") {
      const newLyftMove = await this.createLyftReturnRide(data.move, data.plan, false, true);
      data.move = Object.assign(data.move, { changed: true, lyft_flag: 1, return_ride_id: newLyftMove.id });

      data.plan.moves.splice(data.move.sequence - 1, 1, data.move, newLyftMove);

      this.resequenceMoves(data.plan.moves);
      this.enrichPlan(data.plan);
      this.reclassMoves(data.plan.moves);
      this.retimeMoves(data.plan.moves);

      this.persistPlanChanges(data.plan);

      return;
    }
  };

  createLyftReturnRide = async (driveMove, plan, lyftFirst = false, toParkedLocation = false) => {
    try {
      let startingLocation = driveMove.lane.delivery;
      let parkedLocation = toParkedLocation
        ? await LaneHelper.getLocation(this.context.apolloClient, plan.parked_location)
        : {};
      let endingLocation = toParkedLocation ? parkedLocation : driveMove.lane.pickup;

      log && console.log(`Return to the parked car location? ${toParkedLocation}`);
      log && console.log(`parkedLocations: ${parkedLocation.name}`, parkedLocation);
      log &&
        console.log(
          `createLyftReturnRide: creating lyft that starts at ${startingLocation.name} and ends at ${endingLocation.name}`
        );

      let gapLane = await LaneHelper.getLaneByLocations(
        this.context.apolloClient,
        startingLocation.id,
        endingLocation.id
      );
      if (gapLane == null) {
        //this is in case the inverse of the drive's lane hasn't been created yet
        log &&
          console.log(`Creating a new lane that starts at ${startingLocation.name} and ends at ${endingLocation.name}`);
        let newLane = await this.createNewLane(driveMove.customer_id, startingLocation, endingLocation);
        gapLane = newLane;
      }
      const stubbedLyftMove = {
        plan_id: driveMove.plan_id,
        customer_id: driveMove.customer_id,
        lane_id: gapLane.id,
        lyft_flag: 0,
        move_type: "ride",
        ride_type: "lyft",
        rate_class_override: 0,
        lane: gapLane,
        ready_by: lyftFirst ? moment(driveMove.pickup_time) : null,
        lyft_trigger_id: driveMove.id,
        driver_id: plan.driver_id,
        driver_name: plan.driver_name,
        sequence: lyftFirst ? driveMove.sequence - 1 : driveMove.sequence + 1, // put before the drive move if it is a lyft-first move
        changed: true, //Added for ui only (not really a part of the model)
        parent: driveMove, //Added for ui only (not really a part of the model)
        plan: plan, //Added for ui only (not really a part of the model)
        new: true, //Set the move to true so insertable doesn't try to add in the move id
        chargeable: false, // Return rides should default to nonchargeable
      };
      const newLyftMoveResponse = await MoveHelper.insertMoves(this.context.apolloClient, [
        this.makeInsertable(stubbedLyftMove),
      ]);
      const newLyftMove = newLyftMoveResponse.data.insert_moves.returning[0];
      // delete newLyftMove.__typename;
      return newLyftMove;
    } catch (err) {
      console.error(err);
      this.context.handleNotifications(true, "error", `Error creating return lyft for ${driveMove.id}`);
    }
  };

  lyftModalToggle = (move = null) =>
    this.setState({ showLyftModal: !this.state.showLyftModal, lyftModalMove: move ? move : null });

  regionModalToggle = (data = null) =>
    this.setState({ showRegionModal: !this.state.showRegionModal, regionModalData: data ? data : null });

  handleSelectMove = (move) => {
    console.log("handleSelectMove", move.id, this.state.selectedMove);
    if (this.state.selectedMove && move.id === this.state.selectedMove.id) {
      this.setState({ selectedMove: null });
    } else {
      this.setState({ selectedMove: move });
    }
  };
  getCurrentMoveStatus = (plan) => {
    let currentMove = plan.moves.find((m) => moment(m.delivery_time) > moment()); //Find first move whose delivery_time is not in the past
    if (currentMove !== undefined) {
      if (currentMove.status === null) {
        return "Started";
      } else {
        return currentMove.status;
      }
    } else {
      return "None";
    }
  };
  setStatusAvatar = (driverStatus, plan) => {
    const status = driverStatus === 2 ? this.getCurrentMoveStatus(plan) : driverStatus;
    switch (status) {
      case 1: //inactive
        //these height and width values are from the MuiChip-AvatarSmall class, which is erroneously overwritten on deploy
        return <Avatar style={{ height: "24px", width: "24px", backgroundColor: "grey", color: "white" }}>I</Avatar>;
      case 0: //free
        return <Avatar style={{ height: "24px", width: "24px", backgroundColor: "blue", color: "white" }}>F</Avatar>;
      case "delivery successful":
        return (
          <Avatar style={{ height: "24px", width: "24px", backgroundColor: "green", color: "white" }}>
            <CheckIcon />
          </Avatar>
        );
      case "pickup arrived":
      case "pickup started":
      case "pickup successful":
      case "delivery arrived":
      case "delivery started":
        return (
          <Avatar style={{ height: "24px", width: "24px", backgroundColor: "orange", color: "white" }}>
            <PlayArrowIcon />
          </Avatar>
        );
      default:
        return <Avatar style={{ height: "24px", width: "24px", backgroundColor: "grey", color: "white" }}>-</Avatar>;
    }
  };
  setTagIcons = (tags) => {
    const tagsArr = tags.split(","); //probably unneccessary to array-ify the tags
    return (
      <>
        {tagsArr.includes("manual") && <ManualIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />}
        {tagsArr.includes("concierge trained") && (
          <Icon style={{ transform: "scale(0.65)", margin: "-4px" }}>assignment_ind</Icon>
        )}
        {tagsArr.find((tag) => tag !== "manual") && <MoreIcon style={{ transform: "scale(0.65)", margin: "-4px" }} />}
      </>
    );
  };

  handleSyncMovesToTookan = async (plan) => {
    this.retimeMoves(plan.moves);
    await this.persistPlanChanges(plan);
    if (log)
      console.log("handleSyncMovesToTookan => moves:", plan.moves, "apolloClient mutate", this.context.apolloClient);
    // Tookan.handleTookanMoves(
    //   this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
    //   this.context.apolloClient,
    //   plan.moves
    // );
    // driver-app functionality here
  };

  handleSyncPlansToTookan = async (plans) => {
    if (log) console.log("handleSyncPlansToTookan => plans:", plans);
    plans.forEach(async (plan) => {
      this.retimeMoves(plan.moves);
      await this.persistPlanChanges(plan);
      //   Tookan.handleTookanMoves(
      //     this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
      //     this.context.apolloClient,
      //     plan.moves
      //   );
      // driver-app functionality here
    });
  };
  handlePlanHeaderContextClick = async (e, data, target) => {
    if (data.action === "sync-all-moves") {
      await this.handleSyncPlansToTookan(this.props.plans);
      return;
    }

    // TODO: disable next two actions if no suggested moves
    // if(this.props.plans.includes(plan=>plan.moves.includes(move=>move.suggested === true)===true)
    else if (data.action === "accept-all-suggestions") {
      this.props.plans.forEach(async (plan) => {
        let suggestions = plan.moves.filter((move) => {
          return move.suggested === true;
        });
        let findPriorMove = (suggestedMove) => plan.moves.find((move) => move.sequence === suggestedMove.sequence - 1);
        let accepted = await suggestions.forEach(async (move) => {
          if (move.move_type === "ride" && move.suggested === true) {
            move.suggested = false;
            let priorMove = findPriorMove(move);
            await this.acceptSuggestedReturnRide(priorMove, move);
          } else if (move.move_type === "drive") {
            move.suggested = false;
            await this.acceptRoundTripSuggestion(move);
          }
        });
        if (accepted) {
          this.resequenceMoves(plan.moves);
          this.enrichPlan(plan);
          this.reclassMoves(plan.moves);
          this.retimeMoves(plan.moves);

          this.persistPlanChanges(plan);
        }
      });
      return;
    } else if (data.action === "clear-all-suggestions") {
      this.props.plans.forEach((plan) => {
        this.clearPlanSuggestions(plan);
        this.resequenceMoves(plan.moves);
        this.enrichPlan(plan);
        this.reclassMoves(plan.moves);
        this.retimeMoves(plan.moves);
      });
      this.forceUpdate();
      return;
    }
  };

  MoveDraggableDynamicContextMenu = connectMenu(`move-draggable-context-menu`)((props) => {
    const { id, trigger } = props;
    const handleItemClick = trigger ? (trigger.onItemClick ? trigger.onItemClick : this.handleMoveContextClick) : null;
    return (
      <ContextMenu
        id={`move-draggable-context-menu`}
        style={{ filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))" }}
      >
        {trigger && trigger.move.move_type === "ride" && trigger.suggested && (
          <Fragment>
            <MenuItem data={{ action: "accept-ride-suggestion" }} onClick={handleItemClick}>{`Accept`}</MenuItem>
            <MenuItem divider />
          </Fragment>
        )}
        {trigger && trigger.move.move_type === "ride" && !trigger.suggested && (
          <Fragment>
            <MenuItem data={{ action: "move-details" }} onClick={handleItemClick}>
              Move Details
            </MenuItem>
            <MenuItem data={{ action: "delete-return-move" }} onClick={handleItemClick}>
              Delete
            </MenuItem>
            <MenuItem data={{ action: "change-ride-type" }} onClick={handleItemClick}>{`Change Ride Type`}</MenuItem>
            <MenuItem data={{ action: "show-lyft-modal" }} onClick={handleItemClick}>{`Manage Lyft`}</MenuItem>
            <MenuItem divider />
          </Fragment>
        )}
        {trigger && trigger.move && trigger.move.move_type === "ride" && !trigger.suggested && (
          <Fragment>
            <MenuItem
              data={{ action: "share-ride" }}
              onClick={handleItemClick}
              disabled={!trigger.move.lyft_trigger_id}
            >
              Share Ride
            </MenuItem>
            {trigger.move.parent_move && trigger.move.parent_move.sequence && trigger.move.parent_move.sequence > 0 && (
              // If the Lyft is after the drive move, set delivery
              // Otherwise, set the pickup
              <MenuItem
                data={{
                  action: trigger.move.parent_move.sequence < trigger.move.sequence ? "set-destination" : "set-pickup",
                }}
                onClick={handleItemClick}
                disabled={!trigger.move.lyft_trigger_id}
              >
                {trigger.move.parent_move.sequence < trigger.move.sequence ? "Set Destination" : "Set Pickup"}
              </MenuItem>
            )}
          </Fragment>
        )}
        {trigger && trigger.move.move_type === "drive" && !trigger.suggested && (
          <Fragment>
            {trigger.move.pinnable && (
              <Fragment>
                <MenuItem data={{ action: "pinnable" }} onClick={handleItemClick}>
                  Remove Pinnable
                </MenuItem>
                <MenuItem data={{ action: "pinnable-time" }} onClick={handleItemClick}>
                  Set Pinnable Start Time
                </MenuItem>
              </Fragment>
            )}
            {!trigger.move.pinnable && (
              <Fragment>
                <MenuItem data={{ action: "pinnable" }} onClick={handleItemClick}>
                  Pinnable
                </MenuItem>
                <MenuItem disabled={true} data={{ action: "pinnable-time" }} onClick={handleItemClick}>
                  Set Pinnable Start Time
                </MenuItem>
              </Fragment>
            )}
            <MenuItem data={{ action: "move-details" }} onClick={handleItemClick}>
              Move Details
            </MenuItem>
            <MenuItem data={{ action: "edit" }} onClick={handleItemClick}>
              Edit
            </MenuItem>
            <MenuItem data={{ action: "manage-accessorials" }} onClick={handleItemClick}>
              Manage Accessorials
            </MenuItem>
            {trigger.plan.parked_location && (
              <MenuItem
                data={{ action: "add-lyft-ride-to-parked-location" }}
                onClick={handleItemClick}
                disabled={trigger.move.return_ride_id}
              >
                Add Lyft To Parked Car
              </MenuItem>
            )}
            <MenuItem
              data={{ action: "add-lyft-ride" }}
              onClick={handleItemClick}
              disabled={trigger.move.return_ride_id}
            >
              Add Return Lyft Ride
            </MenuItem>
            <MenuItem
              data={{ action: "add-before-lyft-ride" }}
              onClick={handleItemClick}
              disabled={trigger.move.return_ride_id}
            >
              Add Lyft Before Move
            </MenuItem>
            <MenuItem
              data={{ action: "unplan" }}
              onClick={handleItemClick}
              disabled={!(trigger.move.status === null) && !(trigger.move.status === "dispatched")}
            >
              Unplan
            </MenuItem>
            <MenuItem data={{ action: "cancel" }} onClick={handleItemClick}>
              Cancel
            </MenuItem>
            <MenuItem data={{ action: "override-class" }} onClick={handleItemClick}>
              Override Class
            </MenuItem>
            {this.state.useDebugMoveTooltip && (
              <MenuItem data={{ action: "check-driver-pay" }} onClick={handleItemClick}>
                Check Driver Pay
              </MenuItem>
            )}
            <MenuItem
              data={{ action: "lyft-flag" }}
              onClick={handleItemClick}
              disabled={!this.checkIsReturnRideLyft(trigger.move.return_ride_id, trigger.plan.moves)}
            >
              Flag Lyft Trigger
            </MenuItem>
            <MenuItem data={{ action: "track" }} onClick={handleItemClick} disabled={!trigger.move.tracking_link}>
              Track Move
            </MenuItem>
          </Fragment>
        )}
        {trigger && trigger.move.move_type === "drive" && trigger.suggested && (
          <Fragment>
            <MenuItem data={{ action: "accept-round-trip-suggestion" }} onClick={handleItemClick}>
              Accept
            </MenuItem>
          </Fragment>
        )}
        <Fragment>
          <MenuItem data={{ action: "clear-planned-times" }} onClick={handleItemClick}>
            Clear Planned Times
          </MenuItem>
          <MenuItem
            data={{ action: "view-lane" }}
            onClick={() => this.props.history.push(`/map/lanes/${trigger.move.lane_id}`)}
          >
            View Lane
          </MenuItem>
        </Fragment>
      </ContextMenu>
    );
  });

  handleContextMoveId = () => {
    let contextId = this.state.contextMenuActionMoveId;
    if (!contextId) return null;
    if (this.props.plans) {
      try {
        let idInPlans = this.props.plans
          .find((plan) => plan.moves.find((move) => move.id === this.state.contextMenuActionMoveId))
          .moves.find((move) => move.id === this.state.contextMenuActionMoveId);
        return idInPlans;
      } catch (err) {
        // No planned move matches
        try {
          let idInUnplanned = this.props.unplannedMoves.find((o) => o.id === this.state.contextMenuActionMoveId);
          return idInUnplanned;
        } catch (err) {
          // No unplanned move matches
          return null;
        }
      }
    }
  };

  render() {
    const { classes, plans, unplannedMoves, timelineDate, regions } = this.props;
    const {
      timelineScale,
      planHeight,
      planLabelWidth,
      planLabelPad,
      planLabelLeft,
      headerHeight,
      headerTop,
      rightTab,
      drivers,
      selectedRegionId,
    } = this.state;
    //Filter drivers and unplanned moves by region
    //If "All Regions" tab is selected, selectedRegionId till be 0 and drivers and moves will not be filtered
    const regionFilteredUnplannedMoves =
      selectedRegionId === 0
        ? unplannedMoves
        : unplannedMoves.filter(
            (move) =>
              move &&
              move.lane &&
              move.lane.pickup &&
              move.lane.delivery &&
              (move.lane.delivery.region_id || move.lane.pickup.region_id) &&
              (move.lane.pickup.region_id === selectedRegionId || move.lane.delivery.region_id === selectedRegionId)
          );
    const selectedRegion = selectedRegionId === 0 ? 0 : regions.find((region) => region.id === selectedRegionId);
    const regionFilteredDrivers =
      selectedRegionId === 0 ? drivers : drivers.filter((driver) => driver.team_id === selectedRegion.team_id);
    const regionFilteredPlans =
      selectedRegionId === 0 ? plans : plans.filter((plan) => plan.region.id === this.state.selectedRegionId);
    return (
      <Fragment>
        {/* <LyftCallModal
                    open={this.state.showLyftModal}
                    close={this.lyftModalToggle}
                    drivers={this.state.drivers}
                    move={this.state.lyftModalMove}
                    parentDriver={this.state.drivers.find(o => o.fleet_id === (this.state.lyftModalMove ? this.state.lyftModalMove.driver_id : null))}
                /> */}

        {this.state.showLyftModal && this.state.lyftModalMove && (
          <LyftRideModal
            open={this.state.showLyftModal}
            close={this.lyftModalToggle}
            drivers={this.state.drivers}
            move={this.state.lyftModalMove}
            parentDriver={this.state.drivers.find(
              (o) => o.fleet_id === (this.state.lyftModalMove ? this.state.lyftModalMove.driver_id : null)
            )}
          />
        )}
        {this.state.showAccessorialsModal && this.state.contextMenuActionMoveId && (
          <ManageAccessorials
            open={this.state.showAccessorialsModal}
            close={this.handleCloseAccessorialsModal}
            moveId={this.state.contextMenuActionMoveId}
          />
        )}
        <DragDropContext
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >
          <div className={classes.root}>
            {" "}
            {/* Making this scrollable produces "Droppable: unsupported nested scroll container detected." https://github.com/atlassian/react-beautiful-dnd/issues/131 */}
            <div
              id="timeline-action-bar"
              style={{
                position: "absolute",
                marginLeft: "8px",
                left: planLabelLeft + "px",
                top: "-60px",
                zIndex: 1199,
                filter: "drop-shadow(3px 0px 4px rgba(100, 100, 100, .25))",
              }}
              ref={(ref) => (this.actionBarRef = ref)}
            >
              <Box
                key={"timeline-action-bar"}
                width={"100vw"}
                bgcolor="background.paper"
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                height={60}
                alignItems="flex-end"
                border={1}
                borderTop={0}
                borderLeft={0}
                // borderBottom={1} // Now need to set border={1} then zero out the sides you don't want
                // borderRight={1} // Now need to set border={1} then zero out the sides you don't want
                borderColor="grey.300"
                color="grey.500"
                pl={planLabelPad}
                pr={planLabelPad}
              >
                <Box width={150} mb={1} mr={2}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      this.setState({ settingsModalOpen: true });
                    }}
                  >
                    Settings
                    <SettingsApplicationsIcon />
                  </Button>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Box mr={4}>
                    <RegionTabs
                      regions={Array.from(this.props.regions || [])}
                      handleChange={this.regionChange}
                      value={this.state.selectedRegionId}
                      unplannedMoves={unplannedMoves}
                      plans={plans}
                    />
                  </Box>
                  <Box mr={4}>
                    <DatePicker
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      id="timeline-date"
                      value={timelineDate}
                      onChange={this.handleTimelineDateChange}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
            <div
              id="plan-labels-header"
              style={{
                position: "absolute",
                left: planLabelLeft + "px",
                top: "0px",
                zIndex: 1198,
                filter: "drop-shadow(0px 0px 4px rgba(100, 100, 100, .25))",
              }}
              ref={(ref) => (this.planLabelHeaderRef = ref)}
            >
              <ContextMenuTrigger
                id={`plans-header-context-menu`}
                holdToDisplay={1000}
                collect={collect}
                disableIfShiftIsPressed={true}
              >
                <Box
                  key={"plan-label-header"}
                  width={planLabelWidth}
                  bgcolor="background.paper"
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  flexDirection="row"
                  height={headerHeight}
                  border={1}
                  borderTop={0}
                  borderLeft={0}
                  // borderBottom={1} // Now need to set border={1} then zero out the sides you don't want
                  // borderRight={1} // Now need to set border={1} then zero out the sides you don't want
                  borderColor="grey.300"
                  fontSize={24}
                  fontWeight={"bold"}
                  color="grey.500"
                  pl={planLabelPad}
                  pr={planLabelPad}
                >
                  <Box mt={2}>Plans</Box>

                  <Box alignSelf="flex-end">
                    <PopupState variant="popper" popupId="plan-height-popup-popper">
                      {(popupState) => (
                        <div>
                          <IconButton {...bindHover(popupState)}>
                            <FormatLineSpacingIcon />
                          </IconButton>
                          <Popper {...bindPopper(popupState)} transition style={{ zIndex: 1301 }}>
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>
                                <Paper spacing={1}>
                                  <Slider
                                    orientation="vertical"
                                    defaultValue={this.state.planHeight}
                                    getAriaValueText={() => this.state.planHeight}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    style={{ zIndex: 1300, height: "100px" }}
                                    step={1}
                                    marks
                                    min={45}
                                    max={125}
                                    onBlur={popupState.close}
                                    onChangeCommitted={(e, v) => {
                                      this.handlePlanHeightChange(e, v);
                                      popupState.close();
                                    }}
                                  />
                                </Paper>
                              </Fade>
                            )}
                          </Popper>
                        </div>
                      )}
                    </PopupState>
                  </Box>
                  <Box alignSelf="flex-end">
                    <PopupState variant="popper" popupId="timeline-scale-popup-popper">
                      {(popupState) => (
                        <div>
                          <IconButton {...bindHover(popupState)}>
                            <ZoomInIcon />
                          </IconButton>
                          <Popper {...bindPopper(popupState)} transition style={{ zIndex: 1301 }}>
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>
                                <Paper spacing={1}>
                                  <Slider
                                    orientation="vertical"
                                    defaultValue={this.state.timelineScale}
                                    getAriaValueText={() => this.state.timelineScale}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    style={{ zIndex: 1300, height: "100px" }}
                                    step={0.5}
                                    marks
                                    min={0.5}
                                    max={20}
                                    onBlur={popupState.close}
                                    onChangeCommitted={(e, v) => {
                                      this.handleTimelineScaleChange(e, v);
                                      popupState.close();
                                    }}
                                  />
                                </Paper>
                              </Fade>
                            )}
                          </Popper>
                        </div>
                      )}
                    </PopupState>
                  </Box>
                </Box>
              </ContextMenuTrigger>
            </div>
            <div
              id="plan-labels"
              style={{
                position: "absolute",
                left: planLabelLeft + "px",
                top: headerHeight + "px",
                zIndex: 1190,
                filter: "drop-shadow(3px 0px 4px rgba(100, 100, 100, .25))",
              }}
              ref={(ref) => (this.planLabelsRef = ref)}
            >
              {/* Filter plans that are in the selected region */}
              {regionFilteredPlans.map((plan, index) => {
                const tookanDriver =
                  drivers.filter((o) => {
                    return o.fleet_id === plan.driver_id;
                  })[0] || {};
                //if (log) console.log(`Rendering plan #${plan.id} which is ${index} of ${plans.length} plans with driver count ${drivers.length} with status: ${tookanDriver.status}`)

                return (
                  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Driver Profile Box ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                  <ContextMenuTrigger
                    id={`plan-context-menu`}
                    plan={plan}
                    holdToDisplay={1000}
                    collect={collectPlan}
                    disableIfShiftIsPressed={true}
                    key={"plan" + index}
                  >
                    <Tooltip
                      title={
                        <Box display="flex" flexDirection="column" width={"15em"}>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item>Driver Status: </Grid>
                            <Grid item>
                              {tookanDriver
                                ? tookanDriver.status === 2
                                  ? "Busy"
                                  : tookanDriver.status === 1
                                  ? "Inactive"
                                  : "Free"
                                : "No Tookan Data"}
                            </Grid>
                          </Grid>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item>Move Status: </Grid>
                            <Grid item>{this.getCurrentMoveStatus(plan)}</Grid>
                          </Grid>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item>Driver Tags: </Grid>
                            <Grid item>
                              {tookanDriver.tags && tookanDriver.tags.length > 0 ? tookanDriver.tags : "None"}
                            </Grid>
                          </Grid>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item>Total Moves: </Grid>
                            <Grid item>{plan.moves.length}</Grid>
                          </Grid>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item>Completed Moves: </Grid>
                            <Grid item>{plan.moves.filter((m) => m.status === "delivery successful").length}</Grid>
                          </Grid>
                          <Grid item container justify="space-between" xs={12}>
                            <Grid item>Remaining Moves: </Grid>
                            <Grid item>{plan.moves.filter((m) => m.status !== "delivery successful").length}</Grid>
                          </Grid>
                          {plan.parked_location && (
                            <Grid item container justify="space-between" xs={12}>
                              <Grid item>Parked Location: </Grid>
                              <Grid item>{plan.parked_location}</Grid>
                            </Grid>
                          )}
                        </Box>
                      }
                      placement="right"
                      width="fit-content"
                    >
                      <Box
                        key={`PlanLabel${plan.id}`}
                        width={planLabelWidth}
                        pl={planLabelPad}
                        bgcolor={index % 2 === 0 ? "background.paper" : "hsl(180, 20%, 97%)"}
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="row"
                        height={planHeight}
                        alignItems="center"
                        border={1}
                        borderTop={0}
                        borderLeft={0}
                        // borderBottom={1} // Now need to set border={1} then zero out the sides you don't want
                        // borderRight={1} // Now need to set border={1} then zero out the sides you don't want
                        borderColor="grey.300"
                        region={plan.region.id}
                      >
                        <Badge
                          className={classes.margin}
                          color={
                            tookanDriver.status === 0 ? "primary" : tookanDriver.status === 2 ? "error" : "default"
                          }
                          variant="dot"
                        >
                          <Avatar
                            alt={tookanDriver.username}
                            src={tookanDriver.fleet_thumb_image}
                            className={classes.avatar}
                          />
                        </Badge>
                        <Box display="flex" flexDirection="column">
                          <Box color="black" ml={2} mr={2}>
                            <Typography variant="caption">
                              <strong>{`${plan.driver_name} (${plan.id})`}</strong>
                            </Typography>
                          </Box>
                          <Box ml={2} mr={2} display="flex" flexDirection="row" alignItems="center">
                            {
                              <Chip
                                size="small"
                                avatar={this.setStatusAvatar(tookanDriver.status, plan)}
                                label={`${plan.moves.filter((m) => m.status === "delivery successful").length} / ${
                                  plan.moves.filter((m) => m.move_type === "drive").length
                                }`}
                              />
                            }
                            {tookanDriver.tags ? this.setTagIcons(tookanDriver.tags) : null}
                            <Typography style={{ fontSize: "85%", marginLeft: 5 }} color="textSecondary">
                              {plan.region.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Tooltip>
                  </ContextMenuTrigger>
                );
              })}
            </div>
            {
              //If the timeline is not showing current date disable the marker
              Math.abs(
                Number(moment.duration(timelineDate.clone().startOf("day").diff(moment().startOf("day"))).asDays())
              ) < 1 && (
                <TimelineMarker
                  timelineDate={timelineDate}
                  interval={5 * 1000}
                  timelineScale={timelineScale}
                  top={headerTop}
                  width={2}
                  height={regionFilteredPlans.length * planHeight + headerHeight}
                  color={"red"}
                  opacity={0.5}
                  zIndex={1170}
                  startingOffset={this.state.planLabelWidth + this.state.planLabelPad + this.state.planLabelLeft}
                />
              )
            }
            <div
              id="timeline-header"
              style={{
                width: 60 + 40 + 26 * 60 * timelineScale,
                position: "absolute",
                height: headerHeight + "px",
                top: headerTop + "px",
                zIndex: 1160,
                filter: "drop-shadow(0px 3px 4px rgba(100, 100, 100, .25))",
              }}
              ref={(ref) => (this.timelineHeaderRef = ref)}
            >
              <TripTimelineHeader plans={plans} timelineScale={timelineScale} />
            </div>
            <div
              id="plans-timeline"
              style={{
                width: 26 * 60 * this.state.timelineScale,
                top: headerHeight + "px",
                zIndex: 1150,
                position: "absolute",
                left: planLabelWidth + planLabelLeft + "px",
              }}
            >
              {/* re-insert suggestions to prevent props.plans from overwriting them on re-render */}
              {/* Filter plans that are in the selected region */}
              {this.enrichAllPlans(regionFilteredPlans).map((plan, index) => {
                //if (log) console.log(`Rendering plan #${plan.id} which is ${index} of ${plans.length} plans`)
                return (
                  <Plan
                    timelineScale={this.state.timelineScale}
                    planHeight={planHeight}
                    plan={plan}
                    index={index}
                    key={`PlanNum${plan.id}`}
                    handleSelectMove={this.handleSelectMove}
                    selectedMove={this.state.selectedMove}
                    allPlannedMoves={this.props.plans.map((p) => p.moves).flat()}
                    useDebugMoveTooltip={this.state.useDebugMoveTooltip}
                    region={plan.region.id}
                    // style={plan.region.id===1 ? {visibility:"hidden"} : null}
                  />
                );
              })}
            </div>
            <div
              id="right-panel"
              className={classes.rightPanelTabs}
              style={{
                position: "absolute",
                width: "260px",
                right: "-30px",
                top: headerTop * -1 + "px",
                zIndex: 1180,
                filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))",
              }}
              ref={(ref) => (this.rightPanelRef = ref)}
            >
              <Box
                border={1}
                borderTop={0}
                borderLeft={0}
                borderRight={0}
                // borderBottom={1} // Now need to set border={1} then zero out the sides you don't want
                borderColor="grey.300"
              >
                <Button
                  size="small"
                  className={classes.tabButton}
                  color={rightTab === 0 ? "primary" : "default"}
                  onClick={() => {
                    this.setState({ rightTab: 0 });
                  }}
                >
                  Drivers
                  <StyledBadge
                    badgeContent={
                      drivers.filter((o) => {
                        return o.status == 0 || o.status == 2;
                      }).length
                    }
                    color="primary"
                  >
                    <PersonPinIcon />
                  </StyledBadge>
                </Button>

                <Button
                  size="small"
                  className={classes.tabButton}
                  color={rightTab === 1 ? "primary" : "default"}
                  onClick={() => {
                    this.setState({ rightTab: 1 });
                  }}
                >
                  <ContextMenuTrigger
                    id={`unplanned-moves-header-context-menu`}
                    holdToDisplay={1000}
                    collect={collect}
                    disableIfShiftIsPressed={true}
                  >
                    Moves
                    {regionFilteredUnplannedMoves.length > 0 ? (
                      <StyledBadge
                        className={classes.animateJiggle}
                        badgeContent={regionFilteredUnplannedMoves.length}
                        color="primary"
                      >
                        <DirectionsCarIcon />
                      </StyledBadge>
                    ) : (
                      <StyledBadge>
                        <DirectionsCarIcon />
                      </StyledBadge>
                    )}
                  </ContextMenuTrigger>
                </Button>
              </Box>
              {rightTab === 0 && <Drivers classes={classes} drivers={regionFilteredDrivers} interval={2 * 1000} />}
              {rightTab === 1 && <UnplannedMoves moves={regionFilteredUnplannedMoves} />}
            </div>
          </div>
        </DragDropContext>

        <ChangeRideTypeModal
          open={this.state.rideTypeChangeModalOpen}
          onSave={async (rideType) => {
            try {
              this.setState({ rideTypeChangeModalOpen: false });
              MoveHelper.updateMultiple(this.context.apolloClient, [
                {
                  id: this.state.contextMenuActionMoveId,
                  ride_type: rideType,
                },
              ]);
              console.log(`Updated move #${this.state.contextMenuActionMoveId} to ride_type ${rideType}`);
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error changing ride type`);
            }
          }}
          onCancel={() => {
            this.setState({ rideTypeChangeModalOpen: false });
          }}
        />

        <SetParkedLocationModal
          open={this.state.setParkedLocationModalOpen}
          plan={this.state.contextMenuActionPlan}
          apolloClient={this.context.apolloClient}
          onSave={async (parkedLocation) => {
            try {
              this.setState({ setParkedLocationModalOpen: false });
              PlanHelper.updateParkedCarLocation(
                this.context.apolloClient,
                this.state.contextMenuActionPlan.id,
                parkedLocation === undefined ? null : parkedLocation.id
              );
              if (parkedLocation === undefined) {
                // this.context.handleNotifications(true, "success", `Cleared the parked location for ${this.state.contextMenuActionPlan.driver_name}`);
              } else {
                // this.context.handleNotifications(true, "success", `${this.state.contextMenuActionPlan.driver_name}'s car parked at ${parkedLocation.name}`);
              }
            } catch (err) {
              this.context.handleNotifications(true, "error", `Error setting parked location: ${err}`);
            }
          }}
          onCancel={() => {
            this.setState({ setParkedLocationModalOpen: false });
          }}
        />

        <SetPinnableStartTimeModal
          timelineDate={this.props.timelineDate}
          open={this.state.setPinnableStartTimeModalOpen}
          onSave={async (pickupTime) => {
            try {
              this.setState({ setPinnableStartTimeModalOpen: false });
              await MoveHelper.updateMultiple(this.context.apolloClient, [
                {
                  id: this.state.contextMenuActionMoveId,
                  pickup_time: pickupTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                },
              ]).then(async (res) => {
                if (res.data.insert_moves.returning.length > 0) {
                  const parentPlan = this.props.plans.find((p) =>
                    p.moves.find((m) => m.id === this.state.contextMenuActionMoveId)
                  );
                  const parentMove = parentPlan.moves.find((m) => m.id === this.state.contextMenuActionMoveId);
                  await this.retimeMoves(parentPlan.moves);
                  if (log)
                    console.log(
                      "Move retimed to:",
                      moment(parentMove.delivery_time).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    );
                  await MoveHelper.updateMultiple(this.context.apolloClient, [
                    {
                      id: this.state.contextMenuActionMoveId,
                      delivery_time: moment(parentMove.delivery_time).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    },
                  ]);
                }
              });
              if (log)
                console.log(
                  `Updated move #${this.state.contextMenuActionMoveId} with pickup_time ${pickupTime.format(
                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                  )}`
                );
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error setting pinnable start time`);
            }
          }}
          onCancel={() => {
            this.setState({ setPinnableStartTimeModalOpen: false });
          }}
        />

        <DeleteUnplannedMoveModal
          timelineDate={this.props.timelineDate}
          open={this.state.deleteUnplannedMoveModal}
          move_id={this.state.contextMenuActionMoveId}
          onSave={() => {
            try {
              MoveHelper.deleteUnplannedMove(this.context.apolloClient, this.state.contextMenuActionMoveId);
              this.setState({ deleteUnplannedMoveModal: false });
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error deleting unplanned move`);
            }
          }}
          onCancel={() => {
            this.setState({ deleteUnplannedMoveModal: false });
          }}
        />

        <AdjustPriorityModal
          open={this.state.adjustPriorityModalOpen}
          onSave={async (priorityInt) => {
            try {
              this.setState({ adjustPriorityModalOpen: false });
              MoveHelper.updateMultiple(this.context.apolloClient, [
                {
                  id: this.state.contextMenuActionMoveId,
                  priority: priorityInt,
                },
              ]);
              console.log(`Set priority of move #${this.state.contextMenuActionMoveId} to ${priorityInt}`);
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error adjusting priority`);
            }
          }}
          onCancel={() => {
            this.setState({ adjustPriorityModalOpen: false });
          }}
        />

        <OverrideClassModal
          open={this.state.overrideClassModalOpen}
          defaultOption={
            this.state.contextMenuActionMove && this.state.contextMenuActionMove.rate_class_override === 1
              ? this.state.contextMenuActionMove.class
              : "none"
          }
          onSave={async (theClass) => {
            try {
              this.setState({ overrideClassModalOpen: false, contextMenuActionMove: null });
              let persistObj = {
                id: this.state.contextMenuActionMove.id,
                rate_class_override: theClass !== "none" ? 1 : 0,
              };
              if (theClass !== "none") {
                Object.assign(persistObj, {
                  class: theClass,
                });
              }
              MoveHelper.updateMultiple(this.context.apolloClient, [persistObj]);
              console.log(`Updated move #${this.state.contextMenuActionMove.id} to class ${theClass}`);
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error setting override`);
            }
          }}
          onCancel={() => {
            this.setState({ overrideClassModalOpen: false, contextMenuActionMove: null });
          }}
        />

        <AddMoveModal
          open={this.state.addMoveModalOpen}
          onSave={async (move) => {
            try {
              this.setState({ addMoveModalOpen: false });
              console.log(`Creating new move`, move);
              MoveHelper.insertMoves(this.context.apolloClient, [move]);
            } catch (err) {
              this.context.handleNotifications(true, "error", `Error creating new move: ${err}`);
            }
          }}
          onCancel={() => {
            this.setState({ addMoveModalOpen: false });
          }}
          apolloClient={this.context.apolloClient}
          makeInsertable={this.makeInsertable}
        />

        <ClearPlannedTimesModal
          open={this.state.clearPlannedTimesModalOpen}
          onSave={async (cancelStatus) => {
            try {
              this.setState({ clearPlannedTimesModalOpen: false });
              MoveHelper.updateMultiple(this.context.apolloClient, [
                {
                  id: this.state.contextMenuActionMoveId,
                  pickup_time: null,
                  delivery_time: null,
                  updatedat: "now()",
                },
              ]);
              this.retimeMoves(this.state.contextMenuActionPlan.moves);
              // this.context.handleNotifications(true, "success", `Updated move #${this.state.contextMenuActionMoveId} to set pickup and delivery times to null`)
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error changing ride type`);
            }
          }}
          onCancel={() => {
            this.setState({ clearPlannedTimesModalOpen: false });
          }}
        />
        {this.state.contextMenuActionMoveId && this.state.cancelMoveModalOpen && (
          <CancelMoveModal
            open={this.state.cancelMoveModalOpen}
            onSave={async (cancelStatus) => {
              try {
                let data = {
                  id: this.state.contextMenuActionMoveId,
                  cancel_status: cancelStatus,
                  updatedat: "now()",
                };
                if (cancelStatus === "canceled") {
                  // Set ready_by for the partial obj since it is expected when unplanning a move, even due to cancellations
                  Object.assign(data, {
                    payable: false,
                    chargeable: false,
                    status: "canceled",
                    ready_by: this.state.contextMenuActionMove.ready_by,
                  });
                } else if (cancelStatus === "delivered") {
                  Object.assign(data, { payable: true, chargeable: true });
                } else if (cancelStatus === "started") {
                  Object.assign(data, { payable: true, chargeable: true });
                }
                MoveHelper.updateMultiple(this.context.apolloClient, [data]);

                console.log(`Updated move #${this.state.contextMenuActionMoveId} to cancel_status ${cancelStatus}`);

                //Rob 8/12 - Automatically unplan a canceled move
                if (cancelStatus === "canceled") {
                  try {
                    if (this.state.contextMenuActionMove.plan_id != null) {
                      await MoveHelper.unplanMove(this.context.apolloClient, data);
                      //   Tookan.handleMoveUnplan(
                      //     this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"],
                      //     this.context.apolloClient,
                      //     this.state.contextMenuActionMove
                      //   );
                      // driver-app functionality here
                      this.resequenceMoves(this.state.contextMenuActionPlan.moves);
                      this.enrichPlan(this.state.contextMenuActionPlan);
                      this.reclassMoves(this.state.contextMenuActionPlan.moves);
                      this.retimeMoves(this.state.contextMenuActionPlan.moves);
                    }
                  } catch (error) {
                    console.log(`Error trying to automatically unplan the canceled move.`, error);
                  }
                }

                this.setState({ contextMenuActionMoveId: null, cancelMoveModalOpen: false });
              } catch (err) {
                console.error(err);
                this.context.handleNotifications(true, "error", `Error setting cancel status`);
              }
            }}
            onCancel={() => {
              this.setState({ contextMenuActionMoveId: null, cancelMoveModalOpen: false });
            }}
            move={
              this.state.contextMenuActionMoveId && this.state.cancelMoveModalOpen ? this.handleContextMoveId() : null
            }
          />
        )}

        <SetDestinationModal
          open={this.state.setDestinationModalOpen}
          onSave={async (chosenDestination, originalPickup, customerId) => {
            try {
              let foundLane = await LaneHelper.getLaneByLocations(
                this.context.apolloClient,
                originalPickup.id,
                chosenDestination.id
              );
              if (foundLane == null) {
                //this is in case the inverse of the drive's lane hasn't been created yet
                let newLane = await this.createNewLane(customerId, originalPickup, chosenDestination);
                foundLane = newLane;
              }
              // find/create lane from destination
              this.setState({ setDestinationModalOpen: false });
              MoveHelper.updateMultiple(this.context.apolloClient, [
                {
                  id: this.state.contextMenuActionMoveId,
                  lane_id: foundLane.id,
                  updatedat: "now()",
                },
              ]).then((res) => {
                let ridesPlan = this.props.plans.find((plan) => plan.id === res.data.insert_moves.returning[0].plan_id);
                // It looks like at some point this mutation changed and returns two responses, adding a catch to try both
                if (!ridesPlan)
                  ridesPlan = this.props.plans.find(
                    (plan) => plan.id === res.data.insert_moves.returning[0][0].plan_id
                  );
                ridesPlan.changed = true;
                this.enrichPlan(ridesPlan);
                this.retimeMoves(ridesPlan.moves);
                this.persistPlanChanges(ridesPlan);
              });
              log &&
                console.log(
                  `Updated move #${this.state.contextMenuActionMoveId} to use lane ${foundLane.description} to go to ${chosenDestination.name}`
                );
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error changing ride lane`);
            }
          }}
          onCancel={() => {
            this.setState({ setDestinationModalOpen: false });
          }}
          rideLane={this.state.contextMenuActionMoveLane}
        />

        <SetPickupModal
          open={this.state.setPickupModalOpen}
          onSave={async (chosenPickup, originalDestination, customerId) => {
            try {
              let foundLane = await LaneHelper.getLaneByLocations(
                this.context.apolloClient,
                chosenPickup.id,
                originalDestination.id
              );
              if (foundLane == null) {
                //this is in case the inverse of the drive's lane hasn't been created yet
                let newLane = await this.createNewLane(customerId, chosenPickup, originalDestination);
                foundLane = newLane;
              }
              console.log(" --- foundLane", foundLane);
              // find/create lane from destination
              this.setState({ setPickupModalOpen: false });
              await MoveHelper.updateMultiple(this.context.apolloClient, [
                {
                  id: this.state.contextMenuActionMoveId,
                  lane_id: foundLane.id,
                  updatedat: "now()",
                },
              ]).then(async (res) => {
                let ridesPlan = this.props.plans.find((plan) => plan.id === res.data.insert_moves.returning[0].plan_id);
                // It looks like at some point this mutation changed and returns two responses, adding a catch to try both
                if (!ridesPlan)
                  ridesPlan = this.props.plans.find(
                    (plan) => plan.id === res.data.insert_moves.returning[0][0].plan_id
                  );
                ridesPlan.changed = true;
                this.enrichPlan(ridesPlan);
                this.retimeMoves(ridesPlan.moves);
                this.persistPlanChanges(ridesPlan);
              });
              log &&
                console.log(
                  `Updated move #${this.state.contextMenuActionMoveId} to use lane ${foundLane.description} to start at ${chosenPickup.name}`
                );
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error changing ride lane`);
            }
          }}
          onCancel={() => {
            this.setState({ setPickupModalOpen: false });
          }}
          rideLane={this.state.contextMenuActionMoveLane}
        />

        <ShareRideModal
          // selected moves defaults to those rides currently sharing
          options={this.state.rideShareOptions.map((m) => {
            return { id: m.id, lyft_trigger_id: m.lyft_trigger_id, driver_name: m.driver_name };
          })}
          selected={this.state.rideShareOptions
            .filter((m) => m.lyft_trigger_id === m.currentShareGroupId)
            .map((m) => {
              return { id: m.id, lyft_trigger_id: m.lyft_trigger_id, driver_name: m.driver_name };
            })}
          open={this.state.shareRideModalOpen}
          actionTargetId={this.state.contextMenuActionMoveId}
          onSave={async (selected) => {
            try {
              // TODO: add conditional notification
              // this.context.handleNotifications(true, "success", `Rides ${selected.map(m => m.id).join(", ")}  are now sharing a lyft`);
              // console.log(`Rides ${selected.map(m=>m.id).join(", ")} are now sharing a lyft from {selected[0].lane.description}`);
              let selectedIds = selected.map((s) => s.id);
              let drivesToUnflag = this.props.plans
                .map((p) => p.moves)
                .flat()
                .filter(
                  (m) =>
                    m.move_type === "drive" &&
                    selectedIds.includes(m.return_ride_id) &&
                    m.id !== this.state.rideShareOptions[0].currentShareGroupId
                )
                .map((m) => (m.lyft_flag = 0));
              selected.concat(drivesToUnflag);
              this.setState({ shareRideModalOpen: false });
              MoveHelper.updateMultiple(this.context.apolloClient, selected);
            } catch (err) {
              console.error(err);
              this.context.handleNotifications(true, "error", `Error setting shared ride`);
            }
          }}
          onCancel={() => {
            this.setState({ shareRideModalOpen: false, rideShareOptions: [] });
          }}
        />

        <SettingsModal
          open={this.state.settingsModalOpen}
          onSave={async (settings) => {
            try {
              this.setState({
                settingsModalOpen: false,
                useDebugMoveTooltip: settings.useDebugMoveTooltip,
              });
              log = settings.generalLoggingEnabled;
              driverLog = settings.driverLoggingEnabled;
              enrichPlanLog = settings.enrichPlanLoggingEnabled;
            } catch (err) {
              console.log(err);
            }
          }}
          onCancel={() => {
            this.setState({ settingsModalOpen: false });
          }}
          useDebugMoveTooltip={this.state.useDebugMoveTooltip}
          generalLoggingEnabled={log}
          driverLoggingEnabled={driverLog}
          enrichPlanLoggingEnabled={enrichPlanLog}
        />

        <this.MoveDraggableDynamicContextMenu />

        <ContextMenu
          id={`unplanned-moves-header-context-menu`}
          style={{ filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))" }}
        >
          <MenuItem data={{ action: "add-move" }} onClick={this.handleUnplannedMovesHeaderContextClick}>
            Add Move
          </MenuItem>
        </ContextMenu>

        <ContextMenu
          id={`unplanned-move-draggable-context-menu`}
          style={{ filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))" }}
        >
          <MenuItem data={{ action: "move-details" }} onClick={this.handleUnplannedMoveContextClick}>
            Move Details
          </MenuItem>
          <MenuItem data={{ action: "edit-move" }} onClick={this.handleUnplannedMoveContextClick}>
            Edit
          </MenuItem>
          <MenuItem data={{ action: "adjust-priority" }} onClick={this.handleUnplannedMoveContextClick}>
            Adjust Priority
          </MenuItem>
          <MenuItem data={{ action: "manage-accessorials" }} onClick={this.handleUnplannedMoveContextClick}>
            Manage Accessorials
          </MenuItem>
          <MenuItem data={{ action: "view-lane" }} onClick={this.handleUnplannedMoveContextClick}>
            View Lane
          </MenuItem>
          <MenuItem data={{ action: "edit-pickup" }} onClick={this.handleUnplannedMoveContextClick}>
            Edit Pickup
          </MenuItem>
          <MenuItem data={{ action: "edit-delivery" }} onClick={this.handleUnplannedMoveContextClick}>
            Edit Delivery
          </MenuItem>
          <MenuItem data={{ action: "cancel" }} onClick={this.handleUnplannedMoveContextClick}>
            Cancel
          </MenuItem>
          <MenuItem data={{ action: "delete-unplanned-move" }} onClick={this.handleUnplannedMoveContextClick}>
            Delete
          </MenuItem>
        </ContextMenu>

        <ContextMenu
          id={`driver-context-menu`}
          style={{ filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))" }}
        >
          <MenuItem data={{ action: "add-plan" }} onClick={this.handleDriverContextClick}>
            Add Plan
          </MenuItem>
        </ContextMenu>

        <ContextMenu id={`plan-context-menu`} style={{ filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))" }}>
          <MenuItem data={{ action: "set-parked-location" }} onClick={this.handlePlanContextClick}>
            Set Parked Location
          </MenuItem>
          <MenuItem divider />
          <MenuItem data={{ action: "suggest-return-rides" }} onClick={this.handlePlanContextClick}>
            Suggest Return Rides
          </MenuItem>
          <MenuItem data={{ action: "suggest-milk-runs" }} onClick={this.handlePlanContextClick}>
            Suggest Milk Runs
          </MenuItem>
          <MenuItem data={{ action: "suggest-round-trips" }} onClick={this.handlePlanContextClick}>
            Suggest Round Trips
          </MenuItem>
          <MenuItem divider />
          <MenuItem data={{ action: "clear-suggestions" }} onClick={this.handlePlanContextClick}>
            Clear Suggestions
          </MenuItem>
          <MenuItem data={{ action: "accept-all-suggested-return-rides" }} onClick={this.handlePlanContextClick}>
            Accept All Suggested Return Rides
          </MenuItem>
          <MenuItem data={{ action: "save-all" }} onClick={this.handlePlanContextClick}>
            Save All
          </MenuItem>
          <MenuItem data={{ action: "sync-with-tookan" }} onClick={this.handlePlanContextClick}>
            Sync with Tookan
          </MenuItem>
        </ContextMenu>

        <ContextMenu
          id={`plans-header-context-menu`}
          style={{ filter: "drop-shadow(-3px 0px 4px rgba(100, 100, 100, .25))" }}
        >
          <MenuItem data={{ action: "set-parked-location" }} onClick={this.handlePlanHeaderContextClick}>
            Set Parked Location
          </MenuItem>
          <MenuItem divider />
          <MenuItem data={{ action: "sync-all-moves" }} onClick={this.handlePlanHeaderContextClick}>
            Sync all plans to Tookan
          </MenuItem>
          <MenuItem data={{ action: "accept-all-suggestions" }} onClick={this.handlePlanHeaderContextClick}>
            Accept all Suggested Moves
          </MenuItem>
          <MenuItem data={{ action: "clear-all-suggestions" }} onClick={this.handlePlanHeaderContextClick}>
            Clear all Suggested Moves
          </MenuItem>
        </ContextMenu>
      </Fragment>
    );
  }
}

class ChangeRideTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "lyft",
      name: "type",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { open } = this.props;

    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="change-ride-type-modal">
        <DialogTitle id="change-ride-type-modal">Change Ride Type</DialogTitle>
        <DialogContent>
          <DialogContentText>Select one of the valid ride types from the drop down below</DialogContentText>
          <FormControl style={{ margin: 2, minWidth: 250 }}>
            <InputLabel htmlFor="ride-type">Ride Type</InputLabel>
            <Select
              value={this.state.type}
              onChange={this.handleChange}
              inputProps={{
                name: "type",
                id: "ride-type",
              }}
            >
              <MenuItem value={"lyft"}>Lyft</MenuItem>
              <MenuItem value={"sat"}>Social Auto</MenuItem>
              <MenuItem value={"split"}>Split</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state.type);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

let DeleteUnplannedMoveModal = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="delete-unplanned-move-modal">
      <DialogTitle id="delete-unplanned-move-modal-title">Delete Unplanned Move</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete move {props.move_id}?<br />
          <i>cannot be undone</i>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={props.onSave} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class SetParkedLocationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationId: 0, //0 is used on purpose here to differentiate between first load and all after
      location: {},
    };
  }

  handleChange = async (event) => {
    this.setState({
      locationId: event.target.value,
      location: await LaneHelper.getLocation(this.props.apolloClient, event.target.value),
    });
  };

  uniqBy = (arr, predicate) => {
    const cb = typeof predicate === "function" ? predicate : (o) => o[predicate];

    return [
      ...arr
        .reduce((map, item) => {
          const key = item === null || item === undefined ? item : cb(item);

          map.has(key) || map.set(key, item);

          return map;
        }, new Map())
        .values(),
    ];
  };

  render() {
    const { open, plan } = this.props;

    let uniqueLocations = [];
    if (open) {
      if (plan && plan.moves && plan.moves.length > 0) {
        let locations = [];
        plan.moves.map((move) => {
          locations.push(move.lane.pickup);
          locations.push(move.lane.delivery);
        });
        uniqueLocations = this.uniqBy(locations, (o) => o.id);
      }
    }

    return (
      <Dialog open={open || false} onClose={this.props.onCancel} aria-labelledby="set-parked-location-modal">
        <DialogTitle id="set-parked-location-modal">Set Parked Car Location</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set the location of the driver's parked car.
            <br />
            <i>This location is limited to locations on the moves.</i>
          </DialogContentText>

          <FormControl style={{ margin: 2, width: "100%" }}>
            <InputLabel htmlFor="locationId">Parked Location</InputLabel>
            <Select
              value={this.state.locationId === 0 && plan ? plan.parked_location : this.state.locationId}
              onChange={this.handleChange}
              inputProps={{
                name: "locationId",
                id: "locationId",
              }}
            >
              <MenuItem value={null} key={"remove"}>
                <Typography variant="button" component="p" gutterBottom>
                  Remove
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Unset parked car location
                </Typography>
              </MenuItem>
              {uniqueLocations &&
                uniqueLocations.length > 0 &&
                uniqueLocations.map((location) => (
                  <MenuItem value={location.id} key={location.id}>
                    <Typography variant="button" component="p" gutterBottom>
                      {location.name}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {location.address}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state.location);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class SetPinnableStartTimeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupDate: moment(this.props.timelineDate),
    };
  }

  handleChange = (event) => {
    this.setState({ pickupDate: event });
  };

  render() {
    const { open } = this.props;

    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="set-pinnable-start-time-modal">
        <DialogTitle id="set-pinnable-start-time-modal">Set Pinnable Start Time</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set the start time of the pinnable move
            <br />
            <i>This can be set by dragging on the timeline as well</i>
          </DialogContentText>
          <FormControl style={{ margin: 2, minWidth: 250 }}>
            <DateTimePicker
              value={this.state.pickupDate}
              disablePast
              onChange={this.handleChange}
              label="Planned Pickup Time"
              showTodayButton
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state.pickupDate);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class AdjustPriorityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priority: null,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { open } = this.props;

    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="adjust-priority-modal">
        <DialogTitle id="change-ride-type-modal">Adjust Priority</DialogTitle>
        <DialogContent>
          <DialogContentText>Select new priority for move</DialogContentText>
          <FormControl style={{ margin: 2, minWidth: 250 }}>
            <TextField
              select
              name="priority"
              label={"Priority"}
              style={{
                width: "auto",
                minWidth: "300px",
              }}
              value={this.state.priority}
              onChange={this.handleInputChange}
              margin="normal"
              variant="outlined"
            >
              {[
                { num: 1, name: "Higest Priority" },
                { num: 2 },
                { num: 3 },
                { num: 4 },
                { num: 5, name: "Medium Prioriy" },
                { num: 6 },
                { num: 7 },
                { num: 8 },
                { num: 9, name: "Low Priority" },
              ].map((priority) => (
                <MenuItem key={"priority" + priority.num} value={priority.num}>
                  {priority.num}
                  {priority.name ? " - " + priority.name : ""}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state.priority);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class OverrideClassModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      class: this.props.defaultOption,
      name: "class",
      isOpen: false,
    };
  }
  //Set menu to current override status when modal is open, unset it on modal close
  componentDidUpdate = () => {
    if (this.props.open && !this.state.isOpen) {
      this.setState({ class: this.props.defaultOption, isOpen: true });
    }
    if (!this.props.open && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { open } = this.props;

    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="override-class-modal">
        <DialogTitle id="override-class-modal">Override Class</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Override the class with one of the options below. Setting the class here will prevent the timeline from
            automatically changing it.
          </DialogContentText>
          <FormControl style={{ margin: 2, minWidth: 250 }}>
            <InputLabel htmlFor="rate-class">Class</InputLabel>
            <Select
              value={this.state.class}
              onChange={this.handleChange}
              inputProps={{
                name: "class",
                id: "rate-class",
              }}
            >
              <MenuItem value={"none"}>No Override</MenuItem>
              <MenuItem value={"base"}>Base</MenuItem>
              <MenuItem value={"stranded"}>Stranded</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state.class);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class LyftCallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerPhone: "",
    };
  }

  handleChange = (e) => {
    console.log(e);
    console.log(e.target.value);
    this.setState({ passengerPhone: e.target.value });
  };

  filterDrivers = () => {
    // Filter the array of drivers passed in from Timeline state to only those whose fleet_id matches a driver in the return rides
    return this.props.drivers.filter((o) => {
      for (let move of this.props.move.movesByLyftTriggerId) {
        if (o.fleet_id === move.driver_id) return Object.assign(o, { move_id: move.parent_move.id });
      }
    });
  };

  resendLyftText = async () => {
    // Create an array of phone numbers and parent move IDs for all drivers sharing the Lyft ride
    // If an override number is entered, use the entered # along with the parent move ID of the ride clicked on
    const parentMoves = this.state.passengerPhone
      ? [{ phone: this.state.passengerPhone, id: this.props.move.parent_move.id }]
      : this.filterDrivers().map((o) => ({ phone: o.phone, id: o.move_id }));
    // Trigger a Lyft ride SMS for each number in the driverPhones array
    parentMoves.forEach(async (move) => {
      try {
        await axios({
          url: `/.netlify/functions/handleLyftURL`,
          method: "post",
          data: {
            id: move.id,
            phone: move.phone,
          },
        }).then((res) => {
          if (log) console.log("SMS sent to driver:", res);
          // this.context.handleNotifications(true, "success", `SMS sent to all drivers sharing this ride`);
          this.props.close(null);
        });
      } catch (err) {
        if (log) console.log("SMS failed to send:", err);
        this.context.handleNotifications(true, "error", `Error sending SMS: ${err}`);
      }
    });
  };

  manualLyftCall = () => {
    try {
      this.context.apolloClient
        .mutate({
          mutation: gql`
            mutation newLyftRide($id: bigint, $phone: String) {
              insert_lyftrides(
                objects: { move_id: $id, passenger_phone: $phone }
                on_conflict: { constraint: lyftrides_move_id_key, update_columns: last_attempt }
              ) {
                returning {
                  move_id
                  passenger_phone
                }
              }
            }
          `,
          variables: {
            id: this.props.move.lyft_trigger_id,
            phone:
              this.state.passengerPhone && this.state.passengerPhone.trim().length() > 0
                ? this.state.passengerPhone
                : null,
          },
          onError: this.context.handleNotifications(true, "error", `Manual Lyft trigger failed`),
        })
        .then((res) => {
          if (log) console.log("New lyftrides insertion:", res);
          // this.context.handleNotifications(true, "success", "Lyft call manually triggered");
          this.props.close(null);
        });
    } catch (err) {
      if (log) console.log("Manual Lyft trigger failed:", err);
      this.context.handleNotifications(true, "error", `Manual Lyft trigger failed: ${err}`);
    }
  };

  render() {
    const { open, close } = this.props;

    return (
      <Dialog open={open} onClose={() => close(null)} aria-labelledby="lyft-call-modal">
        <DialogTitle id="override-class-modal">Lyft Call Management</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Resend the Lyft ride SMS to all drivers sharing this ride or manually trigger the call. If a driver cannot
            be reached by phone or there is no number on record, enter a specific number to send the Lyft ride to. If a
            phone number is entered, the SMS will only be sent to this number.
            {!this.state.passengerPhone.match("(^[0-9]+$|^$)") ? (
              <b style={{ color: "red" }}> Do not use any spaces, dashes, or other characters (eg: 8009137674).</b>
            ) : (
              " Do not use any spaces, dashes, or other characters (eg: 8009137674)."
            )}
          </DialogContentText>
          <FormControl style={{ margin: 2, minWidth: 250 }}>
            <TextField
              // style={{marginLeft: theme.spacing(1),marginRight: theme.spacing(1),width: 200}}
              error={!this.state.passengerPhone.match("(^[0-9]+$|^$)")}
              id="phone-number-override"
              label="Phone number override"
              placeholder="8009137674"
              InputProps={{
                startAdornment: <InputAdornment position="start">{"+1"}</InputAdornment>,
              }}
              value={this.state.passengerPhone}
              onChange={this.handleChange}
              margin="normal"
              helperText={
                this.props.parentDriver
                  ? `${this.props.parentDriver.name}: ${this.props.parentDriver.phone}`
                  : "No phone on record"
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this.resendLyftText}
            disabled={!this.state.passengerPhone.match("(^[0-9]+$|^$)")}
          >
            Send SMS
          </Button>
          <Button
            color="primary"
            onClick={this.manualLyftCall}
            disabled={!this.state.passengerPhone.match("(^[0-9]+$|^$)")}
          >
            Manual call
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
LyftCallModal.contextType = GlobalContext;

class AddMoveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parser: "carmax-email",
      moveInput: "",
      shipmentId: "",
      tags: [],
    };
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAdd = async () => {
    if (log) console.log("AddMoveModal:handleAdd building new move");
    let move = MoveHelper.buildNewMoveObject();
    switch (this.state.parser) {
      case "carmax-email":
        move = await this.parseCarmaxEmailString(this.state.moveInput, move, this.state.shipmentId);
        if (log) console.log("AddMoveModal:handleAdd move built from email: ", move);
        this.props.onSave(move);
        break;
      case "carmax-otm":
        this.parseCarmaxOTMString(this.state.moveInput, move);
        this.props.onSave(move);
        break;
      default:
        if (log) console.log("AddMoveModal:handleAdd unknown parser");
        this.props.onCancel();
        break;
    }
    this.setState({ moveInput: "", shipmentId: "" });
  };
  getCarMaxInfo = async (stock, move) => {
    if (stock.length < 8) {
      return null;
    }
    try {
      await axios({
        url: `/.netlify/functions/carmax?stock=${stock}`,
        //url: `http://www.whateverorigin.org/get?url=https://api.carmax.com/v1/api/vehicles/${stock}`,
        method: "get",
      }).then((res) => {
        if (log) console.log("CarMax vehicle info: ", res.data);
        return res.data;
      });
    } catch (err) {
      if (log) console.error("Error getting carmax vehicle info:", err.toString());
      return null;
    }
  };
  parseCarmaxEmailString = async (input, move, shipmentId) => {
    /*
        String format
            0:  VIN
            1:  Stock #
            2:  Vehicle
            3:  Pickup Stop
            4:  Dropoff Stop
        Example
            KNAGM4A79F5607528	17196545	2015 KIA OPTIMA	6055CARMAX - HARRISONBURG	7101CARMAX - RICHMOND
            
        */
    const carmaxCustomerId = 2;
    const inputSplit = input.split("\t");
    const vehicleSplit = inputSplit[2].split(" ");
    if (log) console.log("AddMoveModal:handleAdd parsing carmax email string", input, inputSplit, vehicleSplit);
    move.vehicle_vin = inputSplit[0];
    move.vehicle_stock = inputSplit[1];
    move.vehicle_year = vehicleSplit[0];
    move.vehicle_make = vehicleSplit[1].toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    move.vehicle_model = vehicleSplit[2].toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    move.customer_id = carmaxCustomerId;
    move.pickup_time = null;
    move.delivery_time = null;
    move.ready_by = "now()";
    move.deliver_by = null;
    move.tags = this.state.tags.join();
    move.reference_num = shipmentId;
    //Default the store numbers
    let pickup = 0;
    let dropoff = 0;
    //Get the pickup store number
    var pickupNumbers = inputSplit[3].match(/[\-0-9]+/g);
    if (pickupNumbers.length > 0) {
      pickup = Number(pickupNumbers[0]);
    }
    //Get the delivery store number
    var dropoffNumbers = inputSplit[4].match(/[\-0-9]+/g);
    if (dropoffNumbers.length > 0) {
      dropoff = Number(dropoffNumbers[0]);
    }
    const carmaxInfo = await this.getCarMaxInfo(move.vehicle_stock);
    if (carmaxInfo != null) {
      console.log("Carmax info returned from API: ", carmaxInfo);
      pickup = Number(carmaxInfo.Store.Id);
      move.vehicle_vin = carmaxInfo.Vin;
      move.vehicle_stock = carmaxInfo.StockNumber;
      move.vehicle_year = carmaxInfo.Year;
      move.vehicle_make = carmaxInfo.Make;
      move.vehicle_model = carmaxInfo.Model;
      move.vehicle_color = carmaxInfo.ExteriorColor;
      move.manual_flag = carmaxInfo.Transmission !== "Automatic";
      move.vehicle_image = `https://img2.carmax.com/img/vehicles/${move.vehicle_stock}/1.jpg?width=128`;
      move.move_details = `${carmaxInfo.Miles}, ${carmaxInfo.Body}, ${carmaxInfo.Drivetrain}, ${
        carmaxInfo.Cylinders
      } Cylinder, ${carmaxInfo.Engine}, ${carmaxInfo.NewTireCount ? `${carmaxInfo.NewTireCount} New Tires, ` : ""}${
        carmaxInfo.FeatureHighlights
      }`;
      move.reference_num = shipmentId;
    }
    //Attempt to identify the lane using the store numbers and a fuzzy match on location names
    if (pickup > 0 && dropoff > 0) {
      const lane = await LaneHelper.getLaneByFuzzyLocationNamesAndCustomerId(
        this.props.apolloClient,
        `%${pickup}%`,
        `%${dropoff}%`,
        carmaxCustomerId
      );
      if (lane != null) {
        console.log("Found a matching lane by carmax location numbers", lane);
        move.lane_id = lane.id;
        move.lane = lane;
      }
    }
    if (log) console.log("Finished building move from CarMax email string. Now making move insertable ", move);
    move = await this.props.makeInsertable(move);
    if (log) console.log("Finished making move insertable: ", move);
    return move;
  };
  parseCarmaxOTMString = (input, move) => {
    if (log) console.log("AddMoveModal:handleAdd parsing carmax OTM export string");
  };
  handleAddTag = (tag) => {
    if (log) console.log("AddMoveModal:handleAddTag Adding tag to move: ", tag, this.state.tags);
    this.setState({
      tags: [...this.state.tags, tag],
    });
  };
  handleDeleteTag = (tag) => {
    if (log) console.log("AddMoveModal:handleDeleteTag Deleting tag from move: ", tag, this.state.tags);
    this.setState({
      tags: this.state.tags.filter((c) => c !== tag),
    });
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="add-move-modal">
        <DialogTitle id="add-move-modal">Add Move Shortcut</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off" style={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl style={{ margin: 2, width: "100%" }}>
              <TextField
                id="shipment-id"
                name="shipmentId"
                label="Shipment ID"
                value={this.state.shipmentId}
                onChange={this.handleChange}
                margin="normal"
              />
            </FormControl>
            <FormControl style={{ margin: 2, width: "100%" }}>
              <TextField
                multiline
                rows={4}
                id="move-input"
                name="moveInput"
                label="Paste move string here"
                value={this.state.moveInput}
                onChange={this.handleChange}
                margin="normal"
              />
            </FormControl>
            <FormControl style={{ margin: 2, width: "100%" }}>
              <InputLabel htmlFor="parser">Parser</InputLabel>
              <Select
                value={this.state.parser}
                onChange={this.handleChange}
                inputProps={{
                  name: "parser",
                  id: "parser",
                }}
              >
                <MenuItem value={"carmax-email"}>CarMax Email</MenuItem>
                <MenuItem value={"carmax-otm"}>CarMax OTM</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <ChipInput
                variant={"outlined"}
                value={this.state.tags}
                onAdd={(tag) => this.handleAddTag(tag)}
                onDelete={(tag, index) => this.handleDeleteTag(tag, index)}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
// class CancelMoveModal extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             status: props.move.cancel_status,
//             name: 'status',
//             index: 0,
//             customerCharge: {
//                 id: '',
//                 code: '',
//                 cost: 0,
//                 customer: 0,
//                 driver: 0,
//                 notes: '',
//             },
//             driverPay: {
//                 id: '',
//                 code: '',
//                 cost: 0,
//                 driver: 0,
//                 notes: '',
//             },

//         }
//     }
//     componentWillReceiveProps = newProps => {
//         if (JSON.stringify(this.props.move) === JSON.stringify(newProps.move)) return;
//         else this.setState({ status: newProps.move.cancel_status, index: 0 })
//     }
//     handleChange = (event) => {
//         console.log("props", this.props)
//         this.setState({ [event.target.name]: event.target.value })
//     }
//     handleDialogContent = () => {
//         switch (this.state.status) {
//             case "started":
//                 return (
//                     <>
//                         <DialogContentText id="alert-dialog-description">
//                             The cancellation status of this move will be set to "started". It has been partially worked and may require additional charges to the customer or partial driver pay. Please add any additions as accessorials below:
//                         </DialogContentText>
//                         <Subscription
//                             subscription={getAccessorials('subscription')}
//                             variables={{ id: this.props.move.id }}
//                             onError={err => this.context.handleNotifications(true, "error", "Query failed to retrieve moves data: " + err.toString())}>
//                             {({ ...result }) => {
//                                 if (result.loading) return <Loading />;
//                                 if (result.error) return `Error! ${result.error.message}`;
//                                 return (
//                                     <>
//                                         <DialogContentText id="alert-dialog-description">
//                                             Does the customer need to be charged?
//                                         </DialogContentText>
//                                         <Grid container spacing={1} direction="row" justify="center" alignItems="center">
//                                             <Grid item xs={3}>
//                                                 <Select
//                                                     value={this.state.customerCharge.code}
//                                                     displayEmpty
//                                                     name="code"
//                                                 >
//                                                     {
//                                                         (process.env.REACT_APP_ACC_CODES || '').split(',').map(code => <MenuItem key={`${code}-menuitem`} value={code}>{code}</MenuItem>)
//                                                     }
//                                                 </Select>
//                                             </Grid>
//                                         </Grid>
//                                         <DialogContentText id="alert-dialog-description">
//                                             Does the driver need to be paid?
//                                         </DialogContentText>

//                                     </>
//                                 )
//                             }}
//                         </Subscription>
//                     </>
//                 )
//                 break;
//             case "canceled":
//                 return (
//                     <>
//                         <DialogContentText id="alert-dialog-description">
//                             The cancellation status of this move will be set to "canceled". It will be removed from the list unplanned moves and will not appear on any driver pay or customer invoices.
//                         </DialogContentText>
//                     </>
//                 )
//                 break;
//             case "delivered":
//                 return (
//                     <>
//                         <DialogContentText id="alert-dialog-description">
//                             The cancellation status of this move will be set to "delivered". It is unable to be canceled due to being already delivered or being too far along in the move process to stop. Nothing on the move will change and driver pay and customer invoices will be unaffected.
//                         </DialogContentText>
//                     </>
//                 )
//                 break;
//             default:
//                 return null
//         }
//     }

//     handleButtons = () => {
//         if (this.state.index === 0) {
//             if (["pending", "seen"].indexOf(this.state.status) >= 0) {
//                 return (
//                     <>
//                         <Button onClick={this.props.onCancel} color="primary">
//                             Close
//                         </Button>
//                         <Button onClick={() => { this.props.onSave(this.state.status) }} color="primary">
//                             Save
//                         </Button>
//                     </>
//                 )
//             } else return (
//                 <>
//                     <Button onClick={this.props.onCancel} color="primary">
//                         Close
//                     </Button>
//                     <Button onClick={() => { this.setState({ index: 1 }) }} color="primary">
//                         Next
//                     </Button>
//                 </>
//             )
//         } else return (
//             <>
//                 <Button onClick={this.props.onCancel} color="primary">
//                     Close
//                 </Button>
//                 <Button onClick={() => { this.setState({ index: 0 }) }} color="primary">
//                     Back
//                 </Button>
//                 <Button onClick={() => { this.props.onSave(this.state.status) }} color="primary">
//                     Save
//                 </Button>
//             </>
//         )
//     }

//     handleClose = () => {
//         this.props.onCancel();
//         this.setState({ index: 0 });
//     }

//     render() {
//         const { open } = this.props
//         return (
//             <Dialog open={open} onClose={this.handleClose} aria-labelledby="change-cancel-status-modal">
//                 <DialogTitle id="change-cancel-status-modal">Change Cancel Status</DialogTitle>
//                 <DialogContent>
//                     {
//                         this.state.index === 0 ? (
//                             <>
//                                 <DialogContentText id="alert-dialog-description">
//                                     Set the status of a move cancelation request from a customer.
//                                     Sometimes a move is already in progress and cannot be canceled.
//                                     <br />
//                                     <br />
//                                     <span style={{ fontSize: ".8em" }}>
//                                         <strong>Pending</strong> - The cancelation request that has not yet been seen by a dispatcher<br />
//                                         <strong>Seen</strong> - Acknowledged by the dispatcher so it can be removed from any dispatcher notifications<br />
//                                         <strong>Started</strong> - The dispatcher was able to tell the driver to return the vehicle to the pickup location<br />
//                                         <strong>Canceled</strong> - The dispatcher was able to cancel the move before it was worked<br />
//                                         <strong>Delivered</strong> - The dispatcher was NOT able to cancel the move as it was already delivered
//                                      </span>
//                                 </DialogContentText>
//                                 <FormControl style={{ margin: 2, minWidth: 250 }}>
//                                     <InputLabel htmlFor="cancel-status">Cancel Status</InputLabel>
//                                     <Select
//                                         value={this.state.status}
//                                         onChange={this.handleChange}
//                                         inputProps={{
//                                             name: 'status',
//                                             id: 'cancel-status',
//                                         }}
//                                     >
//                                         <MenuItem value={'pending'}>Pending</MenuItem>
//                                         <MenuItem value={'seen'}>Seen</MenuItem>
//                                         <MenuItem value={'started'}>Started</MenuItem>
//                                         <MenuItem value={'canceled'}>Canceled</MenuItem>
//                                         <MenuItem value={'delivered'}>Delivered</MenuItem>
//                                     </Select>
//                                 </FormControl>
//                             </>
//                         ) : this.handleDialogContent()
//                     }
//                 </DialogContent>
//                 <DialogActions>
//                     {this.handleButtons()}
//                 </DialogActions>
//             </Dialog>
//         )
//     }
// };
class SetDestinationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      destinationId: 0,
    };
  }
  handleChange = (name, value) => (event) => {
    this.setState({ [name]: value === undefined ? event.target.value : value });
  };
  handleSelectChange = (name) => (event) => {
    this.setState({ [name]: event ? event.label : "", destinationId: event.value });
  };
  handleEnter = () => {
    // this.setState({ location: {'label':`${this.props.rideLane.delivery.name} [${this.props.rideLane.customer.name}] ${this.props.rideLane.delivery.address}`, 'value':this.props.rideLane.delivery.id} })
    this.setState({ destinationId: this.props.rideLane.delivery.id });
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onEnter={this.handleEnter}
        onClose={this.props.onCancel}
        aria-labelledby="change-ride-lane-modal"
      >
        <DialogTitle id="change-ride-lane-modal">Set Lyft Destination</DialogTitle>
        <DialogContent style={{ height: "50vh" }}>
          <Query
            query={GET_ALL_LOCATIONS}
            onError={(err) => {
              console.error(err);
              this.context.handleNotifications(true, "error", "Query failed to retrieve location data");
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return "loading...";
              return (
                <FormControl style={{ margin: 2, minWidth: 250 }}>
                  {/* <span>Current Destination Id: {this.state.destinationId}</span> */}
                  <ReactSelectAutocomplete
                    valueOptions={data.locations.map((l) => {
                      return { label: `${l.name} [${l.customer.name}] ${l.address}`, value: l.id };
                    })}
                    onChange={this.handleChange}
                    onSelectChange={this.handleSelectChange}
                    location={this.state.location}
                    label="Set Location"
                    name="location"
                    placeholder="Destination"
                    long={true}
                  ></ReactSelectAutocomplete>
                </FormControl>
              );
            }}
          </Query>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(
                {
                  name: this.state.location.split(" [")[0],
                  address: this.state.location.split("] ")[0],
                  id: this.state.destinationId,
                },
                this.props.rideLane.pickup,
                this.props.rideLane.customer_id
              );
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class SetPickupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      pickupId: 0,
    };
  }
  handleChange = (name, value) => (event) => {
    if (!event) return;
    this.setState({ [name]: value === undefined ? event.target.value : value });
  };
  handleSelectChange = (name) => (event) => {
    if (!event) return;
    this.setState({ [name]: event ? event.label : "", pickupId: event.value });
  };
  handleEnter = () => {
    // this.setState({ location: {'label':`${this.props.rideLane.delivery.name} [${this.props.rideLane.customer.name}] ${this.props.rideLane.delivery.address}`, 'value':this.props.rideLane.delivery.id} })
    this.setState({ pickupId: this.props.rideLane.delivery.id });
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onEnter={this.handleEnter}
        onClose={this.props.onCancel}
        aria-labelledby="change-ride-lane-modal-pickup"
      >
        <DialogTitle id="change-ride-lane-modal-pickup">Set Lyft Pickup</DialogTitle>
        <DialogContent style={{ height: "50vh" }}>
          <Query
            query={GET_ALL_LOCATIONS}
            onError={(err) => {
              console.error(err);
              this.context.handleNotifications(true, "error", "Query failed to retrieve location data");
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return "loading...";
              return (
                <FormControl style={{ margin: 2, minWidth: 250 }}>
                  {/* <span>Current Pickup Id: {this.state.pickupId}</span> */}
                  <ReactSelectAutocomplete
                    valueOptions={data.locations.map((l) => {
                      return { label: `${l.name} [${l.customer.name}] ${l.address}`, value: l.id };
                    })}
                    onChange={this.handleChange}
                    onSelectChange={this.handleSelectChange}
                    location={this.state.location}
                    label="Set Location"
                    name="location"
                    placeholder="Pickup"
                    long={true}
                  ></ReactSelectAutocomplete>
                </FormControl>
              );
            }}
          </Query>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(
                {
                  name: this.state.location.split(" [")[0],
                  address: this.state.location.split("] ")[0],
                  id: this.state.pickupId,
                },
                this.props.rideLane.delivery,
                this.props.rideLane.customer_id
              );
              this.setState({ location: "", pickupId: 0 });
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
class ClearPlannedTimesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { open } = this.props;
    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="clear-planned-times-modal">
        <DialogTitle id="clear-planned-times-modal">Clear Planned Times</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the planned pickup and delivery times (set them to null)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave();
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
class SettingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useDebugMoveTooltip: this.props.useDebugMoveTooltip || false,
      generalLoggingEnabled: this.props.generalLoggingEnabled || false,
      driverLoggingEnabled: this.props.driverLoggingEnabled || false,
      enrichPlanLoggingEnabled: this.props.enrichPlanLoggingEnabled || false,
    };
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog open={open} onClose={this.props.onCancel} aria-labelledby="settings-modal">
        <DialogTitle id="settings-modal">Settings</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Customize the settings of the plans view</DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.useDebugMoveTooltip}
                  onChange={this.handleChange("useDebugMoveTooltip")}
                  value="useDebugMoveTooltip"
                  margin="primary"
                />
              }
              label="Use debug move tooltip"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.generalLoggingEnabled}
                  onChange={this.handleChange("generalLoggingEnabled")}
                  value="generalLoggingEnabled"
                  margin="primary"
                />
              }
              label="Enable general logging"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.driverLoggingEnabled}
                  onChange={this.handleChange("driverLoggingEnabled")}
                  value="driverLoggingEnabled"
                  margin="primary"
                />
              }
              label="Enable driver logging"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.enrichPlanLoggingEnabled}
                  onChange={this.handleChange("enrichPlanLoggingEnabled")}
                  value="enrichPlanLoggingEnabled"
                  margin="primary"
                />
              }
              label="Enable plan enrichment logs"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onSave(this.state);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
class ShareRideModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      _isEntered: false,
    };
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    const arr = this.state.selected.slice();
    const changedRide = arr
      .filter((o) => o.id === name)
      .map((o) => {
        return { id: o.id, lyft_trigger_id: event.target.checked ? this.state.triggerId : null };
      });
    const index = arr.findIndex((o) => name === o.id);
    const value = this.props.options.find((o) => name === o.id);
    if (index > -1) {
      arr.splice(index, 1, changedRide[0]);
      this.setState({ selected: arr });
    } else {
      arr.push(value);
      this.setState({ selected: arr });
    }
  };
  handleEnter = () => {
    this.props.options
      .map((m) => m.id)
      .forEach((id) => {
        if (this.props.selected.map((m) => m.id).includes(id)) {
          this.setState({ [id]: true });
        } else {
          this.setState({ [id]: false });
        }
      });
    this.setState({
      selected: this.props.selected,
      triggerId: this.props.selected[0].lyft_trigger_id,
      _isEntered: true,
    });
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog open={open} onEnter={this.handleEnter} aria-labelledby="change-cancel-status-modal">
        <DialogTitle id="change-cancel-status-modal">Create Share Ride Group</DialogTitle>
        <DialogContent>
          <FormGroup>
            {this.state._isEntered &&
              this.props.options.map((o) => (
                <FormControlLabel
                  key={`shareOption${o.id}`}
                  label={`Move: ${o.id} (${o.driver_name})`}
                  control={
                    <Checkbox
                      checked={this.state[o.id]}
                      onChange={this.handleChange(o.id)}
                      value={o.id}
                      // disabled={(this.props.actionTargetId === o.id)}
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                  }
                />
              ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ _isEntered: false });
              this.props.onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.setState({ _isEntered: false });
              this.props.onSave(this.state.selected);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
class TimelineMarker extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      currentTimeMarkerPosition: {},
      currentDisplayTime: "",
    };
    this.currentTimeMarkerRef = null;
  }
  handleScroll = (event) => {
    if (event.target.tagName === "MAIN") {
      this.currentTimeMarkerDiv.style.top = event.target.scrollTop - this.props.top + "px";
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.currentTimeMarkerDiv = findDOMNode(this.currentTimeMarkerRef);
    window.addEventListener("scroll", this.handleScroll, true);
    this.timerID = setInterval(() => this.updateTimeMarkerPosition(), this.props.interval || 60000);
    this.setState();
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.timerID);
  }
  updateTimeMarkerPosition = () => {
    if (this._isMounted) {
      const now = moment();
      const minSinceMidnight = moment
        .duration(now.diff(this.props.timelineDate.clone().startOf("day")))
        .asMinutes()
        .toFixed(0);
      this.setState({
        currentTimeMarkerPosition: minSinceMidnight * this.props.timelineScale,
        displayTime: now.format("LT"),
      });
      //if (log) console.log(`Updating current time marker position`, {minSinceMidnight, xPos: (minSinceMidnight * this.props.timelineScale)})
    } else {
      //if (log) console.log('Skipped marker update because _isMounted == false')
    }
  };
  render() {
    const { top, width, height, color, opacity, zIndex, startingOffset } = this.props;
    const { currentTimeMarkerPosition, displayTime } = this.state;
    return (
      <Fragment>
        <div
          id="current-time-marker"
          style={{
            position: "absolute",
            height: `${height}px`,
            zIndex: zIndex,
            left: `${startingOffset + currentTimeMarkerPosition}px`,
            top: `${top}px`,
            width: `${width}px`,
            backgroundColor: color,
            filter: `opacity(${opacity})`,
          }}
        />
        <div
          id="current-time-marker-label"
          style={{
            position: "absolute",
            height: `30px`,
            zIndex: zIndex,
            left: `${startingOffset + currentTimeMarkerPosition + 10}px`,
            top: `${top}px`,
            //width: `100px`,
            color: color,
            filter: `opacity(${opacity})`,
            whiteSpace: "nowrap",
          }}
          ref={(ref) => (this.currentTimeMarkerRef = ref)}
        >
          <Typography variant="caption" display="block" gutterBottom>
            {displayTime}
          </Typography>
        </div>
      </Fragment>
    );
  }
}
class Drivers extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {};
    this.timerID = null;
  }
  componentDidMount = async () => {
    this._isMounted = true;
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { classes, drivers } = this.props;
    return (
      <div style={{ height: "100vh", minHeight: "100vh", overflow: "auto" }}>
        <List
          component="div"
          aria-label="Drivers"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {
                drivers.filter((o) => {
                  return o.status == 0;
                }).length
              }{" "}
              Free
            </ListSubheader>
          }
        >
          {drivers
            .filter((o) => {
              return o.status == 0;
            })
            .map((driver, index) => (
              <Fragment key={driver.fleet_id}>
                <ContextMenuTrigger
                  id={`driver-context-menu`}
                  driver={driver}
                  holdToDisplay={1000}
                  collect={collect}
                  disableIfShiftIsPressed={true}
                >
                  <ListItem button key={driver.fleet_id}>
                    <ListItemAvatar>
                      <Badge className={classes.margin} color="primary" variant="dot">
                        <Avatar alt={driver.username} src={driver.fleet_thumb_image} className={classes.avatar} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText primary={driver.username} secondary={driver.last_updated_location_time} />
                  </ListItem>
                </ContextMenuTrigger>
                <Divider light />
              </Fragment>
            ))}
        </List>
        <List
          component="div"
          aria-label="Drivers"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {
                drivers.filter((o) => {
                  return o.status == 2;
                }).length
              }{" "}
              Busy
            </ListSubheader>
          }
        >
          {drivers
            .filter((o) => {
              return o.status == 2;
            })
            .map((driver, index) => (
              <Fragment key={driver.fleet_id}>
                <ContextMenuTrigger
                  id={`driver-context-menu`}
                  driver={driver}
                  holdToDisplay={1000}
                  collect={collect}
                  disableIfShiftIsPressed={true}
                >
                  <ListItem button key={driver.fleet_id}>
                    <ListItemAvatar>
                      <Badge className={classes.margin} color="error" variant="dot">
                        <Avatar alt={driver.username} src={driver.fleet_thumb_image} className={classes.avatar} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText primary={driver.username} secondary={driver.last_updated_location_time} />
                  </ListItem>
                </ContextMenuTrigger>
                <Divider light />
              </Fragment>
            ))}
        </List>
        <List
          component="div"
          aria-label="Drivers"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {
                drivers.filter((o) => {
                  return o.status == 1;
                }).length
              }{" "}
              Inactive
            </ListSubheader>
          }
        >
          <Divider />
          {drivers
            .filter((o) => {
              return o.status == 1;
            })
            .map((driver, index) => (
              <Fragment key={driver.fleet_id}>
                <ContextMenuTrigger
                  id={`driver-context-menu`}
                  driver={driver}
                  holdToDisplay={1000}
                  collect={collect}
                  disableIfShiftIsPressed={true}
                >
                  <ListItem button key={driver.fleet_id}>
                    <ListItemAvatar>
                      <Avatar alt={driver.username} src={driver.fleet_thumb_image || ""} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText primary={`${driver.username}`} secondary={driver.last_updated_location_time} />
                  </ListItem>
                </ContextMenuTrigger>
                <Divider light />
              </Fragment>
            ))}
        </List>
      </div>
    );
  }
}
Timeline.propTypes = {
  classes: PropTypes.object.isRequired,
};
Timeline.contextType = GlobalContext;
export default withRouter(withStyles(styles)(Timeline));
