import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../utils/Loading';
import APReportFilter from './APReport/APReportFilter';
import APReportFooter from './APReport/APReportFooter';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = true;

const cap = (str) => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
}

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  if (localRange) return localRange;
  else return `week`;
}
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  const localStart = localStorage.getItem(`apreport-start`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return moment.utc(moment().startOf(`day`).subtract(1, `week`)).format();
}
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  const localEnd = localStorage.getItem(`apreport-end`);
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return moment.utc(moment().startOf(`day`)).format();
}
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
}

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

////////// COMPONENT //////////
function APReport(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [status, setStatus] = useState(null);
  const localDriverId = parseInt(localStorage.getItem(`driverId`));
  const [driverId, setDriverId] = useState(localDriverId || null);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  useEffect(() => {
    localStorage.setItem(`driverId`, driverId)
  }, [driverId])

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`apreport-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(1, value)).format());
      setEnd(moment.utc(moment().startOf(`day`)).format());
    }
    else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  }

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`apreport-start`, newDate);
      setStart(newDate);
    }
    else {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`apreport-end`, newDate);
      setEnd(newDate);
    }
  }

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.move.driver_name && o.move.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.id && (o.move.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.move_type && o.move.move_type.toLocaleLowerCase().includes(search))
        ) {
          return true;
        }
        else return false;
      })
    }
  }

  const generateCSV = (appayments) => {
    const createCsvRow = (appayment) => {
      return {
        ID: appayment.id,
        DRIVER: appayment.move.driver_name || `SAT Driver`,
        MOVE_ID: appayment.move.id,
        DATE: moment(appayment.move ? appayment.move.pickup_time : appayment.createdat).format(`MM/DD/YYYY`),
        TYPE: appayment.type && appayment.move.move_type ? cap(`${appayment.move.move_type} - ${appayment.type}${appayment.type === `accessorial` ? `: ${appayment.accessorial.code}` : ``}`) : `-`,
        STATUS: appayment.status ? cap(appayment.status) : `-`,
        TOTAL: appayment.amount ? `$${appayment.amount.toFixed(2)}` : (appayment.amount === 0 ? `$0.00` : `-`),
      }
    }
    const csvRows = appayments.map(appayment => createCsvRow(appayment));
    const csvOptions = {
      filename: `${driverId ? appayments[0].move.driver_name.replace(/ /g, "_") : `All`}_AP_Records_from_${start}_to_${end}`,
      showTitle: true,
      title: `${driverId ? appayments[0].move.driver_name : `All`} AP Records from ${start} to ${end}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const goToMoveDetails = (moveId) => {
    props.history.push(`/moves/${moveId}`);
  }

  const getTableActions = (appayments) => {
    return [
      { label: `Generate\xa0CSV`, handler: () => generateCSV(appayments) },
    ];
  }
  const getRowActions = (appayment) => {
    return [
      { label: `View\xa0Move\xa0Details`, handler: () => goToMoveDetails(appayment.move.id) },
    ];
  }

  return (<>
    <div className={cls.root}>
      { ctx && ctx.userIsAuthenticated() && (
        <Container maxWidth="lg">
          <Typography className={cls.headTxt}>AP Report Index</Typography>
          
          <APReportFilter
            status={status}
            driverId={driverId}
            range={range}
            start={start}
            end={end}
            onStatusChange={setStatus}
            onDriverChange={setDriverId}
            onRangeChange={handleRangeChange}
            onDateChange={handleDateChange}
            disablePickers={disablePickers}
          />

          <div className={cls.break} />
          <Query query={GET_APPAYMENTS} variables={{ status: status, driverId: driverId, start: start, end: end }} onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve appayments: ${err.toString()}`)}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <Loading fixed />
              if (error) {
                console.log(`Failed to retrieve appayments:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING AP RECORDS</Typography>
                  </div>
                )
              }
              if (data && data.appayments && data.appayments.length > 0) {
                // log && console.log(`APPayments for APReport Index:`, data.appayments);
                const filteredAP = applyFilters(data.appayments);

                // Set a consistent amount object that holds the totals
                var amount = {};

                // Valid moves to calculate base totals
                const subtotalPayments = filteredAP.filter(item => item.amount > 0);
                const paidPayments = subtotalPayments.filter(item => item.status === `paid`);

                // Base totals from valid moves
                amount.subtotal = round(subtotalPayments.length > 0 ? subtotalPayments.map(item => item.amount).reduce((total, current) => total + current) : 0, 2);
                amount.paid = round(paidPayments.length > 0 ? paidPayments.map(item => item.amount).reduce((total, current) => total + current) : 0, 2);

                // Set calculations from base totals
                amount.total = checkNeg(amount.subtotal);
                amount.unpaid = checkNeg(amount.total - amount.paid);

                // log && console.log(`AP Amount:`, amount);

                const headers = [
                  { id: `ID`, alignLeft: true, numeric: true, label: `ID` },
                  { id: `DRIVER`, alignLeft: true, numeric: false, label: `Driver` },
                  { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
                  { id: `DATE`, alignLeft: false, numeric: true, label: `Date` },
                  { id: `TYPE`, alignLeft: true, numeric: false, label: `Type` },
                  { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
                  { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                ]
                const rows = filteredAP.map(appayment => {
                  return {
                    ID: appayment.id,
                    DRIVER: appayment.move.driver_name || `SAT Driver`,
                    MOVE_ID: appayment.move.id,
                    DATE: moment(appayment.move ? appayment.move.pickup_time : appayment.createdat).format(`MM/DD/YYYY`),
                    TYPE: appayment.type && appayment.move.move_type ? cap(`${appayment.move.move_type} - ${appayment.type}${appayment.type === `accessorial` ? `: ${appayment.accessorial.code}` : ``}`) : `-`,
                    STATUS: appayment.status ? cap(appayment.status) : `-`,
                    TOTAL: appayment.amount ? `$${appayment.amount.toFixed(2)}` : (appayment.amount === 0 ? `$0.00` : `-`),
                    appayment: appayment
                  }
                })

                return (<>
                  <AccordianTable
                    title={`${rows.length} AP Records`}
                    headers={headers}
                    rows={rows}
                    actions={getTableActions(data.appayments)}
                    search={search}
                    defaultOrder={defaultOrder}
                    defaultOrderBy={defaultOrderBy}
                    order={order}
                    orderBy={orderBy}
                    tablePage={tablePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setTablePage={setTablePage}
                    setRowsPerPage={setRowsPerPage}
                    setExpandedRowId={setExpandedRowId}
                    className={cls.table}
                    refetch={refetch}
                    refreshPersistAs="ar_report"
                  >
                    {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <AccordianRow
                          key={`ap-record-${row.appayment.id}`}
                          rowId={row.appayment.id}
                          expandedRowId={expandedRowId}
                          setExpandedRowId={setExpandedRowId}
                          columns={[
                            { align: 'left', value: row.ID },
                            { align: 'left', value: row.DRIVER },
                            { align: 'left', value: row.MOVE_ID },
                            { align: 'right', value: row.DATE },
                            { align: 'left', value: row.TYPE },
                            { align: 'left', value: row.STATUS },
                            { align: 'right', value: row.TOTAL },
                          ]}
                          actions={getRowActions(row.appayment)}
                          onClick={() => goToMoveDetails(row.MOVE_ID)}
                          className={cls.row}
                        >
                          <div />
                        </AccordianRow>
                      ))}
                  </AccordianTable>
                  <APReportFooter amount={amount} />
                </>)
              }
              else return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>NO AP RECORDS FOUND</Typography>
                </div>
              )
            }}
          </Query>
        </Container>)
      }
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

////////// GRAPHQL //////////
const GET_APPAYMENTS = gql`
query get_armoves($status: String, $driverId: bigint, $start: timestamptz!, $end: timestamptz!) {
  appayments(
    where: {
      move: {
        active: {_eq: 1}
        pickup_time: {_gte: $start, _lte: $end}
      }
      status: {_eq: $status}
      driver_id: {_eq: $driverId}
    }
    order_by: {id: desc}
   ) {
    id
    driver_id
    move_id
    status
    amount
    createdat
    type
    accessorial {
      id
      code
    }
    move {
      id
      pickup_time
      active
      move_type
      driver_name
    }
  }
}
`;

////////// EXPORT //////////
export default withRouter(APReport);