import 'date-fns';
import React, { Component } from "react";
import LaneForm from '../pages/laneEntry/LaneForm'
// import gql from "graphql-tag";
// import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { GlobalContext } from '../../global-context';


const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
})

const LaneHelpers = require('sat-lane-manager/lanes');

class LaneEntry extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        { this.context && this.context.userIsAuthenticated() && (
          <Container maxWidth="lg">
            <LaneForm
              formType={this.props.location.pathname.includes('add') ? "add" : "edit"}
              laneId={this.props.location.pathname.includes('add') ? null : this.props.match.params.id}
              laneHelpers={new LaneHelpers(
                `https://${process.env.REACT_APP_GQL_SD}.herokuapp.com/v1/graphql`,
                this.context.userProfile["https://api_keys.io/jwt/claims"]["GQLSecret"],
                this.context.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"])}
            >
            </LaneForm>
          </Container>)
        }
      </div>
    )
  }
}

LaneEntry.propTypes = {
  classes: PropTypes.object.isRequired,
};
LaneEntry.contextType = GlobalContext;

export default withStyles(styles)(LaneEntry);