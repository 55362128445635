import React, { useState, useContext } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import fragments from '../../utils/graphQL/fragments'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import MarkAsPaidModal from './MarkAsPaidModal';

const log = false

export default function MarkAsPaidButton({ payPeriod, refetch }) {
    const ctx = useContext(GlobalContext);
    const cls = useStyles();

    const [open, setOpen] = useState(false)

    const handleClick = event => {
      setOpen(true)
    }

    return (
        <Query query={GET_APPAYMENTS} variables={{ driverId: payPeriod.driver_id, start: payPeriod.pickup_time_start , end: payPeriod.pickup_time_end  }}>
            {({ loading, error, data, refetch }) => {
                return (<>
                    {data && data.appayments && data.appayments.length > 0 &&
                      <MarkAsPaidModal
                        open={open}
                        setOpen={setOpen}
                        appayments={data.appayments}
                      />
                    }
                    <Button disabled={payPeriod.status === 'paid' || loading || error || open || (data && data.appayments && data.appayments.length == 0)} size="small" variant="contained" color="primary" onClick={handleClick}>Mark as Paid</Button>
                </>)
            }}
        </Query>
    )
}


const useStyles = makeStyles(theme => ({
    notFound: {
        padding: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    notFoundTxt: {
        color: theme.palette.text.secondary,
        lineHeight: 1.25,
        textAlign: 'center',
        fontSize: '21px',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
        },
    },
}));

const GET_APPAYMENTS = gql`
query get_unpaid_payments_for_mark_as_paid_button($driverId: bigint, $start: timestamptz, $end: timestamptz) {
  appayments(where: {
    move: {
      active: {_eq: 1},
      pickup_time: {_gte: $start, _lte: $end},
      _or: [{cancel_status: {_neq: "canceled"}}, {cancel_status: {_is_null: true}}]
    },
    active: {_eq: 1},
    status: {_eq: "unpaid"},
    driver_id: {_eq: $driverId},
  }, order_by: {move: {pickup_time: desc}}) {
    ...APPayment
    move {
      ...Move
    }
    accessorial {
      ...Accessorials
    }
  }
}
${fragments.move}
${fragments.appayment}
${fragments.accessorials}
`;