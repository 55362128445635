import 'date-fns';
import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import fragments from '../utils/graphQL/fragments';
import { GlobalContext } from '../../global-context';

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  grid: {
    width: '60%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dlPairs: {
    margin: '1em 0',
    borderBottom: '1px solid rgb(231, 231, 231)',
  }
})

const GET_REGION = gql`
  query lookup_region($id: bigint!) {
    regions(
      where: {
          id: {
            _eq: $id
          }
      },
      order_by: {
        id: asc
      }
    ) {
      ...Region
    }
  }
  ${fragments.region}
`;

class RegionDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { classes, match } = this.props;
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          { this.context && this.context.userIsAuthenticated() && (
            <Query query={GET_REGION} variables={{ id: match.params.id }} onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve region data")}}>
              {({ loading, error, data }) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                const region = data.regions[0]
                return (
                  <React.Fragment>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} >
                        <Card className="">
                          <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              <Link to="/regions">
                                {`<`}
                              </Link>{" "}
                              <strong>{region.name}</strong>{" "}
                              <small className="text-secondary">
                                {region.id}
                              </small>
                            </Typography>
                            <Typography variant="body2" component="div">
                              <Grid container>
                                <Grid item md={12}><h6>ID</h6>
                                  <p>{region.id}</p>
                                </Grid>
                                <Grid item md={12}><h6>Name</h6>
                                  <p>{region.name}</p>
                                </Grid>
                                <Grid item md={12}><h6>Description</h6>
                                  <p>{region.description}</p>
                                </Grid>
                                <Grid item md={12}><h6>Team ID</h6>
                                  <p>{region.team_id}</p>
                                </Grid>
                              </Grid>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12} />
                    </Grid>
                  </React.Fragment>
                );
              }}
            </Query>)
          }
        </Container>
      </div>
    )
  }
}

RegionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

RegionDetails.contextType = GlobalContext

export default withStyles(styles)(RegionDetails);