import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import MoveTile from '../../reusable/MoveTile';

////////// COMPONENT //////////
export default function MoveDetailsRelated(props) {
  const cls = useStyles();
  const { move } = props;

  let relatedMoves = move && move.plan && move.plan.moves && move.plan.moves.length > 0 ? move.plan.moves.filter(m => m.id !== move.id) : [];

  return (<>
    {relatedMoves && relatedMoves.length > 0 ? <>
      <Grid container justify="center" alignItems="flex-start" spacing={2}>
        {relatedMoves.map(relatedMove =>
          <Grid key={`move-tile-${relatedMove.id}`} item xs className={cls.tile}>
            <MoveTile move={relatedMove} />
          </Grid>
        )}
      </Grid>
    </> :
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>NO RELATED MOVES FOUND</Typography>
      </div>}
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '420px',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '320px',
      maxWidth: '360px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));