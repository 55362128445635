import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, InputLabel, OutlinedInput, InputAdornment, Grid, DialogTitle, DialogContent, DialogContentText, FormControl, FormLabel, FormGroup, FormControlLabel, CircularProgress, Dialog, DialogActions, Button, IconButton, Icon, Tooltip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { GlobalContext } from "../../../global-context";

let log = false;

export default function RateRuleFormModal ({
    open = false,
    rateRule = null,
    onSave = unsavedRateRule => {},
    onCancel = () => {}
}) {
    const ctx = useContext(GlobalContext);
    const cls = useStyles();

    const [unsavedRateRule, setUnsavedRateRule] = useState(rateRule)
    const [hasChanges, setHasChanges] = useState(false)

    useEffect(() => {
        log && console.log('Inbound rate rule prop set to: ', rateRule)
        if (!rateRule) return
        setUnsavedRateRule(rateRule)
    }, [rateRule])

    useEffect(() => {
        if (!unsavedRateRule) return
        log && console.log('Unsaved rate rule changed to: ', unsavedRateRule)
    }, [unsavedRateRule])

    const handleInputChange = name => event => {
        setHasChanges(true)
        setUnsavedRateRule({ ...unsavedRateRule, [name]: event.target.value });
    };

    const handleButtonGroupChange = name => (event, value) => {
        setHasChanges(true)
        setUnsavedRateRule({ ...unsavedRateRule, [name]: value });
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="rate-rule-form-modal"
        >
            <DialogTitle id="rate-rule-form-modal">Rate Rule Editor</DialogTitle>
            <DialogContent dividers>
                {unsavedRateRule && <>
                    <DialogContentText>
                        Update the pricing for this rate rule
                    </DialogContentText>
                    <FormControl>
                        <FormGroup>
                            <Grid container spacing={4}>
                                <Grid item md={4}>
                                    <FormControl fullWidth className={cls.margin} variant="outlined">
                                        <InputLabel htmlFor="distance-start">Start Mileage</InputLabel>
                                        <OutlinedInput
                                            id="distance-start"
                                            value={unsavedRateRule.distance_start || 0}
                                            onChange={handleInputChange('distance_start')}
                                            endAdornment={<InputAdornment position="end">mi</InputAdornment>}
                                            labelWidth={100}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth className={cls.margin} variant="outlined">
                                        <InputLabel htmlFor="distance-end">End Mileage</InputLabel>
                                        <OutlinedInput
                                            id="distance-end"
                                            value={unsavedRateRule.distance_end || 0}
                                            onChange={handleInputChange('distance_end')}
                                            endAdornment={<InputAdornment position="end">mi</InputAdornment>}
                                            labelWidth={90}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth className={cls.margin} variant="outlined">
                                        <InputLabel htmlFor="rate">Rate</InputLabel>
                                        <OutlinedInput
                                            id="rate"
                                            value={unsavedRateRule.rate || 0}
                                            onChange={handleInputChange('rate')}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            labelWidth={50}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormGroup>
                        <FormGroup>
                            <Grid container spacing={4} style={{marginTop: '10px'}}>
                                <Grid item md={12}>
                                    <ToggleButtonGroup
                                    value={unsavedRateRule.type || 'per'}
                                    onChange={handleButtonGroupChange('type')}
                                    aria-label="type"
                                    exclusive
                                    >
                                        <ToggleButton value="per" aria-label="per mile">
                                            Per Mile
                                        </ToggleButton>
                                        <ToggleButton value="flat" aria-label="flat rate">
                                            Flat Rate
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item md={12}>
                                    <ToggleButtonGroup
                                    value={unsavedRateRule.class || 'base'}
                                    onChange={handleButtonGroupChange('class')}
                                    aria-label="class"
                                    exclusive
                                    >
                                        <ToggleButton value="stranded" aria-label="Concierge (Stranded)">
                                            Concierge (Stranded)
                                        </ToggleButton>
                                        <ToggleButton value="base" aria-label="Round Trip (base)">
                                            Round Trip (base)
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </>}
                {!unsavedRateRule && <>
                    <DialogContentText>
                        No rate rule provided to edit
                    </DialogContentText>
                </>}
            </DialogContent>
            <DialogActions>
            <Button onClick={onCancel} color="primary">
                Cancel
            </Button>
            <Button onClick={() => {onSave(unsavedRateRule)}} color="primary" autoFocus>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    )
};

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      padding: theme.spacing(2),
      height: '100%'
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 600,
    },
    margin: {
      margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(2),
        marginLeft: theme.spacing(5)
    },
    refreshBtn: {
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.secondary.veryLight
        }
    },
}));