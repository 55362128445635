import React from 'react';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core/';
import { Query } from "react-apollo";
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';

const GET_REGIONS = gql`
  query {
    regions(order_by: {id: asc}) {
      ...Region
    }
  }
  ${fragments.region}
`;

export default function RegionSelect({
    value,
    name="Region",
    onChange=() => {},
    placeholder='Select a region...',
    label='Region',
    required=false,
    allowAny=false,
    fullWidth=true,
    variant="outlined",
    margin="dense",
    style={
      width: '100%',
      margin: 0,
    },
    InputProps={
      startAdornment: (
        <InputAdornment style={{ verticalAlign: "top" }} position="start">
          <Icon color="disabled" fontSize="small">public</Icon>
        </InputAdornment>)
    }
  }) {
    return (
      <Query query={GET_REGIONS} onError={err => console.error("Query failed to retrieve regions for region select: " + err.toString())}>
        {({ loading, error, data }) => {
          return (
            <TextField
              select
              fullWidth={fullWidth}
              required={required}
              name={name}
              label={label}
              placeholder={loading ? 'Loading...' : placeholder}
              variant={variant}
              margin={margin}
              value={value}
              onChange={onChange}
              style={style}
              InputProps={InputProps}
            >
              {allowAny ?
                <MenuItem key={"Any Regions"} value={0}>Any Region</MenuItem> :
                <MenuItem key={"Select a Region"} value={0}><em>Select a Region</em></MenuItem>
              }
              {loading &&
                <MenuItem key={"Loading..."} value={0}>Loading...</MenuItem>
              }
              {error &&
                <MenuItem key={"Error fetching regions"} value={0}>Error fetching regions</MenuItem>
              }
              {data && data.regions && data.regions.map(region => (
                <MenuItem key={region.name} value={region.id}>
                  {region.id} - {region.name}
                </MenuItem>
              ))}
            </TextField>
          )
        }}
      </Query>
    )
}