import React from 'react';
import moment from 'moment';

import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
}

////////// COMPONENT //////////
export default function ARIndex(props) {
  const cls = useStyles();

  const collect = (props) => {
    return props
  }

  const { invoices, selectedInvoiceId, setSelectedInvoiceId, buildInvoiceContextMenuActions, setTablePage } = props;

  const handleRowSelect = (invoiceId) => {
    setTablePage(0);
    setSelectedInvoiceId(invoiceId)
  }

  return (<>
    <div className={cls.root} onClick={props.handleDrawerClose}>
      {invoices && invoices.map(invoice => {

        // Set a consistent amount object that holds the totals
        var amount = {};

        // Valid records to calculate base totals
        const subtotalMoves = invoice.armoves.filter(item => item.due_amount > 0);
        const discountedMoves = subtotalMoves.filter(item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0);
        const disputedMoves = subtotalMoves.filter(item => item.disputed === true);
        const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);

        // Base totals from valid records
        amount.subtotal = round(subtotalMoves.length > 0 ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
        amount.discounted = round(discountedMoves.length > 0 ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current) : 0, 2);
        amount.disputed = round(disputedMoves.length > 0 ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0, 2);
        amount.paid = round(paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0, 2);

        // Calculate totals
        amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);

        const actions = buildInvoiceContextMenuActions ? buildInvoiceContextMenuActions(invoice, amount) : [];

        let selected;
        if (selectedInvoiceId !== null) selected = invoice.id === selectedInvoiceId;
        else selected = false;

        return (<React.Fragment key={invoice.id}>
          <ContextMenuTrigger
            id={`invoice-index-${invoice.id}-cm`}
            source={`invoice-index-${invoice.id}-cm`}
            holdToDisplay={1000}
            collect={collect}
            disableIfShiftIsPressed={true}
          >
            <div className={selected ?
              (invoice.status === `closed` ? cls.rowClosedActive : (invoice.status === `processing` ? cls.rowProcessActive : cls.rowAcquireActive)) :
              (invoice.status === `closed` ? cls.rowClosed : (invoice.status === `processing` ? cls.rowProcess : cls.rowAcquire))}
              onClick={() => handleRowSelect(invoice.id)}>
              <Grid container spacing={0} justify="space-between">
                <Grid item xs={8}>
                  <Typography variant="body2" display="inline" className={cls.txtLeft + " " + cls.txtBig + " " + cls.ellipse}>{invoice.customer.name}&nbsp;</Typography>
                  <Typography variant="body2" display="inline" className={cls.txtLeft + " " + cls.txtBigBold + " " + cls.ellipse}>#{invoice.id}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" className={cls.txtRight + " " + cls.txtBig + " " + cls.ellipse}>${amount.total.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={cls.txtLeft + " " + cls.txtSmall + " " + cls.ellipse}>{moment.utc(moment(invoice.start_datetime)).format('MM/DD/YY')} - {moment.utc(moment(invoice.end_datetime)).format('MM/DD/YY')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {invoice.armoves.length > 0 && amount.discounted > 0 ?
                    <Tooltip disableFocusListener title={`Has Discounts`}>
                      <Icon className={cls.indexIcon}>local_offer</Icon>
                    </Tooltip>
                    :
                    null}
                  {invoice.armoves.length > 0 && amount.disputed > 0 ?
                    <Tooltip disableFocusListener title={`Has Disputes`}>
                      <Icon className={cls.indexIcon}>announcement</Icon>
                    </Tooltip>
                    :
                    null}
                  {invoice.armoves.length > 0 && amount.paid > 0 && amount.paid < amount.total ?
                    <Tooltip disableFocusListener title={`Partially Paid`}>
                      <Icon className={cls.indexIcon}>star_half</Icon>
                    </Tooltip>
                    :
                    null}
                  {invoice.armoves.length > 0 && amount.paid > 0 && amount.paid >= amount.total ?
                    <Tooltip disableFocusListener title={`Fully Paid`}>
                      <Icon className={cls.indexIcon}>star</Icon>
                    </Tooltip>
                    :
                    null}
                </Grid>
              </Grid>
            </div>
            <ContextMenu id={`invoice-index-${invoice.id}-cm`} style={{ boxShadow: '2px 4px 12px #00000050' }}>
              {actions.map(action => (!action.hide &&
                <MenuItem data={{ action: action, ...action.data }} onClick={action.handler} key={`invoice-index-${action.name}`}>
                  {action.label}
                </MenuItem>
              ))}
            </ContextMenu>
          </ContextMenuTrigger>
        </React.Fragment>)
      })}
    </div>
    <div style={{ width: '100%', height: '4px' }} />
    <Typography style={{ width: '100%', padding: '0 8px' }} variant="caption">*All datetimes are presented in UTC</Typography>
    <div style={{ width: '100%', height: '12px' }} />
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    background: '#fff',
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowAcquire: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: '#fff',
    color: theme.palette.text.disabled,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
  },
  rowAcquireActive: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.secondary.light,
    color: '#fff',
    transition: '0.1s',
  },
  rowProcess: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: '#fff',
    color: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
  },
  rowProcessActive: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.error.light,
    color: '#fff',
    transition: '0.1s',
  },
  rowClosed: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: '#fff',
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
  },
  rowClosedActive: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.primary.main,
    color: '#fff',
    transition: '0.1s',
  },
  txtLeft: {
    textAlign: 'left',
  },
  txtRight: {
    float: 'right',
    textAlign: 'right',
  },
  txtBig: {
    fontSize: '16px',
  },
  txtBigBold: {
    verticalAlign: 'top',
    marginTop: theme.spacing(0.25),
    fontSize: '12px',
    fontWeight: 600,
  },
  txtSmall: {
    fontSize: '12px',
  },
  ellipse: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  indexIcon: {
    float: 'right',
    textAlign: 'right',
    fontSize: '16px',
  },
}));