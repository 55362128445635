import 'date-fns';
import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import LocationsTableView from './locations/LocationsTableView';
import fragments from '../utils/graphQL/fragments';
import { GlobalContext } from '../../global-context';

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
})

const GET_LOCATIONS = gql`
  {
    locations(
      order_by: {
        updatedat: desc
      }, 
      where: {
          active: {
            _eq: 1
          }
      }
    ) {
      ...Location
    }
  }
  ${fragments.location}
`;

const LOCATIONS_SUBSCRIPTION = gql`
  subscription {
    locations(
      order_by: {
        updatedat: desc
      }, 
      where: {
          active: {
            _eq: 1
          }
      }
    ) {
      ...Location
    }
  }
  ${fragments.location}
`;

class Locations extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          { this.context && this.context.userIsAuthenticated() && (
            <Query query={GET_LOCATIONS} onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve locations data")}}>
              {({ subscribeToMore, ...result }) => {
                if (result.loading) return "Loading...";
                if (result.error) return `Error! ${result.error.message}`;
                return (
                  <LocationsTableView
                    subscribeToLocations={() => {
                      subscribeToMore({
                        document: LOCATIONS_SUBSCRIPTION,
                        updateQuery: (prev, { subscriptionData }) => {
                          if (!subscriptionData.data) return prev;
                          return { locations: subscriptionData.data.locations }
                        }
                      })
                    }}
                    locations={result.data.locations}
                  />
                );
              }}
            </Query>)
          }
        </Container>
      </div>
    )
  }
}

Locations.propTypes = {
  classes: PropTypes.object.isRequired,
};

Locations.contextType = GlobalContext

export default withStyles(styles)(Locations);