import React, { Component } from "react";
import { GlobalContext } from "../../global-context";
import { withStyles } from "@material-ui/core/styles";
import { Container, Typography, Button } from "@material-ui/core";
import { Redirect, withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
});

class PublicPage extends Component {
  componentDidMount = () => {
    if (this.context.userIsAuthenticated()) this.props.history.push("/moves");
  };
  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="lg">
          <Redirect to={"/authenticate"} />
      </Container>
    );
  }
}

PublicPage.contextType = GlobalContext;

export default withStyles(styles)(withRouter(PublicPage));
