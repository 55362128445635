import 'date-fns';
import React, { Component } from "react";
import { GlobalContext } from "../../global-context";
import { ApolloProvider } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import fragments from '../utils/graphQL/fragments';
import gql from "graphql-tag";
import { Grid, Select, MenuItem, OutlinedInput, FormControl, InputLabel, Button, TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Loading from '../utils/Loading';
import { Query } from "react-apollo";
import queries from '../utils/graphQL/queries';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InvoicePage from './invoices/InvoicePageNew';

let log = false

const GET_CUSTOMERS = gql`
  query {
    customers {
      ...Customer
    }
  }
  ${fragments.customer}
`;

const styles = theme => ({
  grid: {
    width: '100%',
    marginBottom: theme.spacing(7.5)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdown: {
    minWidth: 200
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  topMargin: {
    marginTop: theme.spacing(2)
  }
})

class Invoices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().startOf('month').subtract(1, 'month'),
      endDate: moment().startOf('month').subtract(1, 'day'),
      customers: [],
      selectedCustomerId: '',
      selectedCustomerObj: {},
      invoiceNumber: "",
      singlePageInvoice: false,
      addBOL: false,
      accountSuffix: '202605'
    }
  }

  componentWillMount = () => {
    if (this.context && this.context.userIsAuthenticated()) this.getCustomers()
  }

  // Return all availavle customers from Hasura
  getCustomers = () => {
    this.context.apolloClient.query({
      query: GET_CUSTOMERS
    }).then(res => {
      if (log) console.log("GET_CUSTOMERS -- response.data:", res.data);
      this.setState({ customers: res.data.customers })
    }).catch(err => {
      console.error(err);
      this.context.handleNotifications(true, "error", `Failed to retrieve customer data`);
    })
  }

  // Match the id selected to a Tookan driver obj and set to state
  handleCustomerSelect = event => {
    const id = event.target.value
    if (id === null || id === '' || !id) {
      this.setState({ selectedCustomerId: '', selectedCustomerObj: {} })
    } else {
      let foundCustomer = this.state.customers.find(o => o.id === id)
      this.setState({ selectedCustomerId: id, selectedCustomerObj: foundCustomer }, () => this.forceUpdate())
    }
  }

  handleDateSelect = name => event => this.setState({ [name]: moment(event) })

  assignPrintRef = (ref) => this.printRef = ref

  handleInputChange = name => event => this.setState({ [name]: event.target.value })

  handleCheckbox = name => this.setState({ [name]: !this.state[name] })

  render() {
    const { classes } = this.props;

    return (
      <ApolloProvider client={this.context.apolloClient}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classes.grid} justify="space-around" alignItems="center">
            <Grid item xs={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="customer-select-dropdown">
                  Customer
              </InputLabel>
                <Select
                  className={classes.dropdown}
                  margin="none"
                  value={this.state.selectedCustomerId}
                  onChange={this.handleCustomerSelect}
                  input={<OutlinedInput labelWidth={70} name="customer_id" id="customer-select-dropdown" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {this.state.customers.map(customer =>
                    <MenuItem key={'key' + customer.id} value={customer.id}>{customer.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                margin="normal"
                id="startDate"
                label="Start Date"
                value={this.state.startDate}
                onChange={this.handleDateSelect("startDate")}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                margin="normal"
                id="endDate"
                label="End Date"
                value={this.state.endDate}
                onChange={this.handleDateSelect("endDate")}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-name"
                label="Invoice Number"
                className={classes.textField}
                value={this.state.invoiceNumber}
                onChange={this.handleInputChange('invoiceNumber')}
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <ReactToPrint
                trigger={() =>
                  <Button size="small" variant="contained" color="primary" className={classes.button} disabled={!this.state.selectedCustomerId > 0}>
                    <PrintIcon className={classes.leftIcon} />
                    Print
                </Button>
                }
                pageStyles={'@page { size: auto;  margin: 4cm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
                content={() => this.printRef}
              />
            </Grid>
            <Grid item xs={7} className={classes.topMargin}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.singlePageInvoice}
                    onChange={() => this.handleCheckbox('singlePageInvoice')}
                    value={this.state.singlePageInvoice}
                    color="primary"
                  />
                }
                label="Generate singe-page-per-move invoices"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                style={{position: "relative", left: "8%"}}
                // disabled={this.state.selectedCustomerId !== 2} // Let all the homies use it
                id="outlined-account-suffix"
                label="Account Suffix"
                className={classes.textField}
                value={this.state.accountSuffix}
                onChange={this.handleInputChange('accountSuffix')}
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.addBOL}
                    onChange={() => this.handleCheckbox('addBOL')}
                    value={this.state.singlePageInvoice}
                    color="primary"
                  />
                }
                label="Add BOL images"
              />
            </Grid>
          </Grid>
          {/* <Grid container className={classes.grid} justify="space-around" alignItems="center"> */}

          {/* </Grid> */}
        </MuiPickersUtilsProvider>
        { this.context && this.context.userIsAuthenticated() && (
          <Query query={queries.movesByRange}
            variables={{ startDate: moment(this.state.startDate).format(), endDate: moment(this.state.endDate).add(1, "day").format() }}
            onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve moves data")}}>
            {({ ...result }) => {
              if (result.loading) return <Loading />;
              if (result.error) return `Error! ${result.error.message}`;
              // return Array.from(result.data.moves.filter(o => o.customer_id === this.state.selectedCustomerId) || []).map(move =>
              //   <h4>{move.id}</h4>
              // )
              return <InvoicePage
                moves={Array.from(result.data.moves || [])}
                customer={this.state.selectedCustomerObj}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                assignPrintRef={this.assignPrintRef}
                addBOL={this.state.addBOL}
                singlePageInvoice={this.state.singlePageInvoice}
                invoiceNumber={this.state.invoiceNumber}
                accountSuffix={this.state.accountSuffix}
              />

              // return <div
              //   moves={Array.from(result.data.moves || [])}
              //   customer={this.state.selectedCustomerObj}
              //   startDate={this.state.startDate}
              //   endDate={this.state.endDate}
              //   assignPrintRef={this.assignPrintRef}
              // />
            }}
          </Query>)
        }
      </ApolloProvider >
    )
  }
}

Invoices.contextType = GlobalContext;

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Invoices);