import React from 'react';

import { makeStyles, Grid, Paper, Typography } from "@material-ui/core";

import FaqCard from './FaqCard';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  head: {
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}));

////////// COMPONENT //////////
export default function FaqTables(props) {
  const cls = useStyles();
  const { allFaqs, dealerFaqs, driverFaqs, setModalData, setModalRender } = props;

  return (<>
    <Grid container spacing={2}>
      <Grid item md={4} sm={12} xs={12}>
        {allFaqs && <>
          <Paper elevation={4} className={cls.paper}>
            <Typography className={cls.head}>All FAQs</Typography>
            <Grid container spacing={2}>
              {allFaqs.map(f => <FaqCard key={f.id} faq={f} setModalData={setModalData} setModalRender={setModalRender} />)}
            </Grid>
          </Paper>
        </>}
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        {dealerFaqs && <>
          <Paper elevation={4} className={cls.paper}>
            <Typography className={cls.head}>Dealer FAQs</Typography>
            <Grid container spacing={2}>
              {dealerFaqs.map(f => <FaqCard key={f.id} faq={f} setModalData={setModalData} setModalRender={setModalRender} />)}
            </Grid>
          </Paper>
        </>}
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        {driverFaqs && <>
          <Paper elevation={4} className={cls.paper}>
            <Typography className={cls.head}>Driver FAQs</Typography>
            <Grid container spacing={2}>
              {driverFaqs.map(f => <FaqCard key={f.id} faq={f} setModalData={setModalData} setModalRender={setModalRender} />)}
            </Grid>
          </Paper>
        </>}
      </Grid>
    </Grid>
  </>)
}