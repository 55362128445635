import React, { useContext, useState } from "react";
import moment from 'moment';
import { makeStyles, Container, Typography, Button } from "@material-ui/core";
import Loading from '../utils/Loading';
import { Query } from "react-apollo";
import { GlobalContext } from "../../global-context";
import gql from "graphql-tag";
import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable'
import ARMoveDetail from './ARInvoices/ARMoveDetail';
import Modal from "../reusable/Modal";

////////// TOOLS //////////
const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
}

////////// COMPONENT //////////
export default function Disputes(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [modalRender, setModalRender] = useState(null);
  const [modalData, setModalData] = useState(null);

  const applyFilters = (rows) => {
    if (!search || search.length < 1) return rows
    else {
      return rows.filter(row =>
        (row.move.customer.name && row.move.customer.name.toLocaleLowerCase().includes(search)) ||
        (row.move.id && (row.move.id + ``).toLocaleLowerCase().includes(search)) ||
        (row.move.reference_num && row.move.reference_num.toLocaleLowerCase().includes(search)) ||
        (row.dispute_reason && row.dispute_reason.toLocaleLowerCase().includes(search))
      )
    }
  }

  const buildARMoveContextMenuActions = armove => {
    return [
      { name: `move-details`, label: `View\xa0Move\xa0Details`, id: armove.move_id, handler: handleContextMenuClick },
      { name: `edit-move`, label: `Edit\xa0Move`, data: { armove: armove }, handler: handleContextMenuClick },
    ]
  }

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `move-details`) {
      props.history.push(`/moves/${data.action.id}`);
      return
    }
    if (data.action.name === 'edit-move') {
      props.history.push(`/moves/${data.armove.move.id}/edit`)
      return
    }
  }

  const handleReview = (armove) => {
    console.log('handleReview(armove): ', armove)
    setModalData(armove);
    setModalRender(`review`);
  }

  return (
    <div className={cls.root}>
      <Modal open={modalRender !== null} data={modalData} setData={setModalData} render={modalRender} setRender={setModalRender} />
      { ctx && ctx.userIsAuthenticated() && (
        <Query
          query={GET_DISPUTED_ARMOVES(`query`)}
          onError={err => ctx.handleNotifications(true, "error", "Subscription failed to retrieve disputed ARMoves: " + err.toString())}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <Loading />
            if (error) {
              console.log(`Error getting disputed armoves:`, error);
              return (
                <div className={cls.rootTable}>
                  <Container maxWidth="sm">
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR GETTING DISPUTED MOVES</Typography>
                    </div>
                  </Container>
                </div>
              )
            }
            if (data) {
              let filteredData = applyFilters(data.armoves)
              const headers = [
                { id: `CUSTOMER`, alignLeft: true, numeric: false, label: `Customer` },
                { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
                { id: `REF_NUM`, alignLeft: true, numeric: true, label: `Ref\xa0#` },
                { id: `MOVE_DATE`, alignLeft: true, numeric: true, label: `Move\xa0Date` },
                { id: `DISPUTE`, alignLeft: true, numeric: false, label: `Dispute\xa0Reason` },
                { id: `DISCOUNT`, alignLeft: false, numeric: true, label: `Discount` },
                { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
                { id: `REVIEW`, alignLeft: false, numeric: false, label: `` },
              ]
              const rows = filteredData.map(armove => {
                return {
                  CUSTOMER: armove.move.customer.name ? armove.move.customer.name : `N/A`,
                  MOVE_ID: armove.move.id,
                  REF_NUM: armove.move.reference_num ? armove.move.reference_num : `N/A`,
                  MOVE_DATE: moment.utc(moment(armove.billable_datetime)).format(`MM/DD/YYYY`),
                  DISPUTE: armove.disputed ? armove.dispute_reason : `N/A`,
                  DISCOUNT: armove.move.discount_amount ? `$${armove.move.discount_amount.toFixed(2)}` : (armove.discount_amount === 0 ? `$0.00` : `N/A`),
                  TOTAL: armove.due_amount ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}` : (armove.due_amount === 0 ? `$0.00` : `N/A`),
                  REVIEW: <Button className={cls.reviewButton} variant="outlined" onClick={() => handleReview(armove)}>Review</Button>,
                  armove: armove
                }
              })
              return (<>
                <div className={cls.rootTable}>
                  {data.armoves && data.armoves.length > 0 ?
                    <Container className={cls.table} maxWidth="lg">

                      <AccordianTable
                        title={`${data.armoves.length} Disputed Moves`}
                        size={`small`}
                        headers={headers}
                        rows={rows}
                        actions={[]}
                        search={search}
                        order={order}
                        orderBy={orderBy}
                        tablePage={tablePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        setSearch={setSearch}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        setTablePage={setTablePage}
                        setRowsPerPage={setRowsPerPage}
                        setExpandedRowId={setExpandedRowId}
                        refetch={refetch}
                        refreshPersistAs="disputes"
                      >
                        {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                          .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                          .map(row => (
                            <AccordianRow
                              key={`disputed-armove-${row.armove.id}`}
                              rowId={row.armove.id}
                              expandedRowId={expandedRowId}
                              setExpandedRowId={setExpandedRowId}
                              columns={[
                                { align: 'left', value: row.CUSTOMER },
                                { align: 'left', value: row.MOVE_ID },
                                { align: 'left', value: row.REF_NUM },
                                { align: 'left', value: row.MOVE_DATE },
                                { align: 'left', value: row.DISPUTE },
                                { align: 'right', value: row.DISCOUNT },
                                { align: 'right', value: row.TOTAL },
                                { align: 'right', value: row.REVIEW, preventClick: true },
                              ]}
                              actions={buildARMoveContextMenuActions(row.armove)}
                              className={expandedRowId === row.armove.id ? cls.rowActive : cls.row}
                            >
                              <ARMoveDetail key={`armovedetail-${row.armove.id}`} row={row} armove={row.armove} actions={buildARMoveContextMenuActions(row.armove)} />
                            </AccordianRow>
                          ))}
                      </AccordianTable>
                    </Container>
                    :
                    <Container maxWidth="sm">
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO DISPUTED MOVES FOUND</Typography>
                      </div>
                    </Container>
                  }
                </div>
              </>)
            }
            else {
              return (<>
                <div className={cls.rootTable}>
                  <Container maxWidth="sm">
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO DISPUTED MOVES FOUND</Typography>
                    </div>
                  </Container>
                </div>
              </>)
            }
          }}
        </Query>)
      }
    </div>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: '#ffa0a0',
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowActive: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ff8080',
    color: '#fff',
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: '#ff8080',
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  reviewButton: {
    zIndex: 1001,
    margin: '6px 0',
    backgroundColor: '#ff8080',
    color: '#fff',
    "&:hover": {
      backgroundColor: '#ff4040',
      color: '#fff',
    },
  },
}));

////////// QUERIES //////////
const GET_DISPUTED_ARMOVES = (type = 'query') => gql`
${type} get_armoves {
  armoves(where: {
    disputed: {_eq: true}, move: {disputed: {_eq: true}}
  }, order_by: {id: desc}) {
    id
    move_id
    arevent_id
    billable_datetime
    discount_amount
    discount_reason
    disputed
    dispute_reason
    due_amount
    paid_amount
    notes
    author
    accounting_item_id
    invoice {
      id
      accounting_num
      customer {
        id
        accounting_id
      }
    }
    move {
      id
      class
      chargeable
      customer_id
      delivery_stop_id
      status
      delivery_arrived
      delivery_started
      delivery_successful
      discount_amount
      disputed
      pickup_arrived
      pickup_started
      pickup_successful
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_odometer
      vehicle_stock
      vehicle_vin
      vehicle_year
      reference_num
      tags
      lane {
        id
        description
        distance_miles
        dealer_base_discount
        dealer_stranded_discount
        tolls
        delivery {
          id
          address
          name
        }
        pickup {
          id
          address
          name
        }
      }
      raterule {
        id
        rate
        type
      }
      customer {
        id
        name
        address
        billing_frequency
        payment_terms
        auto_pay
      }
    }
    details {
      id
      name
      notes
      amount
    }
    revisions(order_by: {revision: desc}) {
      id
      revision
      due_amount
      discount_amount
      discount_reason
      disputed
      dispute_reason
      details {
        id
        name
        notes
        amount
      }
    }
  }
}
`;